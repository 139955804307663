import BaseStore from "../../common/stores/BaseStore.jsx";
import ReportConstant from "../constants/ReportConstant.jsx";
import ReportService from "../services/ReportService.jsx";
import moment from "moment";

class ChildrenAgeGroupReportStore extends BaseStore {
  constructor() {
    super();
    this.resetReport()
    this.subscribe(() => this.handler.bind(this))
  }

  resetReport () {
    this.errorMessage = null
    this.loading = true

    this.reportDTO = []

    this.surveyData = []

    this.liveWith = 'PARENT'

    this.activeBarChart = ReportConstant.CHART_BAR_VIEW.DAY

    this.barChartData = {}

    this.activeTable = ReportConstant.TABLE_TYPE.SURVEY_DATA_LIST

    this.totalSurveyData = 0

    this.currentPageSurveyData = 1

    this.selectedStartDate = moment().subtract(4, 'months')

    this.selectedEndDate = moment()
  }

  getChildrenAgeGroupData() {
    return this.reportDTO
  }

  isLoading () {
    return this.loading
  }

  getActiveBarChart() {
    return this.activeBarChart
  }

  getActiveTable() {
    return this.activeTable
  }

  getSurveyData() {
    return this.surveyData
  }

  getBarChartData() {
    return this.barChartData
  }

  getLiveStatus() {
    return this.liveWith
  }

  getcurrentpage() {
    return this.currentPageSurveyData
  }

  getTotal() {
    return this.totalSurveyData
  }

  handler (action) {
    switch (action.actionType) {
      case ReportConstant.LOAD:
        this.loading = true
        this.emitChange()
        break
      case ReportConstant.ACTION_TYPE.SHOW_CHILDREN_AGE_GROUP_REPORT:
        this.errorMessage = null
        this.reportDTO = action.data
        this.loading = false
        this.emitChange()
        break
      case ReportConstant.ACTION_TYPE.SHOW_CHILDREN_SURVEY_DATA:
        this.errorMessage = null
        this.surveyData = action.data.surveyData
        this.totalSurveyData = action.data.total
        this.currentPageSurveyData = action.data.currentPage
        this.loading = false
        this.emitChange()
        break
      case ReportConstant.ACTION_TYPE.SHOW_CHILDREN_AGE_GROUP_CHART_REPORT:
        this.errorMessage = null
        this.barChartData = action.data
        this.loading = false
        this.emitChange()
        break
      case ReportConstant.ACTION_TYPE.CHANGE_BAR_CHART_VIEW:
        this.activeBarChart = action.data
        this.emitChange()
        break
      case ReportConstant.ACTION_TYPE.CHANGE_DATE_RANGE:
        this.selectedStartDate = action.data.startDate
        this.selectedEndDate = action.data.endDate
        this.emitChange()
        break
      case ReportConstant.ERROR:
        this.errorMessage = action.message
        this.loading = false
        this.emitChange()
        break
      default:
        break
    }
  }
}

const childrenAgeGroupReportStore = new ChildrenAgeGroupReportStore()

export default childrenAgeGroupReportStore
