/**
 * Created by PhuNH on 17/1/7.
 */
import React from 'react'

export default class SearchBoxComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = this._getState();
        this.typingTimer = null;
        this.typingInterval = this.props.delayInputTiming || 200;
    }

    _getState() {
        return {
            keyword: ''
        };
    }

    _handleKeyDown() {
        clearTimeout(this.typingTimer);
    }

    _handleKeyUp(event) {
        this.setState({
            keyword: event.target.value
        });
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(this._changeKeyword.bind(this), this.typingInterval);
    }

    _handleKeyPress(event) {
        if (event.key === 'Enter') {
            this._changeKeyword(event);
        }
    }

    _changeKeyword() {
        this.props.changeKeyword(this.state.keyword);
    }

    shouldComponentUpdate() {
        return false;
    }

    render() {
        return (
            <div className="row">
                <div className="pull-right search-product col-md-4">
                    <div className="form-group is-empty">
                        <input className="form-control" type="text" placeholder={this.props.placeholder}
                               onKeyPress={this._handleKeyPress.bind(this)} onKeyDown={this._handleKeyDown.bind(this)}
                               onKeyUp={this._handleKeyUp.bind(this)}/>
                        <span className="material-input"/>
                    </div>
                    <button className="btn btn-primary" onClick={this._changeKeyword.bind(this)}>
                        <i className="fa fa-search"/>
                    </button>
                </div>
            </div>
        )
    }
}