/**
 * Created by hoangnv on 1/9/17.
 */

import React from 'react';
import PropTypes from 'prop-types';

export default class ToggleLoadingButton extends React.Component {

  constructor(props) {
    super();
    this.state = this.getState(props);
    this.onToggleButton = this.onToggleButton.bind(this);
  }

  getState(props) {
    return {
      isLoading: props.isLoading,
      btnActiveClass: props.isLoading ?
          props.loadingBtnClass + ' running' :
          props.btnClass,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this.getState(nextProps));
  }

  onToggleButton() {
    let isLoading = !this.state.isLoading;
    this.setState({
      isLoading: isLoading,
      btnActiveClass: isLoading ?
          this.props.loadingBtnClass + ' running' :
          this.props.btnClass,
    });
    if (isLoading) {
      this.props.onStartLoading && this.props.onStartLoading();
    } else {
      this.props.onStopLoading && this.props.onStopLoading();
    }
  }

  render() {
    return (
        <div className={this.state.btnActiveClass}
             onClick={this.onToggleButton} disabled={this.props.disabled}>
          {
            this.state.isLoading ?
                this.props.loadingBtnLabel :
                this.props.btnLabel
          }
          <div className={this.props.loadingIconClass}></div>
        </div>
    );
  }
}

ToggleLoadingButton.propTypes = {
  btnClass: PropTypes.string,
  loadingBtnClass: PropTypes.string,
  loadingIconClass: PropTypes.string,
  btnLabel: PropTypes.string,
  loadingBtnLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  onStartLoading: PropTypes.func,
  onStopLoading: PropTypes.func,
};