/**
 * Created by PhuNH on 17/1/7.
 */
import React from 'react'
import DesignTableRowComponent from './DesignTableRowComponent.jsx'

export default class DesignTableComponent extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className="tbl-admin tbl-template">
                <table className="table table-striped table-hover table-bordered table-list">
                    <thead>
                    <tr>
                        <th className="idCol">デザインID</th>
                        <th>サムネイル</th>
                        <th>EC ID</th>
                        <th>作成者</th>
                        <th className="dateCol">作成日時</th>
                        <th className="dateCol">更新日時</th>
                        <th className="statusCol">ステータス</th>
                        <th className="actionCol">アクション</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.designRows}
                    </tbody>
                </table>
            </div>
        )
    }

    get designRows() {
        let designs = this.props.designs;
        if (designs.length > 0)
            return (designs.map(function(design) {
                return <DesignTableRowComponent design={design} key={design.id}/>
            }));
        else
            return <tr>
                <td colSpan="8">{ this.props.designFilter.keyword ? <span>検索結果は0件です。</span> : <span>デザインは0件です。</span> }</td>
            </tr>
    }
}