/**
 * Created by thangkc on 20/09/2016.
 */
import React from 'react';
import UserConstant from "../../../user/constants/UserConstant.jsx"
import DateTimeFormat from "../../../common/components/DateTimeFormat.jsx"
import OrderStatusFormat from "../../../common/components/order/OrderStatusFormat.jsx"
import OrderConstant from "../../constants/OrderConstant.jsx"

export default class OrderLogInfoComponent extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className="col-md-6">
                <div className="process-history">
                    <h4>処理履歴</h4>
                    <dl>
                        <dt>■&#12288;デザインステータス</dt>
                        <dd>{this.logStatus}</dd>
                    </dl>
                    <dl>
                        <dt>■&#12288;メール送信履歴</dt>
                        <dd>{this.logMail}</dd>
                    </dl>
                    <dl>
                        <dt>■&#12288;同期履歴</dt>
                        <dd>{this.logSynchronization}</dd>
                    </dl>
                    <dl>
                        <dt>■&#12288;Design</dt>
                        <dd>{this.logDeleteDesign}</dd>
                    </dl>
                    <dl>
                        <dt>■&#12288;領収発行履歴</dt>
                        <dd>{this.logInvoice}</dd>
                    </dl>
                </div>
            </div>)
    }

    createRole(role) {
        switch (role){
            case UserConstant.ROLE.USER:  return "会員";
            case UserConstant.ROLE.GUEST: return "ゲスト";
            case UserConstant.ROLE.ADMIN: return "運営者";
            case UserConstant.ROLE.BOOT:  return "自動";
            case UserConstant.ROLE.SYNC:  return "自動";
            default: return '';
        }
    }

    get logStatus() {
        var logs = this.props.order.logs || [];
        var createRole = this.createRole;
        return logs.filter(function (log) {
            return log.actionType == "STATUS"
        }).sort(function (a, b) {
            return  b.idInt - a.idInt
        }).map(function (log, index, arr) {
            var array = log.title.split(':');
            var status = array[0];
            var paddingZero = {
                padding: '0px'
            };
            var note = null;
            if((index == (arr.length - 1) || status == OrderConstant.STATUS.PAUSE || status == OrderConstant.STATUS.DRAFT) && array[1]){
                note = '(' + array[1] + ')';
            }else{
                note = null;
            }
            return (
                <p key={log.id}>・<span className="status">
                    <OrderStatusFormat data={status}/>
                    <span style={paddingZero}>{note}</span>
                </span>
                    <span className="name">{createRole(log.creatorRole)}</span>
                    <DateTimeFormat className="date" data={log.creatingTime}/>
                </p>
            )
        })
    }

    get logInvoice() {
      var logs = this.props.invoiceLog
      return logs.sort(function (a,b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }).map(function (log) {
        return (
            <p key={log.identifier}>
              ・<span style={{width: '30%'}}>{log.name}</span>
              <span style={{width: '30%'}}>{log.describe}</span>
              <DateTimeFormat className="date" data={log.createdAt}/>
            </p>
        )
      })
    }

    get logMail() {
        var logs = this.props.order.logs || [];
        return logs.filter(function (log) {
            return log.actionType == "MAIL"
        }).sort(function (a, b) {
            return b.idInt - a.idInt
        }).map(function (log) {
            return (
                <p key={log.id}>・<span className="mail-title"><b>{log.title}</b></span>
                    <DateTimeFormat className="date" data={log.creatingTime}/>
                </p>
            )
        })
    }

    get logSynchronization(){
        var logs = this.props.order.logs || [];
        return logs.filter(function(log){
            return log.actionType == 'SYNCHRONIZE' && (log.creatorRole == 'ADMIN' || log.creatorRole == 'SYNC')
        }).sort(function(a, b){
            return b.idInt - a.idInt
        }).map(function(log){
            return (
                <p key={log.id}>・<span className="synchronization">
                    <b>
                    {log.creatorRole == 'SYNC' ? 'AUTO' : log.creator.name}
                    </b></span>
                    <DateTimeFormat className="date" data={log.creatingTime}/>
                </p>
            )
        })
    }

    get logDeleteDesign() {
        let logs = this.props.order.logs || [];
        let createRole = this.createRole;
        return logs.filter(function (log) {
            return log.actionType == "DELETE"
        }).sort(function (a, b) {
            return b.idInt - a.idInt
        }).map(function (log) {
            return (
              <p key={log.id}>・<span className="status"><b>{log.title}</b></span>
                  <span className="name">{createRole(log.creatorRole)}</span>
                  <DateTimeFormat className="date" data={log.creatingTime}/>
              </p>
            )
        })
    }
}
