import BaseStore from "../../common/stores/BaseStore.jsx";
import OrderConstant from "../constants/OrderConstant.jsx";
import DesignConstant from "../../design/constants/DesignConstant.jsx";
import OrderService from "../services/OrderService.jsx";

class OrderStore extends BaseStore {
  constructor() {
    super();
    this.ordersDTO = {
      currentPage: 1,
      maxPage: 0,
      orders: []
    };
    this.orderFilter = {};
    this.resetOrderFilter();
    this.orderDetail = {
      status: "",
      orderer: {},
      receiver: {},
      createdUser: {},
      products: [],
      designs: [],
      invoiceCreateable: false,
      httpCode: {
        changeStatusCode: 200
      },
      messages: {
        changeStatusMessage: ""
      }
    };
    this.requestResult = {
      httpCode: {},
      messages: {},
      errorCodes: {},
      data: {
        verifyOrderPayment: {}
      }
    };
    this.subscribe(() => this.handler.bind(this));
    this.invoiceLog = [];
  }

  handler(action) {
    switch (action.actionType) {
      case OrderConstant.ACTION_TYPE.LOADED_ORDERS:
        this.ordersDTO = action.ordersDTO;
        this.orderFilter = action.orderFilter;
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.DETAIL:
        this.orderDetail = action.order;
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.INVOICE_LOG:
        this.invoiceLog = action.log;
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.SHOW_ORDER_DETAIL:
        this.viewDetail = true;
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.HIDE_ORDER_DETAIL:
        this.viewDetail = false;
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.ON_CHANGE_KEYWORD:
        this.orderFilter.currentPage = 1;
        this.orderFilter.keyword = action.keyword;
        OrderService.loadOrders(this.orderFilter);
        break;
      case OrderConstant.ACTION_TYPE.ON_SEARCH_STATUS:
        this.orderFilter.currentPage = 1;
        this.orderFilter.status = action.status;
        this.orderFilter.processMethod = action.processMethod;
        OrderService.loadOrders(this.orderFilter);
        break;
      case OrderConstant.ACTION_TYPE.ON_SEARCH_PAGE:
        this.orderFilter.currentPage = action.currentPage;
        OrderService.loadOrders(this.orderFilter);
        break;
      case OrderConstant.ACTION_TYPE.CHANGE_INVOICE_CREATABLE_SUCCESS:
        OrderService.loadOrders(this.orderFilter);
        break;
      case DesignConstant.ACTION_TYPE.DELETE:
        this.deleteDesign(action.deletedDesigns);
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.ON_CHANGE_STATUS:
        this.orderDetail = action.order;
        this.requestResult.httpCode.orderStatusChange = action.httpCode;
        this.requestResult.messages.orderStatusChange = action.message;
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.ON_CHANGE_EC_STATUS:
        this.orderDetail = action.orderForEc;
        this.setChangeStatusCodeAndMessageForEc(action.httpCodeForEc, action.messageForEc);
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.ON_ROLLBACK_CHANGE_STATUS:
        this.requestResult.httpCode.orderStatusChange = action.httpCode;
        this.requestResult.messages.orderStatusChange = action.message;
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.SYNCHRONIZE_ORDER_SUCCESSFULLY:
        this.orderDetail = action.order;
        this.requestResult.httpCode.orderSynchronization = action.httpCode;
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.SYNCHRONIZE_ORDER_UNSUCCESSFULLY:
        this.requestResult.httpCode.orderSynchronization = action.httpCode;
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.CREATE_IMAGEMAGIC_ORDER:
        this.requestResult.httpCode.createImageMagicOrder = action.httpCode;
        this.requestResult.errorCodes.createImageMagicOrder = action.errorCode;
        if (action.httpCode === 200) {
          this.requestResult.data.createImageMagicOrder = {};
          this.orderDetail = action.data;
        } else if (action.errorCode === OrderConstant.EXCEPTIONS.ORDER_IS_CREATING_EXCEPTION) {
          this.requestResult.data.createImageMagicOrder = {};
          this.orderDetail = action.data;
        } else {
          this.requestResult.data.createImageMagicOrder = action.data;
        }
        switch (action.httpCode) {
          case 200:
            this.orderDetail = action.data.order;
            this.requestResult.data.createImageMagicOrder = {};
            break;
          case 403:
            this.orderDetail = action.data.order;
            this.requestResult.data.createImageMagicOrder = action.data;
            break;
          default:
            this.requestResult.data.createImageMagicOrder = action.data;
            break;
        }
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.VERIFY_ORDER_PAYMENT:
        this.requestResult.httpCode.verifyOrderPayment = action.httpCode;
        this.requestResult.data.verifyOrderPayment = {
          paymentStatus: action.paymentStatus
        };
        this.emitChange();
        break;
      case OrderConstant.ACTION_TYPE.ON_UPDATE_SHIP_INFO:
        this.orderDetail = action.order;
        this.requestResult.httpCode.orderShipInfoChange = action.httpCode;
        this.requestResult.messages.orderShipInfoChange = action.message;
        this.emitChange();
        break;
    }
  }

  setChangeStatusCodeAndMessageForEc(httpCodeForEc, messageForEc) {
    if (this.orderDetail.messages) {
      this.orderDetail.messages.changeStatusMessageForEc = messageForEc;
    } else {
      this.orderDetail.messages = {
        changeStatusMessageForEc: messageForEc
      }
    }
    if (this.orderDetail.httpCode) {
      this.orderDetail.httpCode.changeStatusCodeForEc = httpCodeForEc;
    } else {
      this.orderDetail.httpCode = {
        changeStatusCodeForEc: httpCodeForEc
      }
    }
  }

  deleteDesign(designs) {
    let that = this;
    Array.from(designs).forEach(function (design) {
      let index = that.orderDetail.designs.indexOf(design);
      if (index !== -1) that.orderDetail.designs.splice(index, 1);
    })
  }

  getOrdersDTO() {
    return this.ordersDTO;
  }

  getOrderFilter() {
    return this.orderFilter;
  }

  resetOrderFilter() {
    this.orderFilter = {
      currentPage: 1,
      pageSize: 10,
      status: OrderConstant.STATUS.ALL,
      keyword: '',
      processMethod: ''
    };
  }

  getOrderDetail() {
    return this.orderDetail;
  }

  getInvoiceLog() {
    return this.invoiceLog;
  }

  getViewDetail() {
    return this.viewDetail;
  }

  getCreatingImageMagicOrderResult() {
    let result = {
      httpCode: this.requestResult.httpCode.createImageMagicOrder,
      errorCode: this.requestResult.errorCodes.createImageMagicOrder,
      data: this.requestResult.data.createImageMagicOrder
    };
    this.resetCreatingImageMagicOrderResult();
    return result;
  }

  resetCreatingImageMagicOrderResult() {
    this.requestResult.httpCode.createImageMagicOrder = null;
    this.requestResult.errorCodes.createImageMagicOrder = null;
    this.requestResult.data.createImageMagicOrder = null;
  }

  getOrderStatusChangeResult() {
    let result = {
      httpCode: this.requestResult.httpCode.orderStatusChange,
      message: this.requestResult.messages.orderStatusChange
    };
    this.resetOrderStatusChangeResult();
    return result;
  }

  resetOrderStatusChangeResult() {
    this.requestResult.httpCode.orderStatusChange = null;
    this.requestResult.messages.orderStatusChange = null;
  }

  getSynchronizingOrderResult() {
    let result = {
      httpCode: this.requestResult.httpCode.orderSynchronization
    };
    this.requestResult.httpCode.orderSynchronization = null;
    return result;
  }

  getOrderPaymentResult() {
    let result = {
      httpCode: this.requestResult.httpCode.verifyOrderPayment,
      paymentStatus: this.requestResult.data.verifyOrderPayment.paymentStatus
    };
    this.resetOrderPaymentResult();
    return result;
  }

  resetOrderPaymentResult() {
    this.requestResult.httpCode.verifyOrderPayment = null;
    this.requestResult.data.verifyOrderPayment = {};
  }

  getOrderShipInfoChangeResult() {
    let result = {
      httpCode: this.requestResult.httpCode.orderShipInfoChange,
      message: this.requestResult.messages.orderShipInfoChange
    };
    this.resetOrderShipInfoChangeResult();
    return result;
  }

  resetOrderShipInfoChangeResult() {
    this.requestResult.httpCode.orderShipInfoChange = null;
    this.requestResult.messages.orderShipInfoChange = null;
  }
}

const orderStore = new OrderStore();

export default orderStore;
