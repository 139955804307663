/**
 * Created by
 */
import React from 'react'
import AuthenticatedComponent from "../../common/components/AuthenticatedComponent.jsx"
import SearchBoxComponent from '../../common/components/SearchBoxComponent.jsx'
import OurPagingComponent from '../../common/components/paging/OurPagingComponent.jsx'
import CollabDesignTableComponent from './CollabDesignTableComponent.jsx'
import CollabDesignService from "../services/CollabDesignService.jsx"
import CollabDesignAction from '../actions/CollabDesignAction.jsx'
import CollabDesignStore from "../stores/CollabDesignStore.jsx"
import CollabDesignStatusBarComponent from "./CollabDesignStatusBarComponent.jsx";

export default AuthenticatedComponent(
    class CollabDesignListComponent extends React.Component {
        constructor() {
            super();
            this.state = this._getInitialState();
            this._onChange = this._onChange.bind(this);
        }

        _getInitialState() {
            CollabDesignStore._resetFilter();
            return this._getState();
        }

        _getState() {
            return {
                designDTO: CollabDesignStore._getDesignDTO(),
                designFilter: CollabDesignStore._getDesignFilter()
            };
        }

        componentDidMount() {
            CollabDesignService.load(CollabDesignStore._getDesignFilter());
            CollabDesignStore.addChangeListener(this._onChange);
        }

        componentWillUnmount() {
            CollabDesignStore.removeChangeListener(this._onChange);
        }

        _onChange() {
            this.setState(this._getState());
        }

        render() {
            return (
                <section id="CollabDesignListComponent">
                    <h2>デザイン一覧</h2>
                    <SearchBoxComponent changeKeyword={CollabDesignAction.changeKeyword} placeholder="デザインID、又はEC IDで検索"/>
                    <CollabDesignStatusBarComponent designDTO={this.state.designDTO} designFilter={this.state.designFilter}/>
                    <CollabDesignTableComponent designs={this.state.designDTO.designs} designFilter={this.state.designFilter}/>
                    <OurPagingComponent loadNewPage={CollabDesignAction.changePage} dto={this.state.designDTO}/>
                </section>
            );
        }
    }
)