import React from "react";
import OurPagingComponent from "../../../common/components/paging/OurPagingComponent.jsx";
import { Button } from "react-bootstrap/lib";
import FailedChangeStatusOrderStore from "../../stores/FailedChangeStatusOrderStore.jsx";
import FailedChangeStatusOrderAction from "../../actions/FailedChangeStatusOrderAction.jsx";
import FailedChangeStatusOrderTable from "./FailedChangeStatusOrderTable.jsx";

class FailedChangeStatusOrder extends React.Component {
    constructor() {
        super();
        this.state = this._getState()
        this._onChange = this._onChange.bind(this)
    }

    _onChange () {
        this.setState(this._getState())
    }

    _getState() {
        return {
            ordersDto: FailedChangeStatusOrderStore.getOrdersDTO(),
        }
    }

    componentDidMount() {
        FailedChangeStatusOrderAction.fetchOrders();
        FailedChangeStatusOrderStore.addChangeListener(this._onChange);
    }

    componentWillUnmount() {
        FailedChangeStatusOrderStore.removeChangeListener(this._onChange);
    }

    render() {
        const { ordersDto } = this.state
        const isDisableUpdateBtn = ordersDto.orders.length === 0 || !ordersDto.orders.find((order) => order.checked)
        return <div>
            <div className="flex-space-between">
                <Button
                    className="btn btn-raised btn-info"
                    onClick={FailedChangeStatusOrderAction.startChangeStatusOrder}
                    disabled={isDisableUpdateBtn}
                >
                    一括ステータス変更
                </Button>
            </div>
            <FailedChangeStatusOrderTable orders={this.state.ordersDto.orders}/>
            <OurPagingComponent loadNewPage={pageNumber => FailedChangeStatusOrderAction.changeFilter({page: pageNumber})}
                                dto={this.state.ordersDto}/>
        </div>
    }
}

export default FailedChangeStatusOrder