/**
 * Created by PhuNH on 16/11/9.
 */
import React from 'react';
export default class RakutenStatusFormat extends React.Component {
    constructor() {
        super();
    }

    render() {
        var renderOption = this.props.renderOption || false;
        if (renderOption) {
            return (<option value={this.props.data}>{this.statusStr}</option>)
        } else {
            return (<b>{this.statusStr}</b>)
        }
    }

    get statusStr() {
        var status = this.props.data.toLowerCase();
        switch (status) {
            case 'waiting':
                return '発送前入金待ち';
            case 'draft':
                return '発送前入金待ち';
            case 'url_released':
                return '発送前入金待ち';
            case 'creating':
                return '発送待ち';
            case 'shipped':
                return '発送後入金待ち';
            case 'pending':
                return '保留';
            case 'cancel':
                return 'キャンセル';
            default:
                return '';
        }
    }
}
