/**
 * Created by thangkc on 26/01/2016.
 */
import React from 'react';
import AuthenticatedComponent from "../../common/components/AuthenticatedComponent.jsx";
import UserStore from '../stores/UserStore.jsx';
import UserService from '../services/UserService.jsx';

import Griddle from "griddle-react";
import GriddlePagingComponent from "../../common/components/paging/GriddlePagingComponent.jsx";
import DateTimeFormat from "../../common/components/DateTimeFormat.jsx";

export default AuthenticatedComponent(
    class UserListComponent extends React.Component {
        constructor() {
            super();
            this.state = this._getState();
            this._onChange = this._onChange.bind(this);
        }

        _getState() {
            return {
                initData: UserStore.getUserList(),
                filterData: UserStore.getFilteredData(),
                searchValue: UserStore.getSearchValue()
            };
        }
        componentDidMount() {
            UserService.list();
            UserStore.addChangeListener(this._onChange);
        }

        componentWillUnmount() {
            UserStore.removeChangeListener(this._onChange);
        }
        render() {
            return (
                <section id="UserListComponent">
                    <h2>会員一覧</h2>
                    <div className="row">
                        <div className="pull-right search-product col-md-4">
                            <div className="form-group is-empty">
                                <input type="text" placeholder="ログインID、会員名、又はEメールで検索" className="form-control"
                                       onChange={ this.filterData.bind(this) } value={this.state.searchValue}/>
                                <span className="material-input"/>
                            </div>
                            <button type="submit" className="btn btn-primary"><i className="fa fa-search"/></button>
                        </div>
                    </div>
                    <div className="tbl-admin tbl-template">
                        <div className="total"><span>合計:</span><b>{this.state.initData.length}</b>件</div>
                        { this.state.filterData && this.state.initData.length > 0 ?
                            <Griddle
                                results={this.state.filterData}
                                showFilter={false}
                                columnMetadata={this.columnMeta()}
                                columns={["idStr", "accountId", "phoneNumber", "email", "name", "createdAt"]}
                                sortAscendingComponent={<span className="fa fa-sort-alpha-asc"/>}
                                sortDescendingComponent={<span className="fa fa-sort-alpha-desc"/>}
                                useCustomPagerComponent="true"
                                customPagerComponent={GriddlePagingComponent}
                                tableClassName="table table-striped table-hover table-bordered"
                                gridClassName="tbl-admin tbl-template"
                                showTableHeading="true"
                                resultsPerPage="10"
                                noDataMessage="検索結果は0件です。"
                            /> : ""
                        }
                    </div>
                </section>
            )
        }

        filterData(event) {
            event.preventDefault();
            const regex = new RegExp(event.target.value, 'i');
            const filtered = this.state.initData.filter(function (datum) {
                return (datum.name.search(regex) > -1 || datum.nameFurigana.search(regex) > -1 || datum.accountId.search(regex) > -1 || datum.email.search(regex) > -1) ;
            });
            UserStore.setSearchValue(event.target.value);
            UserStore.setFilteredData(filtered);
            this.setState({
                initData: this.state.initData,
                filterData: filtered,
                searchValue: UserStore.getSearchValue()
            });
        }

        _onChange() {
            this.setState(this._getState());
        }

        columnMeta() {
            return [
                {
                    columnName: "idStr",
                    displayName: "会員ID",
                    order: 5,
                    locked: false,
                    visible: true,
                    cssClassName: "idCol"
                },{
                    columnName: "accountId",
                    displayName: "ログインID",
                    order: 2,
                    locked: false,
                    visible: true
                },{
                    columnName: "phoneNumber",
                    displayName: "電話番号",
                    order: 3,
                    locked: false,
                    visible: true
                },{
                    columnName: "email",
                    displayName: "メールアドレス",
                    order: 4,
                    locked: false,
                    visible: true
                },{
                    columnName: "name",
                    displayName: "会員名",
                    order: 1,
                    locked: false,
                    visible: true,
                    customComponent: UserNameFormat
                }, {
                    columnName: "createdAt",
                    displayName: "作成日時",
                    order: 6,
                    locked: false,
                    visible: true,
                    cssClassName: "dateCol",
                    customComponent: DateTimeFormat
                }
            ]
        }
    }
)

class UserNameFormat extends React.Component {
    constructor() {
        super();
    }

    render() {
        function formatName(rowData) {
            return rowData.name + (rowData.nameFurigana != "" ? "(" + rowData.nameFurigana + ")" : "");
        }

        return (
            <b>
                {formatName(this.props.rowData)}
            </b>
        );
    }
}