/**
 * Created by Thangkc on 20/03/2018.
 */
import React from 'react'
import request from 'reqwest'
import when from 'when'
import EcProductCooperationMasterDataAction from '../actions/EcProductCooperationMasterDataAction.jsx'

class EcProductCooperationService {

  getProductCooperationMasterData () {
    when(request({
      url: '/api/rakuten/product-cooperation-master-data',
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          EcProductCooperationMasterDataAction.getProductCooperationMasterData(response.data)
          break
        default:
          break
      }
    }).catch(function () {
    })
  }

  addNewProductCooperationMasterDatas(productCooperationMasterDatas, siteId) {
    let self = this;
    when(request({
      url: '/api/rakuten/product-cooperation-master-data',
      method: 'POST',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
      data: {
        productCooperationMasterDatas: productCooperationMasterDatas.join(","),
        ecSiteId: siteId
      }
    })).then(function (response) {
      switch (response.code) {
        case 200:
          EcProductCooperationMasterDataAction.changeCooperationProductNewData({
            siteId: siteId,
            text: ''
          })
          EcProductCooperationMasterDataAction.newMessage(productCooperationMasterDatas.join(",") + "を追加されました。")
          self.getProductCooperationMasterData()
          break
        default:
          break
      }
    }).catch(function () {
    })
  }

  deleteProductCooperation(rPCMD) {
    let self = this;
    when(request({
      url: '/api/rakuten/product-cooperation-master-data/' + rPCMD.identify,
      method: 'DELETE',
      headers: {
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
      crossOrigin: true,
    })).then(function (response) {
      switch (response.code) {
        case 200:
          EcProductCooperationMasterDataAction.changeCooperationProductNewData()
          EcProductCooperationMasterDataAction.newMessage(rPCMD.ecProductNumber + "を削除されました。")
          self.getProductCooperationMasterData()
          break
        default:
          break
      }
    }).catch(function () {
    })
  }

  changeStatusProductCooperation(rPCMD) {
    let self = this;
    when(request({
      url: '/api/ec/product-cooperation-master-data/status/change/' + rPCMD.identify,
      method: 'PUT',
      headers: {
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
      crossOrigin: true,
    })).then(function (response) {
      switch (response.code) {
        case 200:
          EcProductCooperationMasterDataAction.changeCooperationProductNewData("")
          EcProductCooperationMasterDataAction.newMessage(rPCMD.ecProductNumber + "を変更されました。")
          self.getProductCooperationMasterData()
          break
        case 404:
          EcProductCooperationMasterDataAction.newMessage(rPCMD.ecProductNumber + "が存在しません。")
          self.getProductCooperationMasterData()
          break
        default:
          break
      }
    }).catch(function () {
    })
  }


  changeCooperationProductNewData(object){
    EcProductCooperationMasterDataAction.changeCooperationProductNewData(object)
  }

}

export default new EcProductCooperationService()
