import React from 'react'

export default class StudioAliceBaby1812TableRow extends React.Component {
  constructor () {
    super()
  }

  render () {
    return (
      <tr>
        <td>{this.props.campaign.idStr}</td>
        <td>{this.props.campaign.email}</td>
        <td>{this.props.campaign.birthday}</td>
        <td>{this.props.campaign.storeName}</td>
        <td>{this.props.campaign.iAccount}</td>
      </tr>
    )
  }
}
