/**
 * Created by thangkc on 2018/10/11.
 */
import React from 'react'
import request from 'reqwest'
import when from 'when'
import CampaignAction from '../actions/CampaignAction.jsx'
import LoginAction from '../../authentication/actions/LoginAction.jsx'

class CampaignService {

  alertError () {
    alert('不具合が発生しました！開発者に連絡していただくと幸いです。')
  }

  load (campaignFilter) {
    let url = '/api/campaign/theatre1810' + '?page=' +
      encodeURIComponent(campaignFilter.currentPage)
      + '&pageSize=' + encodeURIComponent(campaignFilter.pageSize)

    when(request({
      url: url,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          CampaignAction.load(response.data, campaignFilter)
          break
        case 401:
          LoginAction.loginUser({})
          break
        case 400:
          break
        default :
          break
      }
    })
  }

  loadStudioAliceBaby1812 (studioAliceBaby1812Filter) {
    let url = '/api/campaign/studioalicebaby1812' + '?page=' +
      encodeURIComponent(studioAliceBaby1812Filter.currentPage)
      + '&pageSize=' + encodeURIComponent(studioAliceBaby1812Filter.pageSize)

    when(request({
      url: url,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          CampaignAction.loadStudioAliceBaby1812(response.data, studioAliceBaby1812Filter)
          break
        case 401:
          LoginAction.loginUser({})
          break
        case 400:
          break
        default :
          break
      }
    })
  }

  exportCSVFile () {
    let self = this
    when(request({
      url: '/api/campaign/theatre1810/export',
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          CampaignAction.doneExportCSVFile()
          break
        case 401:
          LoginAction.loginUser({})
          break
        default:
          self.alertError()
          break
      }
    }).catch(function () {
      self.alertError()
    })
  }

  runZipFile () {
    let self = this
    when(request({
      url: '/api/campaign/theatre1810/zip',
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          CampaignAction.doneZipData()
          break
        case 401:
          LoginAction.loginUser({})
          break
        default:
          self.alertError()
          break
      }
    }).catch(function () {
      self.alertError()
    })
  }

  downloadFile () {
    let url = '/api/campaign/theatre1810/download'
    $('#idown').remove()
    $('<iframe>', {id: 'idown', src: url}).hide().appendTo('body')
  }


  exportCSVStudioAliceBaby1812File () {
    let self = this
    when(request({
      url: '/api/campaign/studioalicebaby1812/export',
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          CampaignAction.doneExportCSVStudioAliceBaby1812File()
          break
        case 401:
          LoginAction.loginUser({})
          break
        default:
          self.alertError()
          break
      }
    }).catch(function () {
      self.alertError()
    })
  }

  downloadStudioAliceBaby1812File () {
    let url = '/api/campaign/studioalicebaby1812/download'
    fetch(url,
      {
        headers: {
          'Csrf-Token': 'nocheck',
          'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
        },
      })
      .then(response => {
        let fileName = response.headers.get("content-disposition").split("''")[1]
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
        });
        //window.location.href = response.url;
      });
  }

}

export default new CampaignService()
