import React from 'react'

class ProductReportTable extends React.Component {

  constructor () {
    super()
  }

  render () {
    let listTitle = this.props.listTitle
    let products = this.props.products
    let total = 0
    return (
      <div className="product-report">
        <div className="left">
          <table className="table table-striped table-hover table-bordered"
                 width="200px">
            <thead>
            <tr>
              <th className="name-col">商品名</th>
            </tr>
            </thead>
            <tbody>
            {
              Object.keys(products).map(function (productName) {
                return (
                  <tr className="name-col" key={productName}>
                    <td>{productName}</td>
                  </tr>
                )
              })
            }

            <tr>
              <td className="sum-title"
                  colSpan={Object.keys(listTitle).length + 1}>合計：
              </td>
            </tr>

            </tbody>
          </table>
        </div>
        <div className="right">
          <table className="table table-striped table-hover table-bordered">
            <thead>
            <tr>
              <th className="name-col">商品名</th>
              {
                Object.keys(listTitle).map(function (key) {
                  return (
                    <th key={key}>
                      {listTitle[key]}
                    </th>
                  )
                })
              }
              <th className="count-col">数量</th>
            </tr>
            </thead>
            <tbody>
            {
              Object.keys(products).map(function (productName) {

                let productData = products[productName]
                let rowTotal = 0
                return (
                  <tr key={productName}>
                    <td className="name-col">{productName}</td>
                    {
                      Object.keys(listTitle).map(function (key) {
                        let product = productData[key]
                        let quantity = 0
                        if (product && product[0]['quantity']) {
                          quantity = product[0]['quantity']
                          total = total + quantity
                          rowTotal = rowTotal + quantity
                        }
                        return (
                          <td key={key}>
                            {
                              quantity
                            }
                          </td>
                        )
                      })
                    }
                    <td>{rowTotal}</td>
                  </tr>
                )
              })
            }

            <tr>
              <td className="sum-title"
                  colSpan={Object.keys(listTitle).length + 1}>
              </td>
              <td className="sum-value">{total}</td>
            </tr>

            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default ProductReportTable