import React from 'react'
class ChildrenAgeGroupReportTable extends React.Component {

  constructor (props) {
    super()
    this.state = this._getState(props);
    this.switchStatus = this.switchStatus.bind(this);
  }

  _getState(props) {
    return {
      status: props.status
    }
  }

  switchStatus() {
    let liveWith = this.state.status
    this.setState({status: liveWith === 'PARENT' ? 'GRAND' : 'PARENT'})
  }

  renderTotal() {
    let totalData = this.props.total
    let totalNoDup = this.props.totalNoDup
    var returnData = []
    if (totalNoDup && this.state.status === 'PARENT') returnData.push(
      <tr>
        <td>重複除外</td>
        <td>{totalNoDup.total}</td>
        <td>{totalNoDup.totalWithMama}</td>
        <td>{totalNoDup.pregnantWithMama}</td>
        <td>{totalNoDup.zeroYearsWithMama}</td>
        <td>{totalNoDup.oneYearWithMama}</td>
        <td>{totalNoDup.twoYearsWithMama}</td>
        <td>{totalNoDup.threeYearsWithMama}</td>
        <td>{totalNoDup.fourYearsWithMama}</td>
        <td>{totalNoDup.fiveYearsWithMama}</td>
        <td>{totalNoDup.sixYearsWithMama}</td>
        <td>{totalNoDup.sevenYearsWithMama}</td>
      </tr>
    )
    if (totalNoDup && this.state.status === 'GRAND') returnData.push(
      <tr>
        <td>重複除外</td>
        <td>{totalNoDup.total}</td>
        <td>{totalNoDup.totalWithGrand}</td>
        <td>{totalNoDup.pregnantWithGrand}</td>
        <td>{totalNoDup.zeroYearsWithGrand}</td>
        <td>{totalNoDup.oneYearWithGrand}</td>
        <td>{totalNoDup.twoYearsWithGrand}</td>
        <td>{totalNoDup.threeYearsWithGrand}</td>
        <td>{totalNoDup.fourYearsWithGrand}</td>
        <td>{totalNoDup.fiveYearsWithGrand}</td>
        <td>{totalNoDup.sixYearsWithGrand}</td>
        <td>{totalNoDup.sevenYearsWithGrand}</td>
      </tr>
    )
    if (totalData && this.state.status === 'PARENT') returnData.push(
      <tr>
        <td>重複あり</td>
        <td>{totalData.total}</td>
        <td>{totalData.totalWithMama}</td>
        <td>{totalData.pregnantWithMama}</td>
        <td>{totalData.zeroYearsWithMama}</td>
        <td>{totalData.oneYearWithMama}</td>
        <td>{totalData.twoYearsWithMama}</td>
        <td>{totalData.threeYearsWithMama}</td>
        <td>{totalData.fourYearsWithMama}</td>
        <td>{totalData.fiveYearsWithMama}</td>
        <td>{totalData.sixYearsWithMama}</td>
        <td>{totalData.sevenYearsWithMama}</td>
      </tr>
    )
    if (totalData && this.state.status === 'GRAND') returnData.push(
      <tr>
        <td>重複あり</td>
        <td>{totalData.total}</td>
        <td>{totalData.totalWithGrand}</td>
        <td>{totalData.pregnantWithGrand}</td>
        <td>{totalData.zeroYearsWithGrand}</td>
        <td>{totalData.oneYearWithGrand}</td>
        <td>{totalData.twoYearsWithGrand}</td>
        <td>{totalData.threeYearsWithGrand}</td>
        <td>{totalData.fourYearsWithGrand}</td>
        <td>{totalData.fiveYearsWithGrand}</td>
        <td>{totalData.sixYearsWithGrand}</td>
        <td>{totalData.sevenYearsWithGrand}</td>
      </tr>
    )

    return returnData
  }

  renderTableRow () {
    let reportData = this.props.reportData

    var returnData = []

    if (this.state.status === 'PARENT')
      {
        returnData = [];
        if (reportData) reportData.forEach(function (dto) {
          returnData.push(
            <tr>
              <td>{dto.year + '/' + dto.month + '/' + dto.day}</td>
              <td>{dto.total}</td>
              <td>{dto.totalWithMama}</td>
              <td>{dto.pregnantWithMama}</td>
              <td>{dto.zeroYearsWithMama}</td>
              <td>{dto.oneYearWithMama}</td>
              <td>{dto.twoYearsWithMama}</td>
              <td>{dto.threeYearsWithMama}</td>
              <td>{dto.fourYearsWithMama}</td>
              <td>{dto.fiveYearsWithMama}</td>
              <td>{dto.sixYearsWithMama}</td>
              <td>{dto.sevenYearsWithMama}</td>
            </tr>
          )
        });
      } else {
      returnData = []
      if (reportData) reportData.map(function (dto) {
        returnData.push(
          <tr>
            <td>{dto.year + '/' + dto.month + '/' + dto.day}</td>
            <td>{dto.total}</td>
            <td>{dto.totalWithGrand}</td>
            <td>{dto.pregnantWithGrand}</td>
            <td>{dto.zeroYearsWithGrand}</td>
            <td>{dto.oneYearWithGrand}</td>
            <td>{dto.twoYearsWithGrand}</td>
            <td>{dto.threeYearsWithGrand}</td>
            <td>{dto.fourYearsWithGrand}</td>
            <td>{dto.fiveYearsWithGrand}</td>
            <td>{dto.sixYearsWithGrand}</td>
            <td>{dto.sevenYearsWithGrand}</td>
          </tr>
        )
      })
    }

    return returnData

  }

  render () {
    return (
      <table className="table table-striped table-hover table-bordered">
        <thead>
        <tr>
          <th rowSpan="2" className="">日付</th>
          <th rowSpan="2" className="">総計</th>
          <th colSpan="10" style={{textAlign:"center"}} onClick={this.switchStatus}>
            {(this.state.status !== 'PARENT') && <i className="fa fa-angle-left fa-10x"/>}
            {(this.state.status === 'PARENT') ? 'ママパパ宅 ' : '祖父母宅'}
            {(this.state.status === 'PARENT') && <i className="fa fa-angle-right fa-10x"/>}
          </th>
        </tr>
        <tr>
          <th className="">合計</th>
          <th className="">マタニティ</th>
          <th className="">0歳</th>
          <th className="">1歳</th>
          <th className="">2歳</th>
          <th className="">3歳</th>
          <th className="">4歳</th>
          <th className="">5歳</th>
          <th className="">6歳</th>
          <th className="">7歳~</th>
        </tr>
        </thead>
        <tbody>
        {this.renderTableRow()}
        {this.renderTotal()}
        </tbody>
      </table>
    )
  }
}

export default ChildrenAgeGroupReportTable
