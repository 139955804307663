import React from 'react';
import RakutenStore from '../../ecsystems/stores/RakutenStore.jsx';
import RakutenConstants from '../../ecsystems/constants/RakutenConstants.jsx';

export default class NotificationBarComponent extends React.Component {

    constructor() {
        super();
        this.state = this.getState();
        this.onChange = this.onChange.bind(this);
    }

    getState(){
        return {
            activeKey: RakutenStore.getActiveKey(),
            pathName: RakutenStore.getPathName()
        };
    }

    onChange(){
        this.setState(this.getState());
    }

    componentDidMount(){
        RakutenStore.addChangeListener(this.onChange);
    }

    render(){
        if(this.state.activeKey){
            if((this.state.activeKey.remainingWorkingDays < RakutenConstants.REMAINING_WORKING_DAYS_TO_DISPLAY_WARNING
                || (this.state.activeKey.remainingWorkingDays == RakutenConstants.REMAINING_WORKING_DAYS_TO_DISPLAY_WARNING
                && this.state.activeKey.isAWorkingDay == true))
                && this.state.pathName != '/login' && this.state.pathName != '/'){
                var expirationDate = new Date(Date.parse(this.state.activeKey.expirationDate));
                return (
                    <div className="notification-bar bg-danger">
            <span style={{float: 'left'}}>
        現在ご利用の楽天APIのライセンスキーは{expirationDate.getFullYear()}/{expirationDate.getMonth() + 1}/{expirationDate.getDate()} まで有効期限をお知らせいたします。
            </span>
                    </div>
                )
            }else{
                return <div></div>
            }
        }else{
            if(this.state.pathName != '/login' && this.state.pathName != '/' && this.state.activeKey !== undefined){
                return (
                    <div className="notification-bar bg-danger">
            <span style={{float: 'left'}}>
        現在ご利用の楽天APIのライセンスキーは無効になりました。
            </span>
                    </div>
                )
            }else{
                return <div></div>
            }
        }
    }
}