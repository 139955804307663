import React from 'react'
import AuthenticatedComponent from '../../../common/components/AuthenticatedComponent.jsx'
import momentPropTypes from "react-moment-proptypes";
import {DateRangePicker} from "react-dates";
import moment from 'moment';
import LoadingButton from '../../../common/components/LoadingButton.jsx';
import ReportService from '../../services/ReportService.jsx';
import ReportProductByOrderStatusStore from '../../stores/ReportProductByOrderStatusStore.jsx';
import {BootstrapTable, SizePerPageDropDown, TableHeaderColumn} from "react-bootstrap-table";
import ReportAction from "../../actions/ReportAction.jsx";

class ReportProductByOrderStatusComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = this._getInitialState();
    this._onDateChange = this._onDateChange.bind(this);
    this._onFocusChange = this._onFocusChange.bind(this);
    this._onChange = this._onChange.bind(this);
    this.searchProductPackageReport = this.searchProductPackageReport.bind(this);
    this.renderSizePerPageDropDown = this.renderSizePerPageDropDown.bind(this);
    this.onToggleDropDown = this.onToggleDropDown.bind(this);
    this.handleGettingReportClick = this.handleGettingReportClick.bind(this);
    moment.locale('ja');
  }

  componentDidMount() {
    ReportService.getProductByOrderStatusReport(
      moment(this.state.selectedStartDate),
      moment(this.state.selectedEndDate)
    )
    ReportProductByOrderStatusStore.addChangeListener(this._onChange)
  }

  componentWillUnmount() {
    ReportProductByOrderStatusStore.removeChangeListener(this._onChange)
  }

  _onChange() {
    this.setState(this._getState())
  }

  _onDateChange({startDate, endDate}) {
    this.setState({selectedStartDate: startDate, selectedEndDate: endDate})
  }

  _onFocusChange(focusedInput) {
    this.setState({focusedInput: focusedInput})
  }

  _getInitialState() {
    return {
      loading: true,
      focusedInput: null,
      selectedEndDate: moment(),
      selectedStartDate: moment().subtract(1, 'months'),
      errorMessage: null,
      products: [],
    }
  }

  _getState() {
    return {
      selectedDateType: this.state.selectedDateType,
      selectedStartDate: this.state.selectedStartDate,
      selectedEndDate: this.state.selectedEndDate,
      focusedInput: this.state.focusedInput,
      products: ReportProductByOrderStatusStore.getProducts(),
      loading: ReportProductByOrderStatusStore.isLoading(),
    }
  }

  handleGettingReportClick(e) {
    if (e) e.preventDefault();
    ReportAction.load()
    ReportService.getProductByOrderStatusReport(
      moment(this.state.selectedStartDate),
      moment(this.state.selectedEndDate)
    )
  }

  searchProductPackageReport(props) {
    return (
      <div className="row">
        <div className="pull-right search-product col-md-12">
          <div className="form-group is-empty">
            {props.searchField}
          </div>
          <button className="btn btn-primary">
            <i className="fa fa-search"/>
          </button>
        </div>
      </div>
    );
  };

  renderSizePerPageDropDown(props) {
    return (
      <SizePerPageDropDown
        className='dropdown-pagesize'
        {...props}
        onClick={() => this.onToggleDropDown(props.toggleDropDown)}/>
    );
  };

  onToggleDropDown(toggleDropDown) {
    toggleDropDown();
  };

  render() {

    const optionPaging = {
      sizePerPage: 10,
      sizePerPageList: [10, 20, 50, 100],
      paginationSize: 4,
      searchPanel: this.searchProductPackageReport,
      sizePerPageDropDown: this.renderSizePerPageDropDown,
      firstPage: 'First',
      lastPage: 'Last',
    };

    return (
      <section id="ReportComponent">
        <div className="report-form">
          <div className="form-inline">
            <label>受注日: </label>
            <DateRangePicker readOnly
                             disabled={this.state.loading}
                             startDatePlaceholderText="開始日"
                             endDatePlaceholderText="完了日"
                             monthFormat="YYYY[年]MMMM"
                             phrases={{
                               closeDatePicker: '完了',
                               clearDates: 'クリア',
                             }}
                             startDate={this.state.selectedStartDate}
                             endDate={this.state.selectedEndDate}
                             focusedInput={this.state.focusedInput}
                             onDatesChange={this._onDateChange}
                             onFocusChange={this._onFocusChange}
                             isOutsideRange={() => false}
                             hideKeyboardShortcutsPanel
                             minimumNights={0}
                             showClearDates/>
            <LoadingButton
              disabled={this.state.selectedStartDate === null ||
              this.state.selectedEndDate === null}
              isLoading={this.state.loading}
              className="btn-raised btn btn-info btn-md"
              onClick={this.handleGettingReportClick}>集計</LoadingButton>
          </div>
        </div>
        <div>
          {(!this.state.errorMessage)
            ? (
              <BootstrapTable search
                              searchPlaceholder="商品名で検索"
                              data={this.state.products}
                              striped bordered
                              pagination={true}
                              options={optionPaging}>
                <TableHeaderColumn width={'30%'} isKey dataField='name'>商品名</TableHeaderColumn>
                <TableHeaderColumn width={'17.5%'} dataField='quantity'>在庫数</TableHeaderColumn>
                <TableHeaderColumn width={'17.5%'} dataField='waitingAndDraft'>未制作</TableHeaderColumn>
                <TableHeaderColumn width={'17.5%'} dataField='waitingCreation'>制作中(確定前)</TableHeaderColumn>
                <TableHeaderColumn width={'17.5%'} dataField='pendingCreationAndCreating'>制作中(確定)</TableHeaderColumn>
              </BootstrapTable>
            )
            : <span className="key-invalid-msg">{this.state.errorMessage}</span>
          }
        </div>
      </section>
    )
  }

}

ReportProductByOrderStatusComponent.propTypes = {
  selectedStartDate: momentPropTypes.momentObj,
  selectedEndDate: momentPropTypes.momentObj,
};

export default AuthenticatedComponent(ReportProductByOrderStatusComponent);