const EcProductCooperationMasterDataConstants = {
  ACTION_TYPE: {
    GET_PRODUCT_COOPERATION_MASTER_DATA: 'GET_PRODUCT_COOPERATION_MASTER_DATA',
    CHANGE_PRODUCT_COOPERATION_NEW_TEXT: 'CHANGE_PRODUCT_COOPERATION_NEW_TEXT',
    NEW_MESSAGE: 'NEW_MESSAGE',
    CHANGE_LOADING: 'CHANGE_LOADING'
  },
  REMAINING_WORKING_DAYS_TO_DISPLAY_WARNING: 5,
}

export default EcProductCooperationMasterDataConstants