import RakutenConstants from '../constants/RakutenConstants.jsx'
import RakutenStore from '../stores/RakutenStore.jsx'

export default {
  getActiveKey (activeKey, pathName) {
    RakutenStore.handler({
      actionType: RakutenConstants.ACTION_TYPE.GET_ACTIVE_KEY,
      activeKey: activeKey,
      pathName: pathName,
    })
  },

}