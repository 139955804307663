import BaseStore from "../../common/stores/BaseStore.jsx";
import MagazineMailConstant from "../constants/MagazineMailConstants.jsx";
import moment from "moment";

class PlanStore extends BaseStore {
  constructor() {
    super();
    this.allPlan = [];
    this.plan = {};
    this.mailQuantity = {};
    this.error = null;
    this.allMailTemplate = [];
    this.subscribe(() => this.handler.bind(this));
  }

  handler(action) {
    switch (action.actionType) {
      case MagazineMailConstant.ACTION_TYPE.LOADED_ALL_PLAN:
        this.allPlan = action.allPlan.map(function (p) {
          p.emailName = p.emailTemplate.name
          p.emailTitle = p.emailTemplate.title
          return p
        });
        this.plan = {};
        this.error = null;
        this.emitChange();
        break;
      case MagazineMailConstant.ACTION_TYPE.LOADED_ALL_MAIL_TEMPLATE:
        this.allMailTemplate = action.templates.map(function (t) {
          t.value = t.identifier
          t.label = t.name
          return t
        });
        this.error = null;
        this.emitChange();
        break;
      case MagazineMailConstant.ACTION_TYPE.LOADED_PLAN:
        this.plan = action.plan;
        this.error = null;
        this.emitChange();
      case MagazineMailConstant.ACTION_TYPE.BAD_REQUEST:
        this.error = action.error
        this.emitChange();
        break;
      case MagazineMailConstant.ACTION_TYPE.ERROR:
        this.error = action.error
        this.emitChange();
        break;
      case MagazineMailConstant.ACTION_TYPE.LOADED_MAIL_QUANTITY:
        this.mailQuantity = action.data;
        this.emitChange();
        break;
    }
  }

  getAllPlan() {
    return this.allPlan
  }

  getAllMailTemplate() {
    return this.allMailTemplate
  }

  getPlan() {
    return this.plan
  }

  getMailQuantity() {
    return this.mailQuantity
  }

  getError() {
    return this.error
  }

}

const planStore = new PlanStore();

export default planStore;
