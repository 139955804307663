/**
 * Created by PhuNH on 16/12/25.
 */
import BaseStore from '../../common/stores/BaseStore.jsx'
import RakutenLicenseKeyConstant from '../constants/RakutenLicenseKeyConstant.jsx'
import RakutenLicenseKeyService from '../services/RakutenLicenseKeyService.jsx'

class RakutenLicenseKeyStore extends BaseStore {
    constructor() {
        super();
        this._initFormData();
        this._initListData();
        this.subscribe(() => this.handler.bind(this));
    }

    _initFormData() {
        this.isChecking = false;
        this.isChecked = false;
        this.isCheckedValid = false;
        this.isAdding = false;
        this.isSubmitted = false;
        this.addError = "";
    }

    _initListData() {
        this.keyList = [];
        this.listError = "";
    }

    /**
     * Register callback to handle all updates
     *
     * @param  {Object} action
     */
    handler(action) {
        switch (action.actionType) {
            case RakutenLicenseKeyConstant.CHECKING_KEY:
                this.isChecking = true;
                this.emitChange();
                break;
            case RakutenLicenseKeyConstant.CHECK_KEY_VALID:
                this.isChecking = false;
                this.isChecked = true;
                this.isCheckedValid = true;
                this.emitChange();
                break;
            case RakutenLicenseKeyConstant.CHECK_KEY_INVALID:
                this.isChecking = false;
                this.isChecked = true;
                this.isCheckedValid = false;
                this.emitChange();
                break;
            case RakutenLicenseKeyConstant.CHANGE_KEY:
                this._initFormData();
                this.emitChange();
                break;
            case RakutenLicenseKeyConstant.ADDING_KEY:
                this.isAdding = true;
                this.emitChange();
                break;
            case RakutenLicenseKeyConstant.ADD_KEY:
                this.isAdding = false;
                this.isSubmitted = true;
                this.addError = "";
                this.isChecked = false;
                this.emitChange();
                RakutenLicenseKeyService.listKey();
                break;
            case RakutenLicenseKeyConstant.ADD_KEY_FAILED:
                this.isAdding = false;
                this.isSubmitted = true;
                this.addError = action.error;
                this.emitChange();
                break;
            case RakutenLicenseKeyConstant.RESET_ADD:
                this.isSubmitted = false;
                this.addError = "";
                this.emitChange();
                break;
            case RakutenLicenseKeyConstant.LIST_KEY:
                this.keyList = action.keyList;
                this.listError = "";
                this.emitChange();
                break;
            case RakutenLicenseKeyConstant.LIST_KEY_FAILED:
                this.keyList = [];
                this.listError = action.error;
                this.emitChange();
                break;
            case RakutenLicenseKeyConstant.RESET_LIST:
                this.listError = "";
                this.emitChange();
                break;
            default:
                break;
        }
    }

    getIsChecked() {
        return this.isChecked
    }

    getValidationState() {
        let validationState;
        if (this.isChecking) validationState = 'warning';
        else if (!this.isChecked) validationState = null;
        else if (!this.isCheckedValid) validationState = 'error';
        else validationState = 'success';
        return validationState;
    }

    getIsAdding() {
        return this.isAdding
    }

    getIsSubmitted() {
        return this.isSubmitted
    }

    getAddError() {
        return this.addError
    }

    getKeyList() {
        return this.keyList
    }

    getListError() {
        return this.listError
    }
}

const rakutenLicenseKeyStore = new RakutenLicenseKeyStore();

export default rakutenLicenseKeyStore;