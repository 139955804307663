import React from 'react';
import YahooService from '../../services/YahooService.jsx';
import YahooStore from '../../stores/YahooStore.jsx';

export default class YahooJPAccessTokenComponent extends React.Component {
    constructor() {
        super();
        this.state = this._getState();
        this._onChange = this._onChange.bind(this);
    }

    _getState() {
        return {
            userInfo: YahooStore.getYahooUserInfo(),
            yconnectInfo: YahooStore.getYconnectInfo(),
            isLoading: false
        };
    }

    _onChange() {
        this.setState(this._getState());
        this.loadYConnect();
    }

    componentDidMount(){
        YahooStore.addChangeListener(this._onChange);
    }

    loadYConnect(){
        if(!this.state.userInfo){
            window.loadYConnect(this.state.yconnectInfo);
        }
        this.setState({
            isLoading: false
        });
    }

    render(){
        if(!this.state.isLoading){
                return (
                    <div className="text-center authen-page">
                        <p><strong>Yahoo! Japanアカウントとの連携を有効にするため、Y!ログインボタンをクリックしてYahoo! Japan IDでログインしてください。</strong></p>
                        <span className="yconnectLogin"></span>
                    </div>
                )
        }else{
            return false;
        }
    }
}