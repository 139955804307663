/**
 * Created by thangkc on 26/01/2016.
 */
import React from 'react';
export default class NumberFormat extends React.Component {
    constructor() {
        super();
    }

    format(n, x) {
        x = x || 0;
        if(n == undefined) return "";
        return n.toFixed(x).replace(/./g, function(c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        });
    }

    render() {
        return (
            <span>
                {this.format(this.props.data)}
            </span>
        );
    }
}