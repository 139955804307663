import React from 'react'
import {Line, Pie} from 'react-chartjs-2';
import 'chartjs-plugin-datalabels'
import ReportConstant from '../../constants/ReportConstant.jsx'

class RepeatOrderReportChart extends React.Component {

  constructor () {
    super()
  }

  createChart () {
    switch (this.props.type) {
      case ReportConstant.CHART_TYPE.LINE:
        return this.lineChart()
      case ReportConstant.CHART_TYPE.PIE:
        return this.pieChart()
      default:
        return ""
    }
  }

  lineChart () {
    let chartData = {
      datasets: [
        {
          label: 'リピート注文',
          data: this.props.datasets[0] || [],
          fill: false,
          backgroundColor:  'rgb(75, 192, 192)',
          borderColor:  'rgb(75, 192, 192)',
        },
        {
          label: '全て注文',
          data: this.props.datasets[1] || [],
          fill: false,
          backgroundColor:  'rgb(255, 99, 132)',
          borderColor:  'rgb(255, 99, 132)',
        }],
      dataLabels: {
        display: false
      },
      labels: this.props.labels || []
    }

    let options =  {
      maintainAspectRatio: false,
      spanGaps: false,
      elements: {
        line: {
          tension: 0.000001
        }
      },
      plugins: {
        filler: {
          propagate: false
        },
        datalabels: {
          display: false
        }
      }
    };


    return <Line data={chartData} options={options} height={300} redraw generateLegend/>
  }

  pieChart () {

    let color = ['#46BFBD', '#FDB45C']

    let options = { }
    var data = []
    if(this.props.datasets){
      let total = this.props.datasets[0] + this.props.datasets[1]
      data = [parseFloat(this.props.datasets[0]*100/total).toFixed(2), parseFloat(this.props.datasets[1]*100/total).toFixed(2)]
    }

    let chartData = {
      datasets: [
        {
          backgroundColor: color,
          data: data,
          cutoutPercentage: -360
        }],
      labels: this.props.labels || [],
      dataLabels: {           //  disabled by default
        colors: ['#fff', '#ccc', '#000'], //  Array colors for each labels
        minRadius: 30, //  minimum radius for display labels (on pie charts)
      }
    }
    return <Pie data={chartData} options={options} height={150} redraw generateLegend/>
  }


  render () {

    return (
        this.createChart()
    )
  }
}

export default RepeatOrderReportChart