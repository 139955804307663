/**
 * Created by anh_bd on 14/08/2020
 */

import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Select from 'react-select';
import {Button, Form, FormGroup, FormControl, Col} from 'react-bootstrap/lib';
import {DateRangePicker} from "react-dates";
import DatePicker from 'react-datepicker'
import moment from "moment";
import MagazineMailService from "../../services/MagazineMailService.jsx";

export default class PlanForm extends React.Component {
  constructor() {
    super();
    this.alignRightText = {
      'textAlign': 'right'
    };
    this.orderStatusOptions = [
      {value: 'DRAFT', label: '下書き'},
      {value: 'URL_RELEASED', label: 'URL発行済み'},
      {value: 'WAITING', label: 'デザイン待ち'},
      {value: 'WAITING_CREATION', label: '制作中(確定前)'},
      {value: 'PAUSE', label: '制作停止'},
      {value: 'CREATING', label: '制作中(確定)'},
      {value: 'PENDING', label: '保留'},
      {value: 'PENDING_CREATION', label: '処理中'},
      {value: 'SHIPPED', label: '出荷済み'},
      {value: 'COMMITTED', label: '確定済み'},
      {value: 'CANCEL', label: 'キャンセル'},
    ];
    this.ecSiteOptions = [
      {value: 'COLOR_ME', label: 'ColorMe'},
      {value: 'RAKUTEN', label: 'Rakuten'},
    ];
    this.runTimeHourOptions = [
      {value: '00', label: '00'},
      {value: '01', label: '01'},
      {value: '02', label: '02'},
      {value: '03', label: '03'},
      {value: '04', label: '04'},
      {value: '05', label: '05'},
      {value: '06', label: '06'},
      {value: '07', label: '07'},
      {value: '08', label: '08'},
      {value: '09', label: '09'},
      {value: '10', label: '10'},
      {value: '11', label: '11'},
      {value: '12', label: '12'},
      {value: '13', label: '13'},
      {value: '14', label: '14'},
      {value: '15', label: '15'},
      {value: '16', label: '16'},
      {value: '17', label: '17'},
      {value: '18', label: '18'},
      {value: '19', label: '19'},
      {value: '20', label: '20'},
      {value: '21', label: '21'},
      {value: '22', label: '22'},
      {value: '23', label: '23'},
    ];
    this.runTimeMinuteOptions = [
      {value: '00', label: '00'},
      {value: '15', label: '15'},
      {value: '30', label: '30'},
      {value: '45', label: '45'},
    ]
    this.state = this.initState();
    this.changePlanName = this.changePlanName.bind(this);
    this.changeTemplateName = this.changeTemplateName.bind(this);
    this.changeRuntime = this.changeRuntime.bind(this);
    this.changeOrderStatus = this.changeOrderStatus.bind(this);
    this.onOrderDateChange = this.onOrderDateChange.bind(this);
    this.onFocusOrderDateChange = this.onFocusOrderDateChange.bind(this);
    this.onShipDateChange = this.onShipDateChange.bind(this);
    this.onFocusShipDateChange = this.onFocusShipDateChange.bind(this);
    this.changeEcSite = this.changeEcSite.bind(this);
    this.planFormSubmit = this.planFormSubmit.bind(this);
    this.changeRunTimeHour = this.changeRunTimeHour.bind(this);
    this.changeRunTimeMinute = this.changeRunTimeMinute.bind(this);
    this.getOrderStatus = this.getOrderStatus.bind(this);
    this.getEcSite = this.getEcSite.bind(this);
    this.planFormSubmit = this.planFormSubmit.bind(this);
    this.calculateReceiver = this.calculateReceiver.bind(this);
  }

  initState() {
    return {
      focusedOrderDate: null,
      focusedShippedDate: null,
      planName: null,
      templateName: null,
      runTime: moment(),
      startOrderDate: moment().add(-1, 'months'),
      endOrderDate: moment(),
      startShippedDate: moment().add(-1, 'months'),
      endShippedDate: moment(),
      selectedOrderStatus: [],
      selectedEcSites: [],
      runTimeHour: this.runTimeHourOptions.filter(h => h.value === '00')[0],
      runTimeMinute: this.runTimeMinuteOptions.filter(h => h.value === '00')[0]
    };
  }

  getOrderStatus(orderStatuses) {
    if (orderStatuses !== undefined && orderStatuses.length > 0) {
      let self = this
      let array = orderStatuses.split(',').map(o => o.trim())
      return self.orderStatusOptions.filter(o => array.indexOf(o.value) !== -1)
    } else return []
  }

  getEcSite(data) {
    if (data !== undefined && data.length > 0) {
      let self = this
      let array = data.split(',').map(o => o.trim())
      return self.ecSiteOptions.filter(o => array.indexOf(o.value) !== -1)
    } else return []
  }

  componentWillReceiveProps() {
    if (this.props.plan && Object.keys(this.props.plan).length !== 0) {
      let runtime = this.props.plan.runtime ? moment(this.props.plan.runtime, "YYYY-MM-DD HH:mm") : moment()
      let runTimeHour = this.runTimeHourOptions.filter(h => h.value === runtime.format("HH"))[0]
      let runTimeMinute = this.runTimeMinuteOptions.filter(h => h.value === runtime.format("mm"))[0]
      this.setState({
        planName: this.props.plan.name,
        templateName: this.props.plan.emailTemplate ?
            this.props.mailTemplates.filter(t => t.value === this.props.plan.emailTemplate.identifier)[0] : '',
        runTime: this.props.plan.runtime !== null ? moment(this.props.plan.runtime) : null,
        runTimeHour: runTimeHour,
        runTimeMinute: runTimeMinute,
        startOrderDate: this.props.plan.orderStartDate !== null ? moment(this.props.plan.orderStartDate) : null,
        endOrderDate: this.props.plan.orderEndDate !== null ? moment(this.props.plan.orderEndDate) : null,
        startShippedDate: this.props.plan.shippedStartDate !== null ? moment(this.props.plan.shippedStartDate) : null,
        endShippedDate: this.props.plan.shippedEndDate !== null ? moment(this.props.plan.shippedEndDate) : null,
        selectedOrderStatus: this.getOrderStatus(this.props.plan.orderStatus),
        selectedEcSites: this.getEcSite(this.props.plan.ecSite),
      }, () => this.calculateReceiver())
    } else {
      this.state = this.initState()
    }
  }

  componentDidUpdate(){
    this.calculateReceiver()
  }

  changePlanName(planName) {
    this.setState({planName: planName.target.value});
  }

  changeTemplateName(templateName) {
    this.setState({templateName: templateName});
  }

  changeRuntime(runTime) {
    this.setState({runTime: runTime});
  }

  changeOrderStatus(orderStatus) {
    this.setState({selectedOrderStatus: orderStatus});
  }

  onOrderDateChange({startDate, endDate}) {
    this.setState({
      startOrderDate: startDate,
      endOrderDate: endDate
    });
  }

  onFocusOrderDateChange(onFocusedInput) {
    this.setState({focusedOrderDate: onFocusedInput});
  }

  onShipDateChange({startDate, endDate}) {
    this.setState({
      startShippedDate: startDate,
      endShippedDate: endDate
    });
  }

  onFocusShipDateChange(onFocusedInput) {
    this.setState({focusedShippedDate: onFocusedInput});
  }

  changeEcSite(ecSite) {
    this.setState({selectedEcSites: ecSite});
  }

  changeRunTimeHour(hour) {
    this.setState({runTimeHour: hour});
  }

  changeRunTimeMinute(minute) {
    this.setState({runTimeMinute: minute});
  }

  planFormSubmit() {
    const dateTimeFormatter = "{0}-{1}-{2} {3}:{4}:{5}";
    const runTimeFormatter = "{0}-{1}-{2} {3}:{4}";
    let PlanForm = {
      name: this.state.planName,
      runtime: this.state.runTime
          && runTimeFormatter.format(this.state.runTime.year(), this.state.runTime.month() + 1,
              this.state.runTime.date(), this.state.runTimeHour.value, this.state.runTimeMinute.value),
      emailTemplateId: this.state.templateName.value,
      ecSite: this.state.selectedEcSites.map(function (item) {
        return item.value;
      }).join(','),
      orderStatus: this.state.selectedOrderStatus.map(function (item) {
        return item.value;
      }),
      orderStartDate: this.state.startOrderDate
          && dateTimeFormatter.format(this.state.startOrderDate.year(), this.state.startOrderDate.month() + 1, this.state.startOrderDate.date(), '00', '00', '00'),
      orderEndDate: this.state.endOrderDate
          && dateTimeFormatter.format(this.state.endOrderDate.year(), this.state.endOrderDate.month() + 1, this.state.endOrderDate.date(), '23', '59', '59'),
      shippedStartDate: this.state.startShippedDate
          && dateTimeFormatter.format(this.state.startShippedDate.year(), this.state.startShippedDate.month() + 1, this.state.startShippedDate.date(), '00', '00', '00'),
      shippedEndDate: this.state.endShippedDate
          && dateTimeFormatter.format(this.state.endShippedDate.year(), this.state.endShippedDate.month() + 1, this.state.endShippedDate.date(), '23', '59', '59')
    };
    let temp = (this.props.plan && Object.keys(this.props.plan).length !== 0) ? MagazineMailService.updatePlan.bind(MagazineMailService, this.props.plan.identifier, PlanForm)
        : MagazineMailService.addPlan.bind(MagazineMailService, PlanForm);
    temp();
    this.state = this.initState();
    this.props.closePlanFormDialog();
  }

  calculateReceiver() {
    const dateTimeFormatter = "{0}-{1}-{2} {3}:{4}:{5}";
    let ecSite = '';
    if (this.state.selectedEcSites.length === 1) ecSite = this.state.selectedEcSites[0].value
    else if (this.state.selectedEcSites.length === 2)
      ecSite = this.state.selectedEcSites[0].value + ',' + this.state.selectedEcSites[1].value
    else ecSite = '';
    let data = {
      ecSite: ecSite,
      orderStatus: this.state.selectedOrderStatus.length > 0 ? this.state.selectedOrderStatus.map(function (item) {
        return item.value;
      }) : '',
      orderStartDate: this.state.startOrderDate
          && dateTimeFormatter.format(this.state.startOrderDate.year(), this.state.startOrderDate.month() + 1, this.state.startOrderDate.date(), '00', '00', '00'),
      orderEndDate: this.state.endOrderDate
          && dateTimeFormatter.format(this.state.endOrderDate.year(), this.state.endOrderDate.month() + 1, this.state.endOrderDate.date(), '23', '59', '59'),
      shippedStartDate: this.state.startShippedDate
          && dateTimeFormatter.format(this.state.startShippedDate.year(), this.state.startShippedDate.month() + 1, this.state.startShippedDate.date(), '00', '00', '00'),
      shippedEndDate: this.state.endShippedDate
          && dateTimeFormatter.format(this.state.endShippedDate.year(), this.state.endShippedDate.month() + 1, this.state.endShippedDate.date(), '23', '59', '59')
    };
    MagazineMailService.calculateReceiver(data)
  }

  render() {
    let exportBtnAttributes = {};
    let warningMessageStyle = {
      display: (!this.state.startOrderDate || !this.state.endOrderDate)
      && (!this.state.startShippedDate || !this.state.endShippedDate) ? 'block' : 'none',
      fontWeight: 'bold'
    };
    if (this.state.planName === null || this.state.planName === ''
        || this.state.templateName === null || this.state.templateName === ''
        || this.state.selectedOrderStatus.length < 1 || this.state.selectedEcSites.length < 1
        || ((!this.state.startOrderDate || !this.state.endOrderDate)
            && (!this.state.startShippedDate || !this.state.endShippedDate))) {
      exportBtnAttributes.disabled = true;
    }
    return (
        <Modal show={this.props.isOpenPlanFormDialog} onHide={this.props.closePlanFormDialog} bsSize="lg">
          <Modal.Header>
            <Modal.Title>計画登録・編集</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form horizontal onSubmit={this.planFormSubmit}>
              <FormGroup>
                {/*plan name*/}
                <Col sm={3} style={this.alignRightText}>
                  計画名 <span className="text-danger">(必須)</span>:
                </Col>
                <Col sm={7}>
                  <FormControl type="text" placeholder="プラン名をご記入ください" onChange={this.changePlanName}
                               value={this.state.planName}
                  />
                </Col>
              </FormGroup>

              <FormGroup>
                {/*template name*/}
                <Col sm={3} style={this.alignRightText}>
                  メールテンプレート <span className="text-danger">(必須)</span>:
                </Col>
                <Col sm={7}>
                  <Select name="templateName" onChange={this.changeTemplateName} clearable={false}
                          options={this.props.mailTemplates} placeholder="メルマガテンプレートを選択してください"
                          value={this.state.templateName}/>
                </Col>
              </FormGroup>

              <FormGroup>
                {/*run time*/}
                <Col sm={3} style={this.alignRightText}>
                  実行時間 <span className="text-danger">(必須)</span>:
                </Col>
                <Col sm={3}>
                  <DatePicker showTimeSelect selected={this.state.runTime} className="form-control"
                              onChange={this.changeRuntime}
                  />
                </Col>
                <Col sm={2}>
                  <Select name="runTimeHour" onChange={this.changeRunTimeHour} clearable={false}
                          options={this.runTimeHourOptions} autosize={true}
                          value={this.state.runTimeHour}/>
                </Col>
                <Col sm={2}>
                  <Select name="runTimeMin" onChange={this.changeRunTimeMinute} clearable={false}
                          options={this.runTimeMinuteOptions} autosize={true}
                          value={this.state.runTimeMinute}/>
                </Col>
              </FormGroup>

              <FormGroup>
                {/*Order status*/}
                <Col sm={3} style={this.alignRightText}>
                  注文ステータス <span className="text-danger">(必須)</span>:
                </Col>
                <Col sm={7}>
                  <Select multi={true} options={this.orderStatusOptions} onChange={this.changeOrderStatus}
                          value={this.state.selectedOrderStatus} placeholder="注文ステータスを選択してください"/>
                </Col>
              </FormGroup>

              <FormGroup>
                {/*order date*/}
                <Col sm={3} style={this.alignRightText}>
                  注文日:
                </Col>
                <Col sm={9}>
                  <DateRangePicker
                      startDatePlaceholderText="開始日"
                      endDatePlaceholderText="完了日"
                      monthFormat="YYYY[年]MMMM"
                      phrases={{
                        closeDatePicker: '关闭',
                        clearDates: '清除日期',
                      }}
                      startDate={this.state.startOrderDate}
                      endDate={this.state.endOrderDate}
                      focusedInput={this.state.focusedOrderDate}
                      onDatesChange={this.onOrderDateChange}
                      onFocusChange={this.onFocusOrderDateChange}
                      isOutsideRange={() => false}
                      keepOpenOnDateSelect
                      hideKeyboardShortcutsPanel
                      minimumNights={0}
                      showClearDates
                      readOnly={true}/>
                </Col>
              </FormGroup>

              <FormGroup>
                {/*shipped date*/}
                <Col sm={3} style={this.alignRightText}>
                  出荷日:
                </Col>
                <Col sm={9}>
                  <DateRangePicker
                      startDatePlaceholderText="開始日"
                      endDatePlaceholderText="完了日"
                      monthFormat="YYYY[年]MMMM"
                      phrases={{
                        closeDatePicker: '关闭',
                        clearDates: '清除日期',
                      }}
                      startDate={this.state.startShippedDate}
                      endDate={this.state.endShippedDate}
                      focusedInput={this.state.focusedShippedDate}
                      onDatesChange={this.onShipDateChange}
                      onFocusChange={this.onFocusShipDateChange}
                      isOutsideRange={() => false}
                      keepOpenOnDateSelect
                      hideKeyboardShortcutsPanel
                      minimumNights={0}
                      showClearDates
                      readOnly={true}/>
                </Col>
              </FormGroup>

              <FormGroup style={warningMessageStyle}>
                <Col sm={3}> </Col>
                <Col sm={7}>
                  <h4 className="text-danger has-error">注文日または、出荷日を選択してください。</h4>
                </Col>
              </FormGroup>

              <FormGroup>
                {/*ec site*/}
                <Col sm={3} style={this.alignRightText}>
                  EC サイト <span className="text-danger">(必須)</span>:
                </Col>
                <Col sm={7}>
                  <Select multi={true} options={this.ecSiteOptions} onChange={this.changeEcSite}
                          value={this.state.selectedEcSites} placeholder="EC サイトを選択してください"/>
                </Col>
              </FormGroup>

              <FormGroup>
                <Col sm={4} style={{textAlign: 'right', marginTop: '10px'}}>
                  <h4>約 <span id="numberOfReceiver"/> 送信想定</h4>
                </Col>
              </FormGroup>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button className="btn btn-raised btn-info"
                    onClick={this.planFormSubmit} {...exportBtnAttributes}>保存</Button>
            <Button className="btn btn-raised btn-danger" onClick={this.props.closePlanFormDialog}>キャンセル</Button>
          </Modal.Footer>
        </Modal>
    )
  }
}
