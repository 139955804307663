/**
 * Created by hoangnv on 1/12/17.
 */
import React from 'react'
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';

export default class ConfirmDialog extends React.Component {
    constructor() {
        super();
        this.confirm = this.confirm.bind(this);
    }

    confirm() {
        this.props.close();
        this.props.confirm();
    }

    render() {
        return (
            <Modal show={this.props.showModal} onHide={this.props.close} bsSize="lg">
                <Modal.Header closeButton>
                    <Modal.Title>確認</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className="ng-binding">{this.props.message}</h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.confirm} bsStyle="default" className="btn-raised">{this.props.confirmTitle ? this.props.confirmTitle : 'はい'}</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
