const SettingConstants = {
  MODULE_STATUS: {
    LOAD: "LOAD_MODULE_STATUS",
    BAD_REQUEST: "BAD_REQUEST_MODULE_STATUS",
    GATE_WAY_TIME_OUT: "GATE_WAY_TIME_OUT_MODULE_STATUS",
    ERROR: "ERROR_MODULE_STATUS",
  },
  ACTION_TYPE: {
    GET_ALL_MODULE_SETTING: "GET_ALL_MODULE_SETTING",
    UPDATE_MODULE_SETTING: "UPDATE_MODULE_SETTING"
  },
  API: {
    GET_ALL_SETTING_MODULE: "/api/setting/module",
    UPDATE_STATUS_SETTING_MODULE: "/api/setting/module/{0}/{1}",
  },
  MESSAGE_RESPONSE: {
    MSG_SUCCESS: "変更されました。",
    MSG_FAILED: "変更が失敗しました。",
    MSG_ERROR: "変更が失敗しました。",
  }
};

export default SettingConstants;