/**
 * Created by thangkc on 14/12/2015.
 */
import BaseStore from '../../common/stores/BaseStore.jsx'
import DesignConstant from '../constants/DesignConstant.jsx'
import DesignService from '../services/DesignService.jsx'

class DesignStore extends BaseStore {
    constructor() {
        super();
        this.designDTO = {
            currentPage: 1,
            maxPage: 0,
            designs: []
        };
        this.designFilter = {};
        this.loading = false;
        this._resetFilter();
        this.subscribe(() => this.handler.bind(this));
    }

    _resetFilter() {
        this.designFilter = {
            currentPage: 1,
            pageSize: 7,
            status: DesignConstant.STATUS.ALL,
            keyword: ''
        };
    }

    /**
     * Register callback to handle all updates
     *
     * @param  {Object} action
     */
    handler(action) {
        switch (action.actionType) {
            case DesignConstant.ACTION_TYPE.LOAD:
                this.designDTO = action.designDTO;
                this.designFilter = action.designFilter;
                this.loading = true;
                this.emitChange();
                break;
            case DesignConstant.ACTION_TYPE.CHANGE_KEYWORD:
                this.designFilter.currentPage = 1;
                this.designFilter.keyword = action.keyword;
                DesignService.load(this.designFilter);
                break;
            case DesignConstant.ACTION_TYPE.CHANGE_STT_FIL:
                this.designFilter.currentPage = 1;
                this.designFilter.status = action.status;
                DesignService.load(this.designFilter);
                break;
            case DesignConstant.ACTION_TYPE.CHANGE_PAGE:
                this.designFilter.currentPage = action.page;
                DesignService.load(this.designFilter);
                break;
            case DesignConstant.ACTION_TYPE.DELETE:
                if(this.designDTO.designs.length === 1 && this.designFilter.currentPage > 1) {
                    this.designFilter.currentPage = this.designFilter.currentPage - 1;
                }
                DesignService.load(this.designFilter);
                this.loading = false;
                break;
            default :
        }
    }

    _getDesignDTO() {
        return this.designDTO;
    }

    _getDesignFilter() {
        return this.designFilter;
    }

    isLoading(){
        return this.loading;
    }
}

const designStore = new DesignStore();

export default designStore;