import BaseStore from "./BaseStore.jsx";
import {LoadingConstant} from "../constants/LoadingConstant.jsx";

class LoadingStore extends BaseStore {
    constructor() {
        super();
        this.subscribe(() => this.handler.bind(this));
        this.loading = false
    }

    handler(action) {
        switch (action.actionType) {
            case LoadingConstant.START_LOADING:
                this.loading = true
                this.emitChange();
                break;
            case LoadingConstant.STOP_LOADING:
                this.loading = false;
                this.emitChange();
                break;
            default:
                break;
        }
    }

    isLoading() {
        return this.loading
    }
}

const alertStore = new LoadingStore();
export default alertStore;