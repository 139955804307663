import BaseStore from '../../common/stores/BaseStore.jsx'
import ReportConstant from '../constants/ReportConstant.jsx'

class ProductTemplateReportStore extends BaseStore {
  constructor () {
    super()
    this.resetReport()
    this.subscribe(() => this.handler.bind(this))
  }

  resetReport () {
    this.errorMessage = null;
    this.loading = false;
    this.csvLoading = false;

    this.templates = null;
    this.products = null;
    this.listProductTitle = [];
    this.listTemplateTitle = [];
  }

  /**
   * Register callback to handle all updates
   *
   * @param  {Object} action
   */
  handler (action) {
    switch (action.actionType) {
      case ReportConstant.LOAD:
        this.loading = true
        this.errorMessage = null
        this.templates = null
        this.products = null
        this.emitChange()
        break
      case ReportConstant.ACTION_TYPE.SHOW_TEMPLATE_REPORT:
        this.templates = action.templates
        this.listTemplateTitle = action.listTitle
        this.loading = false
        this.emitChange()
        break
      case ReportConstant.ACTION_TYPE.SHOW_PRODUCT_REPORT:
        this.products = action.products
        this.listProductTitle = action.listTitle
        this.loading = false
        this.emitChange()
        break
      case ReportConstant.ERROR:
        this.errorMessage = action.message
        this.loading = false
        this.emitChange()
        break
      default:
        break
    }
  }

  getErrorMessage () {
    return this.errorMessage
  }

  getTemplates () {
    return this.templates
  }

  getProducts () {
    return this.products
  }
  getListProductTitle() {
    return this.listProductTitle
  }

  getListTemplateTitle() {
    return this.listTemplateTitle
  }

  isLoading () {
    return this.loading
  }

  isCsvLoading() {
    return this.csvLoading
  }
}

const productTemplateReportStore = new ProductTemplateReportStore()

export default productTemplateReportStore
