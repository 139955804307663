import Dispatcher from "../../dispatcher/Dispatcher.jsx";
import {AlertConstant} from "../../constants/AlertConstant.jsx";


export default {
    alert: (type, message) => {
        Dispatcher.dispatch({
            actionType: AlertConstant.ALERT,
            alertType: type,
            message
        })
    },
    resetAlert: () => {
        Dispatcher.dispatch({
            actionType: AlertConstant.RESET_ALERT
        })
    }
}