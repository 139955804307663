/**
 * Created by hoangnv on 12/26/16.
 */
import React from 'react'
import request from 'reqwest'
import when from 'when'
import RakutenAction from '../actions/RakutenAction.jsx'

class RakutenService {

  getActiveKey (pathName) {
    when(request({
      url: '/api/rakuten/active-key',
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          RakutenAction.getActiveKey(response.data, pathName)
          break
        case 404:
          RakutenAction.getActiveKey(null, pathName)
          break
        case 401:
          RakutenAction.getActiveKey(undefined, pathName)
          break
        default:
          break
      }
    }).catch(function () {
    })
  }
}

export default new RakutenService()
