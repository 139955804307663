/**
 * Created by thangkc on 26/01/2016.
 */
import React from 'react';
import PagingRenderer from './PagingRenderer.jsx';

export default class GriddlePagingComponent extends React.Component {
    constructor() {
        super();
    }

    pageChange(pageNumber) {
        this.props.setPage(pageNumber);
    }

    render() {
        let basedIndex = 0,
            currentPage = this.props.currentPage,
            maxPage = this.props.maxPage,
            changePage = this.pageChange,
            previous = this.props.previous,
            next = this.props.next,
            component = this;

        return (
            <PagingRenderer
                basedIndex={basedIndex}
                currentPage={currentPage}
                maxPage={maxPage}
                changePage={changePage}
                previous={previous}
                next={next}
                component={component}
            />
        )
    }
};