import BaseStore from '../../common/stores/BaseStore.jsx'
import ReportConstant from '../constants/ReportConstant.jsx'

class ProductPackagePlanReportStore extends BaseStore {
    constructor(props) {
        super(props);
        this.resetReport();
        this.subscribe(() => this.handler.bind(this));
    }

    resetReport() {
        this.errorMessage = null;
        this.loading = true;
        this.products = [];
        this.csvLoading = false;
    }

    /**
     * Register callback to handle all updates
     *
     * @param  {Object} action
     */
    handler(action) {
        switch (action.actionType) {
            case ReportConstant.LOAD:
                this.loading = true;
                this.errorMessage = null;
                this.products = [];
                this.emitChange();
                break;
            case ReportConstant.ACTION_TYPE.SHOW_PRODUCT_PACKAGE_PLAN_REPORT:
                this.products = action.data;
                this.loading = false;
                this.emitChange();
                break;
            case ReportConstant.ERROR:
                this.errorMessage = action.message;
                this.loading = false;
                this.emitChange();
                break;
            default:
                break
        }
    }

    getErrorMessage() {
        return this.errorMessage
    }

    getProducts() {
        return this.products
    }

    isLoading() {
        return this.loading
    }

    isCsvLoading() {
        return this.csvLoading
    }
}

const productPackagePlanReportStore = new ProductPackagePlanReportStore();

export default productPackagePlanReportStore;
