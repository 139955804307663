import React from 'react';
import AuthenticatedComponent from '../../../common/components/AuthenticatedComponent.jsx'
import moment from "moment";
import {Alert} from "react-bootstrap";
import FlyerCampaignService from "../../services/FlyerCampaignService.jsx";
import CampaignTableComponent from "./CampaignTableComponent.jsx";
import CampaignForm from "./CampaignForm.jsx";
import FlyerCampaignStore from "../../stores/FlyerCampaignStore.jsx";

class FlyerCampaignList extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initState();
    this.onChange = this.onChange.bind(this);
    this.closeMessage = this.closeMessage.bind(this);
    this.openCampaignFormDialog = this.openCampaignFormDialog.bind(this);
    this.closeCampaignFormDialog = this.closeCampaignFormDialog.bind(this);
  }

  initState() {
    return {
      flyerCampaigns: FlyerCampaignStore.getAllFlyerCampaign(),
      error: null,
      flyerCampaign: {},
      mailQuantity: {},
      isOpenCampaignFormDialog: false
    }
  }

  componentDidMount() {
    FlyerCampaignService.getAllFlyerCampaign();
    FlyerCampaignStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    FlyerCampaignStore.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState(() => (this.getState()))
  }

  getState() {
    return {
      flyerCampaigns: FlyerCampaignStore.getAllFlyerCampaign(),
      flyerCampaign: FlyerCampaignStore.getFlyerCampaign(),
      error: FlyerCampaignStore.getError(),
      isOpenCampaignFormDialog: this.state.isOpenCampaignFormDialog
    }
  } /* end get state */

  openCampaignFormDialog(fc, actionType) {
    let newFlyerCampaign = Object.assign(fc)
    if (actionType === 'COPY') newFlyerCampaign.id = null
    FlyerCampaignService.getFlyerCampaign(newFlyerCampaign)
    this.setState({
      flyerCampaign: newFlyerCampaign,
      isOpenCampaignFormDialog: true
    });
  }

  closeCampaignFormDialog() {
    this.setState({
      isOpenCampaignFormDialog: false,
      flyerCampaign: {}
    });
  }

  closeMessage(event) {
    event.persist();
    this.setState(() => ({
      error: null
    }));
  } /*end close msg*/

  render() {
    return (
        <div>
          <div className="container-fluid padding-0">
            <CampaignTableComponent data={this.state.flyerCampaigns} openCampaignFormDialog={this.openCampaignFormDialog}/>
          </div>

          <CampaignForm isOpenCampaignFormDialog={this.state.isOpenCampaignFormDialog}
                        closeCampaignFormDialog={this.closeCampaignFormDialog}
                        flyerCampaign={this.state.flyerCampaign}/>
        </div>
    )
  }

}

export default AuthenticatedComponent(FlyerCampaignList)
