import React from "react";
import SearchBoxComponent from "../../../common/components/SearchBoxComponent.jsx";
import EpsilonDataTrackingTable from "./EpsilonDataTrackingTable.jsx";
import OurPagingComponent from "../../../common/components/paging/OurPagingComponent.jsx";
import EpsilonDataTrackingStore from "../../stores/EpsilonDataTrackingStore.jsx";
import EpsilonDataTrackingAction from "../../actions/EpsilonDataTrackingAction.jsx";
import EpsilonDataTrackingService from "../../services/EpsilonDataTrackingService.jsx";
import { Button } from "react-bootstrap/lib";

class EpsilonDataTracking extends React.Component {
    constructor() {
        super();
        this.state = this._getState()
        this._onChange = this._onChange.bind(this)
    }

    _onChange () {
        this.setState(this._getState())
    }

    _getState() {
        return {
            ordersDto: EpsilonDataTrackingStore.getOrdersDTO(),
        }
    }

    componentDidMount() {
        EpsilonDataTrackingAction.resetFilter();
        EpsilonDataTrackingAction.fetchOrders();
        EpsilonDataTrackingStore.addChangeListener(this._onChange);
    }

    componentWillUnmount() {
        EpsilonDataTrackingStore.removeChangeListener(this._onChange);
    }

    render() {
        const { ordersDto } = this.state
        const isDisableUpdateBtn = ordersDto.orders.length === 0 || !ordersDto.orders.find((order) => order.checked)
        return <div>
            <div className="flex-space-between">
                <Button
                    className="btn btn-raised btn-info"
                    onClick={EpsilonDataTrackingAction.startEpsilonLinkData}
                    disabled={isDisableUpdateBtn}
                >
                    イプシロン連携
                </Button>
                <div className="search-box-container">
                    <SearchBoxComponent changeKeyword={keyword => EpsilonDataTrackingAction.changeFilter({keyword})}
                                        delayInputTiming={700}
                                        placeholder="検索"/>
                </div>
            </div>
            <EpsilonDataTrackingTable orders={this.state.ordersDto.orders}/>
            <OurPagingComponent loadNewPage={pageNumber => EpsilonDataTrackingAction.changeFilter({page: pageNumber})}
                                dto={this.state.ordersDto}/>
        </div>
    }
}

export default EpsilonDataTracking