import React from 'react'
import {BootstrapTable, SizePerPageDropDown, TableHeaderColumn} from 'react-bootstrap-table'
import FlyerCampaignService from '../../services/FlyerCampaignService.jsx'
import ConfirmDialog from "../../../common/components/dialogs/ConfirmDialog.jsx"
import Button from 'react-bootstrap/lib/Button'
import moment from "moment";
import FlyerCampaignConstants from "../../constants/FlyerCampaignConstant.jsx";

export default class CampaignTableComponent extends React.Component {
  constructor() {
    super();
    this.onToggleDropDown = this.onToggleDropDown.bind(this)
    this.renderSizePerPageDropDown = this.renderSizePerPageDropDown.bind(this)
    this.sizePerPageListChange = this.sizePerPageListChange.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.showConfirmDialog = this.showConfirmDialog.bind(this)
    this.renderActionButtons = this.renderActionButtons.bind(this)
    this.state = {
      optionsTable: {
        sizePerPageList: [5, 10, 20, 50, 100],
        prePage: '<<', // Previous page button text
        nextPage: '>>', // Next page button text
        firstPage: 'First', // First page button text
        lastPage: 'Last', // Last page button text
        sizePerPageDropDown: this.renderSizePerPageDropDown,
        onSizePerPageList: this.sizePerPageListChange,
        defaultSortOrder: 'desc',
        searchPanel: (props) => (<CampaignSearchPanel {...props}/>),
        clearSearch: true,
        searchField: (props) => (<CampaignSearchField {...props}/>)
      },
      sizePerPage: 10,
      showConfirmDialog: false,
      deleteId: null
    }
    this.ageOptions = FlyerCampaignConstants.AGE_OPTIONS
    this.familyOptions = FlyerCampaignConstants.FAMILY_OPTIONS
    this.areaOptions = FlyerCampaignConstants.AREA_OPTIONS
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      optionsTable: {
        sizePerPageList: [5, 10, 20, 50, 100],
        prePage: '<<', // Previous page button text
        nextPage: '>>', // Next page button text
        firstPage: 'First', // First page button text,
        sizePerPage: this.state.sizePerPage,
        lastPage: nextProps.instaUsers ? Math.ceil((nextProps.instaUsers.length / this.state.sizePerPage)).toString() : 'Last', // Last page button text
        sizePerPageDropDown: this.renderSizePerPageDropDown,
        onSizePerPageList: this.sizePerPageListChange,
        searchPanel: (props) => (<CampaignSearchPanel {...props}/>),
        btnGroup: this.createCustomButtonGroup.bind(this),
        clearSearch: true,
        searchField: (props) => (<CampaignSearchField {...props}/>)
      },
      flyerCampaigns: nextProps.data.map(flyer => {
        return {
          clientName: flyer.clientName,
          flyerName: flyer.flyerName,
          quantity: flyer.quantity,
          inStock: flyer.inStock,
          startDate: moment(flyer.startDate).format("YYYY-MM-DD"),
          endDate: moment(flyer.endDate).format("YYYY-MM-DD"),
          surveyOption: flyer.surveyOption,
          ageRange: flyer.ageRange,
          ageRangeFormat: this.getAgeRange(flyer.ageRange),
          family: flyer.family,
          familyFormat: this.getFamily(flyer.family),
          area: flyer.area,
          id: flyer.id
        }
      })
    })
  }

  getAgeRange(data) {
    if (!!data && data.length > 0) {
      let self = this;
      let arr = data.split(',').map(d => d.trim())
      return self.ageOptions.filter(a => arr.indexOf(a.value) !== -1).map(f => f.label).join(", ")
    } else return [];
  }

  getFamily(data) {
    if (!!data && data.length > 0) {
      let self = this;
      let arr = data.split(',').map(d => d.trim())
      return self.familyOptions.filter(f => arr.indexOf(f.value) !== -1).map(f => f.label).join(", ")
    } else return [];
  }

  getArea(data) {
    if (!!data && data.length > 0) {
      let self = this;
      let arr = data.split(',').map(d => d.trim())
      return self.areaOptions.filter(a => arr.indexOf(a.value) !== -1).map(f => f.label).join(", ")
    } else return [];
  }

  /**
   * delete campaign
   */
  showConfirmDialog() {
    this.setState({
      showConfirmDialog: true
    })
  }

  closeConfirmDialog() {
    this.setState({
      showConfirmDialog: false
    })
  }

  deleteCampaign(cell) {
    this.setState({deleteId: cell})
    this.showConfirmDialog()
  }

  /**
   * table funtion
   *
   */
  sizePerPageListChange(sizePerPage) {
    let flyerCampaigns = this.props.data;
    this.setState({
      optionsTable: {
        ...this.state.optionsTable,
        sizePerPage: sizePerPage,
        lastPage: Math.ceil((flyerCampaigns.length / sizePerPage)).toString(), // Last page button text
      },
      sizePerPage: sizePerPage
    })
  }

  onToggleDropDown(toggleDropDown) {
    toggleDropDown()
  }

  createCustomButtonGroup() {
    return (
        <div className="btn-group" role="group">
          <Button className="btn btn-info btn-raised" onClick={this.props.openCampaignFormDialog}>
                    <span>
                        <i className="glyphicon glyphicon-plus">登録</i>
                    </span>
          </Button>
        </div>
    );
  }

  renderSizePerPageDropDown(props) {
    return (
        <SizePerPageDropDown
            variation='dropup'
            className='dropdown-pagesize'
            {...props}
            onClick={() => this.onToggleDropDown(props.toggleDropDown)}/>
    )
  }

  renderActionButtons(cell, row) {
    return (
        <span className='action'>
          <a className='btn btn-raised btn-warning' onClick={() => this.deleteCampaign(row.id)}>
            <i className="fa fa-trash-o"/>
          </a>
          <a className='btn btn-raised btn-info' onClick={() => this.props.openCampaignFormDialog(row, 'UPDATE')}>
            <i className="fa fa-pencil-square-o"/>
          </a>
          <a className='btn btn-raised btn-info' onClick={() => this.props.openCampaignFormDialog(row, 'COPY')}>
            <i className="fa fa-copy"/>
          </a>
        </span>
    )
  }

  render() {
    return (
        <div>
          {/*allenfang bootstrap table*/}
          <BootstrapTable data={this.state.flyerCampaigns} striped hover bordered pagination search={true}
                          options={this.state.optionsTable}>
            <TableHeaderColumn row='0' rowSpan='2' dataField='clientName' dataSort={false}>クライアント名</TableHeaderColumn>
            <TableHeaderColumn row='0' rowSpan='2' dataField='flyerName' dataSort={false}>チラシ名</TableHeaderColumn>
            <TableHeaderColumn row='0' rowSpan='2' dataField='quantity' dataSort={false}>枚数</TableHeaderColumn>
            <TableHeaderColumn row='0' rowSpan='2' dataField='inStock' dataSort={false}>残り枚数</TableHeaderColumn>
            <TableHeaderColumn row='0' colSpan='2' thStyle={{'textAlign': 'center'}}
                               dataSort={false}>製作開始日</TableHeaderColumn>
            <TableHeaderColumn row='1' dataField='startDate' dataSort={false}>開始日</TableHeaderColumn>
            <TableHeaderColumn row='1' dataField='endDate' dataSort={false}>終了日</TableHeaderColumn>
            <TableHeaderColumn row='0' rowSpan='2' dataField='ageRangeFormat' dataSort={false}>年齢</TableHeaderColumn>
            <TableHeaderColumn row='0' rowSpan='2' dataField='familyFormat' dataSort={false}>世帯</TableHeaderColumn>
            <TableHeaderColumn row='0' rowSpan='2' dataField='area' dataSort={false}>地域</TableHeaderColumn>
            <TableHeaderColumn row='0' rowSpan='2' width={"140px"} dataField='id' dataFormat={this.renderActionButtons}
                               isKey>アクション</TableHeaderColumn>
          </BootstrapTable>

          <ConfirmDialog showModal={this.state.showConfirmDialog}
                         accept={FlyerCampaignService.deleteFlyerCampaign.bind(FlyerCampaignService, this.state.deleteId)}
                         close={this.closeConfirmDialog}
                         message={"こちらクライアントを削除でよろしいでしょうか？"}/>
        </div>
    )
  }
}

class CampaignSearchPanel extends React.Component {
  render() {
    return (
        <div className="react-bs-table-tool-bar ">
          <div className="col-lg-12 btn-group ">
            {this.props.searchField}
          </div>
        </div>
    )
  }
}

class CampaignSearchField extends React.Component {
  // It's necessary to implement getValue
  getValue() {
    return this.refs.campaignSearchField.value;
  }

  // It's necessary to implement setValue
  setValue(value) {
    this.refs.campaignSearchField.valuee = value;
  }

  render() {
    return (
        <div className="pull-right search-product">
          <div className="form-group is-empty">
            <input
                ref="campaignSearchField"
                className={`form-control`}
                type='text'
                defaultValue={this.props.defaultValue}
                placeholder="検索しましょう。"
                onKeyUp={this.props.search}/>
            <span className="material-input"/>
          </div>
          <button className="btn btn-primary"><i className="fa fa-search"/></button>
        </div>
    );
  }
}
