const ReportConstant = {
  LOAD: 'LOAD_REPORT',
  SHOW_REPORT: 'SHOW_REPORT',
  ERROR: 'ERROR_REPORT',
  URL: '/api/report',
  ACTION_TYPE: {
    SHOW_TEMPLATE_REPORT: 'SHOW_TEMPLATE_REPORT',
    SHOW_PRODUCT_REPORT: 'SHOW_PRODUCT_REPORT',
    SHOW_PRODUCT_REPORT_BY_ORDER_STATUS: 'SHOW_PRODUCT_REPORT_BY_ORDER_STATUS',
    SHOW_REPEAT_ORDER_REPORT: 'SHOW_REPEAT_ORDER_REPORT',
    SHOW_REPEAT_ORDER_CHART_REPORT: 'SHOW_REPEAT_ORDER_CHART_REPORT',
    SHOW_PRODUCT_PACKAGE_PLAN_REPORT: 'SHOW_PRODUCT_PACKAGE_PLAN_REPORT',
    SHOW_GROUP_PRODUCT_ORDER_REPORT: 'SHOW_GROUP_PRODUCT_ORDER_REPORT',
    SHOW_DASHBOARD_CUSTOM_GROUP_PRODUCT_REPORT: 'SHOW_DASHBOARD_CUSTOM_GROUP_PRODUCT_REPORT',
    SHOW_CHILDREN_AGE_GROUP_CHART_REPORT: 'SHOW_CHILDREN_AGE_GROUP_CHART_REPORT',
    SHOW_CHILDREN_AGE_GROUP_REPORT: 'SHOW_CHILDREN_AGE_GROUP_REPORT',
    SHOW_CHILDREN_SURVEY_DATA: 'SHOW_CHILDREN_SURVEY_DATA',
    ON_SEARCH_PAGE: 'ON_SEARCH_PAGE',
    CREATE_DASHBOARD_GROUP_PRODUCT: 'CREATE_DASHBOARD_GROUP_PRODUCT',
    CREATE_GROUP_PRODUCT: 'CREATE_GROUP_PRODUCT',
    DELETE_GROUP_PRODUCT: 'DELETE_GROUP_PRODUCT',
    DELETE_DASHBOARD: 'DELETE_DASHBOARD',
    GET_CATEGORIES: 'GET_CATEGORIES',
    GET_PRODUCTS: 'GET_PRODUCTS',
    CHANGE_LINE_CHART_VIEW : 'CHANGE_LINE_CHART_VIEW',
    CHANGE_BAR_CHART_VIEW : 'CHANGE_BAR_CHART_VIEW',
    TAB_CHANGE: 'TAB_CHANGE',
    CHANGE_DATE_RANGE: 'CHANGE_DATE_RANGE'
  },
  API: {
    TEMPLATE_REPORT_URL: '/api/reports/templates',
    PRODUCT_REPORT_URL: '/api/reports/products',
    PRODUCT_BY_ORDER_STATUS_REPORT_URL: '/api/reports/productsByOrderStatus',
    REPEAT_ORDER_URL: '/api/reports/repeat-order',
    REPEAT_ORDER_CHART_URL: '/api/reports/repeat-order/chart',
    PRODUCT_PACKAGE_PLAN_URL: '/api/reports/productPackagePlan',
    CHILDREN_AGE_GROUP_REPORT_URL: '/api/reports/childrenAgeGroup',
    CHILDREN_SURVEY_DATA_URL: '/api/reports/childrenAgeGroup/surveyData',
    CHILDREN_AGE_GROUP_REPORT_CHART_URL: '/api/reports/childrenAgeGroup/chart',
    CHILDREN_AGE_GROUP_REPORT_EXPORT_URL: '/api/reports/childrenAgeGroup/export',
    GROUP_PRODUCT_ORDER_URL: '/api/groupProducts',
    CREATE_GROUP_PRODUCT_URL: '/api/groupProduct',
    DASHBOARD_CUSTOM_GROUP_PRODUCT_URL: '/api/dashboards',
    CREATE_DASHBOARD_CUSTOM_GROUP_PRODUCT_URL: '/api/dashboard',
    GET_CATEGORIES_URL: '/api/categories',
    GET_PRODUCT_URL: '/api/category/products',

  },
  TAB_NAME: {
    PRODUCT_TEMPLATE: 'PRODUCT_TEMPLATE',
    REPEAT_ORDER: 'REPEAT_ORDER',
    PRODUCT_PACKAGE_PLAN: 'PRODUCT_PACKAGE_PLAN',
    GROUP_PRODUCT_ORDER: 'GROUP_PRODUCT_ORDER',
    CUSTOM_GROUP_PRODUCT_ORDER: 'CUSTOM_GROUP_PRODUCT_ORDER',
    PRODUCT_REPORT_BY_ORDER_STATUS: 'PRODUCT_REPORT_BY_ORDER_STATUS',
    CHILDREN_AGE_GROUP_REPORT: 'CHILDREN_AGE_GROUP_REPORT',
    SURVEY_DATA_LIST: 'SURVEY_DATA_LIST',
    CHILDREN_REPORT_DATA: 'CHILDREN_REPORT_DATA',
    EPSILON_DATA_TRACKING: 'EPSILON_DATA_TRACKING',
    FAILED_CHANGE_STATUS_ORDER: 'FAILED_CHANGE_STATUS_ORDER'
  },
  CHART_TYPE: {
    LINE: "LINE",
    PIE: "PIE",
    BAR: "BAR",
  },
  CHART_LINE_VIEW: {
    DAY: 'DAY',
    MONTH: 'MONTH',
    YEAR: 'YEAR'
  },
  CHART_BAR_VIEW: {
    DAY: 'DAY',
    MONTH: 'MONTH',
    YEAR: 'YEAR'
  },
  TABLE_TYPE: {
    SURVEY_DATA_LIST: 'SURVEY_DATA_LIST',
    CHILDREN_REPORT_DATA: 'CHILDREN_REPORT_DATA'
  }
}

export default ReportConstant
