/**
 * Created by PhuNH on 16/12/25.
 */
import Dispatcher from '../../common/dispatcher/Dispatcher.jsx'
import RakutenLicenseKeyConstant
  from '../constants/RakutenLicenseKeyConstant.jsx'

export default {
  checkingKey: () => {
    Dispatcher.dispatch({
      actionType: RakutenLicenseKeyConstant.CHECKING_KEY,
    })
  },
  checkKeyValid: () => {
    Dispatcher.dispatch({
      actionType: RakutenLicenseKeyConstant.CHECK_KEY_VALID,
    })
  },
  checkKeyInvalid: () => {
    Dispatcher.dispatch({
      actionType: RakutenLicenseKeyConstant.CHECK_KEY_INVALID,
    })
  },
  changeKey: () => {
    Dispatcher.dispatch({
      actionType: RakutenLicenseKeyConstant.CHANGE_KEY,
    })
  },
  addingKey: () => {
    Dispatcher.dispatch({
      actionType: RakutenLicenseKeyConstant.ADDING_KEY,
    })
  },
  addKey: () => {
    Dispatcher.dispatch({
      actionType: RakutenLicenseKeyConstant.ADD_KEY,
    })
  },
  addKeyFailed: (errorMessage) => {
    Dispatcher.dispatch({
      actionType: RakutenLicenseKeyConstant.ADD_KEY_FAILED,
      error: errorMessage,
    })
  },
  resetAddMessage: () => {
    Dispatcher.dispatch({
      actionType: RakutenLicenseKeyConstant.RESET_ADD,
    })
  },
  listKey: (data) => {
    Dispatcher.dispatch({
      actionType: RakutenLicenseKeyConstant.LIST_KEY,
      keyList: data,
    })
  },
  listKeyFailed: (message) => {
    Dispatcher.dispatch({
      actionType: RakutenLicenseKeyConstant.LIST_KEY_FAILED,
      error: message,
    })
  },
  resetListMessage: () => {
    Dispatcher.dispatch({
      actionType: RakutenLicenseKeyConstant.RESET_LIST,
    })
  },
}