/**
 * Created by PhuNH on 16/12/25.
 */
import React from 'react'
import request from 'reqwest'
import when from 'when'
import Constants from '../../common/constants/Constants.jsx'
import RakutenLicenseKeyAction from '../actions/RakutenLicenseKeyAction.jsx'
import RakutenLicenseKeyConstant from '../constants/RakutenLicenseKeyConstant.jsx'
import RakutenLicenseKeyStore from '../stores/RakutenLicenseKeyStore.jsx'
import LoginAction from '../../authentication/actions/LoginAction.jsx'
import RakutenService from './RakutenService.jsx'

class RakutenLicenseKeyService {
    checkKeyValidity(keyString) {
        RakutenLicenseKeyAction.checkingKey();
        when(request({
            url: RakutenLicenseKeyConstant.CHECK_URL,
            method: 'GET',
            crossOrigin: true,
            headers: {
                'Csrf-Token': "nocheck",
                'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
            },
            type: 'json',
            data: { keyString: keyString }
        })).then(function (response) {
            switch (response.code) {
                case Constants.RESPONSE_STATUS.SUCCESS:
                    RakutenLicenseKeyAction.checkKeyValid();
                    break;
                case Constants.RESPONSE_STATUS.BAD_REQUEST:
                    RakutenLicenseKeyAction.checkKeyInvalid();
                    break;
                case Constants.RESPONSE_STATUS.UNAUTHORIZED:
                    LoginAction.loginUser({});
                    break;
                default:
                    break;
            }
        })
    }

    changeKey() {
        if (RakutenLicenseKeyStore.getIsChecked() || RakutenLicenseKeyStore.getIsSubmitted())
            RakutenLicenseKeyAction.changeKey();
    }

    resetAddMessage() {
        RakutenLicenseKeyAction.resetAddMessage()
    }

    resetListMessage() {
        RakutenLicenseKeyAction.resetListMessage()
    }

    addKey(keyString, expirationDate) {
        RakutenLicenseKeyAction.addingKey();
        when(request({
            url: RakutenLicenseKeyConstant.ADD_URL,
            method: 'POST',
            crossOrigin: true,
            headers: {
                'Csrf-Token': "nocheck",
                'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
            },
            type: 'json',
            data: {
                keyString: keyString,
                expirationDate: expirationDate
            }
        })).then(function (response) {
            switch (response.code) {
                case Constants.RESPONSE_STATUS.SUCCESS:
                    RakutenLicenseKeyAction.addKey();
                    break;
                case Constants.RESPONSE_STATUS.BAD_REQUEST:
                    RakutenLicenseKeyAction.addKeyFailed(response.error.RakutenLicenseKey[0]);
                    break;
                case Constants.RESPONSE_STATUS.UNAUTHORIZED:
                    LoginAction.loginUser({});
                    break;
                default:
                    break;
            }
        })
    }

    listKey() {
        when(request({
            url: RakutenLicenseKeyConstant.LIST_URL,
            method: 'GET',
            crossOrigin: true,
            headers: {
                'Csrf-Token': "nocheck",
                'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
            },
            type: 'json'
        })).then(function (response) {
            switch (response.code) {
                case Constants.RESPONSE_STATUS.SUCCESS:
                    RakutenService.getActiveKey('/ec-systems');
                    RakutenLicenseKeyAction.listKey(response.data);
                    break;
                case Constants.RESPONSE_STATUS.SERVER_ERROR:
                    RakutenLicenseKeyAction.listKeyFailed(response.message);
                    break;
                case Constants.RESPONSE_STATUS.UNAUTHORIZED:
                    LoginAction.loginUser({});
                    break;
                default:
                    break;
            }
        })
    }
}

export default new RakutenLicenseKeyService()
