import React from 'react'
import {Bar, Line, Pie} from 'react-chartjs-2';
import 'chartjs-plugin-datalabels'
import ReportConstant from '../../constants/ReportConstant.jsx'
import Chart from "react-google-charts";

class GroupProductChart extends React.Component {

  constructor () {
    super()
  }

  createChart () {
    switch (this.props.type) {
      case ReportConstant.CHART_TYPE.BAR:
        return this.barChart()
      case ReportConstant.CHART_TYPE.PIE:
        return this.pieChart()
      default:
        return ""
    }
  }

  barChart () {
    let self = this;
    let datasets = (this.props.datasets && this.props.labels) ? this.props.datasets.map(function (item, index) {
      return {
        label: self.props.labels[index],
        data: [item],
        backgroundColor: self.props.colors[index],
        borderColor: self.props.colors[index],
      }
    }) : [];
    let chartData = {
      datasets: datasets,
      labels: [""]
    };

    let options =  {
      maintainAspectRatio: false,
      spanGaps: false,
      plugins: {
        filler: {
          propagate: false
        },
        datalabels: {
          display: false
        }
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            min: 0
          }
        }]
      }
    };

    return <Bar data={chartData} options={options} height={350} redraw generateLegend/>
  }

  pieChart () {
    var data = [];
    if(this.props.datasets && this.props.labels){
      let self = this;
      data = this.props.datasets.map(function (item, index) {
        return [self.props.labels[index], item]
      });
    }
    let total = data.reduce((sum, item) => {
      return sum += item[1];
    }, 0);
    data.unshift(["Labels", "Data"]);
    return (<Chart
      chartType="PieChart"
      data={data}
      options={{
        colors: this.props.colors,
        pieSliceText: 'value-and-percentage',
        sliceVisibilityThreshold: 0,
        title: "合計: " + total + "件",
        titleTextStyle: {
          color: 'red'
        }
      }}
      width={"550px"}
      height={"400px"}
      legend_toggle
      />)
  }

  render () {

    return (
      this.createChart()
    )
  }
}

export default GroupProductChart
