/**
 * Created by ThangKC 8/11/20.
 * User: Thangkc
 * Date: 8/11/20
 * Time: 2:02 PM
 */

import React from 'react'
import {BootstrapTable, SizePerPageDropDown, TableHeaderColumn} from 'react-bootstrap-table'
import MagazineMailService from '../../services/MagazineMailService.jsx'
import ConfirmDialog from "../../../common/components/dialogs/ConfirmDialog.jsx"
import MagazineMailConstants from '../../constants/MagazineMailConstants.jsx'
import OrderConstant from '../../../order/constants/OrderConstant.jsx'
import Button from 'react-bootstrap/lib/Button'

export default class PlanTableComponent extends React.Component {
  constructor() {
    super()
    this.onToggleDropDown = this.onToggleDropDown.bind(this)
    this.renderSizePerPageDropDown = this.renderSizePerPageDropDown.bind(this)
    this.sizePerPageListChange = this.sizePerPageListChange.bind(this)
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.showConfirmDialog = this.showConfirmDialog.bind(this)
    this.renderDeleteButton = this.renderDeleteButton.bind(this)
    this.renderConditions = this.renderConditions.bind(this)
    this.renderStatus = this.renderStatus.bind(this)
    this.state = {
      optionsTable: {
        sizePerPageList: [5, 10, 20, 50, 100],
        prePage: '<<', // Previous page button text
        nextPage: '>>', // Next page button text
        firstPage: 'First', // First page button text
        lastPage: 'Last', // Last page button text
        sizePerPageDropDown: this.renderSizePerPageDropDown,
        onSizePerPageList: this.sizePerPageListChange,
        defaultSortName: 'runtime',  // default sort column runtime
        defaultSortOrder: 'desc',
        searchPanel: (props) => (<PlanSearchPanel {...props}/>),
        clearSearch: true,
        searchField: (props) => (<PlanSearchField {...props}/>)
      },
      sizePerPage: 10,
      showConfirmDialog: false,
      deleteId: null
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      optionsTable: {
        sizePerPageList: [5, 10, 20, 50, 100],
        prePage: '<<', // Previous page button text
        nextPage: '>>', // Next page button text
        firstPage: 'First', // First page button text,
        sizePerPage: this.state.sizePerPage,
        lastPage: nextProps.instaUsers ? Math.ceil((nextProps.instaUsers.length / this.state.sizePerPage)).toString() : 'Last', // Last page button text
        sizePerPageDropDown: this.renderSizePerPageDropDown,
        onSizePerPageList: this.sizePerPageListChange,
        searchPanel: (props) => (<PlanSearchPanel {...props}/>),
        btnGroup: this.createCustomButtonGroup.bind(this),
        clearSearch: true,
        searchField: (props) => (<PlanSearchField {...props}/>)
      }
    })
  }

  /**
   * delete plan
   */
  showConfirmDialog() {
    this.setState({
      showConfirmDialog: true
    })
  }

  closeConfirmDialog() {
    this.setState({
      showConfirmDialog: false
    })
  }

  deletePlan(cell) {
    this.setState({deleteId: cell})
    this.showConfirmDialog()
  }

  /**
   * table funtion
   *
   */
  sizePerPageListChange(sizePerPage) {
    let plans = this.props.data;
    this.setState({
      optionsTable: {
        ...this.state.optionsTable,
        sizePerPage: sizePerPage,
        lastPage: Math.ceil((plans.length / sizePerPage)).toString(), // Last page button text
      },
      sizePerPage: sizePerPage
    })
  }

  onToggleDropDown(toggleDropDown) {
    toggleDropDown()
  }

  renderSizePerPageDropDown(props) {
    return (
        <SizePerPageDropDown
            variation='dropup'
            className='dropdown-pagesize'
            {...props}
            onClick={() => this.onToggleDropDown(props.toggleDropDown)}/>
    )
  }

  renderDeleteButton(cell, row) {
    return (
        <span className='action'>
                {
                  row.status === 'WAITING' ?
                      <a className='btn btn-raised btn-warning' onClick={() => this.deletePlan(cell)}>
                        <i className="fa fa-trash-o"/>
                      </a> : null
                }
          {
            row.status === 'WAITING' ?
                <a className='btn btn-raised btn-info' onClick={() => this.props.openPlanFormDialog(row.identifier)}>
                  <i className="fa fa-pencil-square-o"/>
                </a> : null
          }
                </span>
    )
  }

  renderConditions(cell, row) {
    let ecSite;
    switch (row.ecSite) {
      case 'COLOR_ME':
        ecSite = 'ColorMe'
        break;
      case 'RAKUTEN':
        ecSite = 'Rakuten'
        break;
      default:
        ecSite = 'ColorMe, Rakuten'
    };
    return (
        <ul style={{padding: 0}}>
          <li className=""><b>注文ステータス：</b>{row.orderStatus.split(",").map(function (s) {
            return OrderConstant.STATUS_JP[s.trim()]
          }).join(", ")}</li>
          <li className=""><b>注文日：</b>{row.orderStartDate} ~ {row.orderEndDate}</li>
          <li className=""><b>出荷日：</b>{row.shippedStartDate} ~ {row.shippedEndDate}</li>
          <li className=""><b>EC サイト：</b>{ecSite}</li>
        </ul>
    )
  }

  renderMailName(cell, row) {
    return (
        <ul style={{padding: 0}}>
          <li>{row.emailTemplate.name}</li>
          {row.emailTemplate.isDeleted ?
              <li className="text-danger">メールテンプレートが削除されました。他のメールテンプレートを選択してください。</li>
              : null}
        </ul>
    )
  }

  renderStatus(cell, row) {
    return (
      row.status === "DONE" ?
        <ul style={{padding: 0}}>
          <li>{MagazineMailConstants.STATUS_JP[row.status.trim()]}</li>
          <li><b>配信成功: </b>{row.numberOfSuccessMails}<b>件</b></li>
          <li><b>配信失敗: </b>{row.numberOfFailureMails}<b>件</b></li>
        </ul> :
        MagazineMailConstants.STATUS_JP[row.status]
    )
  }

  createCustomButtonGroup() {
    return (
        <div className="btn-group" role="group">
          <Button className="btn btn-info btn-raised" onClick={this.props.openPlanFormDialog}>
                    <span>
                        <i className="glyphicon glyphicon-plus">登録</i>
                    </span>
          </Button>
        </div>
    );
  }

  trClassFormat(rowData, rIndex) {
    return rowData.emailTemplate.isDeleted ? 'error-pending-creation' : '';
  }

  render() {
    return (
        <div>
          <BootstrapTable data={this.props.data} striped hover bordered pagination search={true}
                          trClassName={this.trClassFormat} options={this.state.optionsTable}>
            <TableHeaderColumn dataField='name' dataSort={true}>計画名</TableHeaderColumn>
            <TableHeaderColumn dataField='emailName' dataFormat={this.renderMailName}
                               dataSort={true}>メール名</TableHeaderColumn>
            <TableHeaderColumn dataField='emailTitle' dataSort={false}>メール件名</TableHeaderColumn>
            <TableHeaderColumn dataField='status' dataSort={false}
                               dataFormat={this.renderConditions}>対象</TableHeaderColumn>
            <TableHeaderColumn dataField='runtime' dataSort={true} columnClassName=""
                               width={"150px"}>送信日時</TableHeaderColumn>
            <TableHeaderColumn dataField='status' dataSort={true} dataFormat={this.renderStatus}
                               width={"110px"}>ステータス</TableHeaderColumn>
            <TableHeaderColumn dataField='identifier' isKey dataFormat={this.renderDeleteButton} export={false}
                               width={"90px"}>アクション</TableHeaderColumn>
          </BootstrapTable>
          <ConfirmDialog showModal={this.state.showConfirmDialog}
                         accept={MagazineMailService.deletePlan.bind(MagazineMailService, this.state.deleteId)}
                         close={this.closeConfirmDialog}
                         message={"計画を削除します。よろしいですか？"}/>
        </div>
    )
  }
}

class PlanSearchPanel extends React.Component {
  render() {
    return (
        <div className="react-bs-table-tool-bar ">
          <div className="col-lg-12 btn-group ">
            {this.props.searchField}
          </div>
        </div>
    )
  }
}

class PlanSearchField extends React.Component {
  // It's necessary to implement getValue
  getValue() {
    return this.refs.planSearchField.value;
  }

  // It's necessary to implement setValue
  setValue(value) {
    this.refs.planSearchField.valuee = value;
  }

  render() {
    return (
        <div className="pull-right search-product">
          <div className="form-group is-empty">
            <input
                ref="planSearchField"
                className={`form-control`}
                type='text'
                defaultValue={this.props.defaultValue}
                placeholder="検索しましょう。"
                onKeyUp={this.props.search}/>
            <span className="material-input"/>
          </div>
          <button className="btn btn-primary"><i className="fa fa-search"/></button>
        </div>
    );
  }
}
