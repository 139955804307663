/**
 * Created by PhuNH on 16/12/24.
 */
import React from 'react';
import { Tabs, Tab, Nav, NavItem, Row, Col} from 'react-bootstrap/lib'
import AuthenticatedComponent from '../../common/components/AuthenticatedComponent.jsx'
import RakutenLicenseKeyComponent from './rakuten/licenseKey/RakutenLicenseKeyComponent.jsx'
import YahooJPAccessTokenComponent from './yahoo/YahooJPAccessTokenComponent.jsx'

export default AuthenticatedComponent(
    class ECSystemComponent extends React.Component {
        constructor() {
            super();
        }

        render() {
            if (this.props.userLoggedIn) return (
                <div className="tab-container">
                    <Tabs defaultActiveKey={1} id="ec-systems">
                      <Tab eventKey={1} title="Rakuten">
                        <RakutenLicenseKeyComponent/>
                      </Tab>
                        <Tab eventKey={2} title="Yahoo JP">
                            <YahooJPAccessTokenComponent/>
                        </Tab>
                    </Tabs>
                </div>
            );
            else return (
                <div></div>
            )
        }
    }
)