import React from "react"
import DateTimeFormat from "../../../common/components/DateTimeFormat.jsx";
import EpsilonDataTrackingAction from "../../actions/EpsilonDataTrackingAction.jsx";
class EpsilonDataTrackingRow extends React.Component {
    constructor(props) {
        super(props);
    }

    getOrderStatus(status) {
        switch (status) {
            case 'SHIPPED':
                return '出荷済み';
            case 'CANCEL':
                return 'キャンセル';
            default:
                return '';
        }
    }

    render() {
        const { order } = this.props
        return (
            <tr>
                <td style={{ textAlign: "center" }}>
                    <input
                        type="checkbox"
                        checked={order.checked}
                        onChange={(e) => EpsilonDataTrackingAction.checkOrder({
                            ...order,
                            checked: e.target.checked
                        })}
                    />
                </td>
                <td>{order.orderNumber}</td>
                <td>{this.getOrderStatus(order.status)}</td>
                <td><DateTimeFormat data={order.orderDate}/></td>
                <td style={{ textAlign: "center" }} className="orSttCol">
                    {order.epsilonLinked ? "成功" : "失敗"}
                </td>
            </tr>
        );
    }
}

export default EpsilonDataTrackingRow