import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Select from 'react-select';
import {Button, Form, FormGroup, FormControl, Col} from 'react-bootstrap/lib';
import {DateRangePicker} from "react-dates";
import moment from "moment";
import FlyerCampaignService from "../../services/FlyerCampaignService.jsx";

export default class CampaignForm extends React.Component {
  constructor(props) {
    super();
    this.state = this.initState();
    this.ageOptions = [
      {value: 'pregnant', label: 'マタニティ'},
      {value: '0', label: '0歳代'},
      {value: '1', label: '1歳代'},
      {value: '2', label: '2歳代'},
      {value: '3', label: '3歳代'},
      {value: '4', label: '4歳代'},
      {value: '5', label: '5歳代'},
      {value: '6', label: '6歳代'},
      {value: '7', label: '7歳代以上'},
    ];
    this.familyOptions = [
      {value: 'PARENTS', label: 'ママパパ世帯'},
      {value: 'GRAND_PARENTS', label: '祖父母世帯'},
    ];
    this.areaOptions = [
      {value: '北海道', label: '北海道'},
      {value: '青森県', label: '青森県'},
      {value: '岩手県', label: '岩手県'},
      {value: '宮城県', label: '宮城県'},
      {value: '秋田県', label: '秋田県'},
      {value: '山形県', label: '山形県'},
      {value: '福島県', label: '福島県'},
      {value: '茨城県', label: '茨城県'},
      {value: '栃木県', label: '栃木県'},
      {value: '群馬県', label: '群馬県'},
      {value: '埼玉県', label: '埼玉県'},
      {value: '千葉県', label: '千葉県'},
      {value: '東京都', label: '東京都'},
      {value: '神奈川県', label: '神奈川県'},
      {value: '新潟県', label: '新潟県'},
      {value: '富山県', label: '富山県'},
      {value: '石川県', label: '石川県'},
      {value: '福井県', label: '福井県'},
      {value: '山梨県', label: '山梨県'},
      {value: '長野県', label: '長野県'},
      {value: '岐阜県', label: '岐阜県'},
      {value: '静岡県', label: '静岡県'},
      {value: '愛知県', label: '愛知県'},
      {value: '三重県', label: '三重県'},
      {value: '滋賀県', label: '滋賀県'},
      {value: '京都府', label: '京都府'},
      {value: '大阪府', label: '大阪府'},
      {value: '兵庫県', label: '兵庫県'},
      {value: '奈良県', label: '奈良県'},
      {value: '和歌山県', label: '和歌山県'},
      {value: '鳥取県', label: '鳥取県'},
      {value: '島根県', label: '島根県'},
      {value: '岡山県', label: '岡山県'},
      {value: '広島県', label: '広島県'},
      {value: '山口県', label: '山口県'},
      {value: '徳島県', label: '徳島県'},
      {value: '香川県', label: '香川県'},
      {value: '愛媛県', label: '愛媛県'},
      {value: '高知県', label: '高知県'},
      {value: '福岡県', label: '福岡県'},
      {value: '佐賀県', label: '佐賀県'},
      {value: '長崎県', label: '長崎県'},
      {value: '熊本県', label: '熊本県'},
      {value: '大分県', label: '大分県'},
      {value: '宮崎県', label: '宮崎県'},
      {value: '鹿児島県', label: '鹿児島県'},
      {value: '沖縄県', label: '沖縄県'},
    ];
    this.changeClientName = this.changeClientName.bind(this);
    this.changeFlyerName = this.changeFlyerName.bind(this);
    this.changeQuantity = this.changeQuantity.bind(this);
    this.onFocusCampaignDateChange = this.onFocusCampaignDateChange.bind(this);
    this.onCampaignDateChange = this.onCampaignDateChange.bind(this);
    this.changeAgeRange = this.changeAgeRange.bind(this);
    this.changeFamily = this.changeFamily.bind(this);
    this.changeArea = this.changeArea.bind(this);
    this.getAgeRange = this.getAgeRange.bind(this);
    this.getFamily = this.getFamily.bind(this);
    this.getArea = this.getArea.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.changeHasSurveyOption = this.changeHasSurveyOption.bind(this);
    this.changeDoNotHaveSurveyOption = this.changeDoNotHaveSurveyOption.bind(this)
  }

  initState() {
    return {
      focusedCampaignDate: null,
      clientName: null,
      flyerName: null,
      quantity: null,
      startCampaignDate: moment(),
      endCampaignDate: moment(),
      ageRange: [],
      family: [],
      area: [],
      hasSurvey: false,
      doNotHaveSurvey: false,
      surveyOption: null
    }
  }

  getState() {
    return {
      focusedCampaignDate: this.state ? this.state.focusedCampaignDate : null,
      clientName: null,
      flyerName: null,
      quantity: null,
      startCampaignDate: moment(),
      endCampaignDate: moment(),
      ageRange: [],
      family: [],
      area: [],
      hasSurvey: false,
      doNotHaveSurvey: false,
      surveyOption: null
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.flyerCampaign && Object.keys(nextProps.flyerCampaign).length !== 0) {
      this.setState({
        clientName: nextProps.flyerCampaign.clientName,
        flyerName: nextProps.flyerCampaign.flyerName,
        quantity: nextProps.flyerCampaign.quantity,
        startCampaignDate: moment(nextProps.flyerCampaign.startDate),
        endCampaignDate: moment(nextProps.flyerCampaign.endDate),
        ageRange: this.getAgeRange(nextProps.flyerCampaign.ageRange),
        family: this.getFamily(nextProps.flyerCampaign.family),
        area: this.getArea(nextProps.flyerCampaign.area),
        hasSurvey: nextProps.flyerCampaign.surveyOption === 2 || nextProps.flyerCampaign.surveyOption === 1,
        doNotHaveSurvey: nextProps.flyerCampaign.surveyOption === 2 || nextProps.flyerCampaign.surveyOption === 0,
        surveyOption: nextProps.flyerCampaign.surveyOption
      })
    } else {
      this.state = this.initState()
    }
  }

  changeClientName(clientName) {
    this.setState({clientName: clientName.target.value});
  }

  changeFlyerName(flyerName) {
    this.setState({flyerName: flyerName.target.value});
  }

  changeQuantity(quantity) {
    this.setState({quantity: quantity.target.value.replace(/\D/,'')});
  }

  onFocusCampaignDateChange(onFocusedInput) {
    this.setState({focusedCampaignDate: onFocusedInput});
  }

  onCampaignDateChange({startDate, endDate}) {
    this.setState({
      startCampaignDate: startDate,
      endCampaignDate: endDate
    });
  }

  changeAgeRange(ageRange) {
    this.setState({ageRange: ageRange});
  }

  changeFamily(family) {
    this.setState({family: family});
  }

  changeArea(area) {
    this.setState({area: area});
  }

  changeHasSurveyOption() {
    let checked = !this.state.hasSurvey
    let option = (checked && this.state.doNotHaveSurvey) ? 2 : checked ? 1 : (!!this.state.doNotHaveSurvey) ? 0 : null
    this.setState({ageRange: []});
    this.setState({hasSurvey: checked, surveyOption: option})
  }

  changeDoNotHaveSurveyOption() {
    let checked = !this.state.doNotHaveSurvey
    let option = (this.state.hasSurvey && checked) ? 2 : (!!this.state.hasSurvey) ? 1 : checked ? 0 : null
    this.setState({doNotHaveSurvey: checked, surveyOption: option})
  }

  getAgeRange(data) {
    if (!!data && data.length > 0) {
      let self = this;
      let arr = data.split(',').map(d => d.trim())
      return self.ageOptions.filter(a => arr.indexOf(a.value) !== -1)
    } else return [];
  }

  getFamily(data) {
    if (!!data && data.length > 0) {
      let self = this;
      let arr = data.split(',').map(d => d.trim())
      return self.familyOptions.filter(f => arr.indexOf(f.value) !== -1)
    } else return [];
  }

  getArea(data) {
    if (!!data && data.length > 0) {
      let self = this;
      let arr = data.split(',').map(d => d.trim())
      return self.areaOptions.filter(a => arr.indexOf(a.value) !== -1)
    } else return [];
  }

  selectAll(selectedItem, myCase) {
    let self = this;
    let result = selectedItem;
    let options;
    switch (myCase) {
      case 1:
        options = self.ageOptions
        break;
      case 2:
        options = self.familyOptions
        break;
      case 3:
        options = self.areaOptions
        break;
    }
    options.forEach(function (o) {
      let count = 0;
      result.forEach(function (i) {
        if (i.value === o.value) count++
      })
      if (!count) result.push(o);
    })
    switch (myCase) {
      case 1:
        self.setState({ageRange: result})
        break;
      case 2:
        self.setState({family: result})
        break;
      case 3:
        self.setState({area: result})
        break;
    }
  }

  formSubmit() {
    let campaignForm = {
      identifier: this.props.flyerCampaign.id,
      clientName: this.state.clientName,
      flyerName: this.state.flyerName,
      quantity: this.state.quantity,
      startDate: moment(this.state.startCampaignDate).format('YYYY/MM/DD'),
      endDate: moment(this.state.endCampaignDate).format('YYYY/MM/DD'),
      surveyOption: this.state.surveyOption,
      ageRange: this.state.ageRange.map(function (a) {
        return a.value
      }).join(','),
      family: this.state.family.map(function (f) {
        return f.value
      }).join(','),
      area: this.state.area.map(function (a) {
        return a.value
      }).join(','),
    }
    let temp = (this.props.flyerCampaign && this.props.flyerCampaign.id)
        ? FlyerCampaignService.updateFlyerCampaign.bind(FlyerCampaignService, this.props.flyerCampaign.id, campaignForm)
        : FlyerCampaignService.addFlyerCampaign.bind(FlyerCampaignService, campaignForm);
    temp();
    this.state = this.initState();
    this.props.closeCampaignFormDialog();
  }

  render() {
    let saveBtnAtr = {};
    if (!this.state.clientName || !this.state.flyerName || !this.state.quantity
        || !this.state.startCampaignDate || !this.state.endCampaignDate
        || (this.state.ageRange.length < 1 && this.state.hasSurvey) || (this.state.surveyOption !== 0 && this.state.surveyOption === null) || this.state.family.length < 1
        || this.state.area.length < 1) {
      saveBtnAtr.disabled = true;
    }
    return (
        <Modal show={this.props.isOpenCampaignFormDialog} onHide={this.props.closeCampaignFormDialog} bsSize="lg">
          <Modal.Header>
            <Modal.Title>クライアント登録・編集</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form horizontal>
              <FormGroup>
                {/*client name*/}
                <Col sm={3} style={this.alignRightText}>
                  クライアント名 <span className="text-danger">(必須)</span>:
                </Col>
                <Col sm={7}>
                  <FormControl type="text" placeholder="クライアント名をご入力ください" onChange={this.changeClientName}
                               value={this.state.clientName}
                  />
                </Col>
              </FormGroup>

              <FormGroup>
                {/*flyer name*/}
                <Col sm={3} style={this.alignRightText}>
                  チラシ名 <span className="text-danger">(必須)</span>:
                </Col>
                <Col sm={7}>
                  <FormControl type="text" placeholder="チラシ名をご入力ください" onChange={this.changeFlyerName}
                               value={this.state.flyerName}
                  />
                </Col>
              </FormGroup>

              <FormGroup>
                {/*quantity*/}
                <Col sm={3} style={this.alignRightText}>
                  枚数 <span className="text-danger">(必須)</span>:
                </Col>
                <Col sm={7}>
                  <FormControl type="number" min={"0"} pattern="[0-9]*"
                               onChange={this.changeQuantity}
                               value={this.state.quantity}
                  />
                </Col>
              </FormGroup>

              <FormGroup>
                {/*campaign date*/}
                <Col sm={3} style={this.alignRightText}>
                  製作開始日 <span className="text-danger">(必須)</span>:
                </Col>
                <Col sm={9}>
                  <DateRangePicker
                      startDatePlaceholderText="開始日"
                      endDatePlaceholderText="完了日"
                      monthFormat="YYYY[年]MMMM"
                      phrases={{
                        closeDatePicker: '关闭',
                        clearDates: '清除日期',
                      }}
                      startDate={this.state.startCampaignDate}
                      endDate={this.state.endCampaignDate}
                      focusedInput={this.state.focusedCampaignDate}
                      onDatesChange={this.onCampaignDateChange}
                      onFocusChange={this.onFocusCampaignDateChange}
                      isOutsideRange={() => false}
                      hideKeyboardShortcutsPanel
                      minimumNights={0}
                      showClearDates
                      readOnly/>
                </Col>
              </FormGroup>

              <FormGroup>
                <Col sm={3} style={this.alignRightText}>
                  アンケート回答有無 <span className="text-danger">(必須)</span>:
                </Col>
                <Col sm={3}>
                  <input type="checkbox"
                         checked={this.state.hasSurvey}
                         onChange={this.changeHasSurveyOption}/> 有
                </Col>
                <Col sm={3}>
                  <input type="checkbox"
                         checked={this.state.doNotHaveSurvey}
                         onChange={this.changeDoNotHaveSurveyOption}/> 無
                </Col>
              </FormGroup>

              {
                this.state.hasSurvey ? <FormGroup >
                  {/*age range*/}
                  <Col sm={3} style={this.alignRightText}>
                    年齢 <span className="text-danger">(必須)</span>:
                  </Col>
                  <Col sm={7}>
                    <Select multi={true} options={this.ageOptions} onChange={this.changeAgeRange}
                            value={this.state.ageRange} placeholder="年齢をご選択ください"/>
                  </Col>
                  <Col sm={2}>
                    <Button className="btn btn-raised btn-info right-side-btn-form"
                            onClick={this.selectAll.bind(this, this.state.ageRange, 1)}>全体</Button>
                  </Col>
                </FormGroup> : null
              }

              <FormGroup>
                {/*family*/}
                <Col sm={3} style={this.alignRightText}>
                  世帯 <span className="text-danger">(必須)</span>:
                </Col>
                <Col sm={7}>
                  <Select multi={true} options={this.familyOptions} onChange={this.changeFamily}
                          value={this.state.family} placeholder="世帯をご選択ください"/>
                </Col>
                <Col sm={2}>
                  <Button className="btn btn-raised btn-info right-side-btn-form"
                          onClick={this.selectAll.bind(this, this.state.family, 2)}>全体</Button>
                </Col>
              </FormGroup>

              <FormGroup>
                {/*area*/}
                <Col sm={3} style={this.alignRightText}>
                  地域 <span className="text-danger">(必須)</span>:
                </Col>
                <Col sm={7}>
                  <Select multi={true} options={this.areaOptions} onChange={this.changeArea}
                          value={this.state.area} placeholder="地域をご選択ください"/>
                </Col>
                <Col sm={2}>
                  <Button className="btn btn-raised btn-info right-side-btn-form"
                          onClick={this.selectAll.bind(this, this.state.area, 3)}>全体</Button>
                </Col>
              </FormGroup>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button className="btn btn-raised btn-info"
                    onClick={this.formSubmit} {...saveBtnAtr}>保存</Button>
            <Button className="btn btn-raised btn-danger" onClick={this.props.closeCampaignFormDialog}>キャンセル</Button>
          </Modal.Footer>
        </Modal>
    );
  }
}
