import React from 'react'
import {
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Tabs,
  Tab,
  Nav,
  NavItem,
  Row
} from 'react-bootstrap/lib'
import AuthenticatedComponent from '../../common/components/AuthenticatedComponent.jsx'
import Theatre1810Component from './theatre/theatre1810/Theatre1810Component.jsx'
import Theatre1905Component from './theatre/theatre1905/Theatre1905Component.jsx'
import StudioAliceBaby1812Component from './studioalicebaby1812/StudioAliceBaby1812Component.jsx'
import CampaignTheatreService from '../services/CampaignTheatreService.jsx'
import CampaignTheatreStore from '../stores/CampaignTheatreStore.jsx'
class CampaignTab extends React.Component {

  constructor () {
    super()
    this.theatre = {
      theatre1810: {
        service: new CampaignTheatreService('theatre1810'),
        store: new CampaignTheatreStore('theatre1810')
      },
      theatre1905: {
        service: new CampaignTheatreService('theatre1905'),
        store: new CampaignTheatreStore('theatre1905')
      }
    }
  }

  render () {
    return (
      <div className="tab-container">
        <Tabs defaultActiveKey={1} id="campaign">
          <Tab eventKey={1} title="Theatre1905データ">
            <Theatre1905Component
              service = {this.theatre.theatre1905.service}
              store   = {this.theatre.theatre1905.store} />
          </Tab>
          <Tab eventKey={2} title="Theatre1810データ">
            <Theatre1810Component
              service = {this.theatre.theatre1810.service}
              store   = {this.theatre.theatre1810.store} />
          </Tab>
          <Tab eventKey={3} title="StudioAliceBaby1812データ">
            <StudioAliceBaby1812Component/>
          </Tab>
        </Tabs>
      </div>
    )
  }

}

export default AuthenticatedComponent(CampaignTab)
