import React from 'react'
import moment from 'moment'
import {DateRangePicker} from 'react-dates'
import LoadingButton from '../../../../common/components/LoadingButton.jsx'
import AuthenticatedComponent from '../../../../common/components/AuthenticatedComponent.jsx'
import OurPagingComponent from '../../../../common/components/paging/OurPagingComponent.jsx'
import ReportService from '../../../services/ReportService.jsx'
import ReportConstant from '../../../constants/ReportConstant.jsx'
import ReportAction from '../../../actions/ReportAction.jsx'
import GroupProductChart from "../GroupProductChart.jsx";
import GroupProductOrderTable from "./GroupProductOrderTable.jsx";
import GroupProductOrderStore from '../../../stores/GroupProductOrderStore.jsx'

class GroupProductOrderComponent extends React.Component {
  constructor() {
    super()
    this.state = this._getState()
    this._onDateChange = this._onDateChange.bind(this)
    this._onFocusChange = this._onFocusChange.bind(this)
    this.changePage = this.changePage.bind(this)
    this._onChange = this._onChange.bind(this)

    moment.locale('ja')
  }

  componentDidMount() {
    ReportService.getGroupProductOrderReport(this.state.selectedStartDate, this.state.selectedEndDate, 1)
    GroupProductOrderStore.addChangeListener(this._onChange)
  }

  componentWillUnmount() {
    GroupProductOrderStore.removeChangeListener(this._onChange)
  }

  _onDateChange({startDate, endDate}) {
    this.setState({selectedStartDate: startDate, selectedEndDate: endDate})
  }

  _onFocusChange(focusedInput) {
    this.setState({focusedInput: focusedInput})
  }

  _onChange() {
    this.setState(this._getState())
  }

  _getState() {
    return {
      selectedStartDate: this.state ? this.state.selectedStartDate ||
        moment().subtract(1, 'months') : moment().subtract(1, 'months'),
      selectedEndDate: this.state
        ? this.state.selectedEndDate || moment()
        : moment(),
      focusedInput: null,
      ordersDTO: GroupProductOrderStore.getGroupProductOrdersData(),
      chartData: GroupProductOrderStore.getChartData(),
      errorMessage: null,
      loading: GroupProductOrderStore.isLoading(),
    }
  }

  handleGettingReportClick(e) {
    ReportAction.load();
    ReportService.getGroupProductOrderReport(this.state.selectedStartDate,
      this.state.selectedEndDate, 1);
  }

  changePage(currentPage) {
    ReportAction.load();
    ReportService.getGroupProductOrderReport(this.state.selectedStartDate,
      this.state.selectedEndDate, currentPage)
  }


  render() {
    return (
      <section>
        <div className="report-form">
          <div className="form-inline">
            <label>受注日:&nbsp;</label>
            <DateRangePicker
              readOnly
              disabled={this.state.loading}
              startDatePlaceholderText="開始日"
              endDatePlaceholderText="完了日"
              monthFormat="YYYY[年]MMMM"
              phrases={{
                closeDatePicker: '完了',
                clearDates: 'クリア',
              }}
              startDate={this.state.selectedStartDate}
              endDate={this.state.selectedEndDate}
              focusedInput={this.state.focusedInput}
              onDatesChange={this._onDateChange}
              onFocusChange={this._onFocusChange}
              isOutsideRange={() => false}
              hideKeyboardShortcutsPanel
              minimumNights={0}
              showClearDates/>

            <LoadingButton
              disabled={this.state.selectedStartDate === null ||
              this.state.selectedEndDate === null}
              isLoading={this.state.loading}
              className="btn-raised btn btn-info btn-md"
              onClick={this.handleGettingReportClick.bind(
                this)}>集計</LoadingButton>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-5">
            <div className="row">
              <GroupProductChart datasets={this.state.chartData.quantities}
                                 labels={this.state.chartData.labels}
                                 colors={this.state.chartData.colors}
                                 type={ReportConstant.CHART_TYPE.PIE}/>
            </div>
          </div>
          <div className="col-12 col-md-7">
            <div className="row">
              <GroupProductChart
                datasets={this.state.chartData.quantities}
                labels={this.state.chartData.labels}
                colors={this.state.chartData.colors}
                type={ReportConstant.CHART_TYPE.BAR}/>

            </div>
          </div>
        </div>

        <div className="tbl-admin tbl-template" id="groupProductOrderReportTable">
          {this.state.loading ?
            <div><span className="loading-spin-icon"/><span style={{display:"inline-block", height: '25px'}}>読み込み中。。。 </span>
            </div> : ''}
          <GroupProductOrderTable
            orders={this.state.ordersDTO.orders}
          />
          <OurPagingComponent loadNewPage={this.changePage}
                              dto={this.state.ordersDTO}/>
        </div>
      </section>
    )
  }
}

export default AuthenticatedComponent(GroupProductOrderComponent)
