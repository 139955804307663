/**
 * Created by
 */
import Dispatcher from '../../common/dispatcher/Dispatcher.jsx'
import CollabDesignConstant from '../constants/CollabDesignConstant.jsx'

export default {
  load: (designDTO, designFilter) => {
    Dispatcher.dispatch({
      actionType: CollabDesignConstant.ACTION_TYPE.LOAD,
      designDTO: designDTO,
      designFilter: designFilter
    })
  },
  changeKeyword: (keyword) => {
    Dispatcher.dispatch({
      actionType: CollabDesignConstant.ACTION_TYPE.CHANGE_KEYWORD,
      keyword: keyword
    })
  },
  changePage: (page) => {
    Dispatcher.dispatch({
      actionType: CollabDesignConstant.ACTION_TYPE.CHANGE_PAGE,
      page: page
    })
  },
  changeStatusFilter: (status) => {
    Dispatcher.dispatch({
      actionType: CollabDesignConstant.ACTION_TYPE.CHANGE_STT_FIL,
      status: status,
    })
  },
  changeApproveDesign: (designId, status) => {
    Dispatcher.dispatch({
      actionType: CollabDesignConstant.ACTION_TYPE.CHANGE_APPROVE_DESIGN,
      designId: designId,
      status: status
    })
  }
}