import React from 'react';
import AuthenticatedComponent from '../../common/components/AuthenticatedComponent.jsx';
import SearchBoxComponent from '../../common/components/SearchBoxComponent.jsx';
import OurPagingComponent from '../../common/components/paging/OurPagingComponent.jsx';
import OrderExportReportForm from './OrderExportReportForm.jsx';
import OrderStatusBarComponent from './OrderStatusBarComponent.jsx';
import OrderTableComponent from './OrderTableComponent.jsx';
import OrderDetailComponent from './detail/OrderDetailComponent.jsx';
import OrderService from '../services/OrderService.jsx';
import OrderAction from '../actions/OrderAction.jsx';
import OrderStore from '../stores/OrderStore.jsx';

export default AuthenticatedComponent(
    class OrderListComponent extends React.Component {
      constructor() {
        super();
        this.state = this._getInitialState();
        this._onChange = this._onChange.bind(this);
        this.closeExportOrderReportDialog = this.closeExportOrderReportDialog.bind(
            this);
        this.openExportOrderReportDialog = this.openExportOrderReportDialog.bind(
            this);
      }

      _onChange() {
        this.setState(this._getState());
      }

      _getInitialState() {
        OrderStore.resetOrderFilter();
        return this._getState();
      }

      _getState() {
        return {
          ordersDTO: OrderStore.getOrdersDTO(),
          orderFilter: OrderStore.getOrderFilter(),
          isOpenExportOrderReportDialog: false,
        };
      }

      componentDidMount() {
        OrderService.loadOrders(OrderStore.getOrderFilter());
        OrderStore.addChangeListener(this._onChange);
      }

      componentWillUnmount() {
        OrderStore.removeChangeListener(this._onChange);
      }

      _loadDetailOrder(orderNumber) {
        OrderService.getOrder(orderNumber);
        OrderService.getInvoiceLog(orderNumber);
      }

      openExportOrderReportDialog() {
        this.setState({
          isOpenExportOrderReportDialog: true,
        });
      }

      closeExportOrderReportDialog() {
        this.setState({
          isOpenExportOrderReportDialog: false,
        });
      }

      render() {
        return (
            <div>
              <h2>注文一覧</h2>
              <OrderExportReportForm
                  isOpenExportOrderReportDialog={this.state.isOpenExportOrderReportDialog}
                  closeExportOrderReportDialog={this.closeExportOrderReportDialog}/>
              <SearchBoxComponent changeKeyword={OrderAction.changeKeyword}
                                  placeholder="EC IDまたは、オーダーIDで検索"/>
              <OrderStatusBarComponent ordersDTO={this.state.ordersDTO}
                                       orderFilter={this.state.orderFilter}
                                       openExportOrderReportDialog={this.openExportOrderReportDialog}/>
              <OrderTableComponent orders={this.state.ordersDTO.orders}
                                   orderFilter={this.state.orderFilter}
                                   loadDetailOrder={this._loadDetailOrder}/>
              <OurPagingComponent loadNewPage={OrderAction.changePage}
                                  dto={this.state.ordersDTO}/>
              <OrderDetailComponent/>
            </div>
        );
      }
    },
);