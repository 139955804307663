import React from 'react';

export default class JapanPostalCodeComponent extends React.Component {

    constructor() {
        super();
    }

    formatPostalCode(postalCode) {
        var regex = new RegExp('^(\\d{3})-?(\\d{4})$');
        var array = regex.exec(postalCode);
        if (array) {
            return array[1] + '-' + array[2];
        } else {
            return postalCode;
        }
    }

    render() {
        return (
            <span>{this.formatPostalCode(this.props.postalCode)}</span>
        )
    }
}