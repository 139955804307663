/**
 * Created by PhuNH on 16/12/26.
 */
import React from 'react'
import {
  Form,
  FormGroup,
  FormControl,
  Button,
  ControlLabel,
  Col,
  Alert
} from 'react-bootstrap/lib'
import EcProductCooperationService from '../../services/EcProductCooperationService.jsx'
import EcProductCooperationMasterDataStore from '../../stores/EcProductCooperationMasterDataStore.jsx'
import LoadingButton from '../../../common/components/LoadingButton.jsx'
import Griddle from 'griddle-react'
import GriddlePagingComponent from '../../../common/components/paging/GriddlePagingComponent.jsx'

import AuthenticatedComponent from '../../../common/components/AuthenticatedComponent.jsx'
import EcProductCooperationMasterDataAction from '../../actions/EcProductCooperationMasterDataAction.jsx'

export default AuthenticatedComponent(
  class ProductCooperationListComponent extends React.Component {
    constructor (props) {
      super(props)
      this.state = this._getState()
      this._onChange = this._onChange.bind(this)
    }

    componentDidMount () {
      EcProductCooperationMasterDataStore.addChangeListener(this._onChange)
      EcProductCooperationService.getProductCooperationMasterData()
    }

    componentWillUnmount () {
      EcProductCooperationMasterDataStore.removeChangeListener(this._onChange)
    }

    _onChange () {
      this.setState(this._getState())
    }

    _getState () {
      return {
        message: EcProductCooperationMasterDataStore.getMessage(),
        value: EcProductCooperationMasterDataStore.getEcCooperationProductData(),
        isLoading: EcProductCooperationMasterDataStore.getLoadingStatus(),
        datas: EcProductCooperationMasterDataStore.getRakutenProductCooperationMasterData(),
        filterData: EcProductCooperationMasterDataStore.getFilteredData(),
        searchValue: EcProductCooperationMasterDataStore.getSearchValue()
      }
    }

    _submitKey (event) {
      event.preventDefault()
      let productNumber = this.state.value.text.split(',').
        map(function (text) {
          return text.trim()
        }).
        filter(function (productNumber) {
          return productNumber !== ''
        })

      EcProductCooperationService.addNewProductCooperationMasterDatas(
        productNumber, this.state.value.siteId)
    }

    _onChangeTextString (event) {
      event.preventDefault()
      EcProductCooperationService.changeCooperationProductNewData({
        siteId: this.state.value.siteId,
        text: event.target.value
      })
    }

    _onChangeSiteIdString (event) {
      event.preventDefault()
      EcProductCooperationService.changeCooperationProductNewData({
        siteId: event.target.value,
        text: this.state.value.text
      })
    }

    _filterData(text) {
      const regex = new RegExp(text, 'i');
      return this.state.datas.filter(function (datum) {
        return (datum.ecProductNumber.search(regex) > -1 ) ;
      });
    }

    filterData(event) {
      event.preventDefault();
      const filtered = this._filterData(event.target.value)

      EcProductCooperationMasterDataStore.setSearchValue(event.target.value);
      EcProductCooperationMasterDataStore.setFilteredData(filtered);
      this.setState({
        message: this.state.message,
        value: this.state.value,
        isLoading: this.state.isLoading,
        datas: this.state.datas,
        filterData: filtered,
        searchValue: EcProductCooperationMasterDataStore.getSearchValue()
      });
    }
    _closeMessage(event){
      event.preventDefault();
      EcProductCooperationMasterDataAction.newMessage("");
      this.setState({
        message: "",
        value: this.state.value,
        isLoading: this.state.isLoading,
        datas: this.state.datas,
        filterData: this.state.filterData,
        searchValue: this.state.searchValue
      });
    }

    render () {
      return (
        <div className="tbl-admin">
          <div className="rakuten-product-cooperation-form">
            <div>
              {this.state.message ? <Alert bsStyle="success" >{this.state.message}<button className="close" onClick={this._closeMessage.bind(this)}>X</button></Alert> : ""}
              <Form horizontal>
                <FormGroup controlId="formControlsSelect">
                  <Col componentClass={ControlLabel} sm={2}>
                    ECサイト
                  </Col>
                  <Col sm={10}>
                    <FormControl componentClass="select" placeholder="ECサイトを選択してください。"
                                 value={this.state.value.siteId}
                                 onChange={this._onChangeSiteIdString.bind(this)}>
                      <option value="RAKUTEN">楽天市場</option>
                      <option value="COLOR_ME">カラーミーショップ</option>
                    </FormControl>
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col componentClass={ControlLabel} sm={2}>
                    商品命名規則
                  </Col>
                  <Col sm={10}>
                    <FormControl
                      componentClass="textarea"
                      value={this.state.value.text}
                      placeholder="カンマ区切り文字列"
                      onChange={this._onChangeTextString.bind(this)}
                    />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col smOffset={2} sm={10}>
                    <LoadingButton isLoading={this.state.isLoading}
                                   disabled={!(this.state.value.text && this.state.value.siteId)}
                                   className="btn-raised btn btn-info btn-lg"
                                   onClick={this._submitKey.bind(this)}>
                      登録
                    </LoadingButton>
                  </Col>
                </FormGroup>


              </Form>

            </div>
          </div>

          <div className="row">
            <div className="pull-right search-product col-md-4">
              <div className="form-group is-empty">
                <input type="text" placeholder="連携商品番号で検索" className="form-control"
                       onChange={ this.filterData.bind(this) } value={this.state.searchValue}/>
                <span className="material-input"/>
              </div>
              <button type="submit" className="btn btn-primary"><i className="fa fa-search"/></button>
            </div>
          </div>
          {this.state.filterData ? <Griddle
            results={this.state.filterData}
            showFilter={false}
            columnMetadata={this.columnMeta()}
            columns={[
              'ecSiteId',
              'categoryName',
              'productName',
              'ecProductNumber',
              'status',
              'identify']}
            sortAscendingComponent={<span className="fa fa-sort-alpha-asc"/>}
            sortDescendingComponent={<span className="fa fa-sort-alpha-desc"/>}
            useCustomPagerComponent="true"
            customPagerComponent={GriddlePagingComponent}
            tableClassName="table table-striped table-hover table-bordered"
            gridClassName="tbl-admin tbl-template"
            showTableHeading="true"
            resultsPerPage="10"
            noDataMessage="検索結果は0件です。"
            sortProperties={[
              { id: 'ecSiteId', sortAscending: true }
            ]}
          /> : ''
          }
        </div>
      )

    }

    columnMeta () {
      return [
        {
          columnName: 'ecSiteId',
          displayName: 'ECサイト',
          order: 1,
          locked: false,
          visible: true,
          cssClassName: 'idCol',
        }, {
          columnName: 'categoryName',
          displayName: 'カテゴリー名',
          order: 2,
          locked: false,
          visible: true,
        }, {
          columnName: 'productName',
          displayName: '商品名',
          order: 3,
          locked: false,
          visible: true,
        }, {
          columnName: 'ecProductNumber',
          displayName: '連携商品番号',
          order: 4,
          locked: false,
          visible: true,
        }, {
          columnName: 'status',
          displayName: '連携ステータス',
          order: 5,
          locked: false,
          visible: true,
          customComponent: ProductCooperationStatusFormat
        }, {
          columnName: 'identify',
          displayName: 'アクション',
          order: 6,
          locked: false,
          visible: true,
          customComponent: ProductCooperationActionFormat,
          sortable: false
        },
      ]
    }
  })

class ProductCooperationActionFormat extends React.Component {
  constructor () {
    super()
  }

  render () {
    function renderButton (rowData) {

      if(rowData.status)
        return <Button bsStyle="danger btn-raised" style={{padding: "8px 20px"}} onClick={changeStatus.bind(this, rowData)}>連携解除</Button>
      else
        return <Button bsStyle="info btn-raised" style={{padding: "8px 20px"}} onClick={changeStatus.bind(this, rowData)}>連携する</Button>
    }

    function changeStatus (rPCMD) {
      let actionText = rPCMD.status ? '連携解除' : '連携'
      let r = confirm(rPCMD.ecProductNumber + 'を'+ actionText + 'してもよろしでしょうか？')
      if (r === true) {
        EcProductCooperationService.changeStatusProductCooperation(rPCMD)
      }
    }

    function deleteProductCooperation (rPCMD) {
      let r = confirm(rPCMD.ecProductNumber + 'を削除してもよろしでしょうか？')
      if (r === true) {
        EcProductCooperationService.deleteProductCooperation(rPCMD)
      }
    }

    return (
      <div>
        {renderButton(this.props.rowData)}
        <Button bsStyle="default btn-raised" style={{padding: "8px 20px"}} onClick={deleteProductCooperation.bind(this, this.props.rowData)}>削除</Button>
      </div>
    )
  }
}

class ProductCooperationStatusFormat extends React.Component {
  constructor () {
    super()
  }

  render () {
    function renderStatus (rowData) {

      if(rowData.status)
        return "連携中"
      else
        return "未連携"
    }

    return (
      <b>
        {renderStatus(this.props.rowData)}
      </b>
    )
  }
}
