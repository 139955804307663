import React from 'react';
import {Button, Form, FormGroup, Col} from 'react-bootstrap/lib';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment';
import Select from 'react-select';
import {DateRangePicker} from 'react-dates';
import OrderService from '../services/OrderService.jsx';
import Modal from 'react-bootstrap/lib/Modal';

class OrderExportReportForm extends React.Component {

    constructor() {
        super();

        this.statusOptions = [
            {value: 'waiting', label: 'デザイン待ち'},
            {value: 'draft', label: '下書き'},
            {value: 'url_released', label: 'URL発行済み'},
            {value: 'creating', label: '制作中'},
            {value: 'shipped', label: '出荷済み'},
            {value: 'pending', label: '保留'},
            {value: 'cancel', label: 'キャンセル'},
            {value: 'committed', label: '確定済み'}
        ];

        this.ecSiteIds = [
            {value: '楽天', label: '楽天'},
            {value: 'カラーミー', label: 'カラーミー'}
        ];

        this.alignRightText = {
            'textAlign': 'right'
        };

        this.now = moment();

        this.state = this.initState();

        this.onOrderDateChange = this.onOrderDateChange.bind(this);
        this.onFocusOrderDateChange = this.onFocusOrderDateChange.bind(this);

        this.onShipDateChange = this.onShipDateChange.bind(this);
        this.onFocusShipDateChange = this.onFocusShipDateChange.bind(this);

        this.changeStatus = this.changeStatus.bind(this);
        this.changeEcSites = this.changeEcSites.bind(this);

        this.createDownloadReportLink = this.createDownloadReportLink.bind(this);
        this.downloadReport = this.downloadReport.bind(this);
    }

    initState() {
        return {
            focusedOrderDate: null,
            minOrderDate: moment().add(-1, 'months'),
            maxOrderDate: moment(),
            focusedShipDate: null,
            minShipDate: moment().add(-1, 'months'),
            maxShipDate: moment(),
            selectedStatus: [],
            selectedEcSites: []
        };
    }

    onOrderDateChange({startDate, endDate}) {
        this.setState({
            minOrderDate: startDate,
            maxOrderDate: endDate
        });
    }

    onFocusOrderDateChange(onFocusedInput) {
        this.setState({
            focusedOrderDate: onFocusedInput
        });
    }

    onShipDateChange({startDate, endDate}) {
        this.setState({
            minShipDate: startDate,
            maxShipDate: endDate
        });
    }

    onFocusShipDateChange(onFocusedInput) {
        this.setState({
            focusedShipDate: onFocusedInput
        });
    }

    changeStatus(selectedStatus) {
        this.setState({
            selectedStatus: selectedStatus
        });
    }

    changeEcSites(selectedEcSites) {
        this.setState({
            selectedEcSites: selectedEcSites
        });
    }

    createDownloadReportLink() {
        var dateFormatter = "{0}/{1}/{2}";
        var orderFilter = {
            minOrderDate: this.state.minOrderDate && dateFormatter.format(this.state.minOrderDate.date(), this.state.minOrderDate.month() + 1, this.state.minOrderDate.year()),
            maxOrderDate: this.state.maxOrderDate && dateFormatter.format(this.state.maxOrderDate.date(), this.state.maxOrderDate.month() + 1, this.state.maxOrderDate.year()),
            minShipDate: this.state.minShipDate && dateFormatter.format(this.state.minShipDate.date(), this.state.minShipDate.month() + 1, this.state.minShipDate.year()),
            maxShipDate: this.state.maxShipDate && dateFormatter.format(this.state.maxShipDate.date(), this.state.maxShipDate.month() + 1, this.state.maxShipDate.year()),
            status: this.state.selectedStatus.map(function (item) {
                return item.value;
            }),
            ecSiteIds: this.state.selectedEcSites.map(function (item) {
                return item.value;
            })
        };
        var downloadLink = null;
        try {
            downloadLink = OrderService.createDownloadReportLink(orderFilter);
        } catch (exception) {
        }
        return downloadLink
    }

    downloadReport(downloadLink) {
        fetch(downloadLink,
          {
              headers: {
                  'Csrf-Token': 'nocheck',
                  'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
              },
          })
          .then(response => {
              let fileName = response.headers.get("content-disposition").split('"')[1]
              response.blob().then(blob => {
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = fileName;
                  a.click();
              });
              //window.location.href = response.url;
          });
    }

    render() {
        //TODO render should only be used to render html.
        var downloadLink = this.createDownloadReportLink();
        var exportBtnAttributes = {};
        if (!downloadLink) {
            exportBtnAttributes.disabled = true;
        }
        var warningMessageStyle = {
            display: (!this.state.minOrderDate || !this.state.maxOrderDate) && (!this.state.minShipDate || !this.state.maxShipDate) ? 'block' : 'none',
            fontWeight: 'bold'
        };

        return (
            <Modal show={this.props.isOpenExportOrderReportDialog} onHide={this.props.closeExportOrderReportDialog}
                   bsSize="lg">
                <Modal.Header closeButton>
                    <Modal.Title>注文情報エクスポート</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 className="text-danger has-error" style={warningMessageStyle}>注文日または、出荷日（出荷予定日）を選択してください。</h5>
                    <Form horizontal>
                        <FormGroup>
                            <Col sm={3} style={this.alignRightText}>
                                注文日:
                            </Col>
                            <Col sm={9}>
                                <DateRangePicker
                                    startDatePlaceholderText="入住日期"
                                    endDatePlaceholderText="退房日期"
                                    monthFormat="YYYY[年]MMMM"
                                    phrases={{
                                        closeDatePicker: '关闭',
                                        clearDates: '清除日期',
                                    }}
                                    startDate={this.state.minOrderDate}
                                    endDate={this.state.maxOrderDate}
                                    focusedInput={this.state.focusedOrderDate}
                                    onDatesChange={this.onOrderDateChange}
                                    onFocusChange={this.onFocusOrderDateChange}
                                    isOutsideRange={() => false}
                                    keepOpenOnDateSelect
                                    hideKeyboardShortcutsPanel
                                    minimumNights={0}
                                    showClearDates
                                    readOnly={true}/>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col sm={3} style={this.alignRightText}>
                                出荷日（出荷予定日）:
                            </Col>
                            <Col sm={9}>
                                <DateRangePicker
                                    startDatePlaceholderText="入住日期"
                                    endDatePlaceholderText="退房日期"
                                    monthFormat="YYYY[年]MMMM"
                                    phrases={{
                                        closeDatePicker: '关闭',
                                        clearDates: '清除日期',
                                    }}
                                    startDate={this.state.minShipDate}
                                    endDate={this.state.maxShipDate}
                                    focusedInput={this.state.focusedShipDate}
                                    onDatesChange={this.onShipDateChange}
                                    onFocusChange={this.onFocusShipDateChange}
                                    isOutsideRange={() => false}
                                    keepOpenOnDateSelect
                                    hideKeyboardShortcutsPanel
                                    minimumNights={0}
                                    showClearDates
                                    readOnly={true}/>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col sm={3} style={this.alignRightText}>
                                デザインステータス<span className="text-danger">(必須)</span>:
                            </Col>
                            <Col sm={7}>
                                <Select multi={true} options={this.statusOptions} onChange={this.changeStatus}
                                        value={this.state.selectedStatus} placeholder="選択してください"/>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col sm={3} style={this.alignRightText}>
                                ECサイト<span className="text-danger">(必須)</span>:
                            </Col>
                            <Col sm={7}>
                                <Select multi={true} options={this.ecSiteIds} onChange={this.changeEcSites}
                                        value={this.state.selectedEcSites} placeholder="選択してください"/>
                            </Col>
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Col sm={12} style={this.alignRightText}>
                        <Button className="btn btn-raised btn-info" onClick={() => this.downloadReport(downloadLink)} {...exportBtnAttributes}>
                            <i className="fa fa-download"/> エクスポート
                        </Button>
                        <Button onClick={this.props.closeExportOrderReportDialog} bsStyle="default"
                                className="btn-raised">完了</Button>
                    </Col>
                </Modal.Footer>
            </Modal>
        )
    }
}

OrderExportReportForm.propTypes = {
    minOrderDate: momentPropTypes.momentObj,
    maxOrderDate: momentPropTypes.momentObj,
    minShipDate: momentPropTypes.momentObj,
    maxShipDate: momentPropTypes.momentObj
};

export default OrderExportReportForm;
