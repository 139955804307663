import BaseStore from '../../common/stores/BaseStore.jsx'
import Cookies from 'universal-cookie'
import CampaignConstants from '../constants/CampaignConstants.jsx'
import CampaignService from '../services/CampaignService.jsx'

class CampaignStore extends BaseStore {

  constructor () {
    super()
    this.cookies = new Cookies()

    this.isDoneExportStudioAliceBaby1812 = null

    this.studioAliceBaby1812DTO = {
      currentPage: 1,
      maxPage: 0,
      campaigns: [],
    }
    this.studioAliceBaby1812Filter = {}

    this._resetFilter()

    this.subscribe(() => this.handler.bind(this))
  }

  _resetFilter () {
    this.studioAliceBaby1812Filter = {
      currentPage: 1,
      pageSize: 10,
    }
  }

  handler (action) {
    switch (action.actionType) {
      case CampaignConstants.STUDIOALICEBABY1812_TYPE.CAMPAIGN_LOAD:
        this.studioAliceBaby1812DTO = action.studioAliceBaby1812DTO
        this.studioAliceBaby1812Filter = action.studioAliceBaby1812Filter
        this.emitChange()
        break
      case CampaignConstants.STUDIOALICEBABY1812_TYPE.CAMPAIGN_CHANGE_PAGE:
        this.studioAliceBaby1812Filter.currentPage = action.page
        CampaignService.loadStudioAliceBaby1812(this.studioAliceBaby1812Filter)
        break
      case CampaignConstants.STUDIOALICEBABY1812_TYPE.DONE_EXPORT_CSV:
        this.isDoneExportStudioAliceBaby1812 = true
        CampaignService.downloadStudioAliceBaby1812File()
        this.emitChange()
        break
      default:
        break
    }
  }

  _getStudioAliceBaby1812DTO(){
    return this.studioAliceBaby1812DTO
  }

  _getStudioAliceBaby1812Filter(){
    return this.studioAliceBaby1812Filter
  }

  _getExportStudioAliceBaby1812Status (){
    return this.isDoneExportStudioAliceBaby1812
  }
}

const campaignStore = new CampaignStore()

export default campaignStore
