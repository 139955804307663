/**
 * Created by PhuNH on 16/12/26.
 */
import React from 'react'
import Griddle from 'griddle-react'
import GriddlePagingComponent from '../../../../common/components/paging/GriddlePagingComponent.jsx'
import DateTimeFormat from '../../../../common/components/DateTimeFormat.jsx'
import RakutenLicenseKeyService from '../../../services/RakutenLicenseKeyService.jsx'

export default class RakutenLicenseKeyListComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = this._getState(props);
    }

    componentWillReceiveProps(nextProps) {
        this.setState(this._getState(nextProps));
    }

    componentDidMount() {
        RakutenLicenseKeyService.listKey();
    }

    _getState(props) {
        return {
            keyList: props.keyList,
        }
    }

    columnMetadata() {
        return [
            {
                columnName: "keyString",
                sortable: false,
                order: 1,
                locked: false,
                displayName: "ライセンスキー",
                visible: true,
                cssClassName: "key-col"
            }, {
                columnName: "expirationDate",
                sortable: false,
                order: 2,
                locked: false,
                displayName: "期間期限",
                visible: true
            }, {
                columnName: "createdAt",
                sortable: false,
                order: 3,
                locked: false,
                displayName: "登録日時",
                visible: true,
                customComponent: DateTimeFormat
            }
        ]
    }

    rowMetadata() {
        return {
            "bodyCssClassName": (rowData) => {
                if (rowData.isActive)
                    return "green-row";
                return "";
            }
        }
    }

    render() {
        return (
            <div className="tbl-admin tbl-template">
                { (this.state.keyList && this.state.keyList.length > 0) ?
                    <Griddle
                        columns={["keyString", "expirationDate", "createdAt"]}
                        columnMetadata={this.columnMetadata()}
                        rowMetadata={this.rowMetadata()}
                        results={this.state.keyList}
                        resultsPerPage="10"
                        gridClassName="tbl-admin tbl-template"
                        tableClassName="table table-striped table-hover table-bordered"
                        showFilter={false}
                        useCustomPagerComponent="true"
                        customPagerComponent={GriddlePagingComponent}
                        noDataMessage="No key"
                        showTableHeading="true"
                    /> : ""
                }
            </div>
        )
    }
}