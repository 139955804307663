import React from 'react'
import request from 'reqwest'
import when from 'when'
import Constant from "../../common/constants/Constants.jsx";
import SettingConstants from '../constants/SettingConstants.jsx'
import LoginAction from "../../authentication/actions/LoginAction.jsx";
import SettingAction from "../actions/SettingAction.jsx";

class SettingService {
  getAllSettingModule() {
    let url = SettingConstants.API.GET_ALL_SETTING_MODULE;
    when(request({
      url: url,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case Constant.RESPONSE_STATUS.SUCCESS:
          SettingAction.getAllSettingModule(response.data);
          break;
        case Constant.RESPONSE_STATUS.UNAUTHORIZED:
          LoginAction.loginUser({});
          break;
        case Constant.RESPONSE_STATUS.BAD_REQUEST:
          SettingAction.badRequest(response.message);
          break;
        case Constant.RESPONSE_STATUS.SERVER_ERROR:
          SettingAction.error();
          break;
        default:
          break;
      }
    }).catch(function () {

    })
  } /*end get all setting module*/

  updateSettingModule(idString, status) {
    when(request({
      url: SettingConstants.API.UPDATE_STATUS_SETTING_MODULE.format(idString, status),
      method: 'PUT',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      }
    })).then(function (response) {
      switch (response.code) {
        case Constant.RESPONSE_STATUS.SUCCESS:
          SettingAction.updateSettingModule(response.code);
          break;
        case Constant.RESPONSE_STATUS.UNAUTHORIZED:
          LoginAction.loginUser({});
          break;
        case Constant.RESPONSE_STATUS.BAD_REQUEST:
          SettingAction.badRequest(response.message);
          break;
        case Constant.RESPONSE_STATUS.SERVER_ERROR:
          SettingAction.error();
          break;
        default:
          break;
      }
    }).catch(function () {

    })
  }/*end update setting module*/
}

export default new SettingService();
