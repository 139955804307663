const Constants = {
    CRUD_MODE : {
        CREATE: 1,
        READ: 2,
        UPDATE: 3,
        DELETE: 4,
    },
    RESPONSE_STATUS: {
        SUCCESS: 200,
        BAD_REQUEST: 400,
        UNAUTHORIZED: 401,
        NOT_FOUND: 404,
        SERVER_ERROR: 500,
        GATEWAY_TIMEOUT: 504,
        INTERNAL_SERVER_ERROR: 500
    }
};

export default Constants