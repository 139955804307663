/**
 * Created by thangkc on 2019/06/04.
 */
import React from 'react'
import request from 'reqwest'
import when from 'when'
import CampaignAction from '../actions/CampaignAction.jsx'
import LoginAction from '../../authentication/actions/LoginAction.jsx'

class CampaignTheatreService {
  constructor (campaignId) {
    this.campaignId = campaignId;
    if(campaignId == 'theatre1810')
      this.theatreType = 'THEATRE1810_TYPE'

    if(campaignId == 'theatre1905')
      this.theatreType = 'THEATRE1905_TYPE'
  }

  alertError () {
    alert('不具合が発生しました！開発者に連絡していただくと幸いです。')
  }

  load (campaignFilter) {
    let url = '/api/campaign/' + this.campaignId + '?page=' +
      encodeURIComponent(campaignFilter.currentPage)
      + '&pageSize=' + encodeURIComponent(campaignFilter.pageSize)

    let self = this

    when(request({
      url: url,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          CampaignAction.load(response.data, campaignFilter, self.theatreType)
          break
        case 401:
          LoginAction.loginUser({})
          break
        case 400:
          break
        default :
          break
      }
    })
  }

  exportCSVFile () {
    let self = this
    when(request({
      url: `/api/campaign/${self.campaignId}/export`,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          CampaignAction.doneExportCSVFile(self.theatreType)
          break
        case 401:
          LoginAction.loginUser({})
          break
        default:
          self.alertError()
          break
      }
    }).catch(function (e) {
      self.alertError()
    })
  }

  runZipFile () {

    let self = this

    when(request({
      url: `/api/campaign/${self.campaignId}/zip`,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          CampaignAction.doneZipData(self.theatreType)
          break
        case 401:
          LoginAction.loginUser({})
          break
        default:
          self.alertError()
          break
      }
    }).catch(function () {
      self.alertError()
    })
  }

  downloadFile () {
    let self = this
    let url = `/api/campaign/${self.campaignId}/download`
    fetch(url,
      {
        headers: {
          'Csrf-Token': 'nocheck',
          'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
        },
      })
      .then(response => {
        let fileName = response.headers.get("content-disposition").split("''")[1]
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
        });
        //window.location.href = response.url;
      });

  }

  changePage(page){
    CampaignAction.changePage(page, this.theatreType)
  }


}

export default CampaignTheatreService
