import React from 'react'
import request from 'reqwest'
import when from 'when'
import Constant from "../../common/constants/Constants.jsx";
import MagazineConstants from '../constants/MagazineMailConstants.jsx'
import LoginAction from "../../authentication/actions/LoginAction.jsx";
import MagazineAction from "../actions/MagazineMailAction.jsx"

class MagazineMailService {
  getAllPlan() {
    let url = MagazineConstants.API.GET_ALL_PLAN;
    when(request({
      url: url,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case Constant.RESPONSE_STATUS.SUCCESS:
          MagazineAction.loadedAllPlan(response.data)
          break;
        case Constant.RESPONSE_STATUS.UNAUTHORIZED:
          LoginAction.loginUser({});
          break;
        case Constant.RESPONSE_STATUS.BAD_REQUEST:
          MagazineAction.badRequest(response.message);
          break;
        case Constant.RESPONSE_STATUS.SERVER_ERROR:
          MagazineAction.error();
          break;
        default:
          break;
      }
    }).catch(function () {

    })
  } /*end get all plan*/

  getAllMailTemplate() {
    let url = MagazineConstants.API.GET_ALL_MAIL_TEMPLATE;
    when(request({
      url: url,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case Constant.RESPONSE_STATUS.SUCCESS:
          MagazineAction.loadedMailTemplate(response.data)
          break;
        case Constant.RESPONSE_STATUS.UNAUTHORIZED:
          LoginAction.loginUser({});
          break;
        case Constant.RESPONSE_STATUS.BAD_REQUEST:
          MagazineAction.badRequest(response.message);
          break;
        case Constant.RESPONSE_STATUS.SERVER_ERROR:
          MagazineAction.error();
          break;
        default:
          break;
      }
    }).catch(function () {

    })
  }

  getPlan(planId) {
    let url = MagazineConstants.API.GET_PLAN.format(planId);
    when(request({
      url: url,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case Constant.RESPONSE_STATUS.SUCCESS:
          MagazineAction.loadedPlan(response.data)
          break;
        case Constant.RESPONSE_STATUS.UNAUTHORIZED:
          LoginAction.loginUser({});
          break;
        case Constant.RESPONSE_STATUS.BAD_REQUEST:
          MagazineAction.badRequest(response.message);
          break;
        case Constant.RESPONSE_STATUS.SERVER_ERROR:
          MagazineAction.error();
          break;
        default:
          break;
      }
    }).catch(function () {

    })
  }/*end get plan*/

  addPlan(plan) {
    let self = this;
    when(request({
      url: MagazineConstants.API.ADD_PLAN,
      method: 'POST',
      crossOrigin: true,
      data: plan,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      }
    })).then(function (response) {
      switch (response.code) {
        case Constant.RESPONSE_STATUS.SUCCESS:
          self.getAllPlan()
          break;
        case Constant.RESPONSE_STATUS.UNAUTHORIZED:
          LoginAction.loginUser({});
          break;
        case Constant.RESPONSE_STATUS.BAD_REQUEST:
          MagazineAction.badRequest(response.message);
          break;
        case Constant.RESPONSE_STATUS.SERVER_ERROR:
          MagazineAction.error();
          break;
        default:
          break;
      }
    })
  }

  updatePlan(planId, plan) {
    let self = this;
    when(request({
      url: MagazineConstants.API.UPDATE_PLAN.format(planId),
      method: 'PUT',
      crossOrigin: true,
      data: plan,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      }
    })).then(function (response) {
      switch (response.code) {
        case Constant.RESPONSE_STATUS.SUCCESS:
          self.getAllPlan()
          break;
        case Constant.RESPONSE_STATUS.UNAUTHORIZED:
          LoginAction.loginUser({});
          break;
        case Constant.RESPONSE_STATUS.BAD_REQUEST:
          MagazineAction.badRequest(response.message);
          break;
        case Constant.RESPONSE_STATUS.SERVER_ERROR:
          MagazineAction.error();
          break;
        default:
          break;
      }
    }).catch(function () {

    })
  }/*end update plan*/

  getAllUnsubscribeReceiver(receiverFilter) {
    let url = MagazineConstants.API.GET_ALL_UNSUBSCRIBE_RECEIVER + "?currentPage=" + encodeURIComponent(receiverFilter.currentPage)
        + "&pageSize=" + encodeURIComponent(receiverFilter.pageSize);
    if (receiverFilter.keyword.trim()) {
      url += "&keyword=" + encodeURIComponent(receiverFilter.keyword.trim())
    }
    when(request({
      url: url,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case Constant.RESPONSE_STATUS.SUCCESS:
          MagazineAction.loadedAllUnsubscribeReceiver(response.data, receiverFilter)
          break;
        case Constant.RESPONSE_STATUS.UNAUTHORIZED:
          LoginAction.loginUser({});
          break;
        case Constant.RESPONSE_STATUS.BAD_REQUEST:
          MagazineAction.badRequest(response.message);
          break;
        case Constant.RESPONSE_STATUS.SERVER_ERROR:
          MagazineAction.error();
          break;
        default:
          break;
      }
    }).catch(function () {

    })
  } /*end get all receiver*/

  changeToSubscribedReceiver(receiverId, receiverFilter) {
    let self = this;
    when(request({
      url: MagazineConstants.API.CHANGE_TO_SUBSCRIBED_RECEIVER.format(receiverId),
      method: 'DELETE',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      }
    })).then(function (response) {
      switch (response.code) {
        case Constant.RESPONSE_STATUS.SUCCESS:
          self.getAllUnsubscribeReceiver(receiverFilter)
          break;
        case Constant.RESPONSE_STATUS.UNAUTHORIZED:
          LoginAction.loginUser({});
          break;
        case Constant.RESPONSE_STATUS.BAD_REQUEST:
          MagazineAction.badRequest(response.message);
          break;
        case Constant.RESPONSE_STATUS.SERVER_ERROR:
          MagazineAction.error();
          break;
        default:
          break;
      }
    }).catch(function () {

    })
  }/*end changeToSubscribeReceiver*/

  addUnsubscribedReceivers(emailList, receiverFilter) {
    let self = this;
    when(request({
      url: MagazineConstants.API.ADD_UNSUBSCRIBED_RECEIVERS,
      method: 'POST',
      crossOrigin: true,
      data: emailList,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      }
    })).then(function (response) {
      switch (response.code) {
        case Constant.RESPONSE_STATUS.SUCCESS:
          self.getAllUnsubscribeReceiver(receiverFilter)
          break;
        case Constant.RESPONSE_STATUS.UNAUTHORIZED:
          LoginAction.loginUser({});
          break;
        case Constant.RESPONSE_STATUS.BAD_REQUEST:
          MagazineAction.badRequest(response.message);
          break;
        case Constant.RESPONSE_STATUS.SERVER_ERROR:
          MagazineAction.error();
          break;
        default:
          break;
      }
    })
  }/*end add unsubscribe receiver*/

  deletePlan(planId) {
    let self = this;
    when(request({
      url: MagazineConstants.API.CANCEL_PLAN.format(planId),
      method: 'DELETE',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      }
    })).then(function (response) {
      switch (response.code) {
        case Constant.RESPONSE_STATUS.SUCCESS:
          self.getAllPlan()
          break;
        case Constant.RESPONSE_STATUS.UNAUTHORIZED:
          LoginAction.loginUser({});
          break;
        case Constant.RESPONSE_STATUS.BAD_REQUEST:
          MagazineAction.badRequest(response.message);
          break;
        case Constant.RESPONSE_STATUS.SERVER_ERROR:
          MagazineAction.error();
          break;
        default:
          break;
      }
    }).catch(function () {

    })
  }/*end update plan*/

  calculateReceiver(data) {
    when(request({
      url: MagazineConstants.API.CALCULATE_RECEIVER,
      method: 'POST',
      crossOrigin: true,
      data: data,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      }
    })).then(function (response) {
      switch (response.code) {
        case Constant.RESPONSE_STATUS.SUCCESS:
         $("#numberOfReceiver").html(Intl.NumberFormat().format(response.data))
          break;
        case Constant.RESPONSE_STATUS.UNAUTHORIZED:
          LoginAction.loginUser({});
          break;
        case Constant.RESPONSE_STATUS.BAD_REQUEST:
          MagazineAction.badRequest(response.message);
          break;
        case Constant.RESPONSE_STATUS.SERVER_ERROR:
          MagazineAction.error();
          break;
        default:
          break;
      }
    })
  }

  getEmailQuantity() {
    when(request({
      url: MagazineConstants.API.GET_MAIL_QUANTITY,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      }
    })).then(function (response) {
      switch (response.code) {
        case Constant.RESPONSE_STATUS.SUCCESS:
          MagazineAction.loadedMailQuantity(response.data)
          break;
        case Constant.RESPONSE_STATUS.UNAUTHORIZED:
          LoginAction.loginUser({});
          break;
        case Constant.RESPONSE_STATUS.BAD_REQUEST:
          MagazineAction.badRequest(response.message);
          break;
        case Constant.RESPONSE_STATUS.SERVER_ERROR:
          MagazineAction.error();
          break;
        default:
          break;
      }
    })
  }

}

export default new MagazineMailService();
