/**
 * Created by PhuNH on 17/1/7.
 */
import React from 'react'
import DateTimeFormat from "../../common/components/DateTimeFormat.jsx"
import DesignActionFormat from "../../common/components/design/DesignActionFormat.jsx"
import DesignImageFormat from "../../common/components/design/DesignImageFormat.jsx"
import DesignStatusFormat from "../../common/components/design/DesignStatusFormat.jsx"

export default class DesignTableRowComponent extends React.Component {
    constructor() {
        super();
    }

    render() {
        return(
            <tr>
                <td className="idCol">{this.props.design.randomId}</td>
                <td><DesignImageFormat rowData={this.props.design} className="designImage designList"/></td>
                <td>{this.props.design.orderNumber}</td>
                <td>{this.props.design.userName}</td>
                <td className="dateCol"><DateTimeFormat data={this.props.design.createdAt}/></td>
                <td className="dateCol"><DateTimeFormat data={this.props.design.updatedAt}/></td>
                <td className="statusCol"><DesignStatusFormat data={this.props.design.status}/></td>
                <td className="actionCol"><DesignActionFormat rowData={[this.props.design]}/></td>
            </tr>
        )
    }
}