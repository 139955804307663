/**
 * Created by hoangnv on 12/26/16.
 */
import BaseStore from '../../common/stores/BaseStore.jsx'
import EcProductCooperationMasterDataConstants from '../constants/EcProductCooperationMasterDataConstants.jsx'

class EcProductCooperationMasterDataStore extends BaseStore {
  constructor () {
    super()
    this.activeKey = undefined
    this.pathName = null;

    this.message = "";

    this.ecCooperationProductData = {
      siteId: 'RAKUTEN',
      text: ''
    }
    this.isLoading = false

    this.subscribe(() => this.handler.bind(this))

    this.rakutenProductCooperationMasterDatas = null
    this.filteredData = null

    this.searchStr = ""
  }

  filterData(text, datas) {
    const regex = new RegExp(text, 'i');
    return datas.filter(function (datum) {
      return (datum.ecProductNumber.search(regex) > -1 ) ;
    });
  }

  handler (action) {
    switch (action.actionType) {
      case EcProductCooperationMasterDataConstants.ACTION_TYPE.GET_PRODUCT_COOPERATION_MASTER_DATA:
        let tmp =[];
        action.data.forEach(function (currentValue, index, arr) {
          currentValue.rPCMDs.forEach(function (rPCMD, idnex) {
            rPCMD.categoryName = currentValue.categoryName
            rPCMD.productName = currentValue.productName

            tmp.push(rPCMD)
          })
        })

        this.rakutenProductCooperationMasterDatas = tmp
        this.filteredData = this.searchStr !== "" ? this.filterData(this.searchStr, this.rakutenProductCooperationMasterDatas) : tmp
        this.isLoading = false
        this.emitChange()
        break
      case EcProductCooperationMasterDataConstants.ACTION_TYPE.CHANGE_PRODUCT_COOPERATION_NEW_TEXT:
        this.ecCooperationProductData = action.data
        this.emitChange()
        break
      case EcProductCooperationMasterDataConstants.ACTION_TYPE.NEW_MESSAGE:
        this.message = action.data
        break
      case EcProductCooperationMasterDataConstants.ACTION_TYPE.CHANGE_LOADING:
        this.isLoading = action.data
        this.emitChange()
        break
      default:
        break
    }
  }

  getActiveKey () {
    return this.activeKey
  }

  getMessage(){
    return this.message
  }

  getEcCooperationProductData () {
    return this.ecCooperationProductData
  }

  getRakutenProductCooperationMasterData () {
    return this.rakutenProductCooperationMasterDatas
  }

  getFilteredData () {
    return this.filteredData
  }

  getLoadingStatus(){
    return this.isLoading
  }

  getPathName(){
    return this.pathName;
  }

  setSearchValue(str) {
    this.searchStr = str;
  }

  setFilteredData(data) {
    this.filteredData = data;
  }

  getSearchValue() {
    return this.searchStr
  }
}

const ecProductCooperationMasterDataStore = new EcProductCooperationMasterDataStore()
export default ecProductCooperationMasterDataStore