import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import {Col, Form, FormControl, FormGroup} from "react-bootstrap";

export default class CreateDashboardModal extends React.Component {
  constructor() {
    super();
    this.state = this._getState();
    this._onChange = this._onChange.bind(this);
    this.alignRightText = {
      'textAlign': 'right'
    };
    this.onNameChange = this.onNameChange.bind(this);
    this.onNoteChange = this.onNoteChange.bind(this);
    this.createDashBoard = this.createDashBoard.bind(this);
  }

  _onChange() {
    this.setState(this._getState());
  }

  _getState() {
    return {
      data: {
        name: '',
        note: ''
      }
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  onNameChange(e){
    this.setState({
      data: {
        ...this.state.data,
        name: e.target.value
      }
    })
  }

  onNoteChange(e){
    this.setState({
      data: {
        ...this.state.data,
        note: e.target.value
      }
    })
  }

  createDashBoard() {
    this.props.createDashboard(this.state.data);
    this.setState({
      data: {
        name: '',
        note: ''
      }
    });
    this.props.closeCreateDashboardModal();
  }

  render() {
    return (
      <Modal show={this.props.isOpenCreateDashboardModal} onHide={this.props.closeCreateDashboardModal} >
        <Modal.Header closeButton>
          <h2 className="modal-title">ダンボール登録</h2>
        </Modal.Header>
        <Modal.Body>
          <Form horizontal>
            <FormGroup>
              <Col sm={3} style={this.alignRightText}>
                ダンボール週類<span className="text-danger">(必須)</span>:
              </Col>
              <Col sm={7}>
                <FormControl type='text' placeholder="Name"
                             value={this.state.data.name} onChange={this.onNameChange}/>
              </Col>
            </FormGroup>
            <FormGroup>
              <Col sm={3} style={this.alignRightText}>
                詳細:
              </Col>
              <Col sm={7}>
                <FormControl type='text' placeholder="Note"
                             value={this.state.data.note} onChange={this.onNoteChange}/>
              </Col>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-raised btn-primary" onClick={this.createDashBoard}>保存</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
