const FlyerCampaignConstants = {
  ACTION_TYPE: {
    LOADED_ALL_FLYER_CAMPAIGN: "LOADED_ALL_FLYER_CAMPAIGN",
    LOADED_FLYER_CAMPAIGN: "LOADED_FLYER_CAMPAIGN",
    BAD_REQUEST: "BAD_REQUEST_FLYER_CAMPAIGN",
    ERROR: "ERROR_FLYER_CAMPAIGN"
  },
  API: {
    GET_ALL_FLYER_CAMPAIGN: "/api/flyerCampaigns",
    ADD_FLYER_CAMPAIGN: "/api/flyerCampaign",
    UPDATE_FLYER_CAMPAIGN: "/api/flyerCampaign",
    DELETE_FLYER_CAMPAIGN: "/api/flyerCampaign"
  },
  AGE_OPTIONS: [
    {value: 'pregnant', label: 'マタニティ'},
    {value: '0', label: '0歳代'},
    {value: '1', label: '1歳代'},
    {value: '2', label: '2歳代'},
    {value: '3', label: '3歳代'},
    {value: '4', label: '4歳代'},
    {value: '5', label: '5歳代'},
    {value: '6', label: '6歳代'},
    {value: '7', label: '7歳代以上'},
  ],
  FAMILY_OPTIONS: [
    {value: 'PARENTS', label: 'ママパパ世帯'},
    {value: 'GRAND_PARENTS', label: '祖父母世帯'},
  ],
  AREA_OPTIONS: [
    {value: 'HOKKAIDO', label: '北海道'},
    {value: 'TOHOKU', label: '東北'},
    {value: 'KANTO', label: '関東'},
    {value: 'CHUUBU', label: '中部'},
    {value: 'KINKI', label: '近畿'},
    {value: 'CHINA', label: '中国'},
    {value: 'SHIKOKU', label: '西国'},
    {value: 'KYUSHU', label: '九州'},
  ]
}

export default FlyerCampaignConstants;
