import React from 'react';
import AuthenticatedComponent from '../../../common/components/AuthenticatedComponent.jsx'
import MagazineMailService from "../../services/MagazineMailService.jsx";
import ReceiverStore from '../../stores/ReceiverStore.jsx'
import ReceiverTableComponent from './ReceiverTableComponent.jsx'
import AddUnsubscribeReceiverDialog from "./AddUnsubscribeReceiverDialog.jsx";
import moment from 'moment'
import {Alert} from 'react-bootstrap'
import OurPagingComponent from "../../../common/components/paging/OurPagingComponent.jsx";
import SearchBoxComponent from "../../../common/components/SearchBoxComponent.jsx";
import Button from "react-bootstrap/lib/Button";
import MagazineMailAction from "../../actions/MagazineMailAction.jsx";

class ReceiverListComponent extends React.Component {
  constructor(props) {
    super(props);
    /* == CSS ==  */

    /* == VARIABLE == */

    /* == FUNCTION == */
    ReceiverStore.resetReceiverFilter();
    this.state = {
      unsubscribedReceiversDTO: ReceiverStore.getUnsubscribedReceiversDTO(),
      receiverFilter: ReceiverStore.getReceiverFilter(),
      error: ReceiverStore.getError(),
      isOpenAddUnsubscribeReceiverDialog: false
    };
    this.onChange = this.onChange.bind(this);
    this.closeMessage = this.closeMessage.bind(this);
    this.openAddUnsubscribeReceiverDialog = this.openAddUnsubscribeReceiverDialog.bind(this);
    this.closeAddUnsubscribeReceiverDialog = this.closeAddUnsubscribeReceiverDialog.bind(this);
    /* == SETTINGS == */
    moment.locale('ja');
  } /* end constructor */

  componentDidMount() {
    MagazineMailService.getAllUnsubscribeReceiver(ReceiverStore.getReceiverFilter());
    ReceiverStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    ReceiverStore.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState(() => (this.getState()))
  }

  getState() {
    return {
      unsubscribedReceiversDTO: ReceiverStore.getUnsubscribedReceiversDTO(),
      receiverFilter: ReceiverStore.getReceiverFilter(),
      error: ReceiverStore.getError(),
      isOpenAddUnsubscribeReceiverDialog: false
    }
  } /* end get state */

  openAddUnsubscribeReceiverDialog() {
    this.setState({isOpenAddUnsubscribeReceiverDialog: true});
  }

  closeAddUnsubscribeReceiverDialog() {
    this.setState({
      isOpenAddUnsubscribeReceiverDialog: false,
    });
  }

  closeMessage(event) {
    event.persist();
    this.setState(() => ({
      error: null
    }));
  } /*end close msg*/

  /* == MAIN == */
  render() {
    return (
        <div>
          <div className="container-fluid padding-0">
            <div className="row">
              <div className="col-md-12">
                {this.state.successMessage ? <Alert bsStyle="success">{this.state.successMessage}
                  <button className="close" onClick={this.closeMessage}>
                    <i className="fa fa-times" aria-hidden="true"/>
                  </button>
                </Alert> : ""}
                {this.state.waringMessage ? <Alert bsStyle="warning">{this.state.waringMessage}
                  <button className="close" onClick={this.closeMessage}>
                    <i className="fa fa-times" aria-hidden="true"/>
                  </button>
                </Alert> : ""}
                {this.state.errorMessage ? <Alert bsStyle="danger">{this.state.errorMessage}
                  <button className="close" onClick={this.closeMessage}>
                    <i className="fa fa-times" aria-hidden="true"/>
                  </button>
                </Alert> : ""}
              </div>
            </div>
            {/*end message*/}

            <div style={{display: "flex"}}>
              <div className="btn-group col-sm-1" role="group" style={{marginLeft: "-15px"}}>
                <Button className="btn btn-info btn-raised" onClick={this.openAddUnsubscribeReceiverDialog}>
                    <span>
                        <i className="glyphicon glyphicon-plus">追加</i>
                    </span>
                </Button>
              </div>

              <div className="col-sm-11" style={{paddingTop: "1%"}}>
                <SearchBoxComponent changeKeyword={MagazineMailAction.changeKeyword}
                    placeholder="メールで検索する"/>
              </div>

            </div>

            <ReceiverTableComponent data={this.state.unsubscribedReceiversDTO.guests}
                                    filter={this.state.receiverFilter}
                                    openAddUnsubscribeReceiverDialog={this.openAddUnsubscribeReceiverDialog}/>
            <OurPagingComponent loadNewPage={MagazineMailAction.changePage}
                                dto={this.state.unsubscribedReceiversDTO}/>
          </div>

          <AddUnsubscribeReceiverDialog
              isOpenAddUnsubscribeReceiverDialog={this.state.isOpenAddUnsubscribeReceiverDialog}
              closeAddUnsubscribeReceiverDialog={this.closeAddUnsubscribeReceiverDialog}/>
        </div>
    )
  }/*end render*/
}

export default AuthenticatedComponent(ReceiverListComponent);
