/**
 * Created by
 */

const CollabDesignConstant = {
    API: {
        URL:            "/api/collab-design"
    },
    ACTION_TYPE: {
        LOAD:           "COLLAB_DESIGN_LOAD",
        CHANGE_KEYWORD: "COLLAB_DESIGN_CHANGE_KEYWORD",
        CHANGE_PAGE:    "COLLAB_DESIGN_CHANGE_PAGE",
        CHANGE_STT_FIL: "COLLAB_DESIGN_CHANGE_STT_FIL",
        CHANGE_APPROVE_DESIGN:    "CHANGE_APPROVE_DESIGN"
    },
    STATUS: {
        ALL:            "ALL",
        WAITING:        "WAITING",
        APPROVED:       "APPROVED",
        REJECTED:       "REJECTED"
    },
    STATUS_JP: {
        ALL:            "全て",
        WAITING:        "承認待ち",
        APPROVED:       "承認",
        REJECTED:       "拒否"
    }
};

export default CollabDesignConstant
