/**
 * Created by thangkc on 26/01/2016.
 */
import React from "react";
import request from "reqwest";
import when from "when";
import LoginAction from "../../authentication/actions/LoginAction.jsx";
import DesignAction from "../actions/DesignAction.jsx";
import DesignConstant from "../constants/DesignConstant.jsx";

class DesignService {
    load(designFilter) {
        let url = DesignConstant.API.URL + "?page=" + encodeURIComponent(designFilter.currentPage)
            + "&pageSize=" + encodeURIComponent(designFilter.pageSize);
        if (designFilter.status && designFilter.status !== DesignConstant.STATUS.ALL)
            url += "&status=" + encodeURIComponent(designFilter.status);
        if (designFilter.keyword.trim())
            url += "&keyword=" + encodeURIComponent(designFilter.keyword.trim());

        when(request({
            url: url,
            method: 'GET',
            crossOrigin: true,
            headers: {
                'Csrf-Token': "nocheck",
                'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
            }
        })).then(function (response) {
            switch (response.code) {
                case 200:
                    DesignAction.load(response.data, designFilter);
                    break;
                case 401:
                    LoginAction.loginUser({});
                    break;
                case 400:
                    break;
                default :
                    break;
            }
        })
    }

    deleteDesign(designs) {
        let designSeriesIdString = designs[0].designSeries ? ("?designSeriesIdString=" + designs[0].designSeries.id) : '';
        when(request({
            url: DesignConstant.API.URL + "/" + designs[0].orderNumber + "/" + designs[0].groupNumber + designSeriesIdString,
            method: 'DELETE',
            crossOrigin: true,
            headers: {
                'Csrf-Token': "nocheck",
                'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
            },
            type: 'json'
        })).then(function (response) {
            switch (response.code) {
                case 200:
                    DesignAction.deleteDesign(designs);
                    break;
                case 401:
                    LoginAction.loginUser({});
                    break;
                case 400:
                    break;
                default :
                    break;
            }
        })
    }
}

export default new DesignService()
