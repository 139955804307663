/**
 * Created by thangkc on 20/09/2016.
 */
import React from 'react';
import LoadingButton from '../../../../common/components/LoadingButton.jsx'
import FlashComponent from '../../../../common/components/FlashComponent.jsx'
import Constants from "../../../../common/constants/Constants.jsx"
import DateTimeFormat from "../../../../common/components/DateTimeFormat.jsx"
import OrderConstant from "../../../constants/OrderConstant.jsx"
import OrderService from "../../../services/OrderService.jsx"
import OrderStore from "../../../stores/OrderStore.jsx"
import RakutenStatusFormat from "./RakutenStatusFormat.jsx"
import OrderStatusFormat from "../../../../common/components/order/OrderStatusFormat.jsx"

export default class StatusComponent extends React.Component {

    constructor(props) {
        super();
        this.state = this._getState(props);
        this._onChange = this._onChange.bind(this);
        this.toggleUpdate = this.toggleUpdate.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.synchronizeOrder = this.synchronizeOrder.bind(this);
        this.resetSynchronizationOrderResult = this.resetSynchronizationOrderResult.bind(this);
    }

    _onChange() {
        this.setState(this._getState(this.props));
    }

    _getState(props) {
        return {
            crudMode: Constants.CRUD_MODE.READ,
            updateButtonTitle: '編集',
            selectedOrderStatus: props.order.status,
            isSynchronizingOrder: false,
            isCreatingImageMagicOrder: props.order.status === OrderConstant.STATUS.PENDING_CREATION &&
                props.order.pendingCreationState !== OrderConstant.CREATION_STATE.DONE &&
                props.order.pendingCreationErrorMessage === "",
            orderSynchronizationResult: OrderStore.getSynchronizingOrderResult()
        };
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            selectedOrderStatus: nextProps.order.status
        })
    }

    componentDidMount() {
        OrderStore.addChangeListener(this._onChange);
    }

    componentWillUnmount(){
        OrderStore.removeChangeListener(this._onChange);
    }

    toggleUpdate() {
        if (this.state.crudMode == Constants.CRUD_MODE.READ) {
            this.setState({
                crudMode: Constants.CRUD_MODE.UPDATE,
                updateButtonTitle: '保存'
            });
        } else {
            this.setState({
                crudMode: Constants.CRUD_MODE.READ,
                updateButtonTitle: '編集'
            });
            if (this.state.selectedOrderStatus != this.props.order.status) {
                OrderService.changeEcOrderStatus(this.props.order.orderNumber, this.state.selectedOrderStatus);
            }
        }
    }

    changeStatus(event){
        this.state.selectedOrderStatus = event.target.value;
    }

    synchronizeOrder(){
        this.setState({
            isSynchronizingOrder: true,
            orderSynchronizationResult: {}
        });
        OrderService.synchronizeOrder(this.props.order.orderNumber)
    }

    resetSynchronizationOrderResult(){
        this.setState({
            orderSynchronizationResult: {}
        })
    }

    colorMeStatus(order) {
        return (
            <div>
                <div className="clearfix">
                    <h3 className="colorMe-title pull-left">自社ショップ</h3>
                    <div className="clearfix pull-right">
                        {this.renderSynchronizationOrderResult()}
                        <LoadingButton isLoading={this.state.isSynchronizingOrder}
                                       className="btn-raised btn btn-info"
                                       onClick={this.synchronizeOrder}
                                       disabled={this.state.isCreatingImageMagicOrder || this.state.selectedOrderStatus === OrderConstant.STATUS.CREATING}>同期</LoadingButton>
                    </div>
                </div>
                <h4 className="pull-left">注文基本情報</h4>
                <table className="table table-bordered">
                    <tbody>
                    <tr>
                        <th width="190px">受注番号</th>
                        <td>{order.orderNumber}</td>
                        <th>注文日時</th>
                        <td><DateTimeFormat data={order.orderDate}/></td>
                    </tr>
                    <tr>
                        <th>注文ステータス</th>
                        <td colSpan="3">
                            <OrderStatusFormat data={order.status} renderOption={false} />
                        </td>
                    </tr>
                    <tr>
                        <th>決済</th>
                        <td colSpan="3">{order.colorMePaid == true ? "済" : "未" }</td>
                    </tr>
                    </tbody>
                </table>
            </div>)
    }

    rakutenStatus(order) {
        return (
            <div>
                <div className="clearfix">
                    <h3 className="rakuten-title pull-left">Rakuten</h3>
                    <div className="clearfix pull-right">
                        {this.renderSynchronizationOrderResult()}
                        <LoadingButton isLoading={this.state.isSynchronizingOrder}
                                       className="btn-raised btn btn-info"
                                       onClick={this.synchronizeOrder}
                                       disabled={this.state.isCreatingImageMagicOrder || this.state.selectedOrderStatus === OrderConstant.STATUS.CREATING}>同期</LoadingButton>
                    </div>
                </div>
                <h4 className="pull-left">注文基本情報</h4>
                <table className="table table-bordered">
                    <tbody>
                    <tr>
                        <th width="190px">受注番号</th>
                        <td>{order.orderNumber}</td>
                        <th width="190px">注文日時</th>
                        <td><DateTimeFormat data={order.orderDate}/></td>
                    </tr>
                    <tr>
                        <th width="190px">注文ステータス</th>
                        <td colSpan="3">

                            {this.state.crudMode == Constants.CRUD_MODE.READ
                                ?
                                order.rakutenStatus || order.status
                                :
                                this.state.selectedOrderStatus == OrderConstant.STATUS.CANCEL
                                    ?
                                    <select defaultValue={this.state.selectedOrderStatus} onChange={this.changeStatus}>
                                        <RakutenStatusFormat data={OrderConstant.STATUS.CANCEL} renderOption={true}/>
                                    </select>
                                    :
                                    <select defaultValue={this.state.selectedOrderStatus} onChange={this.changeStatus}>
                                        <RakutenStatusFormat data={OrderConstant.STATUS.DRAFT} renderOption={true}/>
                                        <RakutenStatusFormat data={OrderConstant.STATUS.CREATING} renderOption={true}/>
                                        <RakutenStatusFormat data={OrderConstant.STATUS.SHIPPED} renderOption={true}/>
                                        <RakutenStatusFormat data={OrderConstant.STATUS.PENDING} renderOption={true}/>
                                        <RakutenStatusFormat data={OrderConstant.STATUS.CANCEL} renderOption={true}/>
                                        <RakutenStatusFormat data={OrderConstant.STATUS.URL_RELEASED} renderOption={true}/>
                                    </select>
                            }
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>)
    }

    renderSynchronizationOrderResult(){
        if(this.state.orderSynchronizationResult.httpCode){
            var result = null;
            switch(this.state.orderSynchronizationResult.httpCode){
                case 200:
                    result = {
                        className: 'text-success',
                        message: '注文情報が同期されました。'
                    };
                    break;
                default:
                    result = {
                        className: 'text-danger',
                        message: '注文同期に失敗しました。'
                    };
                    break;
            }
            return <FlashComponent visible={true} timeout={5000} className={result.className} onHide={this.resetSynchronizationOrderResult}>
                {result.message}
            </FlashComponent>
        }else{
            return null;
        }
    }

    loading(){
        return(<div>Loading data</div>)
    }

    get getStatus() {
        var order = this.props.order;
        if (order == {})
            return this.loading();
        if (order.ecSiteId == OrderConstant.EC_SITE_ID.RAKUTEN)
            return this.rakutenStatus(order);
        else if(order.ecSiteId == OrderConstant.EC_SITE_ID.YAHOO)
            return this.yahooStatus(order);
        else if(order.ecSiteId == OrderConstant.EC_SITE_ID.COLOR_ME)
            return this.colorMeStatus(order);
        else
            return null;
    }

    render() {
        return (this.getStatus)
    }
}
