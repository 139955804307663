'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactClass = require('react-class');

var _reactClass2 = _interopRequireDefault(_reactClass);

var _objectAssign = require('object-assign');

var _objectAssign2 = _interopRequireDefault(_objectAssign);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var emptyFn = function emptyFn() {};

var ReactRadioGroup = function (_Component) {
    _inherits(ReactRadioGroup, _Component);

    function ReactRadioGroup(props) {
        _classCallCheck(this, ReactRadioGroup);

        var _this = _possibleConstructorReturn(this, Object.getPrototypeOf(ReactRadioGroup).call(this, props));

        _this.state = {
            defaultValue: props.defaultValue
        };
        return _this;
    }

    _createClass(ReactRadioGroup, [{
        key: 'componentDidUpdate',
        value: function componentDidUpdate() {
            if (!this.shouldGenerateChildren()) {
                this.setRadioNames();
                this.setCheckedRadio();
            }
        }
    }, {
        key: 'componentDidMount',
        value: function componentDidMount() {
            if (!this.shouldGenerateChildren()) {
                this.setRadioNames();
                this.setCheckedRadio();
            }
        }
    }, {
        key: 'setCheckedRadio',
        value: function setCheckedRadio() {
            var value = this.props.value != null ? this.props.value : this.state.defaultValue;

            this.someRadio(function (radio) {
                if (radio.value == value) {
                    radio.checked = true;
                    return true;
                }
            });
        }
    }, {
        key: 'setRadioNames',
        value: function setRadioNames() {
            this.forEachRadio(function (radio) {
                radio.setAttribute('name', this.props.name);
            });
        }
    }, {
        key: 'someRadio',
        value: function someRadio(fn) {
            var $radios = this.getRadios();

            return [].some.call($radios, fn, this);
        }
    }, {
        key: 'forEachRadio',
        value: function forEachRadio(fn) {
            var $radios = this.getRadios();

            return [].forEach.call($radios, fn, this);
        }
    }, {
        key: 'getRadios',
        value: function getRadios() {
            return (0, _reactDom.findDOMNode)(this).querySelectorAll('input[type="radio"]');
        }
    }, {
        key: 'render',
        value: function render() {
            var props = this.prepareProps(this.props, this.state);

            return _react2.default.createElement('div', props);
        }
    }, {
        key: 'getValue',
        value: function getValue() {
            if (this.value == undefined) {
                this.value = this.state.defaultValue;
            }

            return this.value;
        }
    }, {
        key: 'handleChange',
        value: function handleChange(event) {
            var target = event.target;
            var fn = this.props.onChange || emptyFn;
            var value = this.value = target.value;

            fn(value, event);

            if (this.props.value == null) {
                this.setState({
                    defaultValue: value
                });
            }
        }
    }, {
        key: 'shouldGenerateChildren',
        value: function shouldGenerateChildren() {
            return !this.props.children;
        }
    }, {
        key: 'prepareProps',
        value: function prepareProps(thisProps, state) {

            var props = {};

            (0, _objectAssign2.default)(props, thisProps);

            if (this.shouldGenerateChildren()) {
                props.labelStyle = this.prepareLabelStyle(props, state);
                props.inputStyle = this.prepareInputStyle(props, state);
                props.children = this.prepareChildren(props, state);
            }

            props.onChange = this.handleChange;

            return props;
        }
    }, {
        key: 'prepareLabelStyle',
        value: function prepareLabelStyle(props) {
            return (0, _objectAssign2.default)({}, props.defaultLabelStyle, props.labelStyle);
        }
    }, {
        key: 'prepareInputStyle',
        value: function prepareInputStyle(props) {
            return (0, _objectAssign2.default)({}, props.defaultInputStyle, props.inputStyle);
        }
    }, {
        key: 'prepareChildren',
        value: function prepareChildren(props, state) {

            var checkedValue = props.value != null ? props.value : state.defaultValue;

            return (props.items || []).map(function (item, index, arr) {

                var inputStyle = (0, _objectAssign2.default)({}, props.inputStyle);
                var labelStyle = (0, _objectAssign2.default)({}, props.labelStyle);
                var disabledStyle = (0, _objectAssign2.default)({}, props.disabledStyle);
                var disabled = false;
                var checked;
                var value;
                var children;

                if (typeof item === 'string') {
                    value = item;
                    checked = checkedValue == value;
                    children = item;
                } else {
                    value = item.value;
                    children = item.label || item.value;
                    checked = checkedValue == value;
                    disabled = !!item.disabled;

                    if (item.inputStyle) {
                        (0, _objectAssign2.default)(inputStyle, item.inputStyle);
                    }
                    if (item.style) {
                        (0, _objectAssign2.default)(labelStyle, item.style);
                    }
                }

                if (disabled) {

                    (0, _objectAssign2.default)(inputStyle, props.disabledInputStyle);
                    (0, _objectAssign2.default)(labelStyle, props.disabledStyle, props.disabledLabelStyle);

                    if (item && item.disabledStyle) {
                        (0, _objectAssign2.default)(labelStyle, item.disabledStyle);
                    }
                }

                if (checked) {
                    (0, _objectAssign2.default)(inputStyle, props.checkedInputStyle);
                    (0, _objectAssign2.default)(labelStyle, props.checkedLabelStyle);

                    if (item && item.checkedStyle) {
                        (0, _objectAssign2.default)(labelStyle, item.checkedStyle);
                    }
                }

                var inputProps = {
                    checked: checked,
                    value: value,
                    name: props.name,
                    type: 'radio',
                    disabled: disabled,
                    style: inputStyle,
                    onChange: emptyFn
                };

                var radioProps = {
                    key: index,
                    checked: checked,
                    index: index,
                    name: props.name,
                    value: value,
                    style: labelStyle,
                    inputProps: inputProps,
                    label: children,
                    item: item,
                    children: [_react2.default.createElement('input', inputProps), children]
                };

                var renderFn = props.renderRadio;
                var result;

                if (renderFn) {
                    result = renderFn(radioProps, index, arr);
                }

                if (result === undefined) {
                    result = _react2.default.createElement('label', radioProps);
                }

                return result;
            });
        }
    }]);

    return ReactRadioGroup;
}(_reactClass2.default);

exports.default = ReactRadioGroup;


ReactRadioGroup.defaultProps = {
    defaultLabelStyle: {
        cursor: 'pointer'
    },
    defaultInputStyle: {
        cursor: 'pointer'
    }
};

ReactRadioGroup.propTypes = {
    name: _react.PropTypes.string.isRequired,
    items: function items(props, name) {
        if (!props.children && !props.items) {
            return new Error('Your component has no children. In this case, you should specify an items array.');
        }
    }
};