/**
 * Created by thangkc on 20/09/2016.
 */
import React from 'react';
import NumberFormat from '../../../common/components/NumberFormat.jsx'

export default class BillComponent extends React.Component {
    constructor() {
        super();
        this.state = this._getState();
        this._onChange = this._onChange.bind(this);
    }

    _onChange() {
        this.setState(this._getState());
    }

    _getState() {
        return {};
    }


    render() {
        var order = this.props.order;
        return (
            <div className="row">
                <div className="col-md-6">
                    <h4>商品変更履歴</h4>
                    <p></p>
                </div>
                <div className="col-md-6">
                    <table className="table table-bordered bill">
                        <tbody>
                        <tr>
                            <th width="190px">商品金額会計（税込）</th>
                            <td><NumberFormat data={order.goodsPrice} />円</td>
                        </tr>
                        <tr>
                            <th>ギフト包装料&nbsp;</th>
                            <td><NumberFormat data={order.wrappingPrice} />円</td>
                        </tr>
                        <tr>
                            <th>送料&nbsp;</th>
                            <td><NumberFormat data={order.postagePrice} />円</td>
                        </tr>
                        <tr>
                            <th>手数料&nbsp;</th>
                            <td><NumberFormat data={order.transferCommission} />円</td>
                        </tr>
                        <tr>
                            <th>{order.payMethod == 'GMO後払い' ? 'GMO後払い手数料' : '代引'}&nbsp;</th>
                            <td><NumberFormat data={order.deliveryPrice} />円</td>
                        </tr>
                        <tr>
                            <th>値引き&nbsp;</th>
                            <td><NumberFormat data={order.couponAllTotalPrice} />円</td>
                        </tr>
                        <tr>
                            <th>ポイント利用分&nbsp;</th>
                            <td><NumberFormat data={order.usedPoint} />ポイント</td>
                        </tr>
                        <tr>
                            <th>会計金額（税込）</th>
                            <td><NumberFormat data={order.requestPrice} />円</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
