import React from 'react'
import MagazineMailService from "../../services/MagazineMailService.jsx";
import ConfirmDialog from "../../../common/components/dialogs/ConfirmDialog.jsx"
import ReceiverTableRowComponent from "./ReceiverTableRowComponent.jsx";
import ReceiverStore from "../../stores/ReceiverStore.jsx";
export default class ReceiverTableComponent extends React.Component {
  constructor() {
    super()
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this)
    this.showConfirmDialog = this.showConfirmDialog.bind(this)
    this.recoverSubscribe = this.recoverSubscribe.bind(this)
    this.state = {
      sizePerPage: 10,
      showConfirmDialog: false,
      receiverId: null,
      receiverFilter: ReceiverStore.getReceiverFilter(),
    }
  }

  showConfirmDialog() {
    this.setState({
      showConfirmDialog: true
    })
  }

  closeConfirmDialog() {
    this.setState({
      showConfirmDialog: false
    })
  }

  recoverSubscribe(cell) {
    this.setState({receiverId: cell})
    this.setState({receiverFilter: ReceiverStore.getReceiverFilter()})
    this.showConfirmDialog()
  }

  /**
   * table funtion
   *
   */

  get orderRows() {
    let self = this;
    var unsubscribeReceivers = this.props.data;
    if(unsubscribeReceivers.length > 0)
      return (unsubscribeReceivers.map(function(unsubscribeReceiver){
        return <ReceiverTableRowComponent unsubscribeReceiver={unsubscribeReceiver} key={unsubscribeReceiver.id}
                                       changeToSubscribedReceiver={self.recoverSubscribe}/>
      }));
    else
      return <tr>
        <td colSpan="2" style={{textAlign: "center"}}>{this.props.filter.keyword ? <span>検索結果は0件です。</span> : <span>注文は0件です。</span> }</td>
      </tr>
  }

  render() {

    return (
        <div>
          <div className="tbl-admin tbl-template">
            <table className="table table-striped table-hover table-bordered">
              <thead>
              <tr>
                <th style={{width: '70%'}}>ユーザ名</th>
                <th>アクション</th>
              </tr>
              </thead>
              <tbody>
              {this.orderRows}
              </tbody>
            </table>
          </div>

          <ConfirmDialog showModal={this.state.showConfirmDialog}
                         accept={MagazineMailService.changeToSubscribedReceiver
                             .bind(MagazineMailService, this.state.receiverId, this.state.receiverFilter)}
                         close={this.closeConfirmDialog}
                         message={"メールアドレスを削除します。よろしいですか？"}/>
        </div>
    )
  }
}