import React from 'react'

import ToggleLoadingButton from '../../../common/components/ToggleLoadingButton.jsx'
import {
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
} from 'react-bootstrap/lib'
import TheatreCommonTable from './TheatreCommonTable.jsx'
import OurPagingComponent from '../../../common/components/paging/OurPagingComponent.jsx'

export default class TheatreCommonComponent extends React.Component {

  constructor (props) {
    super()
    this.TheatreStore = props.store
    this.TheatreService = props.service
    this.state = this.initState()
    this._onChange = this._onChange.bind(this)
    this.startDownloadTheatre = this.startDownloadTheatre.bind(this)
    this.endDownloadTheatre = this.endDownloadTheatre.bind(this)
    this.changePage = this.changePage.bind(this)

  }

  initState () {
    this.TheatreStore._resetFilter()
    return this._getState()
  }

  _getState () {
    let doneExport = this.TheatreStore.getExportStatus()
    let doneZipFile = this.TheatreStore.getZipStatus()
    let loadingBtnLabel = 'CSVエクスポート中'
    let isDownloadTheatre = true

    if (doneExport == null && doneZipFile == null){
      isDownloadTheatre = false
    } else {
      if (doneExport) loadingBtnLabel = 'ZIPファイル梱包'
      if (doneZipFile) isDownloadTheatre = false
    }

    return {
      isDownloadTheatre: isDownloadTheatre,
      loadingBtnLabel: loadingBtnLabel,
      exportStatus: this.TheatreStore.getExportStatus(),
      zipStatus: this.TheatreStore.getZipStatus(),
      campaignDTO: this.TheatreStore._getCampaignDTO(),
      campaignFilter: this.TheatreStore._getCampaignFilter(),
    }
  }

  _onChange () {
    this.setState(this._getState())
  }

  componentDidMount () {
    this.TheatreService.load(this.TheatreStore._getCampaignFilter())
    this.TheatreStore.addChangeListener(this._onChange)
  }

  componentWillUnmount () {
    this.TheatreStore.removeChangeListener(this._onChange)
  }

  startDownloadTheatre () {
    let self = this
    self.setState({isDownloadTheatre: true})
    self.TheatreService.exportCSVFile()
  }

  changePage(page) {
    let self = this
    self.TheatreService.changePage(page)
  }

  endDownloadTheatre () {}

  render () {
    return (
      <section className='campaign-tab'>

        <Form horizontal>
          <FormGroup controlId="formControlsSelect">
            <h3 className="col-sm-3">
              全てデータ
            </h3>
            <Col sm={7}>
              <ToggleLoadingButton
                onStartLoading={this.startDownloadTheatre}
                onStopLoading={this.endDownloadTheatre}
                isLoading={this.state.isDownloadTheatre}
                disabled={this.state.isDownloadTheatre}
                btnLabel='ダウンロード'
                loadingBtnLabel={this.state.loadingBtnLabel}
                btnClass='btn btn-primary btn-raised btn-lg ld-ext-left'
                loadingBtnClass='btn btn-warning btn-raised btn-lg ld-ext-left'
                loadingIconClass='ld ld-ring ld-spin'>
              </ToggleLoadingButton>
            </Col>
          </FormGroup>
        </Form>
        <TheatreCommonTable campaigns={this.state.campaignDTO.campaigns}
                       campaignFilter={this.state.campaignFilter}/>

        <OurPagingComponent loadNewPage={this.changePage}
                            dto={this.state.campaignDTO}/>
      </section>
    )
  }

}

