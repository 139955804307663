import Dispatcher from '../../common/dispatcher/Dispatcher.jsx'
import SettingConstants from '../constants/SettingConstants.jsx'

export default {
  load: () => {
    Dispatcher.dispatch({
      actionType: SettingConstants.MODULE_STATUS.LOAD,
    })
  },
  getAllSettingModule: (data) => {
    Dispatcher.dispatch({
      actionType: SettingConstants.ACTION_TYPE.GET_ALL_MODULE_SETTING,
      data: data
    })
  },
  updateSettingModule: (responseCode) => {
    Dispatcher.dispatch({
      actionType: SettingConstants.ACTION_TYPE.UPDATE_MODULE_SETTING,
      responseCode: responseCode
    })
  },
  badRequest: (message) => {
    Dispatcher.dispatch({
      actionType: SettingConstants.MODULE_STATUS.BAD_REQUEST,
      message: message,
    })
  },
  error: () => {
    Dispatcher.dispatch({
      actionType: SettingConstants.MODULE_STATUS.ERROR
    })
  },
}