import React from "react";
import EpsilonDataTrackingRow from "./EpsilonDataTrackingRow.jsx";
import EpsilonDataTrackingAction from "../../actions/EpsilonDataTrackingAction.jsx";

class EpsilonDataTrackingTable extends React.Component {
    constructor() {
        super();
    }

    get orderRows() {
        const { orders } = this.props
        if(orders.length > 0)
            return (orders.map((order, index) => {
                return <EpsilonDataTrackingRow order={order} key={index + order.orderNumber} />
            }));
        else
            return <tr>
                <td colSpan="5"><span>検索結果は0件です。</span></td>
            </tr>
    }

    getCheckAllStatus() {
        const { orders } = this.props
        if (orders.length > 0) {
            const checkedOrders = orders.filter((order) => order.checked)
            return checkedOrders.length === orders.length
        }
        return false
    }

    render() {
        const { orders } = this.props
        return (
            <div className="table-wrapper tbl-admin tbl-template">
                <table style={{ tableLayout: "fixed" }} className="table table-striped table-hover table-bordered">
                    <thead>
                    <tr>
                        <th width={"5%"} style={{ textAlign: "center" }}>
                            <input
                                checked={this.getCheckAllStatus()}
                                type="checkbox"
                                onChange={(e) => {
                                    EpsilonDataTrackingAction.checkAllOrder(orders.map((order) => ({
                                        ...order,
                                        checked: e.target.checked
                                    })))
                                }}
                            />
                        </th>
                        <th width={"30%"}>注文番号</th>
                        <th width={"20%"}>ステータス</th>
                        <th width={"30%"}>EC注文日付</th>
                        <th width={"15%"} style={{ textAlign: "center" }}>イプシロン連携状態</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.orderRows}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default EpsilonDataTrackingTable