import React from 'react'
import moment from 'moment'
import AuthenticatedComponent from '../../../common/components/AuthenticatedComponent.jsx'
import ReportService from "../../services/ReportService.jsx";
import ReportConstant from "../../constants/ReportConstant.jsx";
import ChildrenAgeGroupReportChart from "./ChildrenAgeGroupReportChart.jsx";
import {DateRangePicker} from "react-dates";
import LoadingButton from "../../../common/components/LoadingButton.jsx";
import ChildrenAgeGroupReportTable from "./ChildrenAgeGroupReportTable.jsx";
import ReportAction from "../../actions/ReportAction.jsx";
import ChildrenAgeGroupReportStore from "../../stores/ChildrenAgeGroupReportStore.jsx";
import RadioGroup from 'react-radio';
import ChildrenSurveyTable from "./ChildrenSurveyTable.jsx";
import OurPagingComponent from "../../../common/components/paging/OurPagingComponent.jsx";

class ChildrenAgeGroupReportComponent extends React.Component {
  constructor() {
    super();
    this.state = this._getState()
    this._onDateChange = this._onDateChange.bind(this)
    this._onFocusChange = this._onFocusChange.bind(this)
    this._onChange = this._onChange.bind(this)
    this.changePage = this.changePage.bind(this)
    moment.locale('ja')
  }

  componentDidMount() {
    ReportService.getChildrenAgeGroupReport(this.state.selectedStartDate, this.state.selectedEndDate, 1)
    ReportService.getChildrenAgeGroupReportChart(this.state.selectedStartDate, this.state.selectedEndDate)
    ReportService.getChilrenSurveyData(this.state.selectedStartDate, this.state.selectedEndDate, 1)
    ChildrenAgeGroupReportStore.addChangeListener(this._onChange)
  }

  componentWillUnmount () {
    ChildrenAgeGroupReportStore.removeChangeListener(this._onChange)
  }

  handleGettingReportClick(e) {
    if (e) e.preventDefault();
    ReportAction.load()
    ReportService.getChildrenAgeGroupReport(
      moment(this.state.selectedStartDate),
      moment(this.state.selectedEndDate), 1
    )
    ReportService.getChildrenAgeGroupReportChart(moment(this.state.selectedStartDate), moment(this.state.selectedEndDate))
    ReportService.getChilrenSurveyData(
      moment(this.state.selectedStartDate),
      moment(this.state.selectedEndDate), 1
    )
  }

  _changeTable (tableName) {
    this.setState({activeTable: tableName})
  }

  changePage (currentPage) {
    ReportAction.load()
    let elmnt = document.getElementById('childrenAgeGroupReportTable')
    window.scrollTo(elmnt.offsetLeft, elmnt.offsetTop - 60)
    ReportService.getChilrenSurveyData(this.state.selectedStartDate,
      this.state.selectedEndDate, currentPage)
  }

  renderActiveTableClass(tableName){
    return this.state.activeTable === tableName ? 'active' : ''
  }

  renderTableComponent() {
    if (this.state.activeTable === ReportConstant.TABLE_TYPE.CHILDREN_REPORT_DATA) return (
      <ChildrenAgeGroupReportTable
        reportData={this.state.reportDTO.report}
        total={this.state.reportDTO.total}
        totalNoDup={this.state.reportDTO.totalNoDup}
        status={this.state.liveWith}
      />
    ); else return (
      <div>
        <ChildrenSurveyTable surveyData={this.state.surveyData}/>
        <OurPagingComponent loadNewPage={this.changePage}
                            dto={{
                              currentPage: ChildrenAgeGroupReportStore.getcurrentpage(),
                              maxPage: Math.ceil(ChildrenAgeGroupReportStore.getTotal() / 10)
                            }}/>
      </div>
    )
  }

  exportExcel() {
    this.setState({
      exportLoading: true,
    });
    ReportService.getChildrenAgeGroupReportFile(
      moment(this.state.selectedStartDate),
      moment(this.state.selectedEndDate)
    )
    let self = this;
    setTimeout(function () {
      self.setState({
        exportLoading: false,
      })
    }, 3000)
  }

  _onDateChange ({startDate, endDate}) {
    this.setState({selectedStartDate: startDate, selectedEndDate: endDate})
  }

  _onFocusChange (focusedInput) {
    this.setState({focusedInput: focusedInput})
  }

  _onChange () {
    this.setState(this._getState())
  }

  viewBarReport(value, event) {
    ReportService.setActiveBarChart(value)
  }

  _getState () {
    return {
      viewBarChart: ChildrenAgeGroupReportStore.getActiveBarChart(),
      selectedStartDate: this.state ? this.state.selectedStartDate ||
        moment().subtract(4, 'months') : moment().subtract(4, 'months'),
      selectedEndDate: this.state
        ? this.state.selectedEndDate || moment()
        : moment(),
      focusedInput: null,
      reportDTO: ChildrenAgeGroupReportStore.getChildrenAgeGroupData(),
      liveWith: ChildrenAgeGroupReportStore.getLiveStatus(),
      errorMessage: null,
      loading: ChildrenAgeGroupReportStore.isLoading(),
      exportLoading: false,
      barChartData: ChildrenAgeGroupReportStore.getBarChartData(),
      activeTable: ChildrenAgeGroupReportStore.getActiveTable(),
      surveyData: ChildrenAgeGroupReportStore.getSurveyData()
    }
  }

  render() {
    const barChart = () => {
      switch (this.state.viewBarChart) {

        case ReportConstant.CHART_BAR_VIEW.DAY:
          if (this.state.barChartData && this.state.barChartData.dayChartData) return <ChildrenAgeGroupReportChart datasets={this.state.barChartData.dayChartData}
                                         labels={this.state.barChartData.dayChartData.pregnant.labels}
                                         type={ReportConstant.CHART_TYPE.BAR}/>
          else return <h1>No project match</h1>
        case ReportConstant.CHART_BAR_VIEW.MONTH:
          if (this.state.barChartData && this.state.barChartData.monthChartData) return <ChildrenAgeGroupReportChart datasets={this.state.barChartData.monthChartData}
                                         labels={this.state.barChartData.monthChartData.pregnant.labels}
                                         type={ReportConstant.CHART_TYPE.BAR}/>
          else return <h1>No project match</h1>
        case ReportConstant.CHART_BAR_VIEW.YEAR:
          if ( this.state.barChartData && this.state.barChartData.yearChartData) return <ChildrenAgeGroupReportChart datasets={this.state.barChartData.yearChartData}
                                         labels={this.state.barChartData.yearChartData.pregnant.labels}
                                         type={ReportConstant.CHART_TYPE.BAR}/>
          else return <h1>No project match</h1>
        default:
          return <h1>No project match</h1>
      }
    }

    return (
      <section>
        <div className="report-form">
          <div className="form-inline">
            <label>出荷日:&nbsp;</label>
            <DateRangePicker
              readOnly
              disabled={this.state.loading}
              startDatePlaceholderText="開始日"
              endDatePlaceholderText="完了日"
              monthFormat="YYYY[年]MMMM"
              phrases={{
                closeDatePicker: '完了',
                clearDates: 'クリア',
              }}
              startDate={this.state.selectedStartDate}
              endDate={this.state.selectedEndDate}
              focusedInput={this.state.focusedInput}
              onDatesChange={this._onDateChange}
              onFocusChange={this._onFocusChange}
              isOutsideRange={() => false}
              hideKeyboardShortcutsPanel
              minimumNights={0}
              showClearDates/>

            <LoadingButton
              disabled={this.state.selectedStartDate === null ||
              this.state.selectedEndDate === null}
              isLoading={this.state.loading}
              className="btn-raised btn btn-info btn-md"
              onClick={this.handleGettingReportClick.bind(
                this)}>集計
            </LoadingButton>

            <LoadingButton isLoading={this.state.exportLoading}
                           onClick={this.exportExcel.bind(this)}
                           bsStyle="default"
                           className="btn btn-raised btn-info">エクスポート</LoadingButton>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-5">
            <div className="row" style={{height: '52px'}}/>
            <div className="row">
              <ChildrenAgeGroupReportChart datasets={this.state.reportDTO.total}
                                      type={ReportConstant.CHART_TYPE.PIE}/>
            </div>
          </div>

          <div className="col-12 col-md-7">
            <div className="button-wrap">
              <RadioGroup name="colors" value={this.state.viewBarChart}
                          onChange={this.viewBarReport}>
                <input
                  id={ReportConstant.CHART_BAR_VIEW.DAY}
                  className="hidden radio-label" type="radio"
                  value={ReportConstant.CHART_BAR_VIEW.DAY}/>
                <label htmlFor={ReportConstant.CHART_BAR_VIEW.DAY}
                       className="button-label">daily
                </label>
                <input
                  id={ReportConstant.CHART_BAR_VIEW.MONTH}
                  className="hidden radio-label" type="radio"
                  value={ReportConstant.CHART_BAR_VIEW.MONTH}/>
                <label
                  htmlFor={ReportConstant.CHART_BAR_VIEW.MONTH}
                  className="button-label">monthly</label>
                <input
                  id={ReportConstant.CHART_BAR_VIEW.YEAR}
                  className="hidden radio-label" type="radio"
                  value={ReportConstant.CHART_BAR_VIEW.YEAR}/>
                <label
                  htmlFor={ReportConstant.CHART_BAR_VIEW.YEAR}
                  className="button-label">yearly</label>
              </RadioGroup>
            </div>


            <div className="row" id="bar-chart">
              {barChart()}
            </div>
          </div>
        </div>
        <div className="row">
          <ul className="nav nav-tabs report-nav">
            <li className={this.renderActiveTableClass(ReportConstant.TABLE_TYPE.SURVEY_DATA_LIST)} onClick={this._changeTable.bind(this,
              ReportConstant.TAB_NAME.SURVEY_DATA_LIST)}>アンケート回答リスト
            </li>
            <li className={this.renderActiveTableClass(ReportConstant.TABLE_TYPE.CHILDREN_REPORT_DATA)} onClick={this._changeTable.bind(this,
              ReportConstant.TAB_NAME.CHILDREN_REPORT_DATA)}>お子さん年齢レポート
            </li>
          </ul>
        </div>
        <div className="row flex-xl-nowrap tbl-admin tbl-template" id="childrenAgeGroupReportTable">
          {this.state.loading ?
            <div><span className="loading-spin-icon"/><span
              style={{display: 'inline-block', height: '25px'}}>読み込み中。。。 </span>
            </div> : ''}
          {this.renderTableComponent()}
        </div>
      </section>
    )
  }
}

export default AuthenticatedComponent(ChildrenAgeGroupReportComponent)
