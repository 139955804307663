'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.default = checkDecoratorArguments;
function checkDecoratorArguments(functionName, signature) {
	if (process.env.NODE_ENV !== 'production') {
		for (var i = 0; i < (arguments.length <= 2 ? 0 : arguments.length - 2); i += 1) {
			var arg = arguments.length <= i + 2 ? undefined : arguments[i + 2];
			if (arg && arg.prototype && arg.prototype.render) {
				// eslint-disable-next-line no-console
				console.error('You seem to be applying the arguments in the wrong order. ' + ('It should be ' + functionName + '(' + signature + ')(Component), not the other way around. ') + 'Read more: http://react-dnd.github.io/react-dnd/docs-troubleshooting.html#you-seem-to-be-applying-the-arguments-in-the-wrong-order');
				return;
			}
		}
	}
}