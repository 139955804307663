/**
 * Created by thangkc on 20/09/2016.
 */
import React from 'react';
import DesignCreaterInfoComponent from './DesignCreaterInfoComponent.jsx';
import DesignImageFormat from '../../../common/components/design/DesignImageFormat.jsx';
import DesignStatusFormat from '../../../common/components/design/DesignStatusFormat.jsx';
import DesignActionFormat from '../../../common/components/design/DesignActionFormat.jsx';
import DateTimeFormat from '../../../common/components/DateTimeFormat.jsx';
import OrderStatusFormat from '../../../common/components/order/OrderStatusFormat.jsx';
import Button from 'react-bootstrap/lib/Button';
import FlashComponent from '../../../common/components/FlashComponent.jsx';
import LoadingButton from '../../../common/components/LoadingButton.jsx';
import ConfirmDialog from '../../../common/components/dialogs/ConfirmDialog.jsx';
import NotifyDialog from '../../../common/components/dialogs/NotifyDialog.jsx';
import Constants from '../../../common/constants/Constants.jsx';
import OrderConstant from '../../constants/OrderConstant.jsx';
import OrderStore from '../../stores/OrderStore.jsx';
import OrderService from '../../services/OrderService.jsx';

export default class DesignInfoComponent extends React.Component {
  constructor(props) {
    super();
    this.state = this._getState(props);

    this.createImageMagicOrder = this.createImageMagicOrder.bind(this);
    this.resetCreatingImageMagicOrderResult = this.resetCreatingImageMagicOrderResult.bind(
        this);
    this.renderCreatingImageMagicOrderResult = this.renderCreatingImageMagicOrderResult.bind(
        this);
    this.showCreatingIMOrderWithPaymentDialog = this.showCreatingIMOrderWithPaymentDialog.bind(
        this);
    this.closeCreatingIMOrderWithPaymentDialog = this.closeCreatingIMOrderWithPaymentDialog.bind(
        this);
    this.showCreatingIMOrderWithoutPaymentDialog = this.showCreatingIMOrderWithoutPaymentDialog.bind(
        this);
    this.closeCreatingIMOrderWithoutPaymentDialog = this.closeCreatingIMOrderWithoutPaymentDialog.bind(
        this);
    this.verifyOrderPayment = this.verifyOrderPayment.bind(this);
    this.showCreatingIMOrderDialog = this.showCreatingIMOrderDialog.bind(this);

    this.resetOrderStatusChangeResult = this.resetOrderStatusChangeResult.bind(
        this);
    this.isCreatingImageMagicOrderButtonEnabled = this.isCreatingImageMagicOrderButtonEnabled.bind(
        this);
    this._onChange = this._onChange.bind(this);
    this.toggleUpdate = this.toggleUpdate.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.changeStatus = this.changeStatus.bind(this);

    this.renderCreationStateRow = this.renderCreationStateRow.bind(this);
    this.hideCreationErrorDialog = this.hideCreationErrorDialog.bind(this);
    this.showChangingToPauseDialog = this.showChangingToPauseDialog.bind(this);
    this.closeChangingToPauseDialog = this.closeChangingToPauseDialog.bind(
        this);
  }

  _onChange() {
    this.setState(this._getState(this.props));
    this.showCreatingIMOrderDialog();
  }

  _getState(props) {
    return {
      crudMode: Constants.CRUD_MODE.READ,
      updateButtonTitle: '編集',
      selectedOrderStatus: props.order.status,
      orderStatusChangeResult: OrderStore.getOrderStatusChangeResult(),
      isCreatingImageMagicOrder: props.order.status ===
      OrderConstant.STATUS.PENDING_CREATION &&
      props.order.pendingCreationState !== OrderConstant.CREATION_STATE.DONE &&
      props.order.pendingCreationErrorMessage === '',
      createImageMagicOrderResult: OrderStore.getCreatingImageMagicOrderResult(),
      verifyOrderPaymentResult: OrderStore.getOrderPaymentResult(),
    };
  }

  componentDidMount() {
    OrderStore.addChangeListener(this._onChange);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedOrderStatus: nextProps.order.status,
    });
  }

  componentWillUnmount() {
    OrderStore.removeChangeListener(this._onChange);
  }

  toggleUpdate() {
    if (this.state.crudMode === Constants.CRUD_MODE.READ) {
      this.setState({
        crudMode: Constants.CRUD_MODE.UPDATE,
        updateButtonTitle: '保存',
      });
    } else {
      if (this.state.selectedOrderStatus === OrderConstant.STATUS.PAUSE) {
        this.showChangingToPauseDialog();
      } else {
        this.updateStatus();
      }
    }
  }

  updateStatus() {
    this.setState({
      crudMode: Constants.CRUD_MODE.READ,
      updateButtonTitle: '編集',
      orderStatusChangeResult: {},
    });
    if (this.state.selectedOrderStatus !== this.props.order.status) {
      OrderService.changeOrderStatus(this.props.order.orderNumber,
          this.state.selectedOrderStatus);
    }
  }

  resetOrderStatusChangeResult() {
    this.setState({
      orderStatusChangeResult: {},
    });
  }

  changeStatus(event) {
    this.setState({
      selectedOrderStatus: event.target.value,
    });
  }

  verifyOrderPayment() {
    this.setState({
      isCreatingImageMagicOrder: true,
      verifyOrderPaymentResult: {},
    });
    OrderService.verifyOrderPayment(this.props.order.orderNumber);
  }

  showCreatingIMOrderDialog() {
    if (this.state.verifyOrderPaymentResult.httpCode) {
      switch (this.state.verifyOrderPaymentResult.httpCode) {
        case 200:
          if (this.state.verifyOrderPaymentResult.paymentStatus ===
              OrderConstant.PAYMENT_STATUS.SETTLED) {
            this.showCreatingIMOrderWithPaymentDialog();
          } else {
            this.showCreatingIMOrderWithoutPaymentDialog();
          }
          break;
        default:
          this.showCreatingIMOrderWithoutPaymentDialog();
          break;
      }
    } else {
      return null;
    }
  }

  showCreatingIMOrderWithPaymentDialog() {
    this.setState({
      showCreatingIMOrderWithPaymentDialog: true,
      showCreatingIMOrderWithoutPaymentDialog: false,
    });
  }

  closeCreatingIMOrderWithPaymentDialog() {
    this.setState({
      showCreatingIMOrderWithPaymentDialog: false,
    });
  }

  showCreatingIMOrderWithoutPaymentDialog() {
    this.setState({
      showCreatingIMOrderWithPaymentDialog: false,
      showCreatingIMOrderWithoutPaymentDialog: true,
    });
  }

  closeCreatingIMOrderWithoutPaymentDialog() {
    this.setState({
      showCreatingIMOrderWithoutPaymentDialog: false,
    });
  }

  showCreationErrorDialog(message) {
    this.setState({
      creationErrorDialogShown: true,
      creationError: message,
    });
  }

  hideCreationErrorDialog() {
    this.setState({
      creationErrorDialogShown: false,
    });
  }

  createImageMagicOrder(sendmail) {
    this.setState({
      isCreatingImageMagicOrder: true,
      createImageMagicOrderResult: {},
    });
    OrderService.createImageMagicOrder(this.props.order.orderNumber,
        Date.parse(this.props.order.updatedAt), sendmail);
  }

  resetCreatingImageMagicOrderResult() {
    this.setState({
      createImageMagicOrderResult: {},
    });
  }

  showChangingToPauseDialog() {
    this.setState({
      isShowChangingToPauseDialog: true,
    });
  }

  closeChangingToPauseDialog() {
    this.setState({
      isShowChangingToPauseDialog: false,
    });
  }

  render() {
    let order = this.props.order;
    let urlLink ='//' + location.hostname.replace(/-?admin./, '') + '/digital-message/' + order.id
    return (
        <div>
          <h3 className="babyful-title">デザインツール情報</h3>
          <div className="clearfix">
            <h4 className="title pull-left">デザインツール情報</h4>
            <div className="clearfix pull-right">
              {this.renderUpdateOrderStatusMessage()}
              <Button
                  bsStyle={this.state.crudMode === Constants.CRUD_MODE.READ ?
                      'info' :
                      'success'}
                  className="btn-raised" onClick={this.toggleUpdate}
                  disabled={this.state.isCreatingImageMagicOrder}>
                {this.state.updateButtonTitle}
              </Button>
              <ConfirmDialog showModal={this.state.isShowChangingToPauseDialog}
                             accept={this.updateStatus}
                             close={this.closeChangingToPauseDialog}
                             message="注文ステータスを「制作停止」に変更されてもよろしいでしょうか？"/>
            </div>
          </div>
          <table className="table table-bordered">
            <tbody>
            <tr>
              <th width="190px">デザインステータス</th>
              <td>
                {this.state.crudMode === Constants.CRUD_MODE.READ
                    ?
                    <OrderStatusFormat data={this.state.selectedOrderStatus}
                                       renderOption={false}/>
                    :
                    this.renderOrderStatusSelectBox(order.status)
                }
              </td>
            </tr>
            {
              (order.processMethod === 'digital_message' && order.urlReleasedDate) ?
                <tr>
                  <th width="190px">発行URL</th>
                  <td><a href={urlLink} style={{color: "#337ab7"}} target="_blank">こちら</a>です！</td>
                </tr> : null
            }
            {
              order.status === OrderConstant.STATUS.CANCEL ?
                <tr>
                  <th>キャンセル理由</th>
                  <td>{order.cancelReason}</td>
                </tr> : null
            }
            {this.renderCreationStateRow()}
            </tbody>
          </table>
          <DesignCreaterInfoComponent creater={order.createdUser}/>
          <div className="clearfix">
            <h4 className="title pull-left">デザイン情報</h4>
            <div className="clearfix pull-right">
              <ConfirmDialog
                  showModal={this.state.showCreatingIMOrderWithPaymentDialog}
                  accept={this.createImageMagicOrder}
                  close={this.closeCreatingIMOrderWithPaymentDialog}
                  message="ご制作を進めてもよろしいでしょうか？"
                  startProductWithoutSendEmail={() => this.createImageMagicOrder(false)}
              />
              <ConfirmDialog
                  showModal={this.state.showCreatingIMOrderWithoutPaymentDialog}
                  accept={this.createImageMagicOrder}
                  close={this.closeCreatingIMOrderWithoutPaymentDialog}
                  message="注文のキャリア決済支払未完了です。注文制作開始してもよろしいでしょうか？"
                  acceptTitle="制作開始"
                  rejectTitle="キャンセル"
                  startProductWithoutSendEmailTitle="制作開始（メール送信なし）"
                  startProductWithoutSendEmail={() => this.createImageMagicOrder(false)}
              />
              {this.renderCreatingImageMagicOrderResult()}
              {this.isCreatingImageMagicOrderButtonEnabled()
                  ?
                  <LoadingButton
                      isLoading={this.state.isCreatingImageMagicOrder}
                      onClick={this.verifyOrderPayment}
                      className="btn-raised btn btn-info">制作開始</LoadingButton>
                  :
                  null
              }
            </div>
          </div>
          <table className="table table-bordered">
            <thead>
            <tr>
              <th>デザインID</th>
              <th>サムネイル</th>
              <th>作成時間</th>
              <th>ステータス</th>
              <th>ラッピング</th>
              <th width="90px">アクション</th>
            </tr>
            </thead>
            <tbody>
            {this.designRows}
            </tbody>
          </table>
        </div>);
  }

  renderCreationStageProcess(stage, errorMessage, activeLive) {
    let isActive = stage === this.props.order.pendingCreationState;
    let printCompanyName = this.props.order.printCompany ? this.props.order.printCompany : 'IM'
    let title = stage;
    let isReCreation = true;
    let liLive = -1;
    switch (stage) {
      case OrderConstant.CREATION_STATE.CHECK_DESIGN:
        liLive = 0;
        title = 'デザイン数確認';
        isReCreation = true;
        break;
      case OrderConstant.CREATION_STATE.CREATING_DESIGN:
        liLive = 1;
        title = `${printCompanyName}用加工画像作成`;
        isReCreation = true;
        break;
      case OrderConstant.CREATION_STATE.CREATING_XML:
        liLive = 2;
        title = '注文XML制作';
        isReCreation = true;
        break;
      case OrderConstant.CREATION_STATE.UPLOADING:
        liLive = 3;
        title = `${printCompanyName}サーバに画像のアップロード`;
        isReCreation = true;
        break;
      case OrderConstant.CREATION_STATE.SENDING_EMAIL:
        liLive = 4;
        title = '制作開始メール送信';
        isReCreation = false;
        break;
      case OrderConstant.CREATION_STATE.CHANGING_STATUS:
        liLive = 5;
        title = 'ECにて注文ステータス変更';
        isReCreation = false;
        break;
      case OrderConstant.CREATION_STATE.DONE:
        liLive = 6;
        title = '制作開始済み';
        isReCreation = false;
        break;
      default:
        title = stage;
        isReCreation = true;
        break;
    }
    if (isActive && title !== '制作開始済み') title += '中';

    let activeClass = isActive || liLive <= activeLive ? 'current' : '';
    let errorClass = errorMessage !== '' && liLive === activeLive ?
        ' error' :
        '';
    let liError = activeClass + errorClass;

    if (errorMessage !== '' && isActive) {
      return <li key={'_' + stage} className={liError}><NotifyDialog
          showModal={this.state.creationErrorDialogShown}
          confirm={this.hideCreationErrorDialog}
          close={this.hideCreationErrorDialog}
          message={this.state.creationError}
          confirmTitle="確認しました"/>
        {isReCreation && errorMessage !== '' ?
            <span className="msg"><i className="fa fa-exclamation-triangle"
                                     aria-hidden="true"></i>「制作開始」ボタンを押すことが必要です。</span> :
            <span className="msg">「制作開始」ボタンを押すことが必要ない。</span>}
        <div><a onClick={this.showCreationErrorDialog.bind(this, errorMessage)}
                className="text-info link-pointer inner">{title}</a></div>
      </li>;
    } else
      return <li key={stage} className={liError}>
        <div><span className="inner">{title}</span></div>
      </li>;
  }

  renderCreationStateRow() {

    if (this.props.order.status === OrderConstant.STATUS.CREATING ||
        this.props.order.status === OrderConstant.STATUS.PENDING_CREATION) {
      let creationStateErrorMessage = this.props.order.pendingCreationErrorMessage ||
          '';
      let trTitle = creationStateErrorMessage === '' ?
          '処理内容' :
          <label className="text-danger">エラー内容</label>;
      let activeLive = -1;
      switch (this.props.order.pendingCreationState) {
        case OrderConstant.CREATION_STATE.CHECK_DESIGN:
          activeLive = 0;
          break;
        case OrderConstant.CREATION_STATE.CREATING_DESIGN:
          activeLive = 1;
          break;
        case OrderConstant.CREATION_STATE.CREATING_XML:
          activeLive = 2;
          break;
        case OrderConstant.CREATION_STATE.UPLOADING:
          activeLive = 3;
          break;
        case OrderConstant.CREATION_STATE.SENDING_EMAIL:
          activeLive = 4;
          break;
        case OrderConstant.CREATION_STATE.CHANGING_STATUS:
          activeLive = 5;
          break;
        case OrderConstant.CREATION_STATE.DONE:
          activeLive = 6;
          break;
        default:
          break;
      }
      return (
        [<tr>
          <th width="190px">連携制作会社</th>
          <td>
            {this.props.order.printCompany}
          </td>
        </tr>,
          <tr>
            <th width="190px">{trTitle}</th>
            <td>
              <ul className="step-bar list-unstyled">
                {this.renderCreationStageProcess(
                  OrderConstant.CREATION_STATE.CHECK_DESIGN,
                  creationStateErrorMessage, activeLive)}
                {this.renderCreationStageProcess(
                    OrderConstant.CREATION_STATE.CREATING_DESIGN,
                    creationStateErrorMessage, activeLive)}
                {this.renderCreationStageProcess(
                    OrderConstant.CREATION_STATE.CREATING_XML,
                    creationStateErrorMessage, activeLive)}
                {this.renderCreationStageProcess(
                    OrderConstant.CREATION_STATE.UPLOADING,
                    creationStateErrorMessage, activeLive)}
                {this.renderCreationStageProcess(
                    OrderConstant.CREATION_STATE.SENDING_EMAIL,
                    creationStateErrorMessage, activeLive)}
                {this.renderCreationStageProcess(
                    OrderConstant.CREATION_STATE.CHANGING_STATUS,
                    creationStateErrorMessage, activeLive)}
                {this.renderCreationStageProcess(
                    OrderConstant.CREATION_STATE.DONE,
                    creationStateErrorMessage, activeLive)}
              </ul>
            </td>
          </tr>
        ]);
    } else {
      return (<tr></tr>);
    }

  }

  renderOrderStatusSelectBox(status) {
    let options = [];
    if (status === OrderConstant.STATUS.CANCEL) {
      options.push(<OrderStatusFormat data={OrderConstant.STATUS.CANCEL}
                                      renderOption={true}
                                      key={OrderConstant.STATUS.CANCEL}/>);
    }
    else {
      if (status === OrderConstant.STATUS.SHIPPED &&
          this.props.order.ecSiteId === OrderConstant.EC_SITE_ID.RAKUTEN) {
        options.push(<OrderStatusFormat data={OrderConstant.STATUS.SHIPPED}
                                        renderOption={true}
                                        key={OrderConstant.STATUS.SHIPPED}/>);
      }
      else if (status === OrderConstant.STATUS.PENDING_CANCEL) {
        options.push(<OrderStatusFormat data={OrderConstant.STATUS.PENDING_CANCEL}
                                        renderOption={true}
                                        key={OrderConstant.STATUS.PENDING_CANCEL}/>);
        if (this.props.order.ecSiteId === OrderConstant.EC_SITE_ID.RAKUTEN){
          options.push(<OrderStatusFormat data={OrderConstant.STATUS.CANCEL_BY_SHOP}
                                          renderOption={true}
                                          key={OrderConstant.STATUS.CANCEL_BY_SHOP}/>);
          options.push(<OrderStatusFormat data={OrderConstant.STATUS.CANCEL_BY_GUEST}
                                          renderOption={true}
                                          key={OrderConstant.STATUS.CANCEL_BY_GUEST}/>);
        }
        else {
          options.push(<OrderStatusFormat data={OrderConstant.STATUS.CANCEL}
                                          renderOption={true}
                                          key={OrderConstant.STATUS.CANCEL}/>);
        }
      }
      else {
        if (this.props.order.designs.length === 0) {
          options.push(<OrderStatusFormat data={OrderConstant.STATUS.WAITING}
                                          renderOption={true}
                                          key={OrderConstant.STATUS.WAITING}/>);
        }
        options.push(<OrderStatusFormat data={OrderConstant.STATUS.DRAFT}
                                        renderOption={true}
                                        key={OrderConstant.STATUS.DRAFT}/>);
        options.push(<OrderStatusFormat data={OrderConstant.STATUS.URL_RELEASED}
                                        renderOption={true}
                                        key={OrderConstant.STATUS.URL_RELEASED}
                                        visible={status === OrderConstant.STATUS.URL_RELEASED || this.props.order.processMethod === 'digital_message' && this.props.order.urlReleasedDate}/>);
        if (status === OrderConstant.STATUS.PAUSE) {
          options.push(<OrderStatusFormat data={OrderConstant.STATUS.PAUSE}
                                          renderOption={true}
                                          key={OrderConstant.STATUS.PAUSE}/>);
        }
        if (status === OrderConstant.STATUS.WAITING_CREATION) {
          options.push(<OrderStatusFormat
              data={OrderConstant.STATUS.WAITING_CREATION} renderOption={true}
              key={OrderConstant.STATUS.WAITING_CREATION}/>);
        }
        if (status === OrderConstant.STATUS.PENDING_CREATION) {
          options.push(<OrderStatusFormat
              data={OrderConstant.STATUS.PENDING_CREATION} renderOption={true}
              key={OrderConstant.STATUS.PENDING_CREATION}/>);
        }

        options.push(<OrderStatusFormat data={OrderConstant.STATUS.COMMITTED}
                                        renderOption={true}
                                        key={OrderConstant.STATUS.COMMITTED}
                                        visible={this.props.order.hasLineProduct}/>);

        options.push(<OrderStatusFormat data={OrderConstant.STATUS.CREATING}
                                        renderOption={true}
                                        key={OrderConstant.STATUS.CREATING}
                                        visible={!this.props.order.hasLineProduct}/>);

        options.push(<OrderStatusFormat data={OrderConstant.STATUS.SHIPPED}
                                        renderOption={true}
                                        key={OrderConstant.STATUS.SHIPPED}
                                        visible={!this.props.order.hasLineProduct}/>);

        options.push(<OrderStatusFormat data={OrderConstant.STATUS.PENDING}
                                        renderOption={true}
                                        key={OrderConstant.STATUS.PENDING}
                                        visible={this.props.order.ecSiteId ===
                                        OrderConstant.EC_SITE_ID.YAHOO}/>);

        if(this.props.order.ecSiteId == OrderConstant.EC_SITE_ID.RAKUTEN){
          options.push(<OrderStatusFormat data={OrderConstant.STATUS.CANCEL_BY_SHOP}
                                          renderOption={true}
                                          key={OrderConstant.STATUS.CANCEL_BY_SHOP}/>);
          options.push(<OrderStatusFormat data={OrderConstant.STATUS.CANCEL_BY_GUEST}
                                          renderOption={true}
                                          key={OrderConstant.STATUS.CANCEL_BY_GUEST}/>);
        }
        else {
          options.push(<OrderStatusFormat data={OrderConstant.STATUS.CANCEL}
                                          renderOption={true}
                                          key={OrderConstant.STATUS.CANCEL}/>);
        }
      }
    }

    return (<select defaultValue={this.state.selectedOrderStatus}
                    onChange={this.changeStatus}>
      {options}
    </select>);
  }

  get designRows() {
    let order = this.props.order;
    let isBusy = this.state.isCreatingImageMagicOrder;

    if (order.designs.length === 0)
      return (<tr>
        <td colSpan="7">デザインは０件です!</td>
      </tr>);

    let designGroups = {};
    let allDesigns = order.designs || [];
    let designSeries = order.designSeries || [];

    Array.from(allDesigns).forEach(function(design) {
      if (designGroups[design.groupNumber]) {
        designGroups[design.groupNumber].push(design);
      } else {
        designGroups[design.groupNumber] = [design];
      }
    });

    let returnData = [];

    Object.keys(designGroups).forEach(function(key) {
      let designs = designGroups[key];

      designs.sort(function(a, b) {
        return a.sideNumber - b.sideNumber;
      });

      let design = designs[0];
      let designIds = [];
      let designImageFormated = [];
      let designSeries = order.designSeries.find(item => designs[0].designSeries &&  item.id === designs[0].designSeries.id)
      let isSeichoku = designs.length > 0 && designs[0].product.seichoku;
      if (isSeichoku) {
        designIds.push(designs[0].designSeries ? designs[0].designSeries.randomId: "");
        designImageFormated.push(<DesignImageFormat
          className={'col-lg-' + 12 + ' designImage'}
          key={designs[0].designSeries ? designs[0].designSeries.id : ""} rowData={designs[0]}/>);
      } else {
        Array.from(designs).forEach(function (d, index) {
          designIds.push(d.randomId);
          designImageFormated.push(<DesignImageFormat
            className={'col-lg-' + 12 / designs.length + ' designImage'}
            key={d.id} rowData={d} index={index}/>);
        });
      }

      returnData.push(<tr key={designIds.join('-')}>
        <td>{designIds.join('-')}</td>
        <td>
          <span style={{display:"block"}}>{designImageFormated}</span>
          <span style={{display:"block"}}>
            {design.product.name}&nbsp;
            <span>
              {designSeries && designSeries.productOption ? '(' + designSeries.productOption.optionValue1 + designSeries.productOption.optionValue2 + ')' : ''}
            </span>
          </span>
        </td>
        <td><DateTimeFormat data={design.createdAt}/></td>
        <td><DesignStatusFormat data={design.status}/></td>
        <td>{design.packagePlan ? design.packagePlan.name : ''}</td>
        <td><DesignActionFormat rowData={designs} isBusy={isBusy} isSeichoku={isSeichoku}/></td>
      </tr>);

    });

    return returnData;
  }

  isCreatingImageMagicOrderButtonEnabled() {
    return (this.props.order.status === OrderConstant.STATUS.DRAFT
        || this.props.order.status === OrderConstant.STATUS.PAUSE
        || this.props.order.status === OrderConstant.STATUS.WAITING_CREATION
        || this.props.order.status === OrderConstant.STATUS.PENDING_CREATION)
        && this.props.order.designs.length > 0
        && this.props.order.designs.every(function(design) {
          return !design.product.isDeleted && design.product.status !== '1';
        })
        && !this.props.order.isHasLineProduct;
  }

  renderUpdateOrderStatusMessage() {
    if (this.state.orderStatusChangeResult.httpCode) {
      let result = null;
      switch (this.state.orderStatusChangeResult.httpCode) {
        case 200:
          result = {
            message: this.state.orderStatusChangeResult.message,
            className: 'text-success',
          };
          break;
        default:
          result = {
            message: this.state.orderStatusChangeResult.message,
            className: 'text-danger',
          };
          break;
      }
      return <FlashComponent visible={true} timeout={5000}
                             className={result.className}
                             onHide={this.resetOrderStatusChangeResult}>
        {result.message}</FlashComponent>;
    } else {
      return null;
    }
  }

  renderCreatingImageMagicOrderResult() {
    if (this.state.createImageMagicOrderResult.httpCode) {
      let result;
      switch (this.state.createImageMagicOrderResult.httpCode) {
        case 200:
          result = {
            className: 'text-success',
            message: 'バックグラウンドで制作処理を行いました。',
          };
          break;
        default:
          switch (this.state.createImageMagicOrderResult.errorCode) {
            case OrderConstant.EXCEPTIONS.WRONG_PRODUCT_EXCEPTION:
            case OrderConstant.EXCEPTIONS.NOT_ENOUGH_DESIGN_EXCEPTION:
            case OrderConstant.EXCEPTIONS.SPARE_DESIGNS_EXCEPTION:
              if (this.state.createImageMagicOrderResult.data.differenceBetweenDesignsAndProducts <
                  0) {
                result = {
                  className: 'text-danger',
                  message: this.props.order.orderNumber + 'の商品は残り' + Math.abs(
                      this.state.createImageMagicOrderResult.data.differenceBetweenDesignsAndProducts) +
                  '点デザインが必要です',
                };
              } else {
                result = {
                  className: 'text-danger',
                  message: this.props.order.orderNumber + 'の注文は' +
                  this.state.createImageMagicOrderResult.data.differenceBetweenDesignsAndProducts +
                  '点のデザインが超過しています',
                };
              }
              break;
            case OrderConstant.EXCEPTIONS.ORDER_IS_CREATING_EXCEPTION:
            case OrderConstant.EXCEPTIONS.ORDER_IS_MODIFIED:
              result = {
                className: 'text-danger',
                message: '注文情報が更新されています。最新の注文情報をご確認',
              };
              break;
            case OrderConstant.EXCEPTIONS.EC_UPDATE_ERROR:
            default:
              result = {
                className: 'text-danger',
                message: '注文制作に失敗しました。',
              };
              break;
          }
          break;
      }
      return <FlashComponent visible={true} timeout={5000}
                             className={result.className}
                             onHide={this.resetCreatingImageMagicOrderResult}>
        {result.message}
      </FlashComponent>;
    } else {
      return null;
    }
  }
}
