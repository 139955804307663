import React from "react";
import {Alert} from "react-bootstrap";
import AlertAction from "../actions/AlertAction.jsx";
import AlertStore from "../../stores/AlertStore.jsx";

export default class CustomAlert extends React.Component {
    constructor(props) {
        super(props);
        this.state = this._getState()
        this._onChange = this._onChange.bind(this)
    }

    _getState() {
        return {
            alert: AlertStore.getAlert()
        }
    }

    _onChange() {
        this.setState(this._getState())
    }

    componentDidMount() {
        AlertStore.addChangeListener(this._onChange);
    }
    componentWillUnmount() {
        AlertStore.removeChangeListener(this._onChange);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.alert) {
            setTimeout(() => AlertAction.resetAlert(), 3000)
        }
    }

    render() {
        const { alert } = this.state
        if (!alert) return null
        return <div className="alert-wrapper">
            <Alert bsStyle={alert.type}>
                <p>{alert.message}</p>
            </Alert>
        </div>
    }
}