import React from 'react';

export default class YahooJPAuthorizationComponent extends React.Component {

    constructor(){
        super();
        this.state =  this._getState();
        this.fadeOutTimer = {
            timer: null,
            start: function(){
                this.timer = setTimeout(function(){
                    window.location = "/ec-systems";
                }, 3000);
            },
            stop: function(){
                if(this.timer){
                    clearTimeout(this.timer);
                }
            }
        };
        this._onChange = this._onChange.bind(this);
    }

    _getState() {
        return {
            yconnectUserInfo: undefined,
            error: undefined
        };
    }

    _onChange() {
        this.setState(this._getState());
    }

    componentWillUnmount(){
        this.fadeOutTimer.stop();
    }

    render() {
        return (
            <div className="text-center authen-page">
                <p>ログインしました。3秒後に自動的に画面が切り替わります。<br/>
                    ※自動的に画面が切り替わらない場合、
                    <a onClick={this.fadeOutTimer.stop.bind(this.fadeOutTimer)} href="/ec-systems" className="text-info">
                        こちら
                    </a>
                    から移動してください。
                </p>
            </div>
        )
    }
}