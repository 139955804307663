import BaseStore from '../../common/stores/BaseStore.jsx'
import ReportConstant from '../constants/ReportConstant.jsx'

class RepeatOrderReportStore extends BaseStore {
  constructor () {
    super()
    this.resetReport()
    this.subscribe(() => this.handler.bind(this))
  }

  resetReport () {
    this.errorMessage = null
    this.loading = true

    this.ordersDTO = {
      currentPage: 1,
      maxPage: 0,
      orders: []
    };

    this.lineDayRepeat = {
      datasets: [],
      labels: []
    }
    this.lineMonthRepeat =  {
      datasets: [],
      labels: []
    }

    this.lineYearRepeat =  {
      datasets: [],
      labels: []
    }

    this.lineDayTotal = {
      datasets: [],
      labels: []
    }
    this.lineMonthTotal =  {
      datasets: [],
      labels: []
    }

    this.lineYearTotal =  {
      datasets: [],
      labels: []
    }

    this.pieData =  {
      datasets: [],
      labels: []
    }

    this.activeLineChart = ReportConstant.CHART_LINE_VIEW.DAY
  }

  /**
   * Register callback to handle all updates
   *
   * @param  {Object} action
   */
  handler (action) {
    switch (action.actionType) {
      case ReportConstant.LOAD:
        this.loading = true
        this.emitChange()
        break
      case ReportConstant.ACTION_TYPE.SHOW_REPEAT_ORDER_CHART_REPORT:
        this.errorMessage = null
        this.lineDayRepeat = action.data.chartData.lineDayRepeat
        this.lineMonthRepeat = action.data.chartData.lineMonthRepeat
        this.lineYearRepeat = action.data.chartData.lineYearRepeat
        this.lineDayTotal = action.data.chartData.lineDayTotal
        this.lineMonthTotal = action.data.chartData.lineMonthTotal
        this.lineYearTotal = action.data.chartData.lineYearTotal
        this.pieData = action.data.chartData.pie
        this.emitChange()
        break
      case ReportConstant.ACTION_TYPE.SHOW_REPEAT_ORDER_REPORT:
        this.errorMessage = null
        this.ordersDTO = action.data.orderData
        this.loading = false
        this.emitChange()
        break
      case ReportConstant.ACTION_TYPE.CHANGE_LINE_CHART_VIEW:
        console.log(action.data + " store")
        this.activeLineChart = action.data
        this.emitChange()
        break
      case ReportConstant.ERROR:
        this.errorMessage = action.message
        this.loading = false
        this.emitChange()
        break
      default:
        break
    }
  }

  getErrorMessage () {
    return this.errorMessage
  }

  isLoading () {
    return this.loading
  }

  getLineDayRepeatData() {
    return this.lineDayRepeat
  }
  getLineDayTotalData() {
    return this.lineDayTotal
  }

  getLineMonthRepeatData() {
    return this.lineMonthRepeat
  }

  getLineMonthTotalData() {
    return this.lineMonthTotal
  }

  getLineYearRepeatData() {
    return this.lineYearRepeat
  }

  getLineYearTotalData() {
    return this.lineYearTotal
  }

  getPieData() {
    return this.pieData
  }

  getRepeatOrdersData() {
    return this.ordersDTO
  }

  getActiveLineChart() {
    return this.activeLineChart
  }

}

const repeatOrderReportStore = new RepeatOrderReportStore()

export default repeatOrderReportStore