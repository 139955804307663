Object.defineProperty(exports, "__esModule", {
  value: true
});
exports['default'] = getPhrase;
function getPhrase(phrase, args) {
  if (typeof phrase === 'string') return phrase;

  if (typeof phrase === 'function') {
    return phrase(args);
  }

  return '';
}