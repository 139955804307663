const MagazineMailConstants = {
  ACTION_TYPE: {
    LOADED_ALL_PLAN: "LOADED_ALL_PLAN",
    LOADED_PLAN: "LOADED_PLAN",
    LOADED_ALL_UNSUBSCRIBE_RECEIVER: "LOADED_ALL_UNSUBSCRIBE_RECEIVER",
    BAD_REQUEST: "BAD_REQUEST_MAGAZINE_MAIL",
    ERROR: "ERROR_MAGAZINE_MAIL",
    LOADED_ALL_MAIL_TEMPLATE: "LOADED_ALL_MAIL_TEMPLATE",
    ON_CHANGE_KEYWORD: "ON_CHANGE_KEYWORD_RECEIVER_MAIL_MAGAZINE",
    ON_SEARCH_PAGE: "ON_SEARCH_PAGE_RECEIVER_MAIL_MAGAZINE",
    LOADED_MAIL_QUANTITY:"LOADED_MAIL_QUANTITY"
  },
  API: {
    GET_ALL_PLAN: "/api/plans",
    GET_ALL_MAIL_TEMPLATE: "/api/emailTemplates",
    GET_PLAN: "/api/plan/{0}",
    ADD_PLAN: "/api/plan",
    UPDATE_PLAN: "/api/plan/{0}",
    CANCEL_PLAN: "/api/plan/{0}",
    GET_ALL_UNSUBSCRIBE_RECEIVER: '/api/receivers',
    CHANGE_TO_SUBSCRIBED_RECEIVER: "/api/receiver/{0}",
    ADD_UNSUBSCRIBED_RECEIVERS: "/api/receivers",
    CALCULATE_RECEIVER: "/api/plan/count",
    GET_MAIL_QUANTITY: "/api/emailQuantity"
  },
  STATUS_JP: {
    WAITING: '処理待ち',
    RUNNING: '処理中',
    DONE: '完了',
    CANCELED: 'キャンセル'
  },
  ORDER_STATUS: {
    WAITING: '処理待ち',
    RUNNING: '処理中',
    DONE: '完了',
    CANCELED: 'キャンセル'
  }
};

export default MagazineMailConstants;