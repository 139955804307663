/**
 * Created by PhuNH on 17/1/7.
 */
import React from 'react'
import DesignConstant from '../constants/DesignConstant.jsx'
import DesignAction from '../actions/DesignAction.jsx'

export default class DesignStatusBarComponent extends React.Component {
    constructor() {
        super();
        this.componentStyle = {
            marginTop: '10px',
            overflow: 'hidden'
        };
        this.statusUlStyle = {
            float: 'left'
        };
    }

    _filterByStatus(status) {
        DesignAction.changeStatusFilter(status);
    }

    render() {
        return (
            <div style={this.componentStyle}>
                <ul className="nav nav-pills" style={this.statusUlStyle}>
                    {this.renderStatusBar()}
                </ul>
            </div>
        )
    }

    renderStatusBar() {
        let self = this;
        return Object.keys(DesignConstant.STATUS).map(function (key) {
            let status = DesignConstant.STATUS[key];
            let statusBarInfo = self.getStatusBarInfo(status);
            return (
                <li key={key} className={status === self.props.designFilter.status ? "active" : ""}>
                    <a onClick={self._filterByStatus.bind(self, status)}>
                        {statusBarInfo.text}
                        <span className="text-warning"> {statusBarInfo.numberOfOrders ? statusBarInfo.numberOfOrders : 0}件</span>
                    </a>
                </li>
            )
        })
    }

    getStatusBarInfo(status) {
        return {
            numberOfOrders: this.props.designDTO[status],
            text: DesignConstant.STATUS_JP[status.toUpperCase()]
        };
    }
}
