const CampaignConstants = {
    THEATRE1810_TYPE: {
        DONE_EXPORT_CSV: 'THEATRE1810_DONE_EXPORT_CSV',
        DONE_ZIP_FILE: 'THEATRE1810_DONE_ZIP_FILE',
        DONE_DOWNLOAD: 'THEATRE1810_DONE_DOWNLOAD',
        CAMPAIGN_LOAD: "THEATRE1810_CAMPAIGN_LOAD",
        CAMPAIGN_CHANGE_PAGE: "THEATRE1810_CAMPAIGN_CHANGE_PAGE"
    },
    THEATRE1905_TYPE: {
        DONE_EXPORT_CSV: 'THEATRE1905_DONE_EXPORT_CSV',
        DONE_ZIP_FILE: 'THEATRE1905_DONE_ZIP_FILE',
        DONE_DOWNLOAD: 'THEATRE1905_DONE_DOWNLOAD',
        CAMPAIGN_LOAD: "THEATRE1905_CAMPAIGN_LOAD",
        CAMPAIGN_CHANGE_PAGE: "THEATRE1905_CAMPAIGN_CHANGE_PAGE"
    },
    STUDIOALICEBABY1812_TYPE: {
        DONE_EXPORT_CSV: 'STUDIOALICEBABY1812_EXPORT_CSV',
        CAMPAIGN_LOAD: "STUDIOALICEBABY1812_CAMPAIGN_LOAD",
        CAMPAIGN_CHANGE_PAGE: "STUDIOALICEBABY1812_CHANGE_PAGE"
    },
    REMAINING_WORKING_DAYS_TO_DISPLAY_WARNING: 5,
}

export default CampaignConstants
