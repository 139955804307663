import React from 'react'
import AuthenticatedComponent from '../../common/components/AuthenticatedComponent.jsx'
import PlanListComponent from "./planManagement/PlanListComponent.jsx";
import ReceiverListComponent from "./receiverManagement/ReceiverListComponent.jsx";
import {Tab, Tabs} from "react-bootstrap";


class MainMailMagazineComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = this._initState(props);
    this._onChange = this._onChange.bind(this);
  }

  _initState() {
    return this._getState()
  }

  _getState () {
    return {
      activeTab : this.state ? this.state.activeTab : 1,
    }
  }

  _onChange () {
    this.setState(this._getState())
  }

  componentDidMount () {

  }

  componentWillUnmount () {

  }

  render () {
    return (
        <div className="tab-container">
          <Tabs defaultActiveKey={this.state.activeTab} onSelect={activeTab => this.setState({ activeTab })} id="mail-magazine-management">
            <Tab eventKey={1} title="メルマガ計画管理">
              <PlanListComponent/>
            </Tab>
            <Tab eventKey={2} title="メール非送信アドレスリスト管理">
              <ReceiverListComponent/>
            </Tab>
          </Tabs>
        </div>
    )
  }

}

export default AuthenticatedComponent(MainMailMagazineComponent)
