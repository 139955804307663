/**
 * Created by anh_bd on 14/08/2020
 */

import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import {Button, Form, FormGroup, FormControl, Col} from 'react-bootstrap/lib';
import MagazineMailService from '../../services/MagazineMailService.jsx'

export default class AddUnsubscribeReceiverDialog extends React.Component {
  constructor() {
    super();
    this.alignRightText = {
      'textAlign': 'right'
    };
    this.state = this.initState();
    this.changeEmailList = this.changeEmailList.bind(this);
    this.addUnsubscribeReceiver = this.addUnsubscribeReceiver.bind(this);
    this.validateEmailList = this.validateEmailList.bind(this);
  }

  initState() {
    return {
      emailList: null,
      isEmailListValid: true,
    };
  }

  componentWillReceiveProps() {
    this.state = this.initState()
  }

  isEmail(email) {
    let regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }

  validateEmailList(emailList) {
    let self = this;
    let result = true;
    if (emailList !== null && emailList !== '') {
      let mailArray = emailList.split(',')
      mailArray.forEach(function (item) {
        if (!self.isEmail(item.trim())) result = false
      })
    }
    return result;
  }

  changeEmailList(emailList) {
    this.setState({
      emailList: emailList.target.value,
      isEmailListValid: this.validateEmailList(emailList.target.value)
    });
  }

  addUnsubscribeReceiver() {
    var emailList = {
      emailList: this.state.emailList
     };
    var receiverFilter = {
      currentPage: 1,
      pageSize: 10,
      keyword: ''
    }
    MagazineMailService.addUnsubscribedReceivers.bind(MagazineMailService, emailList, receiverFilter)();
    this.state = this.initState();
    this.props.closeAddUnsubscribeReceiverDialog();
  }

  render() {
    var warningMessageStyle = {
      display: !this.state.isEmailListValid ? 'block' : 'none',
      fontWeight: 'bold'
    };
    var exportBtnAttributes = {};
    if (this.state.emailList === null || this.state.emailList.trim() === '' || !this.state.isEmailListValid) {
      exportBtnAttributes.disabled = true;
    }
    return (
        <Modal show={this.props.isOpenAddUnsubscribeReceiverDialog} onHide={this.props.closeAddUnsubscribeReceiverDialog} bsSize="lg">
          <Modal.Header>
            <Modal.Title>メール非送信アドレス追加</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form horizontal onSubmit={this.addUnsubscribeReceiver}>

              <FormGroup>
                {/*email list*/}
                <Col sm={3} style={this.alignRightText}>
                  非送信メール <span className="text-danger">(必須)</span>:
                </Col>
                <Col sm={7}>
                  <FormControl componentClass="textarea" placeholder="xxxx@gmail.com, zzzz@@pc.fw.rakuten.ne.jp" onChange={this.changeEmailList}
                               value={this.state.emailList}
                  />
                </Col>
              </FormGroup>

              <FormGroup style={warningMessageStyle}>
                <Col sm={3}> </Col>
                <Col sm={7}>
                  <h4 className="text-danger has-error">正しい形を入力してください。</h4>
                </Col>
              </FormGroup>

            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button className="btn btn-raised btn-info" onClick={this.addUnsubscribeReceiver} {...exportBtnAttributes}>追加</Button>
            <Button className="btn btn-raised btn-danger" onClick={this.props.closeAddUnsubscribeReceiverDialog}>キャンセル</Button>
          </Modal.Footer>
        </Modal>
    )
  }
}