import React from 'react'

export default class TheatreCommonTableRow extends React.Component {
  constructor () {
    super()
  }

  render () {
    return (
      <tr>
        <td>{this.props.campaign.idStr}</td>
        <td>{this.props.campaign.firstName + ' ' +
        this.props.campaign.lastName}</td>
        <td>{this.props.campaign.firstNameFurigana + ' ' +
        this.props.campaign.lastNameFurigana}</td>
        <td>{this.props.campaign.sex == 1 ? '男性' : '女性'}</td>
        <td>{this.props.campaign.birthday}</td>
        <td>{this.props.campaign.zipCode}</td>
        <td>{this.props.campaign.address}</td>
        <td>{this.props.campaign.phoneNumber}</td>
        <td>{this.props.campaign.email}</td>
        <td>{this.props.campaign.iAccount}</td>
        <td>{this.props.campaign.academy == 1 ? '所属している' : '所属していない'}</td>
        <td>{this.props.campaign.interest == 1 ? '興味あり' : '興味なし'}</td>
      </tr>
    )
  }
}
