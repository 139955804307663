import React from 'react'

class TemplateReportTable extends React.Component {

  constructor (props) {
    super()
  }

  render () {
    let listTitle = this.props.listTitle
    let templates = this.props.templates
    let total = 0
    let renderFirstTd = function (index, rowSpan, name) {
      if (index === 0) {
        return (
          <td className="name-col" rowSpan={rowSpan}>{name}</td>
        )
      } else {
        return null
      }
    }

    return (
      <div className="template-report">
        <div className="left">
          <table className="table table-striped table-hover table-bordered">
            <thead>
            <tr>
              <th className="name-col" width="">商品名</th>
              <th className="name-col">テンプレート名</th>
            </tr>
            </thead>
            <tbody>
            {
              Object.keys(templates).map(function (productName, index) {
                let TemplateData = templates[productName]
                let templateNames = Object.keys(TemplateData)
                return templateNames.map(function (tName, index) {
                  if (index === 0) {
                    return (
                      <tr key={tName}>
                        <td className="name-col"
                            rowSpan={templateNames.length}>{productName}</td>
                        <td>{tName}</td>
                      </tr>
                    )
                  } else {
                    return (
                      <tr key={tName}>
                        <td>{tName}</td>
                      </tr>
                    )
                  }
                })

              })
            }
            <tr>
              <td className="sum-title" colSpan={2} id="sum-total">合計:</td>
            </tr>

            </tbody>
          </table>
        </div>
        <div className="right">
          <table className="table table-striped table-hover table-bordered">
            <thead>
            <tr>
              <th className="name-col">商品名</th>
              <th className="name-col">テンプレート名</th>
              {
                Object.keys(listTitle).map(function (key) {
                  return (
                    <th key={key}>
                      {listTitle[key]}
                    </th>
                  )
                })
              }
              <th className="count-col">数量</th>
            </tr>
            </thead>
            <tbody>
            {
              Object.keys(templates).map(function (productName) {
                let TemplateData = templates[productName]
                let templateNames = Object.keys(TemplateData)

                return templateNames.map(function (tName, index) {
                  let rowTotal = 0
                  let reportTemplates = TemplateData[tName]

                  return (
                    <tr key={tName}>
                      {
                        renderFirstTd(index, templateNames.length, productName)
                      }
                      <td>{tName}</td>
                      {
                        Object.keys(listTitle).map(function (key) {
                          let report = reportTemplates[key]
                          let quantity = 0
                          if (report && report[0]['quantity']) {
                            quantity = report[0]['quantity']
                            total = total + quantity
                            rowTotal = rowTotal + quantity
                          }
                          return (
                            <td key={key} className={quantity === 0 ? 'gray' : ''}>
                              {
                                quantity
                              }
                            </td>
                          )
                        })
                      }
                      <td>{rowTotal}</td>
                    </tr>
                  )
                })

              })
            }
            <tr>
              <td className="sum-title" colSpan={Object.keys(listTitle).length + 2}/>
              <td className="sum-value" >{total}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

}

export default TemplateReportTable