/**
 * Created by thangkc on 11/01/2016.
 */
import React from 'react'
import AuthenticatedComponent from "../../common/components/AuthenticatedComponent.jsx"
import SearchBoxComponent from '../../common/components/SearchBoxComponent.jsx'
import OurPagingComponent from '../../common/components/paging/OurPagingComponent.jsx'
import DesignStatusBarComponent from './DesignStatusBarComponent.jsx'
import DesignTableComponent from './DesignTableComponent.jsx'
import DesignService from "../services/DesignService.jsx"
import DesignAction from '../actions/DesignAction.jsx'
import DesignStore from "../stores/DesignStore.jsx"

export default AuthenticatedComponent(
    class DesignListComponent extends React.Component {
        constructor() {
            super();
            this.state = this._getInitialState();
            this._onChange = this._onChange.bind(this);
        }

        _getInitialState() {
            DesignStore._resetFilter();
            return this._getState();
        }

        _getState() {
            return {
                designDTO: DesignStore._getDesignDTO(),
                designFilter: DesignStore._getDesignFilter()
            };
        }

        componentDidMount() {
            DesignService.load(DesignStore._getDesignFilter());
            DesignStore.addChangeListener(this._onChange);
        }

        componentWillUnmount() {
            DesignStore.removeChangeListener(this._onChange);
        }

        _onChange() {
            this.setState(this._getState());
        }

        render() {
            return (
                <section id="DesignListComponent">
                    <h2>デザイン一覧</h2>
                    <SearchBoxComponent changeKeyword={DesignAction.changeKeyword} placeholder="デザインID、又はEC IDで検索"/>
                    <DesignStatusBarComponent designDTO={this.state.designDTO} designFilter={this.state.designFilter}/>
                    <DesignTableComponent designs={this.state.designDTO.designs} designFilter={this.state.designFilter}/>
                    <OurPagingComponent loadNewPage={DesignAction.changePage} dto={this.state.designDTO}/>
                </section>
            );
        }
    }
)