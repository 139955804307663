/**
 * Created by
 */
import React from 'react'
import CollabDesignTableRowComponent from './CollabDesignTableRowComponent.jsx'

export default class CollabDesignTableComponent extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className="tbl-admin tbl-template">
                <table className="table table-striped table-hover table-bordered">
                    <thead>
                    <tr>
                        <th style={{width: '4%'}}>デザインID</th>
                        <th style={{width: '18%'}}>サムネイル</th>
                        <th style={{width: '12%'}}>EC ID</th>
                        <th style={{width: '10%'}}>連携ID</th>
                        <th style={{width: '5%'}}>ステータス</th>
                        <th style={{width: '10%'}}>作成者</th>
                        <th style={{width: '12%'}}>注文日時</th>
                        <th style={{width: '12%'}}>制作開始日</th>
                        <th style={{width: '12%'}}>出荷予定日</th>
                        <th style={{width: '5%', minWidth: '77px'}}>承認結果</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.designRows}
                    </tbody>
                </table>
            </div>
        )
    }

    get designRows() {
        let designs = this.props.designs;
        if (designs.length > 0)
            return (designs.map(function(design) {
                return <CollabDesignTableRowComponent design={design} key={design.id}/>
            }));
        else
            return <tr>
                <td colSpan="10">{ this.props.designFilter.keyword ? <span>検索結果は0件です。</span> : <span>デザインは0件です。</span> }</td>
            </tr>
    }
}