/**
 * Created by hoangnv on 1/12/17.
 */
import React from 'react'
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';

export default class ConfirmDialog extends React.Component {

    constructor(){
        super();
        this.accept = this.accept.bind(this);
        this.reject = this.reject.bind(this);
        this.startProductWithoutSendEmail = this.startProductWithoutSendEmail.bind(this);
    }

    startProductWithoutSendEmail() {
        this.props.close();
        this.props.startProductWithoutSendEmail();
    }

    accept(){
        this.props.close();
        this.props.accept();
    }

    reject(){
        this.props.close();
        if(this.props.reject){
            this.props.reject();
        }
    }

    render(){
        const { showModal, close, message, startProductWithoutSendEmail, startProductWithoutSendEmailTitle, acceptTitle, rejectTitle } = this.props
        return (
            <Modal show={showModal} onHide={close} bsSize="lg">
                <Modal.Header closeButton>
                    <Modal.Title>確認</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className="ng-binding">{message}</h4>
                </Modal.Body>
                <Modal.Footer>
                    {startProductWithoutSendEmail && (
                        <Button onClick={this.startProductWithoutSendEmail} bsStyle="warning" className="btn-raised">
                            {startProductWithoutSendEmailTitle ? startProductWithoutSendEmailTitle : 'はい（メール送信なし）'}
                        </Button>)
                    }
                    <Button onClick={this.accept} bsStyle="warning" className="btn-raised">{acceptTitle ? acceptTitle : 'はい'}</Button>
                    <Button onClick={this.reject} bsStyle="default" className="btn-raised">{rejectTitle ? rejectTitle : 'いいえ'}</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
