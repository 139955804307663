import React from 'react'
import { WithContext as ReactTags } from 'react-tag-input';
import CreateDashboardModal from "./CreateDashboardModal.jsx";
import CreateGroupProductModal from "./CreateGroupProductModal.jsx";
import ReportService from "../../../services/ReportService.jsx";

class DashboardSetting extends React.Component {

  constructor () {
    super()
    this.state = {
      isOpenCreateDashboardModal: false,
      isOpenCreateGroupModal: false,
      selectedDashboard: {},
    };
    this.openCreateDashboardModal = this.openCreateDashboardModal.bind(this);
    this.closeCreateDashboardModal = this.closeCreateDashboardModal.bind(this);
    this.openCreateGroupModal = this.openCreateGroupModal.bind(this);
    this.closeCreateGroupModal = this.closeCreateGroupModal.bind(this);
    this.handleDeleteGroupProduct = this.handleDeleteGroupProduct.bind(this);
    this.handleDeleteDashboard = this.handleDeleteDashboard.bind(this);
  }

  openCreateDashboardModal() {
    this.setState({
      isOpenCreateDashboardModal: true,
    });
  }

  closeCreateDashboardModal() {
    this.setState({
      isOpenCreateDashboardModal: false,
    });
  }

  openCreateGroupModal(dashboard) {
    this.setState({
      isOpenCreateGroupModal: true,
      selectedDashboard: dashboard,
    });
  }

  closeCreateGroupModal() {
    this.props.resetGroupProduct();
    this.setState({
      isOpenCreateGroupModal: false,
    });
  }

  handleDeleteGroupProduct(dashboard, group) {
    ReportService.deleteGroupInDashboard(dashboard.id, group.id)
  }

  handleDeleteDashboard(dashboard) {
    ReportService.deleteDashboard(dashboard.id)
  }

  renderTableRow(){
    let self = this;
    return  (
      this.props.dashboards.map(function (item, index) {
        let group = item.groupProducts.map(function (item) {
          return {
            id: item.id,
            text: item.name
          }
        });
        return (
          <tr key={index}>
            <td>{item.name} </td>
            <td>{item.note}</td>
            <td>
              <div>
                <ReactTags tags={group} handleDelete={(groupIndex) => self.handleDeleteGroupProduct(item, group[groupIndex])}/>
                <span className='action' style={{textAlign: 'left'}}>
                <a className='btn btn-raised btn-success' onClick={ () => self.openCreateGroupModal(item)}>
                  <i className="fa fa-plus"/>
                </a>
              </span>
              </div>
            </td>
            <td>
            <span className='action'>
              <a className='btn btn-raised btn-warning' onClick={() => self.handleDeleteDashboard(item)}>
                <i className="fa fa-trash-o"/>
              </a>
            </span>
            </td>
          </tr>
        )
      })
    );
  }

  render () {
    return (
      <div className="tbl-admin">
        <table className="table table-striped table-hover table-bordered" style={{marginBottom: "10px"}}>
          <thead>
          <tr>
            <th className="width-250">ダンボール週類</th>
            <th className="width-250">詳細</th>
            <th>該当商品組み合わせ</th>
            <th>アクション</th>
          </tr>
          </thead>
          <tbody>
          {this.renderTableRow()}
          </tbody>
        </table>
        <a className='btn btn-raised' style={{backgroundColor: "#6c5944", color: "#ffffff"}} onClick={this.openCreateDashboardModal}>
          <i className="fa fa-plus"/>
        </a>
        <CreateDashboardModal
          isOpenCreateDashboardModal={this.state.isOpenCreateDashboardModal}
          closeCreateDashboardModal={this.closeCreateDashboardModal}
          createDashboard={this.props.createDashboard}/>
        <CreateGroupProductModal
          isOpenCreateGroupModal={this.state.isOpenCreateGroupModal}
          dashboard={this.state.selectedDashboard}
          categories={this.props.categories}
          closeCreateGroupModal={this.closeCreateGroupModal}
          onCategoryChange={this.props.onCategoryChange}
          onProductChange={this.props.onProductChange}
          onQuantityChange={this.props.onQuantityChange}
          addNewGroupForm={this.props.addNewGroupForm}
          deleteGroupForm={this.props.deleteGroupForm}
          createGroupProduct={this.props.createGroupProduct}
          listGroups={this.props.listGroups}/>
      </div>
    )
  }
}

export default DashboardSetting
