/**
 * Created by thangkc on 14/12/2015.
 */
import React from 'react';
import {Link} from 'react-router';
import LoginStore from '../stores/LoginStore.jsx';
import LoginService from '../services/LoginService.jsx';
import NotificationBarComponent from '../../common/components/NotificationBarComponent.jsx';
import NavItem from '../../common/components/NavItem.jsx';

export default class MainComponent extends React.Component {
  constructor() {
    super();
    this.state = this._getState();
    this._onChange = this._onChange.bind(this);
  }

  _getState() {
    return {
      userLoggedIn: LoginStore.loggedInUser(),
      route: window.location.pathname,
    };
  }

  _onChange() {
    this.setState(this._getState());
  }

  componentDidMount() {
    LoginStore.addChangeListener(this._onChange);
  }

  componentWillUnmount() {
    LoginStore.removeChangeListener(this._onChange);
  }

  render() {
    return (
        <div id="main">
          <div id="header">
            <div className="container">
              <div className="container-fluid">
                <h1 className="pull-left" id="logo">
                  <Link to="/">Babyful Store ベビーフル ストア国内最大級のフォトグッズ専門店</Link>
                </h1>
                {this.headerItems}
              </div>
            </div>
            {this.navBar}
          </div>
          <div className="container" id="contents">
            {this.props.children}
          </div>
          <NotificationBarComponent/>
        </div>
    );
  }

  get headerItems() {
    if (this.state.userLoggedIn) {
      return (
          <div className="pull-right" id="top-right">
            <p className="user">
                        <span className="user-name">
                            <b className="ng-binding">{this.state.userLoggedIn.name} 様</b>
                        </span>
              <button className="logout text-danger" type="button"
                      onClick={this.logout.bind(this)}><i
                  className="fa fa-power-off"/></button>
            </p>
          </div>
      );
    } else {
      return (
          <div></div>
      );
    }
  }

  get navBar() {
    if (this.state.userLoggedIn) {
      return (
          <div className="navbar">
            <div className="container">
              <ul className="nav navbar-nav">
                <NavItem to="/orders">注文一覧</NavItem>
                <NavItem to="/design">デザイン一覧</NavItem>
                {/*<NavItem to="/user">会員一覧</NavItem>*/}
                <NavItem to="/ec-systems">ECサイト認証情報管理</NavItem>
                <NavItem to="/report">レポート</NavItem>
                <NavItem to="/ec-product-cooperation-master-data">在庫連携管理</NavItem>
                {/*<NavItem to="/campaign">キャンペーン管理</NavItem>*/}
                <NavItem to="/setting-batch-status">バッチ実行管理</NavItem>
                <NavItem to="/magazine-mail">メールマガジン管理</NavItem>
                <NavItem to="/flyer-campaign">クライアント管理</NavItem>
                <NavItem to="/collab-design">コラボテンプレート</NavItem>
              </ul>
            </div>
          </div>
      );
    } else
      return (
          <div></div>
      );
  }

  logout(e) {
    e.preventDefault();
    LoginService.logout();
  }
}
