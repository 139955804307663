/**
 * Created by PhuNH on 16/12/25.
 */
const RakutenLicenseKeyConstant = {
    CHECKING_KEY:       'CHECKING_KEY',
    CHECK_KEY_VALID:    'CHECK_KEY_VALID',
    CHECK_KEY_INVALID:  'CHECK_KEY_INVALID',
    CHANGE_KEY:         'CHANGE_KEY',
    ADDING_KEY:         'ADDING_KEY',
    ADD_KEY:            'ADD_KEY',
    ADD_KEY_FAILED:     'ADD_KEY_FAILED',
    RESET_ADD:          'RESET_ADD',
    LIST_KEY:           'LIST_KEY',
    LIST_KEY_FAILED:    'LIST_KEY_FAILED',
    RESET_LIST:         'RESET_LIST',
    CHECK_URL:          '/api/rakuten/keyValidity',
    ADD_URL:            '/api/rakuten/key',
    LIST_URL:           '/api/rakuten/keys',
    FORMAT:             'YYYY-MM-DD'
};

export default RakutenLicenseKeyConstant