/**
 * Created by PhuNH on 16/12/24.
 */
import React from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import AuthenticatedComponent from '../../../../common/components/AuthenticatedComponent.jsx'
import RakutenLicenseKeyStore from '../../../stores/RakutenLicenseKeyStore.jsx'
import RakutenLicenseKeyService from '../../../services/RakutenLicenseKeyService.jsx'
import RakutenLicenseKeyFormComponent from './RakutenLicenseKeyFormComponent.jsx'
import RakutenLicenseKeyListComponent from './RakutenLicenseKeyListComponent.jsx'

export default class RakutenLicenseKeyComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = this._getState();
        this._onChange = this._onChange.bind(this);

        let self = this;
        this.fadeOutAddTimer = {
            timer: null,
            start: () => {
                this.timer = setTimeout(function(){
                    RakutenLicenseKeyService.resetAddMessage();
                }, 3000);
            },
            stop: () => {
                if (this.timer) {
                    clearTimeout(this.timer);
                }
            }
        };
        this.fadeOutListTimer = {
            timer: null,
            start: () => {
                this.timer = setTimeout(function(){
                    RakutenLicenseKeyService.resetListMessage();
                }, 3000);
            },
            stop: () => {
                if (this.timer) {
                    clearTimeout(this.timer);
                }
            }
        };
        this.fadeOutAddTimer.start.bind(this.fadeOutAddTimer);
        this.fadeOutAddTimer.stop.bind(this.fadeOutAddTimer);
        this.fadeOutListTimer.start.bind(this.fadeOutListTimer);
        this.fadeOutListTimer.stop.bind(this.fadeOutListTimer);
    }

    _getState() {
        return {
            validationState: RakutenLicenseKeyStore.getValidationState(),
            isAdding: RakutenLicenseKeyStore.getIsAdding(),
            isSubmitted: RakutenLicenseKeyStore.getIsSubmitted(),
            addError: RakutenLicenseKeyStore.getAddError(),
            keyList: RakutenLicenseKeyStore.getKeyList(),
            listError: RakutenLicenseKeyStore.getListError()
        }
    }

    componentDidMount() {
        RakutenLicenseKeyStore.addChangeListener(this._onChange);
    }

    componentWillUnmount() {
        RakutenLicenseKeyStore.removeChangeListener(this._onChange);
    }

    _onChange() {
        this.setState(this._getState());
    }

    render() {
        if (this.state.isSubmitted) this.fadeOutAddTimer.start();
        if (this.state.listError) this.fadeOutListTimer.start();
        return (
            <div>
                <RakutenLicenseKeyFormComponent
                    validationState={this.state.validationState}
                    isAdding={this.state.isAdding}
                    isSubmitted={this.state.isSubmitted}
                    addError={this.state.addError} />
                <div className="clearfix popup-msg">
                    <ReactCSSTransitionGroup transitionName="fadeOut" transitionEnterTimeout={500} transitionLeaveTimeout={300}>
                        {(this.state.isSubmitted && this.state.addError) ? <span className="text-danger">前回ライセンスキーを入力しないでください。</span> : null}
                        {(this.state.isSubmitted && !this.state.addError) ? <span className="text-success">ライセンスキーが登録されました。</span> : null}
                    </ReactCSSTransitionGroup>
                </div>
                <div className="clearfix popup-msg">
                    <ReactCSSTransitionGroup transitionName="fadeOut" transitionEnterTimeout={500} transitionLeaveTimeout={300}>
                        {this.state.listError ? <span className="text-danger">サーバの接続が失敗しました。</span> : null}
                    </ReactCSSTransitionGroup>
                </div>
                <RakutenLicenseKeyListComponent
                    keyList={this.state.keyList}
                    listError={this.state.listError} />
            </div>
        )
    }
}