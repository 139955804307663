/**
 * Created by
 */
import React from 'react'
import DateTimeFormat from "../../common/components/DateTimeFormat.jsx"
import DesignImageFormat from "../../common/components/design/DesignImageFormat.jsx"
import CollabDesignAction from "../actions/CollabDesignAction.jsx";
import OrderStatusFormat from "../../common/components/order/OrderStatusFormat.jsx";


export default class CollabDesignTableRowComponent extends React.Component {
    constructor() {
        super();
    }

    createImageLink(design) {
        return "/api/image/" + design.orderNumber + "/" + design.imageFolder +  "/" + design.imageName
    }

    changeApproveDesign(designId, status) {
        CollabDesignAction.changeApproveDesign(designId, status)
    }

    render() {
        return(
            <tr>
                <td className="idCol">{this.props.design.randomId}</td>
                <td>
                    <a href={this.createImageLink(this.props.design)} target={"_blank"}>
                        <DesignImageFormat rowData={this.props.design} className="designImage designList"/>
                    </a>
                </td>
                <td>{this.props.design.orderNumber}</td>
                <td>{this.props.design.packageRandomId}</td>
                <td className="orSttCol"><OrderStatusFormat data={this.props.design.orderStatus}/></td>
                <td>{this.props.design.userName}</td>
                <td style={{textAlign:"center"}} className="dateCol">
                    <DateTimeFormat format="yyyy/MM/dd HH:mm:ss" data={this.props.design.orderDate}/>
                </td>
                <td style={{textAlign:"center"}} className="dateCol">
                    <DateTimeFormat format="yyyy/MM/dd" data={this.props.design.startCreationDate}/>
                </td>
                <td style={{textAlign:"center"}} className="dateCol">
                    <DateTimeFormat format="yyyy/MM/dd" data={this.props.design.expectedShipDate}/>
                </td>
                <td className="statusCol">
                    <select value={this.props.design.collabDesignApproval}
                            onChange={(event) => this.changeApproveDesign(this.props.design.id, event.target.value)}>
                        <option value="WAITING"></option>
                        <option value="APPROVED">承認</option>
                        <option value="REJECTED">拒否</option>
                    </select>
                </td>
            </tr>
        )
    }
}