/**
 * Created by thangkc on 20/09/2016.
 */
import React from 'react';
import OrderStatus from '../../constants/OrderConstant.jsx'
import Button from 'react-bootstrap/lib/Button';
import NumberFormat from '../../../common/components/NumberFormat.jsx'
export default class ProductInfoComponent extends React.Component {
    constructor() {
        super();
        this.state = this._getState();
        this._onChange = this._onChange.bind(this);
    }

    _onChange() {
        this.setState(this._getState());
    }

    _getState() {
        return {
            products: {}
        };
    }

    render() {
        var products = this.props.products;
        return (
            <div>
                <div className="clearfix">
                    <h4 className="title pull-left">商品情報</h4>
                    {/*<button className="btn btn-raised btn-info pull-right" disabled={true}>編集</button>*/}
                </div>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th>商品名</th>
                        <th>商品コード</th>
                        <th>値段（税込）</th>
                        <th>EC数量</th>
                        <th>DT数量</th>
                        <th>オプション内容</th>
                        <th>小計（税込）</th>
                    </tr>
                    </thead>
                    <thead>
                    {products.sort(function (a,b) {
                        return a.productId > b.productId
                    }).map(function (product) {
                        return (
                            <tr key={product.orderProductId}>
                                <td>{product.productName}</td>
                                <td style={{minWidth: "90px"}} >{product.productNumber}</td>
                                <td className="text-right" width="101px"><NumberFormat data={product.price}/>円</td>
                                <td className="text-right" width="47px">{product.ecUnit}</td>
                                <td className="text-right" width="47px">{product.unit}</td>
                                <td>{product.selectedChoice}</td>
                                <td className="text-right" width="101px"><NumberFormat data={product.ecUnit * product.price} />円</td>
                            </tr>
                        )
                    })}
                    
                    </thead>
                </table>
            </div>)
    }
}