import React from 'react';

export default class ReceiverTableRowComponent extends React.Component {
  constructor() {
    super();
  }

  render(){
    let unsubscribeReceiver = this.props.unsubscribeReceiver;
    return(
        <tr>
          <td>{unsubscribeReceiver.name}</td>
         <td><span className='action'>
          <a className='btn btn-raised btn-warning'
             onClick={() => this.props.changeToSubscribedReceiver(unsubscribeReceiver.id)}>
            <i className="fa fa-trash-o"/>
          </a>
        </span></td>
        </tr>
    )
  }
}