/**
 * Created by thangkc on 26/01/2016.
 */

const UserConstant = {
    LIST:  "USER_LIST",
    URL: '/api/user',
    ROLE: {
        USER: "USER",
        GUEST: "GUEST",
        ADMIN: "ADMIN",
        BOOT: "BOOT",
        SYNC: "SYNC"
    }
};

export default UserConstant
