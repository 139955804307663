import React from 'react'
import DateTimeFormat from "../../../common/components/DateTimeFormat.jsx"
class RepeatOrderReportTable extends React.Component {

  constructor () {
    super()
  }

  formatOrderProduct (orderProducts) {
    return orderProducts.map(function (product) {
      return product.productNumber + '*' + product.ecUnit
    }).join(', ')
  }

  renderTableRow () {
    let that = this
    let dateLabels = this.props.labels
    let repeatOrderNumbers = this.props.repeatOrderNumbers

    let groupBy = function(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    let data = groupBy(this.props.orders, "orderDateFormatted")

    var returnData = []

    Object.keys(data).forEach(function (key) {

      let orders = data[key]

      orders.forEach(function (order, index) {
        index === 0 ?
          returnData.push(<tr key={order.id}>
            <td rowSpan={orders.length} className="orNumCol"> {key} </td>
            <td rowSpan={orders.length}> {repeatOrderNumbers[dateLabels.indexOf(key)]}</td>
            <td className="orNumCol">
              {order.orderNumber}
              <DateTimeFormat format="受注日時：yyyy/MM/dd HH:mm:ss"
                              data={order.orderDate} className="date-time"/>
            </td>
            <td className="orNumCol">{order.orderer.familyName} {order.orderer.firstName}</td>
            <td>{that.formatOrderProduct(order.products)}</td>
            {
              order.designSeries.length > 0 ? <td>{order.designSeries.map(function (series) {
                return series.template.name
              }).join(', ')}</td> :<td>{order.designs.map(function (design) {
                return design.template.templateName
              }).join(', ')}</td>
            }
            <td>{order.sameOrdererOrders.map(function (o) {
              return <p key={'same' + o.id}><DateTimeFormat format="受注日：yyyy/MM/dd"
                                                            data={o.orderDate} className="date-time"/>
                {o.orderNumber + ': ' + that.formatOrderProduct(o.products)}</p>
            })}</td>
          </tr>) :
          returnData.push(<tr key={order.id}>
            <td className="orNumCol">
              {order.orderNumber}
              <DateTimeFormat format="受注日時：yyyy/MM/dd HH:mm:ss"
                              data={order.orderDate} className="date-time"/>
            </td>
            <td>{order.orderer.familyName} {order.orderer.firstName}</td>
            <td>{that.formatOrderProduct(order.products)}</td>
            {
              order.designSeries.length > 0 ? <td>{order.designSeries.map(function (series) {
                return series.template.name
              }).join(', ')}</td> :<td>{order.designs.map(function (design) {
                return design.template.templateName
              }).join(', ')}</td>
            }
            <td>{order.sameOrdererOrders.map(function (o) {
              return <p key={'same' + o.id}><DateTimeFormat format="受注日：yyyy/MM/dd"
                                                            data={o.orderDate} className="date-time"/>
                {o.orderNumber + ': ' + that.formatOrderProduct(o.products)}</p>
            })}</td>
          </tr>)

      })

    })

    return returnData

  }

  render () {
    return (
      <table className="table table-striped table-hover table-bordered">
        <thead>
        <tr>
          <th className="orNumCol">日付</th>
          <th className="">リピート回数</th>
          <th className="orNumCol">注文番号</th>
          <th className="orNumCol">受注者</th>
          <th className="">商品情報</th>
          <th className="">テンプレート情報</th>
          <th className="">同じ受注者がいる注文</th>
        </tr>
        </thead>
        <tbody>
        {this.renderTableRow()}
        </tbody>
      </table>
    )
  }
}

export default RepeatOrderReportTable
