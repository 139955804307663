import React from 'react'
import Select from 'react-select'
import { DateRangePicker } from 'react-dates'
import momentPropTypes from 'react-moment-proptypes'
import moment from 'moment'
import LoadingButton from '../../../common/components/LoadingButton.jsx'
import AuthenticatedComponent from '../../../common/components/AuthenticatedComponent.jsx'
import ReportService from '../../services/ReportService.jsx'
import ReportProductTemplateStore
  from '../../stores/ProductTemplateReportStore.jsx'
import TemplateReportTable from './TemplateReportTable.jsx'
import ProductReportTable from './ProductReportTable.jsx'

class ProductTemplateMainReportComponent extends React.Component {
  constructor () {
    super()

    this.componentStyle = {
      marginTop: '10px',
      overflow: 'hidden'
    };
    this.exportButtonStyle = {
      float: 'right'
    };

    this.dateTypes = [
      {value: 'SEND_IM_DATE', label: '制作開始日'},
      {value: 'EX_SHIP_DATE', label: '出荷予定日'},
      {value: 'SHIPPED_DATE', label: '出荷日'},
    ]
    this.itemTypes = [
      {value: 0, label: '商品データ'},
      {value: 1, label: 'テンプレートデータ'},
    ]

    this.reportTypes = [
      {value: 'DAY', label: '日'},
      {value: 'MONTH', label: '月'},
    ]

    this.state = this._getInitialState()
    this._onItemTypeSelect = this._onItemTypeSelect.bind(this)
    this._onDateTypeSelect = this._onDateTypeSelect.bind(this)
    this._onDateChange = this._onDateChange.bind(this)
    this._onFocusChange = this._onFocusChange.bind(this)
    this._onReportTypeSelect = this._onReportTypeSelect.bind(this)
    this._onChange = this._onChange.bind(this)
    this.createDownloadReportLink = this.createDownloadReportLink.bind(this);
    this.downloadReport = this.downloadReport.bind(this);

    moment.locale('ja')
  }

  componentDidMount () {
    ReportProductTemplateStore.addChangeListener(this._onChange)
  }

  componentWillUnmount () {
    ReportProductTemplateStore.removeChangeListener(this._onChange)
  }

  _onChange () {
    this.setState(this._getState())
  }

  _onItemTypeSelect (selectedItemType) {
    this.setState({selectedItemType: selectedItemType.value})
  }

  _onDateTypeSelect (selectedDateType) {
    this.setState({selectedDateType: selectedDateType.value})
  }

  _onReportTypeSelect (selectedType) {
    this.setState({selectedReportType: selectedType.value})
  }

  _onDateChange ({startDate, endDate}) {
    this.setState({selectedStartDate: startDate, selectedEndDate: endDate})
  }

  _onFocusChange (focusedInput) {
    this.setState({focusedInput: focusedInput})
  }

  _getInitialState () {
    return {
      selectedDateType: 'EX_SHIP_DATE',
      selectedItemType: 0,
      selectedReportType: 'DAY',
      selectedStartDate: moment().subtract(1, 'months'),
      selectedEndDate: moment(),
      focusedInput: null,
      listProductTitle: [],
      listTemplateTitle: [],
      templates: null,
      products: null,
      errorMessage: null,
      loading: false,
      isOpenTemplateProductExportForm: false,
      csvLoading: false,
    }
  }

  _getState () {
    return {
      selectedDateType: this.state.selectedDateType,
      selectedItemType: this.state.selectedItemType,
      selectedStartDate: this.state.selectedStartDate,
      selectedEndDate: this.state.selectedEndDate,
      focusedInput: this.state.focusedInput,
      listProductTitle: ReportProductTemplateStore.getListProductTitle(),
      listTemplateTitle: ReportProductTemplateStore.getListTemplateTitle(),
      templates: ReportProductTemplateStore.getTemplates(),
      products: ReportProductTemplateStore.getProducts(),
      errorMessage: ReportProductTemplateStore.getErrorMessage(),
      loading: ReportProductTemplateStore.isLoading(),
      csvLoading: ReportProductTemplateStore.isCsvLoading(),
    }
  }

  createDownloadReportLink() {
    var dateFormatter = "{0}/{1}/{2}";
    var options = {
      minDate: this.state.selectedStartDate && dateFormatter.format(this.state.selectedStartDate.date(), this.state.selectedStartDate.month() + 1, this.state.selectedStartDate.year()),
      maxDate: this.state.selectedEndDate && dateFormatter.format(this.state.selectedEndDate.date(), this.state.selectedEndDate.month() + 1, this.state.selectedEndDate.year())
    };
    var downloadLink = null;
    try {
      if (!options.minDate || !options.maxDate) {
        throw "Illegal argument exception";
      }
      var url = this.state.selectedItemType === 0 ? '/api/reports/products/csv' + '?' : '/api/reports/templates/csv' + '?';
      var parameters = Array();
      if (!!options.minDate && !!options.maxDate) {
        parameters.push("fromDate=" + encodeURIComponent(options.minDate) + "&toDate=" + encodeURIComponent(options.maxDate));
      }
      parameters.push("filterDate=" + encodeURIComponent(this.state.selectedDateType));
      parameters.push("reportType=" + encodeURIComponent(this.state.selectedReportType))
      return url + parameters.join('&');
    } catch (exception) {
    }
    return downloadLink
  }

  downloadReport(downloadLink) {
    this.setState({csvLoading: true})
    fetch(downloadLink,
      {
        headers: {
          'Csrf-Token': 'nocheck',
          'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
        },
      })
      .then(response => {
        let fileName = response.headers.get("content-disposition").split('"')[1]
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
        });
        this.setState({csvLoading: false})
      });
  }

  handleGettingReportClick (e) {
    e.preventDefault()
    if (this.state.selectedItemType === 1) {
      ReportService.getTemplateReport(
        this.state.selectedReportType,
        this.state.selectedDateType,
        moment(this.state.selectedStartDate),
        moment(this.state.selectedEndDate),
      )
    } else if (this.state.selectedItemType === 0) {
      ReportService.getProductReport(
        this.state.selectedReportType,
        this.state.selectedDateType,
        moment(this.state.selectedStartDate),
        moment(this.state.selectedEndDate),
      )
    }
  }

  render () {
    var downloadLink = this.createDownloadReportLink();
    return (
      <section id="ReportComponent">
        <div className="report-form">
          <div className="form-inline">
            <label>レポート集類:&nbsp;</label>
            <Select disabled={this.state.loading}
                    clearable={false}
                    options={this.itemTypes}
                    value={this.state.selectedItemType}
                    onChange={this._onItemTypeSelect}/>
            <Select className="report-type"
                    disabled={this.state.loading}
                    clearable={false}
                    options={this.reportTypes}
                    value={this.state.selectedReportType}
                    onChange={this._onReportTypeSelect}/>

            <label>集計期間:&nbsp;</label>
            <Select className="date-type"
                    disabled={this.state.loading}
                    clearable={false}
                    options={this.dateTypes}
                    value={this.state.selectedDateType}
                    onChange={this._onDateTypeSelect}/>
            <DateRangePicker readOnly
                             disabled={this.state.loading}
                             startDatePlaceholderText="開始日"
                             endDatePlaceholderText="完了日"
                             monthFormat="YYYY[年]MMMM"
                             phrases={{
                               closeDatePicker: '完了',
                               clearDates: 'クリア',
                             }}
                             startDate={this.state.selectedStartDate}
                             endDate={this.state.selectedEndDate}
                             focusedInput={this.state.focusedInput}
                             onDatesChange={this._onDateChange}
                             onFocusChange={this._onFocusChange}
                             isOutsideRange={() => false}
                             hideKeyboardShortcutsPanel
                             minimumNights={0}
                             showClearDates/>
            <LoadingButton
              disabled={this.state.selectedStartDate === null ||
              this.state.selectedEndDate === null}
              isLoading={this.state.loading}
              className="btn-raised btn btn-info btn-md"
              onClick={this.handleGettingReportClick.bind(
                this)}>集計</LoadingButton>
            <LoadingButton
              disabled={this.state.selectedStartDate === null || this.state.selectedEndDate === null}
              isLoading={this.state.csvLoading}
              onClick={() => this.downloadReport(downloadLink)}
              className="btn-raised btn btn-info btn-md">エクスポート</LoadingButton>
          </div>
        </div>
        <div className="tbl-admin tbl-template">
          {
            (!this.state.errorMessage) ?
              (this.state.selectedItemType === 0 ?
                  ((this.state.products === undefined ||
                    this.state.products === null) ?
                    '' :
                    Object.keys(this.state.products).length > 0 ?
                      <ProductReportTable
                        products={this.state.products}
                        listTitle={this.state.listProductTitle}/> :
                      '検索結果は０件です。') :
                  ((this.state.templates === undefined ||
                    this.state.templates === null) ?
                    '' :
                    Object.keys(this.state.templates).length > 0 ?
                      <TemplateReportTable
                        templates={this.state.templates}
                        listTitle={this.state.listTemplateTitle}/> :
                      '検索結果は０件です。')
              )
              :
              <span
                className="key-invalid-msg">{this.state.errorMessage}</span>
          }
        </div>
      </section>
    )
  }
}

ProductTemplateMainReportComponent.propTypes = {
  selectedStartDate: momentPropTypes.momentObj,
  selectedEndDate: momentPropTypes.momentObj,
}

export default AuthenticatedComponent(ProductTemplateMainReportComponent)
