import BaseStore from '../../common/stores/BaseStore.jsx';
import SettingConstants from '../constants/SettingConstants.jsx'
import SettingService from "../services/SettingService.jsx";

class SettingStore extends BaseStore {
  constructor(props) {
    super(props);
    this.init = this.init.bind(this);
    this.resetMessage = this.resetMessage.bind(this);
    this.subscribe(() => this.handler.bind(this));
  }

  init() {
    this.moduleSettings = [];
  }

  resetMessage() {
    this.errorMessage = null;
    this.successMessage = null;
    this.waringMessage = null;
    this.emitChange();
  }

  /**
   * Register callback to handle all updates
   *
   * @param  {Object} action
   */
  handler(action) {
    switch (action.actionType) {
      case SettingConstants.MODULE_STATUS.LOAD:
        this.resetMessage();
        this.emitChange();
        break;
      case SettingConstants.ACTION_TYPE.GET_ALL_MODULE_SETTING:
        this.moduleSettings = action.data;
        this.emitChange();
        break;
      case SettingConstants.ACTION_TYPE.UPDATE_MODULE_SETTING:
        SettingService.getAllSettingModule();
        this.successMessage = SettingConstants.MESSAGE_RESPONSE.MSG_SUCCESS;
        setTimeout(() => {
          this.resetMessage();
        }, 2000);
        this.emitChange();
        break;
      case SettingConstants.MODULE_STATUS.BAD_REQUEST:
        this.errorMessage = SettingConstants.MESSAGE_RESPONSE.MSG_FAILED;
        this.successMessage = null;
        this.waringMessage = null;
        this.emitChange();
        break;
      case SettingConstants.MODULE_STATUS.ERROR:
        this.errorMessage = SettingConstants.MESSAGE_RESPONSE.MSG_ERROR;
        this.successMessage = null;
        this.waringMessage = null;
        this.emitChange();
        break;
      default:
        break
    }
  }

  getErrorMessage() {
    return this.errorMessage
  }

  getSuccessMessage() {
    return this.successMessage
  }

  getWaringMessage() {
    return this.waringMessage
  }

  getModuleSettings() {
    return this.moduleSettings;
  }

}

const makeShopStore = new SettingStore();
export default makeShopStore;