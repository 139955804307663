import Dispatcher from '../../common/dispatcher/Dispatcher.jsx'
import ReportConstant from '../constants/ReportConstant.jsx'

export default {
  load: () => {
    Dispatcher.dispatch({
      actionType: ReportConstant.LOAD,
    })
  },
  showTemplateReport: (templates, listTitle) => {
    Dispatcher.dispatch({
      actionType: ReportConstant.ACTION_TYPE.SHOW_TEMPLATE_REPORT,
      templates: templates,
      listTitle: listTitle,
    })
  },
  showProductReport: (products, listTitle) => {
    Dispatcher.dispatch({
      actionType: ReportConstant.ACTION_TYPE.SHOW_PRODUCT_REPORT,
      products: products,
      listTitle: listTitle,
    })
  },
  showProductReportByOrderStatus: (products) => {
    Dispatcher.dispatch({
      actionType: ReportConstant.ACTION_TYPE.SHOW_PRODUCT_REPORT_BY_ORDER_STATUS,
      data: products,
    })
  },
  showRepeatOrderReport: (data) => {
    Dispatcher.dispatch({
      actionType: ReportConstant.ACTION_TYPE.SHOW_REPEAT_ORDER_REPORT,
      data: data,
    })
  },
  showRepeatOrderChartReport: (data) => {
    Dispatcher.dispatch({
      actionType: ReportConstant.ACTION_TYPE.SHOW_REPEAT_ORDER_CHART_REPORT,
      data: data,
    })
  },
  showProductPackagePlanReport: (data) => {
    Dispatcher.dispatch({
      actionType: ReportConstant.ACTION_TYPE.SHOW_PRODUCT_PACKAGE_PLAN_REPORT,
      data: data,
    })
  },
  setActiveLineChart: (data) => {
    Dispatcher.dispatch({
      actionType: ReportConstant.ACTION_TYPE.CHANGE_LINE_CHART_VIEW,
      data: data,
    })
  },
  setActiveBarChart: (data) => {
    Dispatcher.dispatch({
      actionType: ReportConstant.ACTION_TYPE.CHANGE_BAR_CHART_VIEW,
      data: data,
    })
  },
  showGroupProductOrderReport: (data) => {
    Dispatcher.dispatch({
      actionType: ReportConstant.ACTION_TYPE.SHOW_GROUP_PRODUCT_ORDER_REPORT,
      data: data,
    })
  },
  showDashboardCustomGroupProductReport: (data) => {
    Dispatcher.dispatch({
      actionType: ReportConstant.ACTION_TYPE.SHOW_DASHBOARD_CUSTOM_GROUP_PRODUCT_REPORT,
      data: data,
    })
  },
  createDashboardCustomGroupProductReport: () => {
    Dispatcher.dispatch({
      actionType: ReportConstant.ACTION_TYPE.CREATE_DASHBOARD_GROUP_PRODUCT,
    })
  },
  showChildrenAgeGroupCharReport: (data) => {
    Dispatcher.dispatch({
      actionType: ReportConstant.ACTION_TYPE.SHOW_CHILDREN_AGE_GROUP_CHART_REPORT,
      data: data
    })
  },
  showChildrenAgeGroupReport: (data) => {
    Dispatcher.dispatch({
      actionType: ReportConstant.ACTION_TYPE.SHOW_CHILDREN_AGE_GROUP_REPORT,
      data: data
    })
  },
  showChildrenSurveyData: (data) => {
    Dispatcher.dispatch({
      actionType: ReportConstant.ACTION_TYPE.SHOW_CHILDREN_SURVEY_DATA,
      data: data
    })
  },
  createGroupProduct: () => {
    Dispatcher.dispatch({
      actionType: ReportConstant.ACTION_TYPE.CREATE_GROUP_PRODUCT,
    })
  },
  deleteGroupProduct: () => {
    Dispatcher.dispatch({
      actionType: ReportConstant.ACTION_TYPE.DELETE_GROUP_PRODUCT,
    })
  },
  getCategories: (data) => {
    Dispatcher.dispatch({
      actionType: ReportConstant.ACTION_TYPE.GET_CATEGORIES,
      data: data,
    })
  },
  getProducts: (data) => {
    Dispatcher.dispatch({
      actionType: ReportConstant.ACTION_TYPE.GET_PRODUCTS,
      data: data,
    })
  },
  setDateRange: (data) => {
    Dispatcher.dispatch({
      actionType: ReportConstant.ACTION_TYPE.CHANGE_DATE_RANGE,
      data: data,
    })
  },
  error: (message) => {
    Dispatcher.dispatch({
      actionType: ReportConstant.ERROR,
      message: message,
    })
  },
}
