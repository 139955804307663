/**
 * Created by thangkc on 14/12/2015.
 */
import React from "react"
import request from "reqwest"
import when from "when"
import Constant from '../../common/constants/Constants.jsx'
import LoginAction from "../actions/LoginAction.jsx"
import LoginConstant from "../constants/LoginConstant.jsx"

class LoginService {
    login(userName, password) {
        LoginAction.loggingIn();
        when(request({
            url: LoginConstant.URL,
            method: 'POST',
            crossOrigin: true,
            headers: {
                'Csrf-Token': "nocheck"
            },
            type: 'json',
            data: {
                accountId: userName,
                password: password
            }
        })).then(function (response) {
            switch (response.code) {
                case Constant.RESPONSE_STATUS.SUCCESS:
                    LoginAction.loginUser({
                        accountId: response.data[0].accountId,
                        name: response.data[0].name,
                        token: response.data[0].token
                    });
                    break;
                case Constant.RESPONSE_STATUS.UNAUTHORIZED:
                    LoginAction.loginUser({});
                    break;
                case Constant.RESPONSE_STATUS.BAD_REQUEST:
                    LoginAction.error(response.error);
                    break;
                default:
                    break;
            }
        })
    }

    logout() {
        when(request({
            url: LoginConstant.URL,
            method: 'DELETE',
            crossOrigin: true,
            headers: {
                'Csrf-Token': "nocheck",
                'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
            },
            type: 'json'
        })).then(function (response) {
            switch (response.code) {
                case Constant.RESPONSE_STATUS.SUCCESS:
                    LoginAction.logout();
                    break;
                default:
                    break;
            }
        })
    }

    isAuthenticated() {
        when(request({
            url: LoginConstant.URL,
            method: 'GET',
            crossOrigin: true,
            headers: {
                'Csrf-Token': "nocheck",
                'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
            },
            type: 'json'
        })).then(function (response) {
            switch (response.code) {
                case Constant.RESPONSE_STATUS.SUCCESS:
                    LoginAction.loginUser(response.data[0]);
                    break;
                case Constant.RESPONSE_STATUS.UNAUTHORIZED:
                    LoginAction.logout();
                    break;
                default:
                    break;
            }
        })
    }
}

export default new LoginService()
