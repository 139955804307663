/**
 * Created by thangkc on 08/12/2015.
 */
import Dispatcher from '../../common/dispatcher/Dispatcher.jsx'
import DesignConstant from '../constants/DesignConstant.jsx'

export default {
  load: (designDTO, designFilter) => {
    Dispatcher.dispatch({
      actionType: DesignConstant.ACTION_TYPE.LOAD,
      designDTO: designDTO,
      designFilter: designFilter,
    })
  },
  changeKeyword: (keyword) => {
    Dispatcher.dispatch({
      actionType: DesignConstant.ACTION_TYPE.CHANGE_KEYWORD,
      keyword: keyword,
    })
  },
  changeStatusFilter: (status) => {
    Dispatcher.dispatch({
      actionType: DesignConstant.ACTION_TYPE.CHANGE_STT_FIL,
      status: status,
    })
  },
  changePage: (page) => {
    Dispatcher.dispatch({
      actionType: DesignConstant.ACTION_TYPE.CHANGE_PAGE,
      page: page,
    })
  },
  deleteDesign: (designs) => {
    Dispatcher.dispatch({
      actionType: DesignConstant.ACTION_TYPE.DELETE,
      deletedDesigns: designs,
    })
  },
}