import React from 'react'
import CampaignStore from '../../stores/CampaignStore.jsx'
import CampaignService from '../../services/CampaignService.jsx'
import ToggleLoadingButton from '../../../common/components/ToggleLoadingButton.jsx'
import {
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
} from 'react-bootstrap/lib'
import AuthenticatedComponent from '../../../common/components/AuthenticatedComponent.jsx'
import StudioAliceBaby1812Table from './StudioAliceBaby1812Table.jsx'
import OurPagingComponent from '../../../common/components/paging/OurPagingComponent.jsx'
import CampaignAction from '../../actions/CampaignAction.jsx'

class StudioAliceBaby1812Component extends React.Component {

  constructor () {
    super()
    this.state = this.initState()
    this._onChange = this._onChange.bind(this)
    this.startDownloadStudioAliceBaby1812 = this.startDownloadStudioAliceBaby1812.bind(this)
    this.endDownloadStudioAliceBaby1812 = this.endDownloadStudioAliceBaby1812.bind(this)

  }

  initState () {
    CampaignStore._resetFilter()
    return this._getState()
  }

  _getState () {
    let doneExport = CampaignStore._getExportStudioAliceBaby1812Status()
    let loadingBtnLabel = 'CSVエクスポート中'
    let isDownloadStudioAliceBaby1812 = true

    if (doneExport == null ||doneExport){
      isDownloadStudioAliceBaby1812 = false
    }

    return {
      isDownloadStudioAliceBaby1812: isDownloadStudioAliceBaby1812,
      loadingBtnLabel: loadingBtnLabel,
      exportStudioAliceBaby1812Status: CampaignStore._getExportStudioAliceBaby1812Status(),
      studioAliceBaby1812DTO : CampaignStore._getStudioAliceBaby1812DTO(),
      studioAliceBaby1812Filter : CampaignStore._getStudioAliceBaby1812Filter()
    }
  }

  _onChange () {
    this.setState(this._getState())
  }

  componentDidMount () {
    CampaignService.loadStudioAliceBaby1812(CampaignStore._getStudioAliceBaby1812Filter())
    CampaignStore.addChangeListener(this._onChange)
  }

  componentWillUnmount () {
    CampaignStore.removeChangeListener(this._onChange)
  }

  startDownloadStudioAliceBaby1812 () {
    let self = this
    self.setState({isDownloadStudioAliceBaby1812: true})
    CampaignService.exportCSVStudioAliceBaby1812File()
  }

  endDownloadStudioAliceBaby1812 () {

  }

  render () {
    return (
      <section className='campaign-tab'>

        <Form horizontal>
          <FormGroup controlId="formControlsSelect">
            <h3 className="col-sm-4">
              StudioAliceBaby1812データ：
            </h3>
            <Col sm={7}>
              <ToggleLoadingButton
                onStartLoading={this.startDownloadStudioAliceBaby1812}
                onStopLoading={this.endDownloadStudioAliceBaby1812}
                isLoading={this.state.isDownloadStudioAliceBaby1812}
                disabled={this.state.isDownloadStudioAliceBaby1812}
                btnLabel='ダウンロード'
                loadingBtnLabel={this.state.loadingBtnLabel}
                btnClass='btn btn-primary btn-raised btn-lg ld-ext-left'
                loadingBtnClass='btn btn-warning btn-raised btn-lg ld-ext-left'
                loadingIconClass='ld ld-ring ld-spin'>
              </ToggleLoadingButton>
            </Col>
          </FormGroup>
        </Form>
        <StudioAliceBaby1812Table campaigns={this.state.studioAliceBaby1812DTO.campaigns}
                       campaignFilter={this.state.studioAliceBaby1812Filter}/>
        <OurPagingComponent loadNewPage={CampaignAction.changePageStudioAliceBaby1812}
                            dto={this.state.studioAliceBaby1812DTO}/>
      </section>
    )
  }

}

export default AuthenticatedComponent(StudioAliceBaby1812Component)
