import BaseStore from '../../common/stores/BaseStore.jsx'
import ReportConstant from '../constants/ReportConstant.jsx'
import ReportService from "../services/ReportService.jsx";
import moment from "moment";

class DashboardCustomGroupProductStore extends BaseStore {
  constructor () {
    super()
    this.resetReport()
    this.subscribe(() => this.handler.bind(this))
  }

  resetReport () {
    this.errorMessage = null;
    this.loading = true;
    this.dashboards = [];
    this.categories = [];
    this.products = [];
    this.listGroups = [{
      category: null,
      product: null,
      quantity: 0,
      products: []
    }];
    this.selectedStartDate =  moment().subtract(1, 'months');
    this.selectedEndDate = moment();
  }

  /**
   * Register callback to handle all updates
   *
   * @param  {Object} action
   */
  handler (action) {
    switch (action.actionType) {
      case ReportConstant.LOAD:
        this.loading = true;
        this.emitChange();
        break;
      case ReportConstant.ACTION_TYPE.SHOW_DASHBOARD_CUSTOM_GROUP_PRODUCT_REPORT:
        this.errorMessage = null;
        this.dashboards = action.data;
        this.loading = false;
        this.emitChange();
        break;
      case ReportConstant.ACTION_TYPE.CREATE_DASHBOARD_GROUP_PRODUCT:
      case ReportConstant.ACTION_TYPE.DELETE_GROUP_PRODUCT:
      case ReportConstant.ACTION_TYPE.DELETE_DASHBOARD:
        ReportService.getDashBoardGroupProduct(this.selectedStartDate, this.selectedEndDate);
        break;
      case ReportConstant.ACTION_TYPE.CREATE_GROUP_PRODUCT:
        this.resetListGroup();
        ReportService.getDashBoardGroupProduct(this.selectedStartDate, this.selectedEndDate);
        break;
      case ReportConstant.ACTION_TYPE.GET_CATEGORIES:
        this.categories = action.data;
        this.emitChange();
        break;
      case ReportConstant.ACTION_TYPE.GET_PRODUCTS:
        this.listGroups[action.data.index].products = action.data.product;
        this.emitChange();
        break;
      case ReportConstant.ERROR:
        this.errorMessage = action.message;
        this.loading = false;
        this.emitChange();
        break;
      default:
        break;
    }
  }

  getErrorMessage () {
    return this.errorMessage
  }

  isLoading () {
    return this.loading
  }

  getDashBoards() {
    return this.dashboards
  }

  setSelectedDate(startDate, endDate) {
    this.selectedStartDate = startDate;
    this.selectedEndDate = endDate;
  }

  getSelectedStartDate() {
    return this.selectedStartDate;
  }

  getSelectedEndDate() {
    return this.selectedEndDate;
  }

  getCategories() {
    return this.categories;
  }

  getListGroups() {
    return this.listGroups;
  }

  addListGroup() {
    return this.listGroups.push({
      category: null,
      product: null,
      quantity: 0,
      products: []
    })
  }

  deleteGroup(index) {
    return this.listGroups.splice(index, 1);
  }

  resetListGroup() {
    this.listGroups = [{
      category: null,
      product: null,
      quantity: 0,
      products: []
    }];
    this.emitChange();
  }
}

const dashboardCustomGroupProductStore = new DashboardCustomGroupProductStore();

export default dashboardCustomGroupProductStore
