import React from 'react'
import PagingRenderer from './PagingRenderer.jsx'

export default class OurPagingComponent extends React.Component {

    constructor() {
        super();
    }

  /**
   *
   * dto = {
   *    currentPage: 0,
   *    maxPage: 100
   * }
   */


    _selectPage(pageNumber) {
        this._loadNewPage(pageNumber);
    }

    _previousPage() {
        this._loadNewPage(parseInt(this.props.dto.currentPage) - 1);
    }

    _nextPage() {
        this._loadNewPage(parseInt(this.props.dto.currentPage) + 1);
    }

    _loadNewPage(pageIndex) {
        this.props.loadNewPage(pageIndex);
    }

    render() {
        let basedIndex = 1,
            currentPage = parseInt(this.props.dto.currentPage),
            maxPage = parseInt(this.props.dto.maxPage),
            changePage = this._selectPage,
            previous = this._previousPage.bind(this),
            next = this._nextPage.bind(this),
            component = this;

        return (
            <PagingRenderer
                basedIndex={basedIndex}
                currentPage={currentPage}
                maxPage={maxPage}
                changePage={changePage}
                previous={previous}
                next={next}
                component={component}
            />
        )
    }
}