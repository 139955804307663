import React from 'react';
import DateTimeFormat from "../../common/components/DateTimeFormat.jsx"
import OrderStatusFormat from "../../common/components/order/OrderStatusFormat.jsx"
import OrderConstant from "../constants/OrderConstant.jsx"
import OrderService from "../services/OrderService.jsx";

export default class OrderTableRowComponent extends React.Component {
  constructor() {
    super();
    this.div_center = {
      margin: '0 auto'
    };
    this.onSwitchInvoiceStatus = this.onSwitchInvoiceStatus.bind(this)
  }

  onSwitchInvoiceStatus(order, event) {
    OrderService.switchInvoiceStatus(order.orderNumber, event.target.checked)
  }

  render() {
    let order = this.props.order;
    let orderNumber = order.orderNumber;
    return (
        <tr className={(order.status === OrderConstant.STATUS.PENDING_CREATION || order.status === OrderConstant.STATUS.CREATING)
        && order.pendingCreationErrorMessage !== "" ? "error-pending-creation" : ""}>
          <td className="orNumCol">
            <a onClick={this.props.loadDetailOrder.bind(this, orderNumber)}
               className="text-info link-pointer">{orderNumber}</a>
            <DateTimeFormat format="最新編集日時：yyyy/MM/dd HH:mm:ss" data={order.updatedAt} className="date-time"/>
          </td>
          <td style={{whiteSpace: 'pre'}}>{order.imCollaborationId.join('\n')}</td>
          <td>{order.creatorName}</td>
          <td><DateTimeFormat data={order.orderDate}/></td>
          <td className="orSttCol"><OrderStatusFormat data={order.status}/></td>
          <td>
            {order.ecSite !== "RAKUTEN" ?
                <div className="on-of-switch" style={this.div_center}>
                  <input type="checkbox"
                         checked={this.props.order.invoiceCreateable}
                         onChange={(event) => this.onSwitchInvoiceStatus(order, event)}
                         name="on-of-switch"
                         className="on-of-switch-checkbox"
                         id={orderNumber + "my-on-off-switch"}/>
                  <label className="on-of-switch-label" htmlFor={orderNumber + "my-on-off-switch"}>
                    <span className="on-of-switch-inner"/>
                    <span className="on-of-switch-switch"/>
                  </label>
                </div>
                : null}
          </td>
          <td className="mailCol">{order.loginGuideEmailSent ? <i className="fa fa-check-circle"/> : ""}</td>
        </tr>
    )
  }
}