/**
 * Created by thangkc on 20/09/2016.
 */
import React from 'react';
import OrderStore from "../../stores/OrderStore.jsx";
import OrderService from "../../services/OrderService.jsx";
import Constants from '../../../common/constants/Constants.jsx';
import {Button, FormControl} from "react-bootstrap/lib";
import DatePicker from 'react-datepicker';
import OrderConstant from "../../constants/OrderConstant.jsx";
import FlashComponent from "../../../common/components/FlashComponent.jsx";
import moment from 'moment';

export default class ShipInfoComponent extends React.Component {
    constructor() {
        super();
        this.state = this._getState();
        this._onChange = this._onChange.bind(this);
        this.cancelUpdateShipInfo = this.cancelUpdateShipInfo.bind(this);
        this.renderShipId = this.renderShipId.bind(this);
        this.toggleUpdate = this.toggleUpdate.bind(this);
        this.updateShipInfo = this.updateShipInfo.bind(this);
        this.changeShipDate = this.changeShipDate.bind(this);
        this.changeSlipNo = this.changeSlipNo.bind(this);
        this.renderUpdateOrderShipInfoMessage = this.renderUpdateOrderShipInfoMessage.bind(this);
        this.resetOrderShipInfoChangeResult = this.resetOrderShipInfoChangeResult.bind(this);
    }

    _onChange() {
        this.setState(this._getState());
    }

    _getState() {
        return {
            products: {},
            crudMode: Constants.CRUD_MODE.READ,
            updateBtnTitle: '編集',
            orderShipInfoChangeResult: OrderStore.getOrderShipInfoChangeResult(),
            shipInfo: {}
        };
    }

    componentDidMount() {
        OrderStore.addChangeListener(this._onChange);
    }

    componentWillUnmount() {
        OrderStore.removeChangeListener(this._onChange);
    }

    onSwitchInvoiceStatus(order, event, orderFilter) {
        OrderService.switchInvoiceStatusOrderDetail(order.orderNumber, event.target.checked, orderFilter)
    }

    toggleUpdate(sendMail) {
        const {orderPackage} = this.props
        let _shipInfo = {};
        if (!!orderPackage.shipId) {
            _shipInfo = {
                slipNo: orderPackage.shipId,
                shipDate: moment(orderPackage.shipDate),
            }
        }
        if (this.state.crudMode === Constants.CRUD_MODE.READ) {
            this.setState({
                crudMode: Constants.CRUD_MODE.UPDATE,
                updateBtnTitle: '保存',
                orderShipInfoChangeResult: {},
                shipInfo: _shipInfo
            })
        } else {
            this.updateShipInfo(sendMail);
        }
    }

    updateShipInfo(sendMail) {
        this.setState({
            crudMode: Constants.CRUD_MODE.READ,
            updateBtnTitle: '編集',
            orderShipInfoChangeResult: {}
        })
        OrderService.updateShipInfo(this.state.shipInfo, this.props.orderPackage.id, sendMail)
    }

    cancelUpdateShipInfo() {
        this.setState({
            crudMode: Constants.CRUD_MODE.READ,
            updateBtnTitle: '編集',
            shipInfo: {},
            orderShipInfoChangeResult: {}
        })
    }

    changeShipDate(shipDate) {
        this.setState({
            shipInfo: {
                ...this.state.shipInfo,
                shipDate: shipDate
            }
        });
    }

    changeSlipNo(slipNo) {
        this.setState({
            shipInfo: {
                ...this.state.shipInfo,
                slipNo: slipNo.target.value
            }
        })
    }

    resetOrderShipInfoChangeResult() {
        this.setState({
            orderShipInfoChangeResult: {},
        });
    }

    renderUpdateOrderShipInfoMessage() {
        const {orderShipInfoChangeResult} = this.state
        if (orderShipInfoChangeResult.httpCode) {
            let result = null;
            switch (orderShipInfoChangeResult.httpCode) {
                case 200:
                    result = {
                        message: orderShipInfoChangeResult.message,
                        className: 'text-success',
                    };
                    break;
                default:
                    result = {
                        message: orderShipInfoChangeResult.message,
                        className: 'text-danger',
                    };
                    break;
            }
            return <FlashComponent visible={true} timeout={5000}
                                   className={result.className}
                                   onHide={this.resetOrderShipInfoChangeResult}>
                {result.message}</FlashComponent>;
        } else {
            return null;
        }
    }

    renderShipId() {
        const {orderPackage} = this.props
        if (!orderPackage.shipId) {
            return '-';
        } else {
            if (orderPackage.isUseSagawa) {
                return <a className="text-info shipNo" target="_blank"
                          href={"https://k2k.sagawa-exp.co.jp/p/web/okurijosearch.do?okurijoNo=" + orderPackage.shipId}>
                    {orderPackage.shipId}
                </a>
            } else {
                return <a className="text-info shipNo" target="_blank"
                          href={"http://jizen.kuronekoyamato.co.jp/jizen/servlet/crjz.b.NQ0010?id=" + orderPackage.shipId}>
                    {orderPackage.shipId}
                </a>
            }
        }
    }

    render() {
        const {order, orderPackage} = this.props
        const {shipInfo, crudMode, updateBtnTitle} = this.state
        const updateShipInfoBtnAttr = {disable: true};
        const toggleUpdateShipInfoBtnAttr = {disable: true};

        const isEditShipmentInfoButtonEnable = orderPackage.status === OrderConstant.STATUS.CREATING &&
            ([OrderConstant.STATUS.CREATING, OrderConstant.STATUS.PENDING_CREATION, OrderConstant.STATUS.SHIPPED].includes(order.status))

        if (Object.keys(shipInfo).length === 0
            || shipInfo.slipNo === undefined || shipInfo.slipNo.length < 12
            || shipInfo.shipDate === undefined || shipInfo.shipDate === '') {
            updateShipInfoBtnAttr.disabled = true;
        }
        if (isEditShipmentInfoButtonEnable) {
            toggleUpdateShipInfoBtnAttr.disabled = false
        }
        return (
            <div>
                <div className="clearfix">
                    <div style={{display: 'flex', justifyContent: 'flex-end', columnGap: '8px'}}>
                        <div style={{marginTop: '18px'}}>
                            {this.renderUpdateOrderShipInfoMessage()}
                        </div>
                    </div>
                </div>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th colSpan="2">
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <div>
                                    出荷情報{orderPackage.printCompany === "IM_TOKYO" ? ": IM東京" : orderPackage.printCompany === ": IM岐阜" ? "GIFU" : ""}
                                </div>
                                <div style={{display: 'flex', justifyContent: 'flex-end', columnGap: '8px'}}>
                                    {
                                        crudMode !== Constants.CRUD_MODE.READ ?
                                            <Button className="btn btn-raised btn-danger tooltip margin-0 padding-default"
                                                    onClick={this.cancelUpdateShipInfo}>
                                                <p className="tooltip-text tooltip-top">キャンセル</p>
                                                <i className="fa fa-times" aria-hidden="true"></i>
                                            </Button>
                                            : null
                                    }
                                    {
                                        crudMode === Constants.CRUD_MODE.UPDATE &&
                                        <Button
                                            className="btn btn-raised btn-info tooltip margin-0 padding-default" {...updateShipInfoBtnAttr}
                                            onClick={this.toggleUpdate.bind(this, false)}>
                                            <p className="tooltip-text tooltip-top">保存（メール送信なし）</p>
                                            <div className="group-icon">
                                                <i className="fa fa-envelope fa-stack-1x"></i>
                                                <i className="fa fa-ban fa-stack-2x"></i>
                                            </div>
                                        </Button>
                                    }
                                    {
                                        crudMode === Constants.CRUD_MODE.UPDATE ?
                                            <Button
                                                className="btn btn-raised btn-info tooltip margin-0 padding-default" {...updateShipInfoBtnAttr}
                                                onClick={this.toggleUpdate.bind(this, true)}>
                                                <p className="tooltip-text tooltip-top">{updateBtnTitle}</p>
                                                <i className="fa fa-envelope"></i>
                                            </Button>
                                            :
                                            <Button
                                                className="btn btn-raised btn-info tooltip margin-0 padding-default" disabled {...toggleUpdateShipInfoBtnAttr}
                                                onClick={this.toggleUpdate}>
                                                <p className="tooltip-text tooltip-top">{updateBtnTitle}</p>
                                                <i className="fa fa-pencil"></i>
                                            </Button>
                                    }
                                </div>
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th width="190px">{orderPackage.printCompany ? orderPackage.printCompany : "制作会社"}との連携オーダーID</th>
                        <td>{orderPackage.randomId}</td>
                    </tr>
                    <tr>
                        <th>配送 No.</th>
                        <td>
                            {
                                crudMode === Constants.CRUD_MODE.READ ?
                                    this.renderShipId() :
                                    <FormControl type="text" placeholder="配送番号" onChange={this.changeSlipNo}
                                                 value={shipInfo.slipNo}
                                    />
                            }
                        </td>
                    </tr>
                    <tr>
                        <th>出荷予定日</th>
                        <td>{orderPackage.expectedShipDate ? orderPackage.expectedShipDate : '-'}</td>
                    </tr>
                    <tr>
                        <th>出荷日</th>
                        <td>
                            {
                                crudMode === Constants.CRUD_MODE.READ ?
                                    orderPackage.shipDate ? orderPackage.shipDate : '-' :
                                    <DatePicker selected={shipInfo.shipDate} className="form-control"
                                                readOnly={true} onChange={this.changeShipDate}
                                    />
                            }
                        </td>
                    </tr>
                    <tr>
                        <th>到着予定日</th>
                        <td>{orderPackage.defaultExpectedDeliveryDate ? orderPackage.defaultExpectedDeliveryDate : '-'}</td>
                    </tr>
                    <tr>
                        <th>到着希望日</th>
                        <td>{orderPackage.deliveryDays}</td>
                    </tr>
                    <tr>
                        <th>到着希望時間帯</th>
                        <td>{orderPackage.deliveryTime}</td>
                    </tr>
                    <tr>
                        <th>領収書発行</th>
                        <td>
                            {
                                order.ecSiteId !== "RAKUTEN" ?
                                    <div className="on-of-switch" style={this.div_center}>
                                        <input type="checkbox"
                                               checked={order.invoiceCreateable}
                                               onChange={(event) => this.onSwitchInvoiceStatus(order, event, OrderStore.getOrderFilter())}
                                               name="on-of-switch"
                                               className="on-of-switch-checkbox"
                                               id={order.orderNumber + 'detail' + "my-on-off-switch"}/>
                                        <label className="on-of-switch-label"
                                               htmlFor={order.orderNumber + 'detail' + "my-on-off-switch"}>
                                            <span className="on-of-switch-inner"/>
                                            <span className="on-of-switch-switch"/>
                                        </label>
                                    </div>
                                    : null
                            }
                        </td>
                    </tr>
                    <tr>
                        <th>ステータス</th>
                        <td>{OrderConstant.STATUS_JP[orderPackage.status]}</td>
                    </tr>
                    </tbody>
                </table>
            </div>)
    }
}
