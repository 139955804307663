const EpsilonDataTrackingConstants = {
    FETCH_ORDERS: 'FETCH_ORDERS',
    FETCHED_ORDERS: 'FETCHED_ORDERS',
    CHANGE_FILTER: 'CHANGE_FILTER',
    CHECK_ORDER: 'CHECK_ORDER',
    CHECK_ALL_ORDER: 'CHECK_ALL_ORDER',
    RESET_FILTER: 'RESET_FILTER',
    START_EPSILON_LINK_DATA: 'START_EPSILON_LINK_DATA',
    API: {
        GET_ORDER_URL: "/api/reports/epsilon-error-orders",
        BULK_UPDATE_ERROR_ORDERS: "/api/reports/bulk/update/epsilon-error-orders"
    }
}

export default EpsilonDataTrackingConstants