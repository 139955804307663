import React from 'react'

class GroupProductOrderTable extends React.Component {

  constructor () {
    super()
  }

  renderTableRow () {
    let groupBy = function(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    let data = groupBy(this.props.orders, "orderDateFormatted")
    var returnData = []

    Object.keys(data).forEach(function (key) {
      let orders = data[key]
      orders.forEach(function (order, index) {
        index === 0 ?
          returnData.push(<tr key={order.id}>
            <td rowSpan={orders.length} className="orNumCol"> {key} </td>
            <td className="orNumCol">
              {order.orderNumber}
            </td>
            <td className="orNumCol">{order.orderer.familyName} {order.orderer.firstName}</td>
            <td>{order.groupProduct.name.replace("_","\n")}</td>
          </tr>) :
          returnData.push(<tr key={order.id}>
            <td className="orNumCol">
              {order.orderNumber}
            </td>
            <td>{order.orderer.familyName} {order.orderer.firstName}</td>
            <td>{order.groupProduct.name.replace("_","\n")}</td>
          </tr>)
      })
    });

    return returnData
  }

  render () {
    return (
      <table className="table table-striped table-hover table-bordered">
        <thead>
        <tr>
          <th className="orNumCol">日付</th>
          <th className="orNumCol">注文番号</th>
          <th className="orNumCol">受注者</th>
          <th className="">商品情報</th>
        </tr>
        </thead>
        <tbody>
        {this.renderTableRow()}
        </tbody>
      </table>
    )
  }
}

export default GroupProductOrderTable
