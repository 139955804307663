import BaseStore from "../../common/stores/BaseStore.jsx";
import RakutenConstants from "../constants/RakutenConstants.jsx";

class RakutenStore extends BaseStore {
  constructor(){
    super();
    this.activeKey = undefined;
    this.pathName = null;
    this.subscribe(() => this.handler.bind(this));
  }

  handler(action){
    switch (action.actionType){
      case RakutenConstants.ACTION_TYPE.GET_ACTIVE_KEY:
        this.activeKey = action.activeKey;
        this.pathName = action.pathName;
        this.emitChange();
        break;
      default:
        break;
    }
  }

  getActiveKey(){
    return this.activeKey;
  }

  getPathName(){
    return this.pathName;
  }
}

const rakutenStore = new RakutenStore();
export default rakutenStore;