import React from 'react'
import moment from 'moment'
import { DateRangePicker } from 'react-dates'
import LoadingButton from '../../../common/components/LoadingButton.jsx'
import AuthenticatedComponent from '../../../common/components/AuthenticatedComponent.jsx'
import RepeatOrderReportStore
  from '../../stores/RepeatOrderReportStore.jsx'
import RepeatOrderReportTable from './RepeatOrderReportTable.jsx'
import OurPagingComponent from '../../../common/components/paging/OurPagingComponent.jsx'
import RepeatOrderReportChart from './RepeatOrderReportChart.jsx'
import ReportService from '../../services/ReportService.jsx'
import ReportConstant from '../../constants/ReportConstant.jsx'
import RadioGroup from 'react-radio'
import ReportAction from '../../actions/ReportAction.jsx'
import RepeatOrderExportReportForm from "./RepeatOrderExportReportForm.jsx";

class RepeatOrderReportComponent extends React.Component {
  constructor () {
    super()
    this.state = this._getState()
    this._onDateTypeSelect = this._onDateTypeSelect.bind(this)
    this._onDateChange = this._onDateChange.bind(this)
    this._onFocusChange = this._onFocusChange.bind(this)
    this.changePage = this.changePage.bind(this)
    this._onChange = this._onChange.bind(this)
    this.openRepeatOrderExportForm = this.openRepeatOrderExportForm.bind(this)
    this.closeRepeatOrderExportForm = this.closeRepeatOrderExportForm.bind(this)

    moment.locale('ja')
  }

  componentDidMount () {
    ReportService.getRepeatOrderReport(this.state.selectedStartDate,
      this.state.selectedEndDate, 1)
    ReportService.getRepeatOrderChartReport(this.state.selectedStartDate,
      this.state.selectedEndDate)
    RepeatOrderReportStore.addChangeListener(this._onChange)
  }

  componentWillUnmount () {
    RepeatOrderReportStore.removeChangeListener(this._onChange)
  }

  _onDateTypeSelect (selectedDateType) {
    this.setState({selectedDateType: selectedDateType.value})
  }

  _onDateChange ({startDate, endDate}) {
    this.setState({selectedStartDate: startDate, selectedEndDate: endDate})
  }

  _onFocusChange (focusedInput) {
    this.setState({focusedInput: focusedInput})
  }

  _onChange () {
    this.setState(this._getState())
  }

  _getState () {
    return {
      viewLineChart: RepeatOrderReportStore.getActiveLineChart(),
      selectedStartDate: this.state ? this.state.selectedStartDate ||
        moment().subtract(1, 'months') : moment().subtract(1, 'months'),
      selectedEndDate: this.state
        ? this.state.selectedEndDate || moment()
        : moment(),
      focusedInput: null,
      ordersDTO: RepeatOrderReportStore.getRepeatOrdersData(),
      pieData: RepeatOrderReportStore.getPieData(),
      lineDayRepeatData: RepeatOrderReportStore.getLineDayRepeatData(),
      lineDayTotalData: RepeatOrderReportStore.getLineDayTotalData(),
      lineMonthRepeatData: RepeatOrderReportStore.getLineMonthRepeatData(),
      lineMonthTotalData: RepeatOrderReportStore.getLineMonthTotalData(),
      lineYearRepeatData: RepeatOrderReportStore.getLineYearRepeatData(),
      lineYearTotalData: RepeatOrderReportStore.getLineYearTotalData(),
      errorMessage: null,
      loading: RepeatOrderReportStore.isLoading(),
      isOpenRepeatOrderExportForm: false,
    }
  }

  handleGettingReportClick (e) {
    ReportAction.load()
    ReportService.getRepeatOrderReport(this.state.selectedStartDate,
      this.state.selectedEndDate, 1)
    ReportService.getRepeatOrderChartReport(this.state.selectedStartDate,
      this.state.selectedEndDate)
  }

  changePage (currentPage) {
    ReportAction.load()
    let elmnt = document.getElementById('repeatOrderReportTable')
    window.scrollTo(elmnt.offsetLeft, elmnt.offsetTop - 60)
    ReportService.getRepeatOrderReport(this.state.selectedStartDate,
      this.state.selectedEndDate, currentPage)
  }

  viewLineReport (value, event) {
    ReportService.setActiveLineChart(value)
  }

  openRepeatOrderExportForm() {
    this.setState({
      isOpenRepeatOrderExportForm: true,
    });
  }

  closeRepeatOrderExportForm() {
    this.setState({
      isOpenRepeatOrderExportForm: false,
    });
  }

  render () {
    const chart = () => {
      switch (this.state.viewLineChart) {

        case ReportConstant.CHART_LINE_VIEW.DAY:
          return <RepeatOrderReportChart datasets={[
            this.state.lineDayRepeatData.datasets,
            this.state.lineDayTotalData.datasets]}
                                         labels={this.state.lineDayRepeatData.labels}
                                         type={ReportConstant.CHART_TYPE.LINE}/>
        case ReportConstant.CHART_LINE_VIEW.MONTH:
          return <RepeatOrderReportChart datasets={[
            this.state.lineMonthRepeatData.datasets,
            this.state.lineMonthTotalData.datasets]}
                                         labels={this.state.lineMonthRepeatData.labels}
                                         type={ReportConstant.CHART_TYPE.LINE}/>
        case ReportConstant.CHART_LINE_VIEW.YEAR:
          return <RepeatOrderReportChart datasets={[
            this.state.lineYearRepeatData.datasets,
            this.state.lineYearTotalData.datasets]}
                                         labels={this.state.lineYearRepeatData.labels}
                                         type={ReportConstant.CHART_TYPE.LINE}/>

        default:
          return <h1>No project match</h1>
      }
    }

    return (
      <section>
        <div className="report-form">
          <div className="form-inline">
            <label>受注日:&nbsp;</label>
            <DateRangePicker
              readOnly
              disabled={this.state.loading}
              startDatePlaceholderText="開始日"
              endDatePlaceholderText="完了日"
              monthFormat="YYYY[年]MMMM"
              phrases={{
                closeDatePicker: '完了',
                clearDates: 'クリア',
              }}
              startDate={this.state.selectedStartDate}
              endDate={this.state.selectedEndDate}
              focusedInput={this.state.focusedInput}
              onDatesChange={this._onDateChange}
              onFocusChange={this._onFocusChange}
              isOutsideRange={() => false}
              hideKeyboardShortcutsPanel
              minimumNights={0}
              showClearDates/>

            <LoadingButton
              disabled={this.state.selectedStartDate === null ||
              this.state.selectedEndDate === null}
              isLoading={this.state.loading}
              className="btn-raised btn btn-info btn-md"
              onClick={this.handleGettingReportClick.bind(
                this)}>集計</LoadingButton>

            <button className="btn-raised btn btn-info btn-md" onClick={this.openRepeatOrderExportForm.bind(this)}>エクスポート</button>
          </div>
          <RepeatOrderExportReportForm isOpenRepeatOrderExportForm={this.state.isOpenRepeatOrderExportForm}
                                       closeRepeatOrderExportForm={this.closeRepeatOrderExportForm}
          />
        </div>
        <div className="row">
          <div className="col-12 col-md-5">
            <div className="row" style={{height: '52px'}}/>
            <div className="row">
              <RepeatOrderReportChart datasets={this.state.pieData.datasets}
                                      labels={this.state.pieData.labels}
                                      type={ReportConstant.CHART_TYPE.PIE}/>
            </div>
          </div>

          <div className="col-12 col-md-7">
            <div className="button-wrap">
              <RadioGroup name="colors" value={this.state.viewLineChart}
                          onChange={this.viewLineReport}>
                <input
                  id={ReportConstant.CHART_LINE_VIEW.DAY}
                  className="hidden radio-label" type="radio"
                  value={ReportConstant.CHART_LINE_VIEW.DAY}/>
                <label htmlFor={ReportConstant.CHART_LINE_VIEW.DAY}
                       className="button-label">daily
                </label>
                <input
                  id={ReportConstant.CHART_LINE_VIEW.MONTH}
                  className="hidden radio-label" type="radio"
                  value={ReportConstant.CHART_LINE_VIEW.MONTH}/>
                <label
                  htmlFor={ReportConstant.CHART_LINE_VIEW.MONTH}
                  className="button-label">monthly</label>
                <input
                  id={ReportConstant.CHART_LINE_VIEW.YEAR}
                  className="hidden radio-label" type="radio"
                  value={ReportConstant.CHART_LINE_VIEW.YEAR}/>
                <label
                  htmlFor={ReportConstant.CHART_LINE_VIEW.YEAR}
                  className="button-label">yearly</label>
              </RadioGroup>
            </div>


            <div className="row" id="line-chart-day">
              {chart()}
            </div>
          </div>
        </div>

        <div className="tbl-admin tbl-template" id="repeatOrderReportTable">
          {this.state.loading ?
            <div><span className="loading-spin-icon"/><span
              style={{display: 'inline-block', height: '25px'}}>読み込み中。。。 </span>
            </div> : ''}
          <RepeatOrderReportTable
            orders={this.state.ordersDTO.orders}
            labels={this.state.lineDayRepeatData.labels}
            repeatOrderNumbers={this.state.lineDayRepeatData.datasets}
          />
          <OurPagingComponent loadNewPage={this.changePage}
                              dto={this.state.ordersDTO}/>
        </div>
      </section>
    )
  }
}

export default AuthenticatedComponent(RepeatOrderReportComponent)
