import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import Select from "react-select";
import {Col, Form, FormControl, FormGroup} from "react-bootstrap";

export default class CreateGroupProductModal extends React.Component {
  constructor() {
    super();
    this.alignRightText = {
      'textAlign': 'right'
    };

    this.createGroupProduct = this.createGroupProduct.bind(this);
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  createGroupProduct() {
    this.props.listGroups.map(function (item) {
      item.isFormError = !!(item.category === null || item.quantity <= 0 || (item.category && !item.category.isNamingConventionUsed && item.product === null));
      return item
    });

    if (this.props.listGroups.filter(item => item.isFormError).length === 0) {
      this.props.createGroupProduct(this.props.dashboard);
      this.props.closeCreateGroupModal();
    } else {
      this.forceUpdate();
    }
  }

  render() {
    let self = this;
    let categories = this.props.categories.map(function (item) {
      return {
        value: item.id,
        label: item.name,
        isNamingConventionUsed: item.isNamingConventionUsed
      }
    });
    return (
      <Modal show={this.props.isOpenCreateGroupModal} onHide={this.props.closeCreateGroupModal}>
        <Modal.Header closeButton>
          <h2 className="modal-title">商品組み合わせ登録</h2>
        </Modal.Header>
        <Modal.Body>
          <Form horizontal>
            {
              this.props.listGroups.map(function (item, index) {
                let products = item.products.map(function (product) {
                  return {
                    value: product.id,
                    label: product.name
                  }
                });
                return (
                  <div key={index} style={{borderBottom: "1px solid #e3e3e3"}}>
                    <h5 className="text-danger has-error" style={{
                      display: item.isFormError ? 'block' : 'none', fontWeight: 'bold'
                    }}>正しいカテゴリー、商品カテゴリーまたは、数量を選択してください。</h5>
                    <div className="row">
                      <div className="col-sm-11">
                        <FormGroup>
                          <Col sm={3} style={self.alignRightText}>
                            カテゴリー :
                          </Col>
                          <Col sm={9}>
                            <Select placeholder="カテゴリー" options={categories}
                                    onChange={(category) => self.props.onCategoryChange(index, category)}
                                    value={item.category}/>
                          </Col>
                        </FormGroup>
                        {item.category && !item.category.isNamingConventionUsed ? (
                          <FormGroup style={{marginTop: "14px"}}>
                            <Col sm={3} style={self.alignRightText}>
                              商品 :
                            </Col>
                            <Col sm={9}>
                              <Select placeholder="商品" options={products}
                                      onChange={(product) => self.props.onProductChange(index, product)}
                                      value={item.product}/>
                            </Col>
                          </FormGroup>
                        ) : null}

                        <FormGroup style={{marginTop: "14px"}}>
                          <Col sm={3} style={self.alignRightText}>
                            数量 :
                          </Col>
                          <Col sm={9}>
                            <FormControl type='number'
                                         placeholder="数量"
                                         onChange={(quantity) => self.props.onQuantityChange(index, quantity)}
                                         value={item.quantity}/>
                          </Col>
                        </FormGroup>
                      </div>
                      {
                        self.props.listGroups.length > 1 ?
                          <div className="col-sm-1 align-middle">
                            <FormGroup>
                            <span className='action' style={{float: "left"}}>
                              <a className='btn btn-raised btn-danger'
                                 onClick={() => self.props.deleteGroupForm(index)}>
                                <i className="fa fa-trash-o"/>
                              </a>
                            </span>
                            </FormGroup>
                          </div> : null
                      }
                    </div>
                  </div>
                )
              })
            }
            <span className='action' style={{marginTop: "14px"}}>
              <a className='btn btn-raised btn-primary' onClick={this.props.addNewGroupForm}>
                <i className="fa fa-plus"/>
              </a>
            </span>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-raised btn-primary" onClick={this.createGroupProduct}>保存</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
