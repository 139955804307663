import BaseStore from "../../common/stores/BaseStore.jsx";
import FlyerCampaignConstant from "../constants/FlyerCampaignConstant.jsx";
import moment from "moment";

class FlyerCampaignStore extends BaseStore {
  constructor() {
    super();
    this.allFlyerCampaign = [];
    this.flyerCampaign = {
      clientName: null,
      flyerName: null,
      quantity: null,
      startCampaignDate: moment(),
      endCampaignDate: moment(),
      ageRange: [],
      family: [],
      area: [],
      surveyOption: null
    };
    this.error = null;
    this.subscribe(() => this.handler.bind(this))
  }

  handler(action) {
    switch (action.actionType) {
      case FlyerCampaignConstant.ACTION_TYPE.LOADED_ALL_FLYER_CAMPAIGN:
        this.allFlyerCampaign = action.allFlyerCampaign;
        this.error = null;
        this.emitChange();
        break;
      case FlyerCampaignConstant.ACTION_TYPE.LOADED_FLYER_CAMPAIGN:
        this.flyerCampaign = action.flyerCampaign;
        this.error = null;
        this.emitChange();
        break;
      case FlyerCampaignConstant.ACTION_TYPE.BAD_REQUEST:
        this.error = action.error;
        this.emitChange();
        break;
      case FlyerCampaignConstant.ACTION_TYPE.ERROR:
        this.error = action.error;
        this.emitChange();
        break;
    }
  }

  getError() {
    return this.error;
  }

  getAllFlyerCampaign() {
    return this.allFlyerCampaign;
  }

  getFlyerCampaign() {
    return this.flyerCampaign;
  }
}

const flyerCampaign = new FlyerCampaignStore();

export default flyerCampaign;
