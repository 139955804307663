import when from "when";
import request from "reqwest";
import AlertAction from "../../common/components/actions/AlertAction.jsx";
import {AlertConstant} from "../../common/constants/AlertConstant.jsx";
import LoadingAction from "../../common/components/actions/LoadingAction.jsx";
import Constants from "../../common/constants/Constants.jsx";
import FailedChangeStatusOrderConstants from "../constants/FailedChangeStatusOrderConstants.jsx";
import FailedChangeStatusOrderAction from "../actions/FailedChangeStatusOrderAction.jsx";

class FailedChangeStatusOrderService {
    fetchOrders(filter) {
        setTimeout(function () {
            LoadingAction.startLoading()
        }, 0)
        const orderFilter = {}
        Object.entries(filter).forEach(([key, value]) => {
            if (value !== "") orderFilter[key] = value
        })

        let url = FailedChangeStatusOrderConstants.API.GET_ORDER_URL + "?" + new URLSearchParams(orderFilter)
        when(request({
            url: url,
            method: 'GET',
            crossOrigin: true,
            headers: {
                'Csrf-Token': "nocheck",
                'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
            }
        })).then(function (response) {
            LoadingAction.stopLoading()
            switch (response.code) {
                case Constants.RESPONSE_STATUS.SUCCESS:
                    FailedChangeStatusOrderAction.fetchedOrders(response.data, filter);
                    break;
                default:
                    break;
            }
        })
    }

    changeOrderStatus(orders) {
        setTimeout(function () {
            LoadingAction.startLoading()
        }, 0)
        const url = FailedChangeStatusOrderConstants.API.BULK_CHANGE_ORDER_STATUS
        when(request({
            url: url,
            method: 'PUT',
            crossOrigin: true,
            headers: {
                'Csrf-Token': "nocheck",
                'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
            },
            contentType: 'application/json',
            data: JSON.stringify({orders})
        })).then(function (response) {
            LoadingAction.stopLoading()
            switch (response.code) {
                case Constants.RESPONSE_STATUS.SUCCESS:
                    AlertAction.alert(AlertConstant.SUCCESS, "一括更新されました。")
                    FailedChangeStatusOrderAction.fetchOrders();
                    break;
                case Constants.RESPONSE_STATUS.BAD_REQUEST:
                    AlertAction.alert(AlertConstant.DANGER, "一括更新できませんでした。")
                    break;
                case Constants.RESPONSE_STATUS.INTERNAL_SERVER_ERROR:
                    AlertAction.alert(AlertConstant.DANGER, "一括更新できませんでした。")
                    break;
                default:
                    break;
            }
        })
    }
}

export default new FailedChangeStatusOrderService()