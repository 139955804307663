/**
 * Created by thangkc on 20/01/2016.
 */
import React from 'react';

export default class NotFoundComponent extends React.Component {
    render() {
        return (
            <div>
                Not found!
            </div>
        );
    }
}
