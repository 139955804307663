/**
 * Created by thangkc on 14/12/2015.
 */

const LoginConstant = {
    LOGGING_IN: "LOGGING_IN",
    LOGIN_USER: "LOGIN_USER",
    LOGOUT:  "LOGOUT",
    ERROR: 'ERROR',
    URL: '/api/authenticate'
};

export default LoginConstant
