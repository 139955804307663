/**
 * Created by PhuNH on 16/12/25.
 */
import React from 'react'
import { Button, FormGroup, FormControl } from 'react-bootstrap/lib'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import ja from 'moment/locale/ja.js'
import RakutenLicenseKeyConstant from '../../../constants/RakutenLicenseKeyConstant.jsx'
import RakutenLicenseKeyService from '../../../services/RakutenLicenseKeyService.jsx'

export default class RakutenLicenseKeyFormComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = this._initState(props);
        this._onChangeKeyString = this._onChangeKeyString.bind(this);
        this._onChangeDate = this._onChangeDate.bind(this);
        this._checkKey = this._checkKey.bind(this);
        this._submitKey = this._submitKey.bind(this);
        moment.locale('ja');
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            validationState: nextProps.validationState,
            isAdding: nextProps.isAdding,
            isSubmitted: nextProps.isSubmitted,
            addError: nextProps.addError
        });
        if (this.state.keyString == "")
            this.setState({validationState: null});
        if (this.state.isSubmitted && !this.state.addError) {
            this.setState({
                selectedDate: moment(),
                keyString: "",
            });
        }
    }

    _initState(props) {
        return {
            selectedDate: moment(),
            keyString: "",
            validationState: props.validationState,
            isAdding: props.isAdding,
            isSubmitted: props.isSubmitted,
            addError: props.addError
        }
    }

    _onChangeKeyString(event) {
        this.setState({ keyString: event.target.value });
        RakutenLicenseKeyService.changeKey();
    }

    _onChangeDate(date) {
        this.setState({ selectedDate: date })
    }

    _checkKey(event) {
        event.preventDefault();
        if (this.state.keyString != "")
            RakutenLicenseKeyService.checkKeyValidity(this.state.keyString);
    }

    _submitKey(event) {
        event.preventDefault();
        RakutenLicenseKeyService.addKey(
            this.state.keyString,
            this.state.selectedDate.format(RakutenLicenseKeyConstant.FORMAT)
        );
    }

    _renderFeedback() {
        if (this.state.validationState == 'warning') return (
            <FormControl.Feedback>
                <span className="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span>
            </FormControl.Feedback>
        );
        else return (
            <FormControl.Feedback/>
        )
    }

    render() {
        const keyMsgStyle = {
            display: (this.state.validationState == 'error' ? 'block' : 'none')
        };

        return (
            <div className="form-inline rakuten-form">
                <form onSubmit={this._submitKey}>
                    <div>
                        <FormGroup validationState={this.state.validationState}>
                            <label>ライセンスキー:&nbsp;</label>
                            <FormControl className="key-str" type="text"
                                         value={this.state.keyString}
                                         onChange={this._onChangeKeyString} onBlur={this._checkKey}/>
                            {this._renderFeedback()}
                        </FormGroup>
                        <FormGroup className="exp-date-group">
                            <label>期間期限:&nbsp;</label>
                            <DatePicker className="form-control" dateFormat={RakutenLicenseKeyConstant.FORMAT}
                                        minDate={moment()} selected={this.state.selectedDate}
                                        onChange={this._onChangeDate}
                                        locale="ja" readOnly={true}/>
                        </FormGroup>
                        <FormGroup>
                            <Button bsStyle="info" type="submit" className="btn-raised"
                                    disabled={this.state.validationState != 'success' || this.state.isAdding}>
                                {this.state.isAdding ? '登録中...' : '登録'}
                            </Button>
                        </FormGroup>
                        <span className="key-invalid-msg" style={keyMsgStyle}>入力したライセンスキーで天楽に接続できませんでした。</span>
                    </div>
                </form>
            </div>
        );
    }
}