/**
 * Created by thangkc on 14/12/2015.
 */
import BaseStore from '../../common/stores/BaseStore.jsx'
import LoginConstant from '../constants/LoginConstant.jsx'

class LoginStore extends BaseStore {
    constructor() {
        super();
        this.userLoggedIn = null;
        this.error = null;
        this.loading = false;
        this.subscribe(() => this.handler.bind(this));
    }

    /**
     * Register callback to handle all updates
     *
     * @param  {Object} action
     */
    handler(action) {
        switch (action.actionType) {
            case LoginConstant.LOGGING_IN:
                this.loading = true;
                this.emitChange();
                break;
            case LoginConstant.LOGIN_USER:
                this.userLoggedIn =  action.user;
                this.error = null;
                this.loading = false;
                this.emitChange();
                break;
            case LoginConstant.LOGOUT:
                this.userLoggedIn =  null;
                this.emitChange();
                break;
            case LoginConstant.ERROR:
                this.error = action.errors;
                this.loading = false;
                this.emitChange();
                break;
            default :
        }
    }

    loggedInUser() {
        return this.userLoggedIn
    }

    LoginError() {
        return this.error
    }

    isLoading() {
        return this.loading
    }
}

const loginStore = new LoginStore();

export default loginStore;