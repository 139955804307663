/**
 * Created by thangkc on 08/12/2015.
 */
import Dispatcher from '../../common/dispatcher/Dispatcher.jsx'
import LoginConstant from '../constants/LoginConstant.jsx'

export default {
  loggingIn: () => {
    Dispatcher.dispatch({
      actionType: LoginConstant.LOGGING_IN,
    })
  },
  loginUser: (user) => {
    let savedUser = localStorage.getItem('user')
    if (savedUser !== user) {
      localStorage.setItem('user', user);
      if (user.token) {
        localStorage.setItem('token', user.token)
      }
    }
    Dispatcher.dispatch({
      actionType: LoginConstant.LOGIN_USER,
      user: user,
    })
  },
  logout: () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    Dispatcher.dispatch({
      actionType: LoginConstant.LOGOUT,
    })
  },
  error: (error) => {
    Dispatcher.dispatch({
      actionType: LoginConstant.ERROR,
      errors: error,
    })
  },
}
