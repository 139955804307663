import BaseStore from '../../common/stores/BaseStore.jsx'
import YahooConstants from '../constants/YahooConstants.jsx';

class YahooStore extends BaseStore {
    constructor(){
        super();
        this.userInfo = {};
        this.yconnectInfo = {};
        this.subscribe(() => this.handler.bind(this));
    }

    handler(action){
        switch (action.actionType){
            case YahooConstants.ACTION_TYPE.GET_USER_INFO:
                this.userInfo = action.userInfo;
                this.yconnectInfo = action.yconnectInfo;
                this.emitChange();
                break;
            default: break;
        }
    }

    getYahooUserInfo(){
        return this.userInfo;
    }

    getYconnectInfo(){
        return this.yconnectInfo;
    }
}

const yahooStore = new YahooStore()

export default yahooStore