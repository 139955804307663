import BaseStore from '../../common/stores/BaseStore.jsx'
import ReportConstant from '../constants/ReportConstant.jsx'

class GroupProductOrderStore extends BaseStore {
  constructor () {
    super()
    this.resetReport()
    this.subscribe(() => this.handler.bind(this))
  }

  resetReport () {
    this.errorMessage = null;
    this.loading = true;

    this.ordersDTO = {
      currentPage: 1,
      maxPage: 0,
      orders: []
    };

    this.chartData = {
      quantities: [],
      labels: [],
      colors: [],
    };

  }

  /**
   * Register callback to handle all updates
   *
   * @param  {Object} action
   */
  handler (action) {
    switch (action.actionType) {
      case ReportConstant.LOAD:
        this.loading = true;
        this.emitChange();
        break;
      case ReportConstant.ACTION_TYPE.SHOW_GROUP_PRODUCT_ORDER_REPORT:
        this.errorMessage = null;
        this.chartData = action.data.dataCharts;
        this.ordersDTO = action.data.orders;
        this.loading = false;
        this.emitChange();
        break;
      case ReportConstant.ERROR:
        this.errorMessage = action.message;
        this.loading = false;
        this.emitChange();
        break;
      default:
        break;
    }
  }

  getErrorMessage () {
    return this.errorMessage
  }

  isLoading () {
    return this.loading
  }

  getChartData() {
    return this.chartData
  }

  getGroupProductOrdersData() {
    return this.ordersDTO
  }
}

const groupProductOrderStore = new GroupProductOrderStore();

export default groupProductOrderStore
