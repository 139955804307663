/**
 * Created by thangkc on 21/09/2016.
 */
import React from 'react';
import DesignConstant from '../../../design/constants/DesignConstant.jsx'

export default class DesignStatusFormat extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <b>{this.statusStr}</b>
        )
    }

    get statusStr() {
        return DesignConstant.STATUS_JP[this.props.data]
    }
}