import React from 'react'
import moment from 'moment'
import {DateRangePicker} from 'react-dates'
import LoadingButton from '../../../../common/components/LoadingButton.jsx'
import AuthenticatedComponent from '../../../../common/components/AuthenticatedComponent.jsx'
import ReportService from '../../../services/ReportService.jsx'
import ReportConstant from '../../../constants/ReportConstant.jsx'
import ReportAction from '../../../actions/ReportAction.jsx'
import GroupProductChart from "../GroupProductChart.jsx";
import DashboardSetting from "./DashboardSetting.jsx";
import DashboardCustomGroupProductStore from '../../../stores/DashboardCustomGroupProductStore.jsx'

class CustomProductOrderComponent extends React.Component {
  constructor() {
    super()
    this.state = this._getState()
    this._onDateChange = this._onDateChange.bind(this)
    this._onFocusChange = this._onFocusChange.bind(this)
    this._onChange = this._onChange.bind(this)
    this.createDashboard = this.createDashboard.bind(this)
    this.addNewGroupForm = this.addNewGroupForm.bind(this);
    this.deleteGroupForm = this.deleteGroupForm.bind(this);
    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onProductChange = this.onProductChange.bind(this);
    this.onQuantityChange = this.onQuantityChange.bind(this);
    this.createGroupProduct = this.createGroupProduct.bind(this);
    this.resetGroupProduct = this.resetGroupProduct.bind(this);
    this.createDownloadReportLink = this.createDownloadReportLink.bind(this);
    this.downloadReport = this.downloadReport.bind(this);

    moment.locale('ja')
  }

  componentDidMount() {
    ReportService.getDashBoardGroupProduct(this.state.selectedStartDate, this.state.selectedEndDate);
    ReportService.getCategories();
    DashboardCustomGroupProductStore.addChangeListener(this._onChange)
  }

  componentWillUnmount() {
    DashboardCustomGroupProductStore.removeChangeListener(this._onChange)
  }

  _onDateChange({startDate, endDate}) {
    DashboardCustomGroupProductStore.setSelectedDate(startDate, endDate);
    this.setState({
      selectedStartDate: DashboardCustomGroupProductStore.getSelectedStartDate(),
      selectedEndDate: DashboardCustomGroupProductStore.getSelectedEndDate(),
    });
  }

  _onFocusChange(focusedInput) {
    this.setState({focusedInput: focusedInput})
  }

  _onChange() {
    this.setState(this._getState())
  }

  _getState() {
    return {
      selectedStartDate: DashboardCustomGroupProductStore.getSelectedStartDate(),
      selectedEndDate: DashboardCustomGroupProductStore.getSelectedEndDate(),
      focusedInput: null,
      dashboards: DashboardCustomGroupProductStore.getDashBoards(),
      errorMessage: DashboardCustomGroupProductStore.getErrorMessage(),
      categories: DashboardCustomGroupProductStore.getCategories(),
      loading: DashboardCustomGroupProductStore.isLoading(),
      listGroups: DashboardCustomGroupProductStore.getListGroups(),
    }
  }

  handleGettingReportClick(e) {
    ReportAction.load();
    ReportService.getDashBoardGroupProduct(this.state.selectedStartDate,
      this.state.selectedEndDate);
  }

  createDashboard(dashboard){
    ReportService.createDashboardGroupProduct(dashboard)
  }

  createGroupProduct(dashboard){
    ReportService.createGroupProduct(dashboard, this.state.listGroups);
  }

  onCategoryChange(index, category) {
    let listGroups = this.state.listGroups;
    listGroups[index].category = category;
    listGroups[index].product = null;
    this.setState({
      listGroups: listGroups
    });
    if (!category.isNamingConventionUsed) {
      ReportService.getProducts(index, category.value)
    }
  }

  onProductChange(index, product) {
    let listGroups = this.state.listGroups;
    listGroups[index].product = product;
    this.setState({
      listGroups: listGroups
    })
  }

  onQuantityChange(index, e) {
    let listGroups = this.state.listGroups;
    listGroups[index].quantity = e.target && parseInt(e.target.value);
    this.setState({
      listGroups: listGroups
    })
  }

  resetGroupProduct() {
    DashboardCustomGroupProductStore.resetListGroup();
  }

  addNewGroupForm() {
    DashboardCustomGroupProductStore.addListGroup();
    this._onChange();
  }

  deleteGroupForm(index) {
    DashboardCustomGroupProductStore.deleteGroup(index);
    this._onChange();
  }

  downloadReport(downloadLink) {
    fetch(downloadLink,
      {
        headers: {
          'Csrf-Token': 'nocheck',
          'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
        },
      })
      .then(response => {
        let fileName = response.headers.get("content-disposition").split('"')[1]
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
        });
      });
  }

  createDownloadReportLink() {
    var dateFormatter = "{0}/{1}/{2}";
    var orderFilter = {
      minDate: this.state.selectedStartDate && dateFormatter.format(this.state.selectedStartDate.date(), this.state.selectedStartDate.month() + 1, this.state.selectedStartDate.year()),
      maxDate: this.state.selectedEndDate && dateFormatter.format(this.state.selectedEndDate.date(), this.state.selectedEndDate.month() + 1, this.state.selectedEndDate.year())
    };
    var downloadLink = null;
    try {
      if (!orderFilter.minDate || !orderFilter.maxDate) {
        throw "Illegal argument exception";
      }
      var url = '/api/dashboards/csv' + '?';
      var parameters = Array();
      if (!!orderFilter.minDate && !!orderFilter.maxDate) {
        parameters.push("fromDate=" + encodeURIComponent(orderFilter.minDate) + "&toDate=" + encodeURIComponent(orderFilter.maxDate));
      }
      return url + parameters.join('&');
    } catch (exception) {
    }
    return downloadLink
  }

  render() {
    var downloadLink = this.createDownloadReportLink();
    var exportBtnAttributes = {};
    if (!downloadLink) {
      exportBtnAttributes.disabled = true;
    }
    return (
      <section>
        <div className="report-form">
          <div className="form-inline">
            <label>受注日:&nbsp;</label>
            <DateRangePicker
              readOnly
              disabled={this.state.loading}
              startDatePlaceholderText="開始日"
              endDatePlaceholderText="完了日"
              monthFormat="YYYY[年]MMMM"
              phrases={{
                closeDatePicker: '完了',
                clearDates: 'クリア',
              }}
              startDate={this.state.selectedStartDate}
              endDate={this.state.selectedEndDate}
              focusedInput={this.state.focusedInput}
              onDatesChange={this._onDateChange}
              onFocusChange={this._onFocusChange}
              isOutsideRange={() => false}
              hideKeyboardShortcutsPanel
              minimumNights={0}
              showClearDates/>

            <LoadingButton
              disabled={this.state.selectedStartDate === null ||
              this.state.selectedEndDate === null}
              isLoading={this.state.loading}
              className="btn-raised btn btn-info btn-md"
              onClick={this.handleGettingReportClick.bind(
                this)}>集計</LoadingButton>

            <button className="btn-raised btn btn-info btn-md" onClick={() => this.downloadReport(downloadLink)} {...exportBtnAttributes}>エクスポート</button>
          </div>
        </div>
        <div className="row" style={{marginTop: "20px"}}>
          <DashboardSetting
            dashboards={this.state.dashboards}
            createDashboard={this.createDashboard}
            onCategoryChange={this.onCategoryChange}
            onProductChange={this.onProductChange}
            onQuantityChange={this.onQuantityChange}
            addNewGroupForm={this.addNewGroupForm}
            deleteGroupForm={this.deleteGroupForm}
            categories={this.state.categories}
            listGroups={this.state.listGroups}
            createGroupProduct={this.createGroupProduct}
            resetGroupProduct={this.resetGroupProduct}
          />
        </div>
        {
          this.state.dashboards.filter(item => item.groupProducts.length > 0).map(function (item, index) {
            let quantities = item.groupProducts.map(group => group.quantity);
            let labels = item.groupProducts.map(group => group.name);
            let colors = item.groupProducts.map(group => group.color);
            return (
              <div className="row" key={index}>
                <h3 style={{marginBottom: "0px"}}>{item.name}</h3>
                <div className="col-12 col-md-5">
                  <div className="row">
                    <GroupProductChart datasets={quantities}
                                       labels={labels}
                                       colors={colors}
                                       type={ReportConstant.CHART_TYPE.PIE}/>
                  </div>
                </div>
                <div className="col-12 col-md-7">
                  <div className="row" id="line-chart-day">
                    <GroupProductChart
                      datasets={quantities}
                      labels={labels}
                      colors={colors}
                      type={ReportConstant.CHART_TYPE.BAR}/>

                  </div>
                </div>
              </div>
            )
          })
        }
      </section>
    )
  }
}

export default AuthenticatedComponent(CustomProductOrderComponent)
