/**
 * Created by thangkc on 11/08/2020.
 */
import React from 'react';
import AuthenticatedComponent from '../../../common/components/AuthenticatedComponent.jsx'
import MagazineMailService from '../../services/MagazineMailService.jsx'
import PlanStore from '../../stores/PlanStore.jsx'
import moment from "moment";
import {Alert} from "react-bootstrap";
import PlanTableComponent from "./PlanTableComponent.jsx";
import PlanForm from "./PlanForm.jsx";

class PlanListComponent extends React.Component {
    constructor(props) {
        super(props);
        /* == CSS ==  */

        /* == VARIABLE == */

        /* == FUNCTION == */
        this.state = this.initState();
        this.onChange = this.onChange.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
        this.openPlanFormDialog = this.openPlanFormDialog.bind(this);
        this.closePlanFormDialog = this.closePlanFormDialog.bind(this);
        /* == SETTINGS == */
        moment.locale('ja');
    } /* end constructor */

    initState() {
        return {
            plans: [],
            mailTemplates: [],
            error: null,
            planDetail: {},
            plan: null,
            mailQuantity: {},
            isOpenPlanFormDialog: false
        }
    }

    componentDidMount() {
        MagazineMailService.getAllPlan();
        MagazineMailService.getAllMailTemplate();
        MagazineMailService.getEmailQuantity();
        PlanStore.addChangeListener(this.onChange);
    }

    componentWillUnmount() {
        PlanStore.removeChangeListener(this.onChange);
    }

    onChange() {
        this.setState(() => (this.getState()))
    }

    getState() {
        return {
            plans: PlanStore.getAllPlan(),
            plan: PlanStore.getPlan(),
            mailTemplates: PlanStore.getAllMailTemplate(),
            mailQuantity: PlanStore.getMailQuantity(),
            error: PlanStore.getError(),
            isOpenPlanFormDialog: this.state.isOpenPlanFormDialog
        }
    } /* end get state */

    openPlanFormDialog(planId) {
        let self = this;
        if (!planId.type) {
           MagazineMailService.getPlan(planId);
        } else {
            self.setState({plan: {}});
        }
        self.setState( {isOpenPlanFormDialog: true});
    }

    closePlanFormDialog() {
        this.setState({
            isOpenPlanFormDialog: false,
            plan: {}
        });
    }

    closeMessage(event) {
        event.persist();
        this.setState(() => ({
            error: null
        }));
    } /*end close msg*/

    /* == MAIN == */
    render() {
        return (
            <div>
                <div className="container-fluid padding-0">
                    <div className="row">
                        <div className="col-md-12">
                            {this.state.successMessage ? <Alert bsStyle="success">{this.state.successMessage}
                                <button className="close" onClick={this.closeMessage}>
                                    <i className="fa fa-times" aria-hidden="true"/>
                                </button>
                            </Alert> : ""}
                            {this.state.waringMessage ? <Alert bsStyle="warning">{this.state.waringMessage}
                                <button className="close" onClick={this.closeMessage}>
                                    <i className="fa fa-times" aria-hidden="true"/>
                                </button>
                            </Alert> : ""}
                            {this.state.errorMessage ? <Alert bsStyle="danger">{this.state.errorMessage}
                                <button className="close" onClick={this.closeMessage}>
                                    <i className="fa fa-times" aria-hidden="true"/>
                                </button>
                            </Alert> : ""}
                        </div>
                    </div>
                    {/*end message*/}
                    <br/>
                    <div style={{display: 'flex'}}>
                      <b className="col-sm-3">今月契約メール数：{Intl.NumberFormat().format(this.state.mailQuantity.total)}</b> {/*mail per month*/}
                      <b className="col-sm-3">今月送信済みメール数：{Intl.NumberFormat().format(this.state.mailQuantity.used)}</b> {/*mail sent in month*/}
                      <b className="col-sm-3">今月残り：{Intl.NumberFormat().format(this.state.mailQuantity.remain)}</b>           {/*mail left in month*/}
                    </div>

                    <PlanTableComponent data={this.state.plans} openPlanFormDialog={this.openPlanFormDialog}/>
                </div>

                <PlanForm isOpenPlanFormDialog={this.state.isOpenPlanFormDialog}
                          closePlanFormDialog={this.closePlanFormDialog}
                          mailTemplates={this.state.mailTemplates}
                          plan={this.state.plan}/>
            </div>

        )
    }/*end render*/
}

export default AuthenticatedComponent(PlanListComponent);
