/**
 * Created by thangkc on 20/09/2016.
 */
import React from 'react';
import JapanPostalCodeComponent from '../../../common/components/JapanPostalCodeComponent.jsx';

export default class OrdererComponent extends React.Component {
    constructor() {
        super();
        this.state = this._getState();
        this._onChange = this._onChange.bind(this);
    }

    _onChange() {
        this.setState(this._getState());
    }

    _getState() {
        return {};
    }

    ordererName(orderer){
        if (!orderer.familyName && !orderer.firstName) return ""
        return `${orderer.familyName} ${orderer.firstName}`
    }

    ordererNameKana(orderer){
        if (!orderer.familyNameKana && !orderer.familyNameKana) return ""
        return `${orderer.familyNameKana} ${orderer.firstNameKana}`
    }

    ordererAddress(orderer){
        if (!orderer.prefecture && !orderer.city && !orderer.subAddress1 && !orderer.subAddress2) return ""
        return `${orderer.prefecture} ${orderer.city} ${orderer.subAddress1} ${orderer.subAddress2}`
    }

    ordererSexAndBirthday(orderer) {
        if (!orderer.birthDay && !orderer.birthMonth && !orderer.birthYear) return ""
        return `${orderer.sex}・${orderer.birthYear}/${orderer.birthMonth}/${orderer.birthDay}`
    }

    render() {
        var orderer = this.props.orderer;
        var payMethod = this.props.payMethod;
        return (
            <div className="col-md-6">
                <div className="clearfix">
                    <h4 className="title pull-left">注文者情報</h4>
                    {/*<button className="btn btn-raised btn-info pull-right" disabled={true}>編集</button>*/}
                </div>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th colSpan="2">注文者情報</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th width="190px">氏名</th>
                        <td>{this.ordererName(orderer)}</td>
                    </tr>
                    <tr>
                        <th>氏名カナ</th>
                        <td>{this.ordererNameKana(orderer)}</td>
                    </tr>
                    <tr>
                        <th>郵便番号</th>
                        <td>
                            <JapanPostalCodeComponent postalCode={orderer.zipCode}/>
                        </td>
                    </tr>
                    <tr>
                        <th>住所</th>
                        <td>{this.ordererAddress(orderer)}</td>
                    </tr>
                    <tr>
                        <th>電話番号</th>
                        <td>{orderer.phoneNumber ? orderer.phoneNumber : ""}</td>
                    </tr>
                    <tr>
                        <th>性別・生年月日</th>
                        <td>{this.ordererSexAndBirthday(orderer)}</td>
                    </tr>
                    <tr>
                        <th>メールアドレス</th>
                        <td>{orderer.emailAddress ? orderer.emailAddress : ""}</td>
                    </tr>
                    <tr>
                        <th>お支払方法</th>
                        <td>{payMethod ? payMethod : ""}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}