import React from 'react';
import AuthenticatedComponent from '../../common/components/AuthenticatedComponent.jsx'
import SettingStore from "../stores/SettingStore.jsx";
import SettingService from "../services/SettingService.jsx";
import moment from "moment";
import {Alert} from "react-bootstrap";

class SettingStatusModuleComponent extends React.Component {
  constructor(props) {
    super(props);
    /* == CSS ==  */
    this.width_80 = {
      width: '80%'
    };
    this.width_20 = {
      width: '20%',
      textAlign: 'center'
    };
    this.vertical_middle = {
      verticalAlign: 'middle'
    };
    this.div_center = {
      margin: '0 auto'
    };
    /* == VARIABLE == */

    /* == FUNCTION == */
    this.state = this.initState();
    this.onChange = this.onChange.bind(this);
    this.onUpdateModuleSettingStatus = this.onUpdateModuleSettingStatus.bind(this);
    this.closeMessage = this.closeMessage.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
    /* == SETTINGS == */
    moment.locale('ja');
  } /* end constructor */

  initState() {
    return {
      moduleSettings: [],
      moduleStatus: false,
      errorMessage: null,
      successMessage: null,
      waringMessage: null,
    }
  }

  componentDidMount() {
    SettingService.getAllSettingModule();
    SettingStore.addChangeListener(this.onChange);
  }

  onChange() {
    this.setState(() => (this.getState()))
  }

  getState() {
    return {
      successMessage: SettingStore.getSuccessMessage(),
      waringMessage: SettingStore.getWaringMessage(),
      errorMessage: SettingStore.getErrorMessage(),
      moduleSettings: SettingStore.getModuleSettings(),
    }
  } /* end get state */

  onUpdateModuleSettingStatus(setting, event) {
    SettingService.updateSettingModule(setting.id, event.target.checked)
  }/*end onUpdateModuleSettingStatus*/

  closeMessage(event) {
    event.persist();
    this.setState(() => ({
      errorMessage: null,
      successMessage: null,
      waringMessage: null,
    }));
  } /*end close msg*/

  renderTitle(key) {
    switch (key) {
      case 'ANALYTICS_REPORT_MODULE' : return 'ANALYTICS';
      case 'INSTA_SCRAPING_MODULE' : return 'インフルエンサー検索バッチ';
      case 'CHECK_ORDER_PENDING_CREATION_MODULE' : return  '制作開始状態確認';
      case 'SEND_REMIND_CREATE_DESIGN_EMAIL_MODULE' : return  'デザイン作成を促すメール送信';
      case 'SEND_MAGAZINE_EMAIL_MODULE': return '楽天レビュー促進【メルマガ配信】';
      case 'SYNC_COLORME_ORDER_MODULE': return 'ColorMeスケジュール同期';
      default : return ''
    }
  }

  /* == MAIN == */
  render() {
    return (
      <div className="container-fluid padding-0">
        <div className="row">
          <div className="col-md-12">
            {this.state.successMessage ? <Alert bsStyle="success">{this.state.successMessage}
              <button className="close" onClick={this.closeMessage}>
                <i className="fa fa-times" aria-hidden="true"/>
              </button>
            </Alert> : ""}
            {this.state.waringMessage ? <Alert bsStyle="warning">{this.state.waringMessage}
              <button className="close" onClick={this.closeMessage}>
                <i className="fa fa-times" aria-hidden="true"/>
              </button>
            </Alert> : ""}
            {this.state.errorMessage ? <Alert bsStyle="danger">{this.state.errorMessage}
              <button className="close" onClick={this.closeMessage}>
                <i className="fa fa-times" aria-hidden="true"/>
              </button>
            </Alert> : ""}
          </div>
        </div>
        {/*end message*/}
        <div className="row">
          <div className="col-md-12 tbl-admin tbl-template">
            <table className="table table-striped table-hover table-bordered table-layout-fixed">
              <thead>
              <tr>
                <th style={this.width_80}>モジュール名</th>
                <th style={this.width_20}>モジュールの状態</th>
              </tr>
              </thead>
              <tbody>
              {this.state.moduleSettings.map((setting, index) => {
                return (
                  <tr key={index}>
                    <td style={this.vertical_middle}>
                      { this.renderTitle(setting.settingKey)}
                    </td>
                    <td>
                      <div className="on-of-switch" style={this.div_center}>
                        <input type="checkbox"
                               defaultChecked={setting.status}
                               onChange={(event) => this.onUpdateModuleSettingStatus(setting, event)}
                               name="on-of-switch"
                               className="on-of-switch-checkbox"
                               id={setting.id + "my-on-off-switch"}/>
                        <label className="on-of-switch-label" htmlFor={setting.id + "my-on-off-switch"}>
                          <span className="on-of-switch-inner"/>
                          <span className="on-of-switch-switch"/>
                        </label>
                      </div>
                    </td>
                  </tr>
                )
              })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }/*end render*/
}

export default AuthenticatedComponent(SettingStatusModuleComponent);
