/**
 * Created by thangkc on 21/09/2016.
 */
import React from 'react';
import ImageLoader from 'react-imageloader';

export default class DesignImageFormat extends React.Component {
    constructor() {
        super();
    }

    createImageUrl(rowData) {
        let imageName = rowData.imageName.split('.');
        return "/api/image/" + rowData.orderNumber + "/" + rowData.imageFolder +  "/"
            + (rowData.imageResize ? imageName[0] + '_resize.' + imageName[1]  : rowData.imageName.replace('finish','finish_resize'))
    }

    createDeletedInfo(rowData) {
        if(rowData.product.isDeleted || rowData.product.status === '1')
            return (<span className="product-deleted-notification">本商品は販売を終了いたしました</span>);
        else return ""
    }

    createOutOfStockInfo(rowData) {
        if(rowData.product.quantityInStock === 0)
            return (<span className="info-notification">在庫切れ</span>);
        else return ""
    }

    preloader() {
        return <div className=" default-image-box"><span className="loading-spin-icon" ></span></div>;
    }

    createSideIcon(rowData, index) {
        if(rowData.product.numberOfSides === 1) return "";
        if(rowData.product.productType === "PAIR_PRODUCT"){
            if (index === 0) return <span className="front-design" style={{left: "64px"}}>商品１</span>
            else return <span className="back-design" style={{left: "64px"}}>商品２</span>
        }
        if (rowData.product.productType === 'SET_3'){
            switch (index) {
                case 0: return <span className="front-design" style={{left: "64px"}}>商品１</span>
                case 1: return <span className="back-design" style={{left: "64px"}}>商品２</span>
                default: return <span className="front-design" style={{left: "64px"}}>商品３</span>
            }
        }
        if(rowData.sideNumber === 1){
            return <span className="front-design">表</span>
        } else {
            return <span className="back-design">裏</span>
        }
    }

    render() {
        let classNameSeries = this.props.rowData.product.seichoku ? 'item-img-series' : '';
        return (
            <div className={this.props.className}>
                <ImageLoader
                    src={this.createImageUrl(this.props.rowData)}
                    wrapper={React.DOM.div}
                    preloader={this.preloader}
                    className={classNameSeries}>
                    Image load failed!
                </ImageLoader>
                {this.createSideIcon(this.props.rowData, this.props.index)}
                {this.createDeletedInfo(this.props.rowData)}
                {this.createOutOfStockInfo(this.props.rowData)}
            </div>
        );
    }
}
