import Dispatcher from "../../common/dispatcher/Dispatcher.jsx";
import FlyerCampaignConstant from "../constants/FlyerCampaignConstant.jsx";

export default {
  loadedAllFlyerCampaign(flyerCampaigns) {
    Dispatcher.dispatch({
      actionType: FlyerCampaignConstant.ACTION_TYPE.LOADED_ALL_FLYER_CAMPAIGN,
      allFlyerCampaign: flyerCampaigns
    })
  },
  loadedFlyerCampaign(flyerCampaign) {
    Dispatcher.dispatch({
      actionType: FlyerCampaignConstant.ACTION_TYPE.LOADED_FLYER_CAMPAIGN,
      flyerCampaign: flyerCampaign
    })
  },
  badRequest(message) {
    Dispatcher.dispatch({
      actionType: FlyerCampaignConstant.ACTION_TYPE.BAD_REQUEST,
      error: message
    })
  },
  error(message) {
    Dispatcher.dispatch({
      actionType: FlyerCampaignConstant.ACTION_TYPE.ERROR,
      error: message
    })
  }
}
