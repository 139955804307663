import React from "react";
import request from "reqwest";
import when from "when";
import moment from 'moment'
import LoginAction from "../../authentication/actions/LoginAction.jsx";
import OrderAction from "../actions/OrderAction.jsx";
import OrderConstant from "../constants/OrderConstant.jsx";
import Constants from "../../common/constants/Constants.jsx";
import AlertAction from "../../common/components/actions/AlertAction.jsx";
import {AlertConstant} from "../../common/constants/AlertConstant.jsx";
import LoadingAction from "../../common/components/actions/LoadingAction.jsx";


class OrderService {

  loadOrders(orderFilter) {
    var url = OrderConstant.API.GET_ORDER_URL + "?currentPage=" + encodeURIComponent(orderFilter.currentPage)
        + "&pageSize=" + encodeURIComponent(orderFilter.pageSize);
    if (orderFilter.status && orderFilter.status !== OrderConstant.STATUS.ALL) {
      url += "&status=" + encodeURIComponent(orderFilter.status);
    }
    if (orderFilter.keyword.trim()) {
      url += "&keyword=" + encodeURIComponent(orderFilter.keyword.trim());
    }
    if (orderFilter.processMethod.trim()) {
      url += "&processMethod=" + encodeURIComponent(orderFilter.processMethod.trim());
    }
    when(request({
      url: url,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': "nocheck",
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      }
    })).then(function (response) {
      switch (response.code) {
        case 200:
          OrderAction.loadedOrders(response.data, orderFilter);
          break;
        case 401:
          LoginAction.loginUser({});
          break;
        case 400:
          break;
        default:
          break;
      }
    });
  }

  getOrder(orderNumber) {
    LoadingAction.startLoading()
    OrderAction.showOrderDetail();
    when(request({
      url: OrderConstant.API.DETAIL_URL + "?orderNumber=" + orderNumber,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': "nocheck",
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
      type: 'json'
    })).then(function (response) {
      LoadingAction.stopLoading()
      switch (response.code) {
        case 200:
          OrderAction.detail(response.data);
          break;
        case 401:
          LoginAction.loginUser({});
          AlertAction.alert(AlertConstant.DANGER, "Unauthorized")
          break;
        case 400:
          AlertAction.alert(AlertConstant.DANGER, "Something went wrong, try again later!")
          OrderAction.detail({
            status: "",
            orderer: {},
            receiver: {},
            createdUser: {},
            products: [],
            designs: [],
            invoiceCreateable: false,
            httpCode: {
              changeStatusCode: 200
            },
            messages: {
              changeStatusMessage: ""
            }
          })
          break;
        default:
          break;
      }
    })
  }

  getInvoiceLog(orderNumber) {
    when(request({
      url: OrderConstant.API.INVOICE_LOG.format(orderNumber),
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': "nocheck",
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
      type: 'json'
    })).then(function (response) {
      switch (response.code) {
        case 200:
          OrderAction.invoiceLog(response.data);
          break;
        case 401:
          LoginAction.loginUser({});
          break;
        case 400:
          break;
        default:
          break;
      }
    })
  }

  hideOrderDetail() {
    OrderAction.hideOrderDetail();
  }

  changeOrderStatus(orderNumber, status) {
    var url = "";

    switch (status) {
      case OrderConstant.STATUS.CANCEL:
        url = OrderConstant.API.CANCEL_ORDER_URL.format(orderNumber, 1)
        break;
      case OrderConstant.STATUS.CANCEL_BY_GUEST:
        //「お客様都合による」は「1: キャンセル」
        url = OrderConstant.API.CANCEL_ORDER_URL.format(orderNumber, 1)
        break;
      case OrderConstant.STATUS.CANCEL_BY_SHOP:
        //「店舗都合による」は「10: その他」
        url = OrderConstant.API.CANCEL_ORDER_URL.format(orderNumber, 10)
        break;
      default:
        url = OrderConstant.API.CHANGE_STATUS_URL.format(orderNumber, status)
        break;
    }

    when(request({
          url: url,
          method: 'PUT',
          crossOrigin: true,
          headers: {
            'Csrf-Token': "nocheck",
            'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
          },
          type: 'json'
        })
    ).then(function (response) {
      switch (response.code) {
        case 200:
          OrderAction.changeOrderStatus(response.code, response.message, response.data);
          break;
        case 401:
          LoginAction.loginUser({});
          break;
        default:
          OrderAction.rollbackChangeOrderStatus(response.code, "ECサイトステータスを変更に失敗しました。", response.errorCode);
          break;
      }
    });
  }

  changeEcOrderStatus(orderNumber, status) {
    when(request({
          url: OrderConstant.API.CHANGE_STATUS_URL.format(orderNumber, status),
          method: 'PUT',
          crossOrigin: true,
          headers: {
            'Csrf-Token': "nocheck",
            'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
          },
          type: 'json'
        })
    ).then(function (response) {
      switch (response.code) {
        case 200:
          OrderAction.changeEcOrderStatus(response.code, response.message, response.data);
          break;
        case 401:
          LoginAction.loginUser({});
          break;
        default:
          OrderAction.rollbackChangeOrderStatus(response.code, "ECサイトステータスを変更に失敗しました。", response.errorCode);
          break;
      }
    })
  }

  synchronizeOrder(orderNumber) {
    when(request({
      url: OrderConstant.API.SYNCHRONIZE_URL.format(orderNumber),
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': "nocheck",
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
      type: 'json'
    })).then(function (response) {
      switch (response.code) {
        case 200:
          OrderAction.synchronizeOrder(response.data);
          break;
        default:
          OrderAction.rollbackOrderSynchronization(response.code);
          break;
      }
    }).catch(function () {
    });
  }

  createImageMagicOrder(orderNumber, updatedAt, sendmail = true) {
    when(request({
      url: OrderConstant.API.CREATE_IMAGEMAGIC_ORDER.format(orderNumber, updatedAt, sendmail),
      method: 'POST',
      crossOrigin: true,
      headers: {
        'Csrf-Token': "nocheck",
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
      type: 'json'
    })).then(function (response) {
      OrderAction.createImageMagicOrder(response.code, response.errorCode, response.data);
    }).catch(function () {

    });
  }

  verifyOrderPayment(orderNumber) {
    when(request({
      url: OrderConstant.API.VERIFY_ORDER_PAYMENT.format(orderNumber),
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': "nocheck",
        "Authorization": localStorage.getItem('token')
      },
      type: 'json'
    })).then(function (response) {
      switch (response.code) {
        case 200:
          OrderAction.verifyOrderPayment(response.code, response.data.paymentStatus);
          break;
        case 500:
          OrderAction.verifyOrderPayment(response.code, null);
          break;
        default:
          OrderAction.verifyOrderPayment(response.code, null);
          break;
      }
    }).catch(function () {

    });
  }

  createDownloadReportLink(orderFilter) {
    if (((!orderFilter.minOrderDate || !orderFilter.maxOrderDate) && (!orderFilter.minShipDate || !orderFilter.maxShipDate)) ||
        !orderFilter.status || orderFilter.status.length === 0 || !orderFilter.ecSiteIds || orderFilter.ecSiteIds.length === 0) {
      throw "Illegal argument exception";
    }
    var url = OrderConstant.API.EXPORT_ORDER_REPORT + '?';
    var parameters = Array();
    if (!!orderFilter.minOrderDate && !!orderFilter.maxOrderDate) {
      parameters.push("minOrderDate=" + encodeURIComponent(orderFilter.minOrderDate) + "&maxOrderDate=" + encodeURIComponent(orderFilter.maxOrderDate));
    }
    if (!!orderFilter.minShipDate && !!orderFilter.maxShipDate) {
      parameters.push("minShipDate=" + encodeURIComponent(orderFilter.minShipDate) + "&maxShipDate=" + encodeURIComponent(orderFilter.maxShipDate));
    }
    for (let index = 0; index < orderFilter.status.length; index++) {
      parameters.push("orderStatus=" + encodeURIComponent(orderFilter.status[index]));
    }
    for (let index = 0; index < orderFilter.ecSiteIds.length; index++) {
      parameters.push("ecSiteIds=" + encodeURIComponent(orderFilter.ecSiteIds[index]));
    }
    return url + parameters.join('&');
  }

  switchInvoiceStatus(idString, status) {
    when(request({
      url: OrderConstant.API.UPDATE_INVOICE_PUBLISH_STATUS.format(idString, status),
      method: 'PUT',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      }
    })).then(function (response) {
      switch (response.code) {
        case Constants.RESPONSE_STATUS.SUCCESS:
          OrderAction.changeInvoiceCreatableSuccess()
          break;
        case Constants.RESPONSE_STATUS.UNAUTHORIZED:
          LoginAction.loginUser({});
          break;
        case Constants.RESPONSE_STATUS.BAD_REQUEST:
          break;
        default:
          break;
      }
    })
  }

  switchInvoiceStatusOrderDetail(idString, status, orderFilter) {
    let self = this;
    when(request({
      url: OrderConstant.API.UPDATE_INVOICE_PUBLISH_STATUS.format(idString, status),
      method: 'PUT',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      }
    })).then(function (response) {
      switch (response.code) {
        case Constants.RESPONSE_STATUS.SUCCESS:
          self.loadOrders(orderFilter)
          self.getOrder(idString)
          break;
        case Constants.RESPONSE_STATUS.UNAUTHORIZED:
          LoginAction.loginUser({});
          break;
        case Constants.RESPONSE_STATUS.BAD_REQUEST:
          break;
        default:
          break;
      }
    })
  }

  updateShipInfo(shipInfo, packageId, sendMail) {
    when(request({
      url: OrderConstant.API.UPDATE_SHIP_INFO.format(sendMail),
      method: 'PUT',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
      type: 'json',
      data: {
        packageId: packageId,
        slipNo: shipInfo.slipNo,
        shipDate: moment(shipInfo.shipDate).format('YYYY-MM-DD'),
      }
    })).then(function (response) {
      switch (response.code) {
        case 200:
          OrderAction.updateShipInfo(response.code, response.message, response.data);
          break;
        case 401:
          LoginAction.loginUser({});
          break;
        default:
          break;
      }
    })
  }

}

export default new OrderService();
