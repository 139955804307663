/**
 * Created by thangkc on 20/09/2016.
 */
import React from 'react';
import OrderStore from "../../stores/OrderStore.jsx"
import StatusComponent from "./status/StatusComponent.jsx"
import OrdererComponent from "./OrdererComponent.jsx"
import ReceiverComponent from "./ReceiverComponent.jsx"
import ProductInfoComponent from "./ProductInfoComponent.jsx"
import DesignInfoComponent from "./DesignInfoComponent.jsx"
import ShipInfoComponent from "./ShipInfoComponent.jsx"
import OrderLogInfoComponent from "./OrderLogInfoComponent.jsx"
import OrderService from "../../services/OrderService.jsx"
import BillComponent from "./BillComponent.jsx"
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';

export default class OrderDetailComponent extends React.Component {
    constructor() {
        super();
        this.state = this._getState();
        this._onChange = this._onChange.bind(this);
    }

    _onChange() {
        this.setState(this._getState());
    }

    _getState() {
        return {
            order: OrderStore.getOrderDetail(),
            invoiceLog: OrderStore.getInvoiceLog(),
            showModal: OrderStore.getViewDetail(),
        };
    }

    componentDidMount() {
        OrderStore.addChangeListener(this._onChange);
    }

    componentWillUnmount() {
        OrderStore.removeChangeListener(this._onChange);
    }

    close() {
        OrderService.hideOrderDetail();
    }

    render() {
        const { showModal, order, invoiceLog, isLoading } = this.state
        return (
            <div>
                <Modal show={showModal} onHide={this.close.bind(this)} bsSize="large" className="order-setting" dialogClassName="order-detail">
                    <Modal.Header closeButton>
                        <h2 className="modal-title">詳細注文情報</h2>
                    </Modal.Header>

                    <Modal.Body>
                        <StatusComponent order={order}/>

                        <div className="row">
                            <OrdererComponent orderer={order.orderer} payMethod={order.payMethod}/>

                            <ReceiverComponent receiver={order.receiver}/>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th>備考欄</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td height="74px">{order.comment}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="col-md-6 lo">
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th colSpan="2">お届け内容</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th width="190px">お届け希望日</th>
                                        <td>{order.wishDeliveryDate}</td>
                                    </tr>
                                    <tr>
                                        <th>お届け時間帯</th>
                                        <td>{order.shippingTerm}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <ProductInfoComponent products={order.products}/>

                        <BillComponent order={order}/>

                        <hr className="line"/>

                        <DesignInfoComponent order={order}/>

                        <div className="row">
                            <div className="col-md-6">
                                <h4 className="title pull-left">出荷情報</h4>
                                { (order.packages) ? order.packages.map((item, index) => {
                                    item.isUseSagawa = order.isUseSagawa;
                                    return <ShipInfoComponent key={item.id} orderPackage={item} order={order} />
                                }) : ""}
                            </div>
                            <OrderLogInfoComponent order={order} invoiceLog={invoiceLog}/>
                        </div>

                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={this.close.bind(this)} className="btn-raised">閉じる</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
