import React from 'react';
import request from "reqwest";
import when from "when";
import YahooConstants from '../constants/YahooConstants.jsx';
import YahooAction from '../actions/YahooAction.jsx';

class YahooService {

    getUserInfo(){
        when(request({
            url: YahooConstants.API.GET_USER_INFO,
            method: 'GET',
            crossOrigin: true,
            headers: {
                'Csrf-Token': "nocheck",
                'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
            },
            type: 'json'
        })).then(function (response) {
            switch (response.code){
                case 200:
                    YahooAction.getUserInfo(response.data.userInfo, response.data.yconnectInfo);
                    break;
                default:
                    YahooAction.getUserInfo(null);
                    break;
            }
        }).catch(function(){
            YahooAction.getUserInfo(null);
        });
    }
}

export default new YahooService()
