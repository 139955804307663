import React from "react"
import DateTimeFormat from "../../../common/components/DateTimeFormat.jsx";
import FailedChangeStatusOrderAction from "../../actions/FailedChangeStatusOrderAction.jsx";
import OrderStatusFormat from "../../../common/components/order/OrderStatusFormat.jsx";

class FailedChangeStatusOrderRow extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { order } = this.props
        return (
            <tr>
                <td style={{ textAlign: "center" }}>
                    <input
                        type="checkbox"
                        checked={order.checked}
                        onChange={(e) => FailedChangeStatusOrderAction.checkOrder({
                            ...order,
                            checked: e.target.checked
                        })}
                    />
                </td>
                <td>{order.orderNumber}</td>
                <td><OrderStatusFormat data={order.status}/></td>
                <td><DateTimeFormat data={order.orderDate}/></td>
            </tr>
        );
    }
}

export default FailedChangeStatusOrderRow