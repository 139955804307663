import React from 'react'

import TheatreCommonComponent from '../TheatreCommonComponent.jsx'

export default class Theatre1905Component extends React.Component {

  render () {
    return (
      <TheatreCommonComponent
        {...this.props}
      />
      )
  }
}