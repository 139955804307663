import BaseStore from "../../common/stores/BaseStore.jsx";
import MagazineMailConstant from "../constants/MagazineMailConstants.jsx";
import MagazineMailService from "../services/MagazineMailService.jsx";

class ReceiverStore extends BaseStore {
  constructor() {
    super();
    this.unsubscribedReceiversDTO = {
      currentPage: 1,
      maxPage: 0,
      guests: []
    };
    this.receiverFilter = {};
    this.resetReceiverFilter();
    this.error = null;
    this.subscribe(() => this.handler.bind(this));
  }

  handler(action) {
    switch (action.actionType) {
      case MagazineMailConstant.ACTION_TYPE.LOADED_ALL_UNSUBSCRIBE_RECEIVER:
        this.unsubscribedReceiversDTO = action.unsubscribedReceiversDTO;
        this.receiverFilter = action.receiverFilter;
        this.error = null;
        this.emitChange();
        break;
      case MagazineMailConstant.ACTION_TYPE.ON_CHANGE_KEYWORD:
        this.receiverFilter.currentPage = 1;
        this.receiverFilter.keyword = action.keyword;
        MagazineMailService.getAllUnsubscribeReceiver(this.receiverFilter);
        break;
      case MagazineMailConstant.ACTION_TYPE.ON_SEARCH_PAGE:
        this.receiverFilter.currentPage = action.currentPage;
        MagazineMailService.getAllUnsubscribeReceiver(this.receiverFilter);
        break;
      case MagazineMailConstant.ACTION_TYPE.BAD_REQUEST:
        this.error = action.error
        this.emitChange();
        break;
      case MagazineMailConstant.ACTION_TYPE.ERROR:
        this.error = action.error
        this.emitChange();
        break;
    }
  }

  getUnsubscribedReceiversDTO() {
    return this.unsubscribedReceiversDTO
  }

  getReceiverFilter() {
    return this.receiverFilter;
  }

  resetReceiverFilter() {
    this.receiverFilter = {
      currentPage: 1,
      pageSize: 10,
      keyword: ''
    }
  }

  getError() {
    return this.error
  }

}

const receiverStore = new ReceiverStore();

export default receiverStore;
