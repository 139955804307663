import Dispatcher from "../../common/dispatcher/Dispatcher.jsx";
import EpsilonDataTrackingConstants from "../constants/EpsilonDataTrackingConstants.jsx";

export default {
    fetchOrders: () => {
        Dispatcher.dispatch({
            actionType: EpsilonDataTrackingConstants.FETCH_ORDERS
        })
    },
    fetchedOrders: (ordersDTO, filter) => {
        Dispatcher.dispatch({
            actionType: EpsilonDataTrackingConstants.FETCHED_ORDERS,
            ordersDTO: ordersDTO,
            filter: filter,
        })
    },
    changeFilter: (filter) => {
        Dispatcher.dispatch({
            actionType: EpsilonDataTrackingConstants.CHANGE_FILTER,
            filter: {
                currentPage: filter.page,
                keyword: filter.keyword
            }
        })
    },
    checkOrder: (selectedOrder) => {
        Dispatcher.dispatch({
            actionType: EpsilonDataTrackingConstants.CHECK_ORDER,
            selectedOrder: selectedOrder
        })
    },
    checkAllOrder: (orders) => {
        Dispatcher.dispatch({
            actionType: EpsilonDataTrackingConstants.CHECK_ALL_ORDER,
            orders: orders
        })
    },
    startEpsilonLinkData: () => {
        Dispatcher.dispatch({
            actionType: EpsilonDataTrackingConstants.START_EPSILON_LINK_DATA
        })
    },
    resetFilter: () => {
        Dispatcher.dispatch({
            actionType: EpsilonDataTrackingConstants.RESET_FILTER
        })
    }
}