import React from 'react'
import moment from 'moment'
import AuthenticatedComponent from '../../common/components/AuthenticatedComponent.jsx'
import ReportConstant from '../constants/ReportConstant.jsx'

import ProductTemplateMainReportComponent from './productAndTemplate/ProductTemplateMainReportComponent.jsx'
import RepeatOrderReportComponent from './repeartOrder/RepeatOrderReportComponent.jsx'
import ProductPackagePlanReportComponent from './productPackagePlan/ProductPackagePlanReportComponent.jsx'
import GroupProductOrderComponent from "./groupProduct/groupProductOrder/GroupProductOrderComponent.jsx";
import CustomProductOrderComponent from "./groupProduct/customGroupProductOrder/CustomProductOrderComponent.jsx";
import ReportProductByOrderStatusComponent from "./reportProductByOrderDate/ReportProductByOrderStatusComponent.jsx";
import ChildrenAgeGroupReportComponent from "./childrenAgeGroup/ChildrenAgeGroupReportComponent.jsx";
import EpsilonDataTracking from "./epsilonDataTracking/index.jsx";
import FailedChangeStatusOrder from "./failedChangeStatusOrder/MainFailedChangeStatusOrder.jsx"

class MainReportComponent extends React.Component {
  constructor () {
    super()
    this.state = this._getState()
    this._onChange = this._onChange.bind(this)

    moment.locale('ja')
  }

  _changeTab (tabName) {
    this.setState({activeTab: tabName})
  }

  _onChange () {
    this.setState(this._getState())
  }

  _getState () {
    return {
      activeTab: ReportConstant.TAB_NAME.REPEAT_ORDER,
    }
  }

  renderActiveTabClass(tabName){
    return this.state.activeTab === tabName ? 'active' : ''
  }

  renderTabComponent(){
    switch (this.state.activeTab) {
      case ReportConstant.TAB_NAME.PRODUCT_TEMPLATE :
        return <ProductTemplateMainReportComponent/>;
      case ReportConstant.TAB_NAME.REPEAT_ORDER :
        return <RepeatOrderReportComponent/>;
      case ReportConstant.TAB_NAME.PRODUCT_PACKAGE_PLAN :
        return <ProductPackagePlanReportComponent/>;
      case ReportConstant.TAB_NAME.GROUP_PRODUCT_ORDER :
        return <GroupProductOrderComponent/>;
      case ReportConstant.TAB_NAME.CUSTOM_GROUP_PRODUCT_ORDER :
        return <CustomProductOrderComponent/>;
      case ReportConstant.TAB_NAME.PRODUCT_REPORT_BY_ORDER_STATUS:
        return <ReportProductByOrderStatusComponent />;
      case ReportConstant.TAB_NAME.CHILDREN_AGE_GROUP_REPORT:
        return <ChildrenAgeGroupReportComponent />;
      case ReportConstant.TAB_NAME.EPSILON_DATA_TRACKING:
        return <EpsilonDataTracking />;
      case ReportConstant.TAB_NAME.FAILED_CHANGE_STATUS_ORDER:
        return <FailedChangeStatusOrder />
    }
  }

  render () {


    return (
      <section>
        <div className="row">
          <ul className="nav nav-tabs report-nav">
            <li className={this.renderActiveTabClass(ReportConstant.TAB_NAME.PRODUCT_TEMPLATE)}
                onClick={this._changeTab.bind(this,
                    ReportConstant.TAB_NAME.PRODUCT_TEMPLATE)}>商品・テンプレート
            </li>
            <li className={this.renderActiveTabClass(ReportConstant.TAB_NAME.REPEAT_ORDER)}
                onClick={this._changeTab.bind(this,
                    ReportConstant.TAB_NAME.REPEAT_ORDER)}>リピート注文
            </li>
            <li className={this.renderActiveTabClass(ReportConstant.TAB_NAME.PRODUCT_PACKAGE_PLAN)}
                onClick={this._changeTab.bind(this,
                    ReportConstant.TAB_NAME.PRODUCT_PACKAGE_PLAN)}>梱包
            </li>
            <li className={this.renderActiveTabClass(ReportConstant.TAB_NAME.GROUP_PRODUCT_ORDER)}
                onClick={this._changeTab.bind(this,
                    ReportConstant.TAB_NAME.GROUP_PRODUCT_ORDER)}>商品組み合わせ
            </li>
            <li className={this.renderActiveTabClass(ReportConstant.TAB_NAME.PRODUCT_REPORT_BY_ORDER_STATUS)}
                onClick={this._changeTab.bind(this,
                    ReportConstant.TAB_NAME.PRODUCT_REPORT_BY_ORDER_STATUS)}>商品情報
            </li>
            <li className={this.renderActiveTabClass(ReportConstant.TAB_NAME.CHILDREN_AGE_GROUP_REPORT)}
                onClick={this._changeTab.bind(this,
                    ReportConstant.TAB_NAME.CHILDREN_AGE_GROUP_REPORT)}>購入者のお子様年齢
            </li>
            <li className={this.renderActiveTabClass(ReportConstant.TAB_NAME.EPSILON_DATA_TRACKING)}
                onClick={this._changeTab.bind(this,
                    ReportConstant.TAB_NAME.EPSILON_DATA_TRACKING)}>イプシロン連携監視
            </li>
            <li className={this.renderActiveTabClass(ReportConstant.TAB_NAME.FAILED_CHANGE_STATUS_ORDER)}
                onClick={this._changeTab.bind(this,
                    ReportConstant.TAB_NAME.FAILED_CHANGE_STATUS_ORDER)}>注文状態変更失敗
            </li>
          </ul>
        </div>
        <div className="row flex-xl-nowrap">
          {this.renderTabComponent()}
        </div>
      </section>
    )
  }
}

export default AuthenticatedComponent(MainReportComponent)
