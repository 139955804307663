const FailedChangeStatusOrderConstants = {
    FETCH_ORDERS: 'FETCH_FAILED_CHANGE_STATUS_ORDERS',
    FETCHED_ORDERS: 'FETCHED_FAILED_CHANGE_STATUS_ORDERS',
    CHANGE_FILTER: 'CHANGE_FAILED_CHANGE_STATUS_ORDER_FILTER',
    CHECK_ORDER: 'CHECK_FAILED_CHANGE_STATUS_ORDER',
    CHECK_ALL_ORDER: 'CHECK_ALL_FAILED_CHANGE_STATUS_ORDER',
    START_CHANGE_ORDER_STATUS: 'START_CHANGE_ORDER_STATUS',
    API: {
        GET_ORDER_URL: "/api/reports/failed-change-shipped-status-orders",
        BULK_CHANGE_ORDER_STATUS: "/api/reports/bulk/update/failed-change-shipped-status-orders"
    }
}

export default FailedChangeStatusOrderConstants