'use strict';

var ToObject = require('es-abstract/2019/ToObject');
var ToLength = require('es-abstract/2019/ToLength');
var IsCallable = require('es-abstract/2019/IsCallable');

module.exports = function find(predicate) {
	var list = ToObject(this);
	var length = ToLength(list.length);
	if (!IsCallable(predicate)) {
		throw new TypeError('Array#find: predicate must be a function');
	}
	if (length === 0) {
		return void 0;
	}
	var thisArg;
	if (arguments.length > 0) {
		thisArg = arguments[1];
	}

	for (var i = 0, value; i < length; i++) {
		value = list[i];
		// inlined for performance: if (Call(predicate, thisArg, [value, i, list])) {
		if (predicate.apply(thisArg, [value, i, list])) {
			return value;
		}
	}
	return void 0;
};
