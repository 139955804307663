import React from 'react'
import AuthenticatedComponent from '../../../common/components/AuthenticatedComponent.jsx'
import TheatreCommonTableRow from './TheatreCommonTableRow.jsx'

export default class TheatreCommonTable extends React.Component {
  constructor () {
    super()
  }

  render () {
    return (
      <div className="tbl-admin tbl-template">
        <table
          className="table table-striped table-hover table-bordered table-list">
          <thead>
          <tr>
            <th style={{width: '60px'}}>ID</th>
            <th>氏名</th>
            <th>氏名（ふりがな）</th>
            <th style={{width: '70px'}}>性別</th>
            <th>生年月日</th>
            <th>郵便番号</th>
            <th>ご住所</th>
            <th>電話番号</th>
            <th className="mailCol">メールアドレス</th>
            <th>Instagramのアカウント名</th>
            <th>テアトルアカデミーに所属していますか？</th>
            <th>ベビフルでのモデルデビューに興味はありますか？</th>
          </tr>
          </thead>
          <tbody>
          {this.campaignRows}
          </tbody>
        </table>
      </div>
    )
  }

  get campaignRows () {
    let campaigns = this.props.campaigns
    if (campaigns.length > 0)
      return (campaigns.map(function (campaign) {
        return <TheatreCommonTableRow campaign={campaign} key={campaign.id}/>
      }))
    else
      return <tr>
        <td colSpan="12">{<span>  ０件 </span>}</td>
      </tr>
  }
}

// export default AuthenticatedComponent(TheatreCommonTable)
