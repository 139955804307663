/**
 * Created by PhuNH on 16/12/24.
 */
import React from 'react'
import { PropTypes, Link, IndexLink } from 'react-router'

class NavItem extends React.Component {
    render() {
        const { index, to, children, ...props } = this.props;
        const isActive = this.context.history.isActive(to);
        const LinkComponent = index ? IndexLink : Link;

        return (
            <li className={isActive ? 'active' : ''}>
                <LinkComponent to={to} {...props}>{children}</LinkComponent>
            </li>
        )
    }
}
NavItem.contextTypes = { history: PropTypes.history };

export default NavItem
