import React from 'react'
import AuthenticatedComponent from '../../../common/components/AuthenticatedComponent.jsx'
import StudioAliceBaby1812TableRow from './StudioAliceBaby1812TableRow.jsx'

class StudioAliceBaby1812Table extends React.Component {
  constructor () {
    super()
  }

  render () {
    return (
      <div className="tbl-admin tbl-template">
        <table
          className="table table-striped table-hover table-bordered table-list">
          <thead>
          <tr>
            <th style={{width: '60px'}}>ID</th>
            <th>メールアドレス</th>
            <th>生年月日</th>
            <th>撮影希望店舗</th>
            <th>Instagramのアカウント名</th>
          </tr>
          </thead>
          <tbody>
          {this.campaignRows}
          </tbody>
        </table>
      </div>
    )
  }

  get campaignRows () {
    let campaigns = this.props.campaigns
    if (campaigns.length > 0)
      return (campaigns.map(function (campaign) {
        return <StudioAliceBaby1812TableRow campaign={campaign} key={campaign.id}/>
      }))
    else
      return <tr>
        <td colSpan="5">{<span>  ０件 </span>}</td>
      </tr>
  }
}

export default AuthenticatedComponent(StudioAliceBaby1812Table)
