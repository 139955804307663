import BaseStore from "../../common/stores/BaseStore.jsx";
import FailedChangeStatusOrderConstants from "../constants/FailedChangeStatusOrderConstants.jsx";
import FailedChangeStatusOrderService from "../services/FailedChangeStatusOrderService.jsx";

class FailedChangeStatusOrderStore extends BaseStore {
    constructor() {
        super();
        this.subscribe(() => this.handler.bind(this));
        this.ordersDTO = {
            currentPage: 1,
            maxPage: 0,
            orders: []
        };
        this.filter = {
            currentPage: 1,
            pageSize: 5
        };
    }

    handler(action) {
        switch (action.actionType) {
            case FailedChangeStatusOrderConstants.CHANGE_FILTER:
                this.filter.currentPage = action.filter.currentPage || 1
                FailedChangeStatusOrderService.fetchOrders(this.filter)
                this.emitChange();
                break;
            case FailedChangeStatusOrderConstants.FETCH_ORDERS:
                this.filter.currentPage = 1
                FailedChangeStatusOrderService.fetchOrders(this.filter)
                this.emitChange();
                break;
            case FailedChangeStatusOrderConstants.FETCHED_ORDERS:
                this.ordersDTO = action.ordersDTO
                this.filter = action.filter
                this.emitChange();
                break;
            case FailedChangeStatusOrderConstants.CHECK_ORDER:
                this.ordersDTO.orders = this.ordersDTO.orders.map((order) => {
                    if (order.orderNumber === action.selectedOrder.orderNumber) {
                        return action.selectedOrder;
                    }
                    return order
                })
                this.emitChange();
                break;
            case FailedChangeStatusOrderConstants.CHECK_ALL_ORDER:
                this.ordersDTO.orders = action.orders
                this.emitChange();
                break;
            case FailedChangeStatusOrderConstants.START_CHANGE_ORDER_STATUS:
                const ordersData = []
                this.ordersDTO.orders
                    .filter((order) => order.checked)
                    .map((order) => {
                        ordersData.push({
                            orderNumber: order.orderNumber,
                            status: order.status,
                            payMethod: order.payMethod,
                            payMethodId: order.payMethodId
                        })
                    })
                FailedChangeStatusOrderService.changeOrderStatus(ordersData)
                this.emitChange();
                break;
        }
    }

    getOrdersDTO() {
        return this.ordersDTO
    }

    getFilter() {
        return this.filter;
    }
}

const failedChangeStatusOrderStore = new FailedChangeStatusOrderStore()

export default failedChangeStatusOrderStore