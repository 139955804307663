import React from 'react';
import OrderConstant from '../constants/OrderConstant.jsx';
import OrderAction from '../actions/OrderAction.jsx';
import Button from 'react-bootstrap/lib/Button';

export default class OrderStatusBarComponent extends React.Component {
    constructor() {
        super();
        this.renderStatusBar = this.renderStatusBar.bind(this);
        this.getStatusBarInfo = this.getStatusBarInfo.bind(this);
        this.componentStyle = {
            marginTop: '10px',
            overflow: 'hidden'
        };
        this.statusUlStyle = {
            float: 'left'
        };
        this.exportButtonStyle = {
            float: 'right',
            margin: 0
        };
    }

    filterByStatus(status) {
        if (status === OrderConstant.STATUS.SHIPPED_DM) {
            OrderAction.changeStatus(OrderConstant.STATUS.SHIPPED, 'digital_message');
        } else if (status === OrderConstant.STATUS.SHIPPED) {
            OrderAction.changeStatus(OrderConstant.STATUS.SHIPPED, 'normal');
        } else OrderAction.changeStatus(status, '');
    }

    render() {
        return (
            <div style={this.componentStyle}>
                <ul className="nav nav-pills" style={this.statusUlStyle}>
                    {this.renderStatusBar()}
                </ul>
                <Button onClick={this.props.openExportOrderReportDialog} bsStyle="default"
                        className="btn-raised btn-sm btn-info" style={this.exportButtonStyle}>エクスポート</Button>
            </div>
        )
    }

    renderStatusBar() {
        let self = this;

        let orderStatusObject = Object.assign({}, OrderConstant.STATUS);
        delete orderStatusObject[OrderConstant.STATUS.CANCEL_BY_SHOP];
        delete orderStatusObject[OrderConstant.STATUS.CANCEL_BY_GUEST];

        return Object.keys(orderStatusObject).map(function (key) {
            let status = OrderConstant.STATUS[key];
            let localStatus = self.props.orderFilter.status;
            if (self.props.orderFilter.status === OrderConstant.STATUS.SHIPPED
                && self.props.orderFilter.processMethod === 'digital_message') localStatus = OrderConstant.STATUS.SHIPPED_DM
            let statusBarInfo = self.getStatusBarInfo(status);
            return (
                <li key={key} className={status === localStatus ? "active" : ""}>
                    <a onClick={self.filterByStatus.bind(self, status)}>
                        {statusBarInfo.text}
                        <span className="text-warning"> {statusBarInfo.numberOfOrders ? statusBarInfo.numberOfOrders : 0}件</span>
                    </a>
                </li>
            )
        })
    }

    getStatusBarInfo(status) {
        if (!!this.props.ordersDTO[status]) {
            switch (status) {
                case OrderConstant.STATUS.SHIPPED:
                    return {
                        numberOfOrders: this.props.ordersDTO[status][0].numberOfOrder,
                        text: OrderConstant.STATUS_JP[status.toUpperCase()]
                    };
                case OrderConstant.STATUS.ALL:
                    return {
                        numberOfOrders: this.props.ordersDTO[status],
                        text: OrderConstant.STATUS_JP[status.toUpperCase()]
                    };
                default:
                    if (!!this.props.ordersDTO[status][1]) {
                        return {
                            numberOfOrders: this.props.ordersDTO[status][0].numberOfOrder + this.props.ordersDTO[status][1].numberOfOrder,
                            text: OrderConstant.STATUS_JP[status.toUpperCase()]
                        };
                    } else {
                        return {
                            numberOfOrders: this.props.ordersDTO[status][0].numberOfOrder,
                            text: OrderConstant.STATUS_JP[status.toUpperCase()]
                        };
                    }

            }
        } else if (status === OrderConstant.STATUS.SHIPPED_DM
            && !!this.props.ordersDTO[OrderConstant.STATUS.SHIPPED]) {
            return {
                numberOfOrders: this.props.ordersDTO[OrderConstant.STATUS.SHIPPED][1] ? this.props.ordersDTO[OrderConstant.STATUS.SHIPPED][1].numberOfOrder : 0,
                text: OrderConstant.STATUS_JP[status.toUpperCase()]
            };
        } else {
            return {
                numberOfOrders: this.props.ordersDTO[status],
                text: OrderConstant.STATUS_JP[status.toUpperCase()]
            };
        }
    }
}
