import React from 'react'
import AuthenticatedComponent from '../../../common/components/AuthenticatedComponent.jsx'
import momentPropTypes from "react-moment-proptypes";
import Select from "react-select";
import {DateRangePicker} from "react-dates";
import moment from 'moment';
import LoadingButton from '../../../common/components/LoadingButton.jsx';
import ReportService from '../../services/ReportService.jsx';
import ProductPackagePlanReportStore from '../../stores/ProductPackagePlanReportStore.jsx';
import {BootstrapTable, SizePerPageDropDown, TableHeaderColumn} from "react-bootstrap-table";
import {OverlayTrigger, Popover} from "react-bootstrap";

class ProductPackagePlanReportComponent extends React.Component {
    constructor(props) {
        super(props);
        this.dateTypes = [
            {value: 'SEND_IM_DATE', label: '制作開始日'},
            {value: 'EX_SHIP_DATE', label: '出荷予定日'},
            {value: 'SHIP_DATE', label: '出荷日'},
        ];

        this.reportTypes = [
            {value: 'DAY', label: '日'},
            {value: 'MONTH', label: '月'},
        ]

        this.state = this._getInitialState();
        this._onDateTypeSelect = this._onDateTypeSelect.bind(this);
        this._onDateChange = this._onDateChange.bind(this);
        this._onFocusChange = this._onFocusChange.bind(this);
        this._onChange = this._onChange.bind(this);
        this.searchProductPackageReport = this.searchProductPackageReport.bind(this);
        this.renderSizePerPageDropDown = this.renderSizePerPageDropDown.bind(this);
        this.onToggleDropDown = this.onToggleDropDown.bind(this);
        this.handleGettingReportClick = this.handleGettingReportClick.bind(this);
        this.createDownloadReportLink = this.createDownloadReportLink.bind(this);
        this.downloadReport = this.downloadReport.bind(this);
        this._onReportTypeSelect = this._onReportTypeSelect.bind(this)
        moment.locale('ja');
    }

    componentDidMount() {
        this.handleGettingReportClick();
        ProductPackagePlanReportStore.addChangeListener(this._onChange)
    }

    componentWillUnmount() {
        ProductPackagePlanReportStore.removeChangeListener(this._onChange)
    }

    _onChange() {
        this.setState(this._getState())
    }

    _onDateTypeSelect(selectedDateType) {
        this.setState({selectedDateType: selectedDateType.value})
    }

    _onDateChange({startDate, endDate}) {
        this.setState({selectedStartDate: startDate, selectedEndDate: endDate})
    }

    _onFocusChange(focusedInput) {
        this.setState({focusedInput: focusedInput})
    }

    _onReportTypeSelect (selectedType) {
        this.setState({selectedReportType: selectedType.value})
    }

    _getInitialState() {
        return {
            selectedDateType: 'SEND_IM_DATE',
            loading: true,
            csvLoading: false,
            focusedInput: null,
            selectedEndDate: moment(),
            selectedStartDate: moment().subtract(1, 'months'),
            errorMessage: null,
            products: [],
            selectedReportType: 'DAY',
        }
    }

    _getState() {
        return {
            selectedDateType: this.state.selectedDateType,
            selectedStartDate: this.state.selectedStartDate,
            selectedEndDate: this.state.selectedEndDate,
            focusedInput: this.state.focusedInput,
            selectedReportType: this.state.selectedReportType,
            products: ProductPackagePlanReportStore.getProducts(),
            loading: ProductPackagePlanReportStore.isLoading(),
            csvLoading: ProductPackagePlanReportStore.isCsvLoading(),
        }
    }

    handleGettingReportClick(e) {
        if(e) e.preventDefault();
        ReportService.getProductPackagePlanReport(
          moment(this.state.selectedStartDate),
          moment(this.state.selectedEndDate),
          this.state.selectedDateType,
          this.state.selectedReportType
        )
    }

    searchProductPackageReport(props) {
        return (
            <div className="row">
                <div className="pull-right search-product col-md-12">
                    <div className="form-group is-empty">
                        {props.searchField}
                    </div>
                    <button className="btn btn-primary">
                        <i className="fa fa-search"/>
                    </button>
                </div>
            </div>
        );
    };

    renderSizePerPageDropDown(props) {
        return (
            <SizePerPageDropDown
                className='dropdown-pagesize'
                {...props}
                onClick={() => this.onToggleDropDown(props.toggleDropDown)}/>
        );
    };

    createDownloadReportLink() {
        var dateFormatter = "{0}/{1}/{2}";
        var options = {
            minDate: this.state.selectedStartDate && dateFormatter.format(this.state.selectedStartDate.date(), this.state.selectedStartDate.month() + 1, this.state.selectedStartDate.year()),
            maxDate: this.state.selectedEndDate && dateFormatter.format(this.state.selectedEndDate.date(), this.state.selectedEndDate.month() + 1, this.state.selectedEndDate.year())
        };
        var downloadLink = null;
        try {
            if (!options.minDate || !options.maxDate) {
                throw "Illegal argument exception";
            }
            var url = '/api/reports/productPackagePlan/csv' + '?';
            var parameters = Array();
            if (!!options.minDate && !!options.maxDate) {
                parameters.push("fromDate=" + encodeURIComponent(options.minDate) + "&toDate=" + encodeURIComponent(options.maxDate));
            }
            parameters.push("filterDate=" + encodeURIComponent(this.state.selectedDateType));
            parameters.push("reportType=" + this.state.selectedReportType);
            return url + parameters.join('&');
        } catch (exception) {
        }
        return downloadLink
    }

    downloadReport(downloadLink) {
        this.setState({csvLoading: true})
        fetch(downloadLink,
          {
              headers: {
                  'Csrf-Token': 'nocheck',
                  'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
              },
          })
          .then(response => {
              let fileName = response.headers.get("content-disposition").split('"')[1]
              response.blob().then(blob => {
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = fileName;
                  a.click();
              });
              this.setState({csvLoading: false})
          });
    }

    onToggleDropDown(toggleDropDown) {
        toggleDropDown();
    };

    render() {
        var downloadLink = this.createDownloadReportLink();
        const optionPaging = {
            sizePerPage: 10,
            sizePerPageList: [10, 20, 50, 100],
            paginationSize: 4,
            searchPanel: this.searchProductPackageReport,
            sizePerPageDropDown: this.renderSizePerPageDropDown,
            firstPage: 'First',
            lastPage: 'Last',
        };

        function packageNameFormat(cell, row) {
            let tmp = cell.map((data, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <OverlayTrigger
                                    trigger={(data.packageName !== null && data.packageName.length > 35) ? "click" : "focus"}
                                    placement="top"
                                    overlay={<Popover id="popover-basic">{data.packageName}</Popover>}>
                                    <p className="report-text-wrap-ellipsis report-padding-2">{data.packageName === null ? "選択なし" : data.packageName}</p>
                                </OverlayTrigger>
                            </td>
                        </tr>
                    );
                }
            );
            return (
                <table className="table-report-product-package">
                    <tbody>
                        {
                            tmp
                        }
                    </tbody>
                </table>
            );
        }

        function formatNumber(number) {
            if(number) return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            else return 0
        }

        function quantityFormat(cell, row) {
            let tmp = cell.map((data, index) => {
                    return (
                        <tr key={index}>
                            <td>
                                <p className="report-padding-2">{formatNumber(data.quantity)}</p>
                            </td>
                        </tr>
                    );
                }
            );
            return (
                <table className="table-report-product-package">
                    <tbody>
                        {
                            tmp
                        }
                    </tbody>
                </table>);
        }

        return (
            <section id="ReportComponent">
                <div className="report-form">
                    <div className="form-inline">
                        <label>集計期間:&nbsp;</label>
                        <Select
                            disabled={this.state.loading}
                            clearable={false}
                            options={this.dateTypes}
                            value={this.state.selectedDateType}
                            onChange={this._onDateTypeSelect}/>

                        <Select className="report-type"
                                disabled={this.state.loading}
                                clearable={false}
                                options={this.reportTypes}
                                value={this.state.selectedReportType}
                                onChange={this._onReportTypeSelect}/>

                        <DateRangePicker readOnly
                                         disabled={this.state.loading}
                                         startDatePlaceholderText="開始日"
                                         endDatePlaceholderText="完了日"
                                         monthFormat="YYYY[年]MMMM"
                                         phrases={{
                                             closeDatePicker: '完了',
                                             clearDates: 'クリア',
                                         }}
                                         startDate={this.state.selectedStartDate}
                                         endDate={this.state.selectedEndDate}
                                         focusedInput={this.state.focusedInput}
                                         onDatesChange={this._onDateChange}
                                         onFocusChange={this._onFocusChange}
                                         isOutsideRange={() => false}
                                         hideKeyboardShortcutsPanel
                                         minimumNights={0}
                                         showClearDates/>
                        <LoadingButton
                            disabled={this.state.selectedStartDate === null ||
                            this.state.selectedEndDate === null}
                            isLoading={this.state.loading}
                            className="btn-raised btn btn-info btn-md"
                            onClick={this.handleGettingReportClick}>集計</LoadingButton>

                        <LoadingButton
                            disabled={this.state.selectedStartDate === null || this.state.selectedEndDate === null}
                            isLoading={this.state.csvLoading}
                            className="btn-raised btn btn-info btn-md"
                            onClick={() => this.downloadReport(downloadLink)}>エクスポート</LoadingButton>
                    </div>
                </div>
                <div>
                    {(!this.state.errorMessage)
                        ? (
                            <BootstrapTable search
                                            searchPlaceholder = "商品名で検索"
                                            data={this.state.products}
                                            striped bordered
                                            pagination={true}
                                            options={optionPaging}>
                                <TableHeaderColumn width={'45%'} isKey dataField='productName'>商品名</TableHeaderColumn>
                                <TableHeaderColumn width={'40%'} dataField='packages'
                                                   dataFormat={packageNameFormat}>ラッピング名</TableHeaderColumn>
                                <TableHeaderColumn width={'15%'} dataField='packages'
                                                   dataFormat={quantityFormat}>数量</TableHeaderColumn>
                            </BootstrapTable>
                        )
                        : <span className="key-invalid-msg">{this.state.errorMessage}</span>
                    }
                </div>
            </section>
        )
    }

}

ProductPackagePlanReportComponent.propTypes = {
    selectedStartDate: momentPropTypes.momentObj,
    selectedEndDate: momentPropTypes.momentObj,
};

export default AuthenticatedComponent(ProductPackagePlanReportComponent);
