const OrderConstant = {
    EC_SITE_ID: {
        YAHOO: 'YAHOO',
        RAKUTEN: 'RAKUTEN',
        COLOR_ME: 'COLOR_ME'
    },
    API: {
        GET_ORDER_URL: "/api/orders",
        DETAIL_URL: "/api/order",
        INVOICE_LOG: "/api/order/{0}/invoice/log",
        CHANGE_STATUS_URL: "/api/order/{0}/status/{1}",
        CANCEL_ORDER_URL: "/api/order/{0}/cancel/{1}",
        SYNCHRONIZE_URL: "/api/order/{0}/synchronization",
        CREATE_IMAGEMAGIC_ORDER: "/api/order/{0}?updatedAt={1}&sendmail={2}",
        VERIFY_ORDER_PAYMENT: "/api/order/{0}/payment",
        EXPORT_ORDER_REPORT: "/api/export/order-report",
        UPDATE_INVOICE_PUBLISH_STATUS: "/api/order/{0}/invoice/{1}",
        UPDATE_SHIP_INFO: "/api/order/ship/{0}"
    },
    ACTION_TYPE: {
        LOADED_ORDERS: "LOADED_ORDERS",
        DETAIL: "DETAIL",
        INVOICE_LOG: "INVOICE_LOG",
        SHOW_ORDER_DETAIL: "SHOW_ORDER_DETAIL",
        HIDE_ORDER_DETAIL: "HIDE_ORDER_DETAIL",
        ON_CHANGE_KEYWORD: "ON_CHANGE_KEYWORD",
        ON_SEARCH_STATUS: "ON_SEARCH_STATUS",
        ON_SEARCH_PAGE: "ON_SEARCH_PAGE",
        ON_CHANGE_STATUS: "ON_CHANGE_STATUS",
        ON_CHANGE_EC_STATUS: "ON_CHANGE_EC_STATUS",
        ON_ROLLBACK_CHANGE_STATUS: "ON_ROLLBACK_CHANGE_STATUS",
        SYNCHRONIZE_ORDER_SUCCESSFULLY: "SYNCHRONIZE_ORDER_SUCCESSFULLY",
        SYNCHRONIZE_ORDER_UNSUCCESSFULLY: "SYNCHRONIZE_ORDER_UNSUCCESSFULLY",
        CREATE_IMAGEMAGIC_ORDER: "CREATE_IMAGEMAGIC_ORDER",
        CHANGE_INVOICE_CREATABLE_SUCCESS: 'CHANGE_INVOICE_CREATABLE_SUCCESS',
        ON_UPDATE_SHIP_INFO: 'ON_UPDATE_SHIP_INFO',
    },
    STATUS: {
        ALL: "ALL",
        WAITING: "WAITING",
        DRAFT: "DRAFT",
        URL_RELEASED: "URL_RELEASED",
        WAITING_CREATION: "WAITING_CREATION",
        PENDING_CREATION: "PENDING_CREATION",
        PENDING_CANCEL: "PENDING_CANCEL",
        CREATING: "CREATING",
        SHIPPED: "SHIPPED",
        SHIPPED_DM: "SHIPPED_DM",
        COMMITTED: "COMMITTED",
        PENDING: "PENDING",
        PAUSE: "PAUSE",
        CANCEL: "CANCEL",
        CANCEL_BY_SHOP: "CANCEL_BY_SHOP",
        CANCEL_BY_GUEST: "CANCEL_BY_GUEST"
    },
    STATUS_JP: {
        ALL: "全て",
        WAITING: "デザイン待ち",
        DRAFT: "下書き",
        URL_RELEASED: "URL発行済み",
        WAITING_CREATION: "制作中(確定前)",
        PENDING_CREATION: "処理中",
        PENDING_CANCEL: "キャンセル待ち",
        CREATING: "制作中(確定)",
        SHIPPED: "出荷済み",
        SHIPPED_DM: "思いやり配送出荷済",
        COMMITTED: "確定済み",
        PENDING: "保留",
        PAUSE: "制作停止",
        CANCEL: "キャンセル",
        CANCEL_BY_SHOP: "キャンセル（店舗都合）",
        CANCEL_BY_GUEST: "キャンセル（お客様都合）"
    },
    CREATION_STATE: {
        CHECK_DESIGN : "CHECK_DESIGN",
        CREATING_DESIGN: "CREATING_DESIGN",
        CREATING_XML: "CREATING_XML",
        UPLOADING: "UPLOADING",
        CHANGING_STATUS: "CHANGING_STATUS",
        SENDING_EMAIL: "SENDING_EMAIL",
        DONE: "DONE"
    },
    PAYMENT_STATUS: {
        SETTLED: "ORDER_SETTLED",
        NOT_SETTLED: "ORDER_NOT_SETTLED"
    },
    EXCEPTIONS: {
        ORDER_IS_CREATING_EXCEPTION: 'ORDER_IS_CREATING_EXCEPTION',
        WRONG_PRODUCT_EXCEPTION: 'WRONG_PRODUCT_EXCEPTION',
        NOT_ENOUGH_DESIGN_EXCEPTION: 'NOT_ENOUGH_DESIGN_EXCEPTION',
        SPARE_DESIGNS_EXCEPTION: 'SPARE_DESIGNS_EXCEPTION',
        ORDER_IS_MODIFIED: 'ORDER_IS_MODIFIED',
        EC_UPDATE_ERROR: 'EC_UPDATE_ERROR'
    },
    SHIPPER_ID: {
        SAGAWA: 0,
        KURONEKO: 1
    }
};

export default OrderConstant
