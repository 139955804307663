/**
 * Created by thangkc on 20/09/2016.
 */
import React from 'react';

export default class DesignCreaterInfoComponent extends React.Component {
    constructor() {
        super();
        this.state = this._getState();
        this._onChange = this._onChange.bind(this);
    }

    _onChange() {
        this.setState(this._getState());
    }

    _getState() {
        return {
            products: {}
        };
    }

    userInfo(user){

        var userName = user.nameFurigana == "" ? user.name : user.name + ' [' + user.nameFurigana + ']';
        return(<div className="row">
            <div className="col-md-6">
                <span className="member">会員</span>
                <table className="table table-bordered">
                    <tbody>
                    <tr>
                        <th>お知らせ希望メールアドレス</th>
                    </tr>
                    <tr>
                        <td height="90px">{user.email}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="col-md-6">
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th colSpan="2">会員情報</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th width="190px">氏名</th>
                        <td>{userName}</td>
                    </tr>
                    <tr>
                        <th>ログインID</th>
                        <td>{user.accountId}</td>
                    </tr>
                    <tr>
                        <th>登録メールアドレス</th>
                        <td>{user.email}</td>
                    </tr>
                    <tr>
                        <th>電話番号</th>
                        <td>{user.phoneNumber}</td>
                    </tr>
                    <tr>
                        <th>会員ID</th>
                        <td>{user.idStr}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>)
    }

    guestInfo(guest){
        return(<div className="row">
            <div className="col-md-6">
                <span className="member">ゲスト</span>
                <table className="table table-bordered">
                    <tbody>
                    <tr>
                        <th>お知らせ希望メールアドレス</th>
                    </tr>
                    <tr>
                        <td>{guest.email}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="col-md-6">
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th colSpan="2">会員情報</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th width="190px">氏名</th>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <th>ログインID</th>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <th>登録メールアドレス</th>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <th>電話番号</th>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <th>会員ID</th>
                        <td>&nbsp;</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>)
    }

    notUser(){
        return(<div className="row">
            <div className="col-md-6">
                <span className="member"/>
                <table className="table table-bordered">
                    <tbody>
                    <tr>
                        <th>お知らせ希望メールアドレス</th>
                    </tr>
                    <tr>
                        <td>&nbsp;</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="col-md-6">
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th colSpan="2">会員情報</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th width="190px">氏名</th>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <th>ログインID</th>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <th>登録メールアドレス</th>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <th>電話番号</th>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <th>会員ID</th>
                        <td>&nbsp;</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>)
    }

    get getUserInfo(){
        var creater = this.props.creater;
        switch(creater.role){
            case 'USER':
                return this.userInfo(creater);
            case 'GUEST':
                return this.guestInfo(creater);
            default:
                return this.notUser();
        }
    }

    render() {
        return (this.getUserInfo)
    }
}