import React from 'react'
import AuthenticatedComponent from '../../common/components/AuthenticatedComponent.jsx'
import FlyerCampaignList from "./flyerCampaignManagement/CampaignListComponent.jsx";

class FlyerCampaignComponent extends React.Component {

  constructor() {
    super();
  }

  _initState() {
    return this._getState();
  }

  _getState() {

  }

  _onChange() {
    this.setState(this._getState())
  }
  render() {
    return(
        <FlyerCampaignList/>
    )
  }
}

export default AuthenticatedComponent(FlyerCampaignComponent)