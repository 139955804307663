import BaseStore from '../../common/stores/BaseStore.jsx'
import ReportConstant from '../constants/ReportConstant.jsx'

class ReportProductByOrderStatusStore extends BaseStore {
  constructor(props) {
    super(props);
    this.resetReport();
    this.subscribe(() => this.handler.bind(this));
  }

  resetReport() {
    this.errorMessage = null;
    this.loading = true;
    this.products = [];
  }

  /**
   * Register callback to handle all updates
   *
   * @param  {Object} action
   */
  handler(action) {
    switch (action.actionType) {
      case ReportConstant.LOAD:
        this.loading = true;
        this.errorMessage = null;
        this.products = [];
        this.emitChange();
        break;
      case ReportConstant.ACTION_TYPE.SHOW_PRODUCT_REPORT_BY_ORDER_STATUS:
        this.products = action.data;
        this.loading = false;
        this.emitChange();
        break;
      case ReportConstant.ERROR:
        this.errorMessage = action.message;
        this.loading = false;
        this.emitChange();
        break;
      default:
        break
    }
  }

  getErrorMessage() {
    return this.errorMessage
  }

  getProducts() {
    return this.products
  }

  isLoading() {
    return this.loading
  }
}

const reportProductByOrderStatusStore = new ReportProductByOrderStatusStore();

export default reportProductByOrderStatusStore;