import React from 'react'
import moment from "moment";
class ChildrenSurveyTable extends React.Component {

  constructor (props) {
    super()
    this.state = this._getState(props);
  }

  _getState(props) {
    return {
      status: props.status
    }
  }

  monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  renderTableRow () {
    let surveyData = this.props.surveyData

    var returnData = []

    returnData = [];

    let self = this
    if (surveyData) surveyData.forEach(function (dto) {
      let rSpan = (dto.numberOfChildren && dto.numberOfChildren > 0) ? dto.numberOfChildren : 1
        let checkIsPregnant = false
        dto.survey.forEach(function (s) {
            if (s.isPregnant && !checkIsPregnant) {
                rSpan += 1
                checkIsPregnant = true
            }
        })
      if (dto.survey.length === 0) {
        returnData.push(
          <tr key={dto.orderNumber}>
            <td>{dto.shipDate}</td>
            <td>{dto.numberOfChildren}</td>
            <td/>
            <td/>
            <td/>
            <td/>
            <td>{dto.orderNumber}</td>
          </tr>
        )
      }
      dto.survey.forEach(function (s, index) {
        let dateOfBirthStr = s.dateOfBirth ? s.dateOfBirth.substring(0,7) : '';
        let format = 'YYYY-MM';
        let monthDiff = self.monthDiff(new Date(dateOfBirthStr), new Date())
        index === 0 ?
          returnData.push(
            <tr key={dto.orderNumber + index}>
              <td rowSpan={rSpan}>{dto.shipDate}</td>
              <td rowSpan={rSpan}>{dto.numberOfChildren}</td>
              <td>{(!s.dateOfBirth) ? '' : moment(dateOfBirthStr, format).format('YYYY-MM')}</td>
              <td>{(s.isPregnant) ? '' : (Math.floor(monthDiff/12) + '歳' + monthDiff % 12 + 'ヶ月')}</td>
              <td>{(s.gender === "-1") ? '無回答' : (s.gender === "0") ? '女の子' : (s.gender === "1") ? '男の子' : ''}</td>
              <td style={{textAlign: 'center'}}>{(s.isPregnant) ? (<i className="fa fa-check-circle"></i>) : '' }</td>
              <td rowSpan={rSpan}>{dto.orderNumber}</td>
            </tr>
          ) : returnData.push(
            <tr key={dto.orderNumber + index}>
              <td>{(!s.dateOfBirth) ? '' : moment(dateOfBirthStr, format).format('YYYY-MM')}</td>
              <td>{(s.isPregnant) ? '' : (Math.floor(monthDiff/12) + '歳' + monthDiff % 12 + 'ヶ月')}</td>
              <td>{(s.gender === "-1") ? '無回答' : (s.gender === "0") ? '女の子' : (s.gender === "1") ? '男の子' : ''}</td>
              <td style={{textAlign: 'center'}}>{(s.isPregnant) ? (<i className="fa fa-check-circle"></i>) : '' }</td>
            </tr>
          )
      })
    });

    return returnData

  }

  render () {
    return (
      <table className="table table-striped table-hover table-bordered">
        <thead>
        <tr>
          <th className="">日付</th>
          <th className="">子どもの人数</th>
          <th className="">子どもの誕生日</th>
          <th className="">子どもの年齢</th>
          <th className="">子どもの性別</th>
          <th className="">マタニティ</th>
          <th className="">受注番号</th>
        </tr>
        </thead>
        <tbody>
        {this.renderTableRow()}
        </tbody>
      </table>
    )
  }
}

export default ChildrenSurveyTable

