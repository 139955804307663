import BaseStore from "./BaseStore.jsx";
import {AlertConstant} from "../constants/AlertConstant.jsx";

class AlertStore extends BaseStore {
    constructor() {
        super();
        this.subscribe(() => this.handler.bind(this));
        this.alert = null
    }

    handler(action) {
        switch (action.actionType) {
            case AlertConstant.ALERT:
                if (this.alert === null) {
                    this.alert = {
                        type: action.alertType,
                        message: action.message,
                    }
                    this.emitChange();
                }
                break;
            case AlertConstant.RESET_ALERT:
                this.alert = null;
                this.emitChange();
                break;
            default:
                break;
        }
    }

    getAlert() {
        return this.alert
    }
}

const alertStore = new AlertStore();
export default alertStore;