import CampaignConstants from '../constants/CampaignConstants.jsx'
import Dispatcher from '../../common/dispatcher/Dispatcher.jsx'

export default {
  load: (campaignDTO, campaignFilter, theatreId) => {
    Dispatcher.dispatch({
      actionType: CampaignConstants[theatreId].CAMPAIGN_LOAD,
      campaignDTO: campaignDTO,
      campaignFilter: campaignFilter,
    })
  },

  loadStudioAliceBaby1812: (studioAliceBaby1812DTO, studioAliceBaby1812Filter) => {
    Dispatcher.dispatch({
      actionType: CampaignConstants.STUDIOALICEBABY1812_TYPE.CAMPAIGN_LOAD,
      studioAliceBaby1812DTO: studioAliceBaby1812DTO,
      studioAliceBaby1812Filter: studioAliceBaby1812Filter,
    })
  },

  changePage: (page, theatreId) => {
    Dispatcher.dispatch({
      actionType: CampaignConstants[theatreId].CAMPAIGN_CHANGE_PAGE,
      page: page,
    })
  },

  changePageStudioAliceBaby1812: (page) => {
    Dispatcher.dispatch({
      actionType: CampaignConstants.STUDIOALICEBABY1812_TYPE.CAMPAIGN_CHANGE_PAGE,
      page: page,
    })
  },

  doneExportCSVFile (theatreId) {
    Dispatcher.dispatch({
      actionType: CampaignConstants[theatreId].DONE_EXPORT_CSV,
    })


  },

  doneZipData (theatreId) {
    Dispatcher.dispatch({
      actionType: CampaignConstants[theatreId].DONE_ZIP_FILE,
    })
  },

  doneDownload(theatreId) {
    alert("done download Action " + CampaignConstants[theatreId].DONE_DOWNLOAD)
    Dispatcher.dispatch({
      actionType: CampaignConstants[theatreId].DONE_DOWNLOAD,
    })
  },

  doneExportCSVStudioAliceBaby1812File () {
    Dispatcher.dispatch({
      actionType: CampaignConstants.STUDIOALICEBABY1812_TYPE.DONE_EXPORT_CSV,
    })
  },

}
