import EcProductCooperationMasterDataConstants
  from '../constants/EcProductCooperationMasterDataConstants.jsx'
import EcProductCooperationMasterDataStore
  from '../stores/EcProductCooperationMasterDataStore.jsx'

export default {
  getProductCooperationMasterData (data) {
    EcProductCooperationMasterDataStore.handler({
      actionType: EcProductCooperationMasterDataConstants.ACTION_TYPE.GET_PRODUCT_COOPERATION_MASTER_DATA,
      data: data,
    })
  },
  changeCooperationProductNewData (object) {
    let tmp = {
      siteId: EcProductCooperationMasterDataStore.getEcCooperationProductData().siteId,
      text: '',
    }
    EcProductCooperationMasterDataStore.handler({
      actionType: EcProductCooperationMasterDataConstants.ACTION_TYPE.CHANGE_PRODUCT_COOPERATION_NEW_TEXT,
      data: object ? object : tmp,
    })
  },
  newMessage (message) {
    EcProductCooperationMasterDataStore.handler({
      actionType: EcProductCooperationMasterDataConstants.ACTION_TYPE.NEW_MESSAGE,
      data: message,
    })
  },
  chageLoading (isLoading) {
    EcProductCooperationMasterDataStore.handler({
      actionType: EcProductCooperationMasterDataConstants.ACTION_TYPE.CHANGE_LOADING,
      data: isLoading,
    })
  },

}