/**
 * Created by thangkc on 21/09/2016.
 */
import React from 'react';
import ConfirmDialog from "../dialogs/ConfirmDialog.jsx"
import DesignService from "../../../design/services/DesignService.jsx";
import LoadingButton from "../LoadingButton.jsx";
import DesignStore from "../../../design/stores/DesignStore.jsx"
export default class DesignActionFormat  extends React.Component {
    constructor() {
        super();
        this.state =this._getState();
        this._onChange = this._onChange.bind(this);
        this.deleteDesign = this.deleteDesign.bind(this);
        this.closeConfirmDialog = this.closeConfirmDialog.bind(this);
        this.showConfirmDialog = this.showConfirmDialog.bind(this);
    }

    _getState() {
        return {
            showConfirmDialog: false,
            deleteId: null,
            isLoading: false,
        };
    }

    _onChange() {
        this.setState(this._getState());
    }

    render() {
        let downLoadLink = "/api/design/" + this.props.rowData[0].orderNumber + "/" + this.props.rowData[0].groupNumber + "/image" + (this.props.isSeichoku && this.props.rowData[0].designSeries ? '?designSeriesId=' + this.props.rowData[0].designSeries.id : '');
        return (
            <div>
                {this.confirmBox}
                <span className="action">
                    <LoadingButton
                      isLoading={this.state.isLoading}
                      onClick={this.showConfirmDialog}
                      disabled={this.props.isBusy}
                      className="btn btn-raised btn-danger">
                        <i className="fa fa-trash-o"/>
                    </LoadingButton>
                    <a className="btn btn-raised btn-info" href={ downLoadLink } download>
                        <i className="fa fa-download"/>
                    </a>
                </span>
            </div>
        );
    }

    showConfirmDialog(){
        this.setState({
            showConfirmDialog: true
        });
    }

    closeConfirmDialog(){
        this.setState({
            showConfirmDialog: false
        });
    }

    deleteDesign() {
        this.setState({
            isLoading: true
        });
        DesignService.deleteDesign(this.props.rowData);
        this.setState({
            viewConfirm: false
        })
    }

    makeGroupDesignId(designs) {
        let designIds = [];
        let isSeichoku = designs.length > 0 && designs[0].product.seichoku;
        if (isSeichoku) {
            designIds.push(designs[0].designSeries? designs[0].designSeries.randomId : "");
        } else {
            Array.from(designs).forEach(function (d) {
                designIds.push(d.randomId);
            });
        }
        return designIds.join("-")
    }

    get confirmBox(){
        return <ConfirmDialog showModal={this.state.showConfirmDialog}
                              accept={this.deleteDesign}
                              close={this.closeConfirmDialog}
                              message={this.makeGroupDesignId(this.props.rowData) + "のデザインを削除します。よろしいですか？"}/>;

    }

}