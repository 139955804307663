import BaseStore from '../../common/stores/BaseStore.jsx'
import Cookies from 'universal-cookie'
import CampaignConstants from '../constants/CampaignConstants.jsx'
import CampaignTheatreService from '../services/CampaignTheatreService.jsx'

class CampaignTheatreStore extends BaseStore {

  constructor (theatreId) {
    super()
    this.cookies = new Cookies()
    this.isDoneExport = null
    this.isDoneZip = null

    if(theatreId == 'theatre1810'){
      this.TheatreService = new CampaignTheatreService(theatreId)
      this.theatreId = 'THEATRE1810_TYPE'
    } else if(theatreId == 'theatre1905') {
      this.TheatreService = new CampaignTheatreService('theatre1905')
      this.theatreId = 'THEATRE1905_TYPE'
    }


    this.campaignDTO = {
      currentPage: 1,
      maxPage: 0,
      campaigns: [],
    }
    this.campaignFilter = {}

    this._resetFilter()

    this.subscribe(() => this.handler.bind(this))
  }

  _resetFilter () {
    this.campaignFilter = {
      currentPage: 1,
      pageSize: 10,
    }
  }

  handler (action) {
    let self = this

    switch (action.actionType) {
      case CampaignConstants[self.theatreId].DONE_EXPORT_CSV:
        this.isDoneExport = true
        this.isDoneZip = null
        self.TheatreService.runZipFile()
        this.emitChange()
        break
      case CampaignConstants[self.theatreId].DONE_ZIP_FILE:
        this.isDoneExport = null
        this.isDoneZip = true
        self.TheatreService.downloadFile()
        this.emitChange()
        break
      case CampaignConstants[self.theatreId].DONE_DOWNLOAD:
        this.isDoneExport = null
        this.isDoneZip = null
        this.emitChange()
        break
      case CampaignConstants[self.theatreId].CAMPAIGN_LOAD:
        this.campaignDTO = action.campaignDTO
        this.campaignFilter = action.campaignFilter
        this.emitChange()
        break
      case CampaignConstants[self.theatreId].CAMPAIGN_CHANGE_PAGE:
        this.campaignFilter.currentPage = action.page
        self.TheatreService.load(this.campaignFilter)
        break
      default:
        break
    }
  }

  getExportStatus () {
    return this.isDoneExport
  }

  getZipStatus () {
    return this.isDoneZip
  }

  _getCampaignDTO () {
    return this.campaignDTO
  }

  _getCampaignFilter () {
    return this.campaignFilter
  }

  _getTheatreId () {
    return this.theatreId;
  }

}

export default CampaignTheatreStore
