import React from 'react'
import request from 'reqwest'
import when from 'when'
import LoginAction from '../../authentication/actions/LoginAction.jsx'
import ReportAction from '../actions/ReportAction.jsx'
import ReportConstant from '../constants/ReportConstant.jsx'
import CommonService from '../../common/services/CommonService.jsx'

class ReportService {

  getTemplateReport(reportType, DateType ,fromDate, toDate) {
    var params = ['reportType=' + reportType, 'filterDate=' + DateType]
    var dateFormatter = '{0}/{1}/{2}'
    if (!!fromDate) {
      params.push('fromDate=' + encodeURIComponent(
        dateFormatter.format(fromDate.date(), fromDate.month() +
          1, fromDate.year())))
    }
    if (!!toDate) {
      params.push('toDate=' + encodeURIComponent(
        dateFormatter.format(toDate.date(), toDate.month() + 1,
          toDate.year())))
    }

    var url = ReportConstant.API.TEMPLATE_REPORT_URL
    if (params.length > 0) {
      url += '?' + params.join('&')
    }

    ReportAction.load()
    when(request({
      url: url,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          let groupName = response.data.templates.groupBy('productName');

          Object.keys(groupName).forEach(function(key) {
            let tmp = groupName[key][0].templateSeriesId ? groupName[key].groupBy('templateSeriesName') : groupName[key].groupBy('templateName');
            Object.keys(tmp).forEach(function(keyTmp) {
              tmp[keyTmp] =  tmp[keyTmp].groupBy(reportType === 'DAY' ? 'ymd': 'ym')
            });
            groupName[key] = tmp
          });

          let listTitle = CommonService.createReportTitle(fromDate, toDate, reportType);
          ReportAction.showTemplateReport(groupName, listTitle)
          break
        case 401:
          LoginAction.loginUser({})
          break
        case 400:
          ReportAction.error(response.error.report[0])
          break
        case 500:
          ReportAction.error('システムエラーが発生しました！具体的は開発者にご連絡してください。')
          break
        default:
          break
      }
    }).catch(function () {

    })

  }

  getProductReport (reportType, DateType ,fromDate, toDate) {
    var params = ['reportType=' + reportType, 'filterDate=' + DateType]
    var dateFormatter = '{0}/{1}/{2}'
    if (!!fromDate) {
      params.push('fromDate=' + encodeURIComponent(
        dateFormatter.format(fromDate.date(), fromDate.month() +
          1, fromDate.year())))
    }
    if (!!toDate) {
      params.push('toDate=' + encodeURIComponent(
        dateFormatter.format(toDate.date(), toDate.month() + 1,
          toDate.year())))
    }
    var url = ReportConstant.API.PRODUCT_REPORT_URL
    if (params.length > 0) {
      url += '?' + params.join('&')
    }

    ReportAction.load()

    when(request({
      url: url,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:

          let groupName = response.data.products.groupBy('name');

          Object.keys(groupName).forEach(function(key) {
            groupName[key] = groupName[key].groupBy(reportType === 'DAY' ? 'ymd': 'ym')
          });

          let listTitle = CommonService.createReportTitle(fromDate, toDate, reportType);
          ReportAction.showProductReport(groupName, listTitle)
          break
        case 401:
          LoginAction.loginUser({})
          break
        case 400:
          ReportAction.error(response.error.report[0])
          break
        case 500:
          ReportAction.error('システムエラーが発生しました！具体的は開発者にご連絡してください。')
          break
        default:
          break
      }
    }).catch(function () {

    })
  }

  getProductByOrderStatusReport (fromDate, toDate) {
    var params = [];
    var dateFormatter = '{0}-{1}-{2}';
    if (!!fromDate) {
      params.push('fromDate=' + encodeURIComponent(
          dateFormatter.format(fromDate.year(), fromDate.month() +
              1, fromDate.date())))
    }
    if (!!toDate) {
      params.push('toDate=' + encodeURIComponent(
          dateFormatter.format(toDate.year(), toDate.month() + 1,
              toDate.date())))
    }
    var url = ReportConstant.API.PRODUCT_BY_ORDER_STATUS_REPORT_URL;
    if (params.length > 0) {
      url += '?' + params.join('&')
    }

    when(request({
      url: url,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          let groupName = response.data.groupBy('name');
          Object.keys(groupName).forEach(function (key){
            let draft = groupName[key].find(item => item.status === "draft");
            let waiting = groupName[key].find(item => item.status === "waiting");
            let waitingCreation = groupName[key].find(item => item.status === "waiting_creation");
            let pendingCreation = groupName[key].find(item => item.status === "pending_creation");
            let creating = groupName[key].find(item => item.status === "creating");
            groupName[key] = {
              name: key,
              quantity: groupName[key][0].quantity,
              waitingAndDraft: waiting ? waiting.orderCount : 0 + draft ? draft.orderCount : 0,
              waitingCreation: waitingCreation ? waitingCreation.orderCount : 0,
              pendingCreationAndCreating: pendingCreation ? pendingCreation.orderCount : 0 + creating ? creating.orderCount : 0,
            }
          });
          ReportAction.showProductReportByOrderStatus(Object.values(groupName));
          break;
        case 401:
          LoginAction.loginUser({})
          break;
        case 400:
          ReportAction.error(response.error.report[0])
          break;
        case 500:
          ReportAction.error('システムエラーが発生しました！具体的は開発者にご連絡してください。')
          break;
        default:
          break
      }
    }).catch(function () {

    })
  }

  getProductPackagePlanReport(fromDate, toDate, dateType, reportType) {
    let params = ['filterDate=' + dateType, 'reportType=' + reportType];
    let dateFormatter = '{0}/{1}/{2}';
    if (!!fromDate) {
      params.push('fromDate=' + encodeURIComponent(dateFormatter.format(fromDate.date(), fromDate.month() + 1, fromDate.year())))
    }
    if (!!toDate) {
      params.push('toDate=' + encodeURIComponent(dateFormatter.format(toDate.date(), toDate.month() + 1, toDate.year())))
    }
    let url = ReportConstant.API.PRODUCT_PACKAGE_PLAN_URL;
    if (params.length > 0) {
      url += '?' + params.join('&')
    }
    ReportAction.load();
    when(request({
      url: url,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          ReportAction.showProductPackagePlanReport(response.data);
          break;
        case 401:
          LoginAction.loginUser({});
          break;
        case 400:
          ReportAction.error(response.error.report[0]);
          break;
        case 500:
          ReportAction.error('システムエラーが発生しました！具体的は開発者にご連絡してください。');
          break;
        default:
          break
      }
    }).catch(function () {

    })
  }

  getRepeatOrderReport (fromOrderDate, toOrderDate, nextPage) {
    var dateFormatter = '{0}/{1}/{2}'
    when(request({
      url: ReportConstant.API.REPEAT_ORDER_URL,
      data: {
        fromOrderDate: dateFormatter.format(
          fromOrderDate.date(), fromOrderDate.month() + 1,
          fromOrderDate.year()),
        toOrderDate: dateFormatter.format(
          toOrderDate.date(), toOrderDate.month() + 1,
          toOrderDate.year()),
        currentPage: nextPage || 1
      },
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          ReportAction.showRepeatOrderReport(response.data)
          break
        case 401:
          LoginAction.loginUser({})
          break
        case 400:
          ReportAction.error(response.error.report[0])
          break
        case 500:
          ReportAction.error('システムエラーが発生しました！具体的は開発者にご連絡してください。')
          break
        default:
          break
      }
    }).catch(function () {

    })
  }

  getRepeatOrderChartReport (fromOrderDate, toOrderDate) {
    var dateFormatter = '{0}/{1}/{2}'
    when(request({
      url: ReportConstant.API.REPEAT_ORDER_CHART_URL,
      data: {
        fromOrderDate: dateFormatter.format(
          fromOrderDate.date(), fromOrderDate.month() + 1,
          fromOrderDate.year()),
        toOrderDate: dateFormatter.format(
          toOrderDate.date(), toOrderDate.month() + 1,
          toOrderDate.year())
      },
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          ReportAction.showRepeatOrderChartReport(response.data)
          break
        case 401:
          LoginAction.loginUser({})
          break
        case 400:
          ReportAction.error(response.error.report[0])
          break
        case 500:
          ReportAction.error('システムエラーが発生しました！具体的は開発者にご連絡してください。')
          break
        default:
          break
      }
    }).catch(function () {

    })
  }

  getChildrenAgeGroupReportChart(fromDate, toDate) {
    var dateFormatter = '{0}/{1}/{2}'
    when(request({
      url: ReportConstant.API.CHILDREN_AGE_GROUP_REPORT_CHART_URL,
      data: {
        fromDate: dateFormatter.format(
          fromDate.date(), fromDate.month() + 1,
          fromDate.year()),
        toDate: dateFormatter.format(
          toDate.date(), toDate.month() + 1,
          toDate.year())
      },
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          ReportAction.showChildrenAgeGroupCharReport(response.data.chartData)
          break
        case 401:
          LoginAction.loginUser({})
          break
        case 400:
          ReportAction.error(response.error.report[0])
          break
        case 500:
          ReportAction.error('システムエラーが発生しました！具体的は開発者にご連絡してください。')
          break
        default:
          break
      }
    }).catch(function () {

    })
  }

  getChildrenAgeGroupReport (fromDate, toDate, currentPage) {
    var dateFormatter = '{0}/{1}/{2}'
    when(request({
      url: ReportConstant.API.CHILDREN_AGE_GROUP_REPORT_URL,
      data: {
        fromDate: dateFormatter.format(
          fromDate.date(), fromDate.month() + 1,
          fromDate.year()),
        toDate: dateFormatter.format(
          toDate.date(), toDate.month() + 1,
          toDate.year()),
        currentPage: currentPage || 1
      },
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          ReportAction.showChildrenAgeGroupReport(response.data)
          break
        case 401:
          LoginAction.loginUser({})
          break
        case 400:
          ReportAction.error(response.error.report[0])
          break
        case 500:
          ReportAction.error('システムエラーが発生しました！具体的は開発者にご連絡してください。')
          break
        default:
          break
      }
    }).catch(function () {

    })
  }

  getChilrenSurveyData(fromDate, toDate, currentPage) {
    var dateFormatter = '{0}/{1}/{2}'
    when(request({
      url: ReportConstant.API.CHILDREN_SURVEY_DATA_URL,
      data: {
        fromDate: dateFormatter.format(
          fromDate.date(), fromDate.month() + 1,
          fromDate.year()),
        toDate: dateFormatter.format(
          toDate.date(), toDate.month() + 1,
          toDate.year()),
        currentPage: currentPage || 1
      },
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          ReportAction.showChildrenSurveyData(response.data)
          break
        case 401:
          LoginAction.loginUser({})
          break
        case 400:
          ReportAction.error(response.error.report[0])
          break
        case 500:
          ReportAction.error('システムエラーが発生しました！具体的は開発者にご連絡してください。')
          break
        default:
          break
      }
    }).catch(function () {

    })
  }

  getChildrenAgeGroupReportFile(fromDate, toDate) {
    var dateFormatter = '{0}/{1}/{2}'
    let url = ReportConstant.API.CHILDREN_AGE_GROUP_REPORT_EXPORT_URL + '?'
    let parameters = Array();
    parameters.push("fromDate=" + dateFormatter.format(fromDate.date(), fromDate.month() + 1, fromDate.year()))
    parameters.push("toDate=" + dateFormatter.format(toDate.date(), toDate.month() + 1, toDate.year()))
    fetch(url + parameters.join('&'),
      {
        headers: {
          'Csrf-Token': 'nocheck',
          'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
        },
      }).then(function (response) {
      let fileName = response.headers.get("Content-Disposition").split('"')[1]
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
      });
      //window.location.href = response.url;
    }).catch(function (ex) {
      console.log(ex);
    })
  }

  setActiveLineChart(value){
    ReportAction.setActiveLineChart(value)
  }

  setActiveBarChart(value) {
    ReportAction.setActiveBarChart(value)
  }

  setDateRange(startDate, endDate) {
    let data = {
      startDate: startDate,
      endDate: endDate
    }
    ReportAction.setDateRange(data)
  }

  getGroupProductOrderReport (fromOrderDate, toOrderDate, nextPage) {
    var dateFormatter = '{0}/{1}/{2}';
    when(request({
      url: ReportConstant.API.GROUP_PRODUCT_ORDER_URL,
      data: {
        fromDate: dateFormatter.format(
          fromOrderDate.date(), fromOrderDate.month() + 1,
          fromOrderDate.year()),
        toDate: dateFormatter.format(
          toOrderDate.date(), toOrderDate.month() + 1,
          toOrderDate.year()),
        currentPage: nextPage || 1,
        pageSize: 10,
      },
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          ReportAction.showGroupProductOrderReport(response.data);
          break;
        case 401:
          LoginAction.loginUser({});
          break;
        case 400:
          ReportAction.error(response.error.report[0]);
          break;
        case 500:
          ReportAction.error('システムエラーが発生しました！具体的は開発者にご連絡してください。');
          break;
        default:
          break
      }
    }).catch(function () {

    })
  }

  getDashBoardGroupProduct (fromOrderDate, toOrderDate) {
    var dateFormatter = '{0}/{1}/{2}';
    when(request({
      url: ReportConstant.API.DASHBOARD_CUSTOM_GROUP_PRODUCT_URL,
      data: {
        fromDate: dateFormatter.format(
          fromOrderDate.date(), fromOrderDate.month() + 1,
          fromOrderDate.year()),
        toDate: dateFormatter.format(
          toOrderDate.date(), toOrderDate.month() + 1,
          toOrderDate.year())
      },
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          ReportAction.showDashboardCustomGroupProductReport(response.data);
          break;
        case 401:
          LoginAction.loginUser({});
          break;
        case 400:
          ReportAction.error(response.error.report[0]);
          break;
        case 500:
          ReportAction.error('システムエラーが発生しました！具体的は開発者にご連絡してください。');
          break;
        default:
          break
      }
    }).catch(function () {

    })
  }

  createDashboardGroupProduct(dashboard){
    when(request({
      url: ReportConstant.API.CREATE_DASHBOARD_CUSTOM_GROUP_PRODUCT_URL,
      method: 'POST',
      data: {
        name : dashboard.name,
        note: dashboard.note,
      },
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          ReportAction.createDashboardCustomGroupProductReport();
          break;
        case 401:
          LoginAction.loginUser({});
          break;
        case 400:
          alert('すでに、' + dashboard.name +'がありました。');
          break;
        default :
          break
      }
    })
  }

  createGroupProduct(dashboard, listGroups){
    let formData = new FormData();
    formData.append("dashboardId", dashboard.id);
    listGroups.forEach(function (item, index) {
      formData.append("groupInfos[" + index + "].productId", item.product ? item.product.value : '');
      formData.append("groupInfos[" + index + "].categoryId", item.product ? '' : item.category.value);
      formData.append("groupInfos[" + index + "].quantity", item.quantity);
    });
    when(request({
      url: ReportConstant.API.CREATE_GROUP_PRODUCT_URL,
      method: 'POST',
      data: formData,
      crossOrigin: true,
      processData: false,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          ReportAction.createGroupProduct();
          break;
        case 401:
          LoginAction.loginUser({});
          break;
        case 400:
          alert('すでに、商品組み合わせが存在しました。');
          break;
        default :
          break
      }
    })
  }

  deleteGroupInDashboard(dashboardId, groupId){
    when(request({
      url: ReportConstant.API.CREATE_DASHBOARD_CUSTOM_GROUP_PRODUCT_URL + '/' + dashboardId + '/' + groupId,
      method: 'DELETE',
      crossOrigin: true,
      processData: false,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          ReportAction.deleteGroupProduct();
          break;
        case 401:
          LoginAction.loginUser({});
          break;
        case 400:
          break;
        default :
          break
      }
    })
  }

  deleteDashboard(dashboardId){
    when(request({
      url: ReportConstant.API.CREATE_DASHBOARD_CUSTOM_GROUP_PRODUCT_URL + '/' + dashboardId,
      method: 'DELETE',
      crossOrigin: true,
      processData: false,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          ReportAction.deleteGroupProduct();
          break;
        case 401:
          LoginAction.loginUser({});
          break;
        case 400:
          break;
        default :
          break
      }
    })
  }

  getCategories() {
    when(request({
      url: ReportConstant.API.GET_CATEGORIES_URL,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          ReportAction.getCategories(response.data);
          break;
        case 401:
          LoginAction.loginUser({});
          break;
        case 400:
          ReportAction.error(response.error.report[0]);
          break;
        case 500:
          ReportAction.error('システムエラーが発生しました！具体的は開発者にご連絡してください。');
          break;
        default:
          break
      }
    }).catch(function () {

    })
  }

  getProducts(index, categoryId) {
    when(request({
      url: ReportConstant.API.GET_PRODUCT_URL + "?categoryId=" + categoryId,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      },
    })).then(function (response) {
      switch (response.code) {
        case 200:
          ReportAction.getProducts({
            index: index,
            product: response.data,
          });
          break;
        case 401:
          LoginAction.loginUser({});
          break;
        case 400:
          ReportAction.error(response.error.report[0]);
          break;
        case 500:
          ReportAction.error('システムエラーが発生しました！具体的は開発者にご連絡してください。');
          break;
        default:
          break
      }
    }).catch(function () {

    })
  }
}

export default new ReportService()
