/**
 * Created by ThangKC 8/11/20.
 *User: Thangkc
 *Date: 8/11/20
 *Time: 1:17 PM
 */

import Dispatcher from '../../common/dispatcher/Dispatcher.jsx'
import MagazineMailConstants from '../constants/MagazineMailConstants.jsx'

export default {
  loadedAllPlan(plans) {
    Dispatcher.dispatch({
      actionType: MagazineMailConstants.ACTION_TYPE.LOADED_ALL_PLAN,
      allPlan: plans
    })
  },
  loadedPlan(plan) {
    Dispatcher.dispatch({
      actionType: MagazineMailConstants.ACTION_TYPE.LOADED_PLAN,
      plan: plan
    })
  },
  loadedMailTemplate(templates) {
    Dispatcher.dispatch({
      actionType: MagazineMailConstants.ACTION_TYPE.LOADED_ALL_MAIL_TEMPLATE,
      templates: templates
    })
  },
  loadedAllUnsubscribeReceiver(receivers, receiverFilter) {
    Dispatcher.dispatch({
      actionType: MagazineMailConstants.ACTION_TYPE.LOADED_ALL_UNSUBSCRIBE_RECEIVER,
      unsubscribedReceiversDTO: receivers,
      receiverFilter: receiverFilter
    })
  },
  loadedMailQuantity(data) {
    Dispatcher.dispatch({
      actionType: MagazineMailConstants.ACTION_TYPE.LOADED_MAIL_QUANTITY,
      data: data
    })
  },
  changeKeyword: (keyword) => {
    Dispatcher.dispatch({
      actionType: MagazineMailConstants.ACTION_TYPE.ON_CHANGE_KEYWORD,
      keyword: keyword,
    })
  },
  changePage: (currentPage) => {
    Dispatcher.dispatch({
      actionType: MagazineMailConstants.ACTION_TYPE.ON_SEARCH_PAGE,
      currentPage: currentPage
    })
  },
  badRequest: (message) => {
    Dispatcher.dispatch({
      actionType: MagazineMailConstants.ACTION_TYPE.BAD_REQUEST,
      message: message,
    })
  },
  error: () => {
    Dispatcher.dispatch({
      actionType: MagazineMailConstants.ACTION_TYPE.ERROR
    })
  },
}