import Dispatcher from "../../dispatcher/Dispatcher.jsx";
import {LoadingConstant} from "../../constants/LoadingConstant.jsx";

export default {
    startLoading: () => {
        Dispatcher.dispatch({
            actionType: LoadingConstant.START_LOADING
        })
    },
    stopLoading: () => {
        Dispatcher.dispatch({
            actionType: LoadingConstant.STOP_LOADING
        })
    }
}