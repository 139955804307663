import Dispatcher from '../../common/dispatcher/Dispatcher.jsx'
import YahooConstants from '../constants/YahooConstants.jsx'

class YahooAction {
  getUserInfo (userInfo, yconnectInfo) {
    Dispatcher.dispatch({
      actionType: YahooConstants.ACTION_TYPE.GET_USER_INFO,
      userInfo: userInfo,
      yconnectInfo: yconnectInfo,
    })
  }

  logOut (status) {
    if (status == 200) {
      Dispatcher.dispatch({
        actionType: YahooConstants.ACTION_TYPE.LOGOUT,
      })
    }
  }
}

const yahooAction = new YahooAction()
export default yahooAction