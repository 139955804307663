import BaseStore from "../../common/stores/BaseStore.jsx";
import EpsilonDataTrackingConstants from "../constants/EpsilonDataTrackingConstants.jsx";
import EpsilonDataTrackingService from "../services/EpsilonDataTrackingService.jsx";

class EpsilonDataTrackingStore extends BaseStore {
    constructor() {
        super();
        this.subscribe(() => this.handler.bind(this));
        this.ordersDTO = {
            currentPage: 1,
            maxPage: 0,
            orders: []
        };
        this.filter = {
            currentPage: 1,
            pageSize: 10,
            keyword: '',
            orderByOrderDateDesc: false
        };
    }

    handler(action) {
        switch (action.actionType) {
            case EpsilonDataTrackingConstants.CHANGE_FILTER:
                this.filter.currentPage = action.filter.currentPage || 1
                this.filter.keyword = action.filter.keyword || ''
                EpsilonDataTrackingService.fetchOrders(this.filter)
                this.emitChange();
                break;
            case EpsilonDataTrackingConstants.FETCH_ORDERS:
                EpsilonDataTrackingService.fetchOrders(this.filter)
                this.emitChange();
                break;
            case EpsilonDataTrackingConstants.FETCHED_ORDERS:
                this.ordersDTO = action.ordersDTO
                this.filter = action.filter
                this.emitChange();
                break;
            case EpsilonDataTrackingConstants.CHECK_ORDER:
                this.ordersDTO.orders = this.ordersDTO.orders.map((order) => {
                    if (order.orderNumber === action.selectedOrder.orderNumber) {
                        return action.selectedOrder;
                    }
                    return order
                })
                this.emitChange();
                break;
            case EpsilonDataTrackingConstants.CHECK_ALL_ORDER:
                this.ordersDTO.orders = action.orders
                this.emitChange();
                break;
            case EpsilonDataTrackingConstants.START_EPSILON_LINK_DATA:
                const epsilonOrdersData = []
                this.ordersDTO.orders
                    .filter((order) => order.checked)
                    .map((order) => {
                        epsilonOrdersData.push({
                            orderNumber: order.orderNumber,
                            status: order.status,
                            shipNo: order.packages[0].shipId,
                            payMethod: order.payMethod,
                            payMethodId: order.payMethodId
                        })
                    })
                EpsilonDataTrackingService.startEpsilonLinkData(epsilonOrdersData)
                this.emitChange();
                break;
            case EpsilonDataTrackingConstants.RESET_FILTER:
                this.filter = {
                    currentPage: 1,
                    pageSize: 10,
                    keyword: '',
                    orderByOrderDateDesc: false
                }
                this.emitChange();
                break;
        }
    }

    getOrdersDTO() {
        return this.ordersDTO
    }

    getOrderFilter() {
        return this.filter
    }
}

const epsilonDataTrackingStore = new EpsilonDataTrackingStore()

export default epsilonDataTrackingStore