import React from 'react';
import {Bar, Pie} from 'react-chartjs-2';
import 'chartjs-plugin-datalabels'
import ReportConstant from "../../constants/ReportConstant.jsx";

class ChildrenAgeGroupReportChart extends React.Component {
  constructor() {
    super();
  }

  createChart () {
    switch (this.props.type) {
      case ReportConstant.CHART_TYPE.BAR:
        return this.barChart()
      case ReportConstant.CHART_TYPE.PIE:
        return this.pieChart()
      default:
        return ""
    }
  }

  barChart () {
    let chartData = {
      datasets: [
        {
          label: 'マタニティ',
          data: this.props.datasets.pregnant.datasets || [],
          fill: false,
          stack: 1,
          backgroundColor:  'rgb(75, 192, 192)',
          borderColor:  'rgb(75, 192, 192)',
        },
        {
          label: '0歳代',
          data: this.props.datasets.zeroYear.datasets || [],
          fill: false,
          stack: 1,
          backgroundColor:  'rgb(255, 99, 132)',
          borderColor:  'rgb(255, 99, 132)',
        },
        {
          label: '1歳代',
          data: this.props.datasets.oneYear.datasets || [],
          fill: false,
          stack: 1,
          backgroundColor:  'rgb(204, 204, 0)',
          borderColor:  'rgb(204, 204, 0)',
        },
        {
          label: '2歳代',
          data: this.props.datasets.twoYears.datasets || [],
          fill: false,
          stack: 1,
          backgroundColor:  '#8e7cc3',
          borderColor:  '#8e7cc3',
        },
        {
          label: '3歳代',
          data: this.props.datasets.threeYears.datasets || [],
          fill: false,
          stack: 1,
          backgroundColor:  '#93c47d',
          borderColor:  '#93c47d',
        },
        {
          label: '4歳代',
          data: this.props.datasets.fourYears.datasets || [],
          fill: false,
          stack: 1,
          backgroundColor:  'rgb(51, 153, 255)',
          borderColor:  'rgb(51, 153, 255)',
        },
        {
          label: '5歳代',
          data: this.props.datasets.fiveYears.datasets || [],
          fill: false,
          stack: 1,
          backgroundColor:  'rgb(255, 153, 51)',
          borderColor:  'rgb(255, 153, 51)',
        },
        {
          label: '6歳代',
          data: this.props.datasets.sixYears.datasets || [],
          fill: false,
          stack: 1,
          backgroundColor:  '#76a5af',
          borderColor:  '#76a5af',
        },
        {
          label: '7歳',
          data: this.props.datasets.sevenYears.datasets || [],
          fill: false,
          stack: 1,
          backgroundColor:  '#e06666',
          borderColor:  '#e06666',
        },
      ],
      dataLabels: {
        display: false
      },
      labels: this.props.labels || []
    };

    let options =  {
      maintainAspectRatio: false,
      spanGaps: false,
      plugins: {
        filler: {
          propagate: false
        },
        datalabels: {
          display: false
        }
      },
      type:'bar',
      scales: {
        xAxes: [{
          stacked: true
        }],
        yAxes: [{
          stacked: true
        }]
      }
    };

    return <Bar data={chartData} options={options} height={350} redraw generateLegend/>
  }

  pieChart () {
    let color = [
      'rgb(75, 192, 192)',
      'rgb(255, 99, 132)',
      'rgb(204, 204, 0)',
      '#8e7cc3',
      '#93c47d',
      'rgb(51, 153, 255)',
      'rgb(255, 153, 51)',
      '#76a5af',
      '#e06666'
    ]
    let options = {
      legend:{
        display: true,
        position: 'right',
        labels: {
          usePointStyle: true,
        }
      }
    }
    var data = []
    var labelData = []
    if (this.props.datasets) {
      let total = this.props.datasets.total
      data = [
        parseFloat((this.props.datasets.pregnantWithMama + this.props.datasets.pregnantWithGrand)*100/total).toFixed(2),
        parseFloat((this.props.datasets.zeroYearsWithMama + this.props.datasets.zeroYearsWithGrand)*100/total).toFixed(2),
        parseFloat((this.props.datasets.oneYearWithMama + this.props.datasets.oneYearWithGrand)*100/total).toFixed(2),
        parseFloat((this.props.datasets.twoYearsWithMama + this.props.datasets.twoYearsWithGrand)*100/total).toFixed(2),
        parseFloat((this.props.datasets.threeYearsWithMama + this.props.datasets.threeYearsWithGrand)*100/total).toFixed(2),
        parseFloat((this.props.datasets.fourYearsWithMama + this.props.datasets.fourYearsWithGrand)*100/total).toFixed(2),
        parseFloat((this.props.datasets.fiveYearsWithMama + this.props.datasets.fiveYearsWithGrand)*100/total).toFixed(2),
        parseFloat((this.props.datasets.sixYearsWithMama + this.props.datasets.sixYearsWithGrand)*100/total).toFixed(2),
        parseFloat((this.props.datasets.sevenYearsWithMama + this.props.datasets.sevenYearsWithGrand)*100/total).toFixed(2)
      ]

      labelData = [
        'マタニティ(' + (this.props.datasets.pregnantWithMama + this.props.datasets.pregnantWithGrand) + ')',
        '0歳代(' + (this.props.datasets.zeroYearsWithMama + this.props.datasets.zeroYearsWithGrand) + ')',
        '1歳代(' + (this.props.datasets.oneYearWithMama + this.props.datasets.oneYearWithGrand) + ')',
        '2歳代(' + (this.props.datasets.twoYearsWithMama + this.props.datasets.twoYearsWithGrand) + ')',
        '3歳代(' + (this.props.datasets.threeYearsWithMama + this.props.datasets.threeYearsWithGrand) + ')',
        '4歳代(' + (this.props.datasets.fourYearsWithMama + this.props.datasets.fourYearsWithGrand) + ')',
        '5歳代(' + (this.props.datasets.fiveYearsWithMama + this.props.datasets.fiveYearsWithGrand) + ')',
        '6歳代(' + (this.props.datasets.sixYearsWithMama + this.props.datasets.sixYearsWithGrand) + ')',
        '7歳(' + (this.props.datasets.sevenYearsWithMama + this.props.datasets.sevenYearsWithGrand) + ')'
      ]
    }
    let chartData = {
      datasets: [
        {
          backgroundColor: color,
          data: data,
          cutoutPercentage: -360
        }
      ],
      labels: labelData,
      dataLabels: {
        colors: ['#fff', '#ccc', '#000'], //  Array colors for each labels
        minRadius: 1, //  minimum radius for display labels (on pie charts)
      }
    }

    return <Pie data={chartData} options={options} height={200} redraw generateLegend/>
  }

  render() {
    return this.createChart()
  }
}

export default ChildrenAgeGroupReportChart
