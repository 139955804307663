import Dispatcher from "../../common/dispatcher/Dispatcher.jsx";
import FailedChangeStatusOrderConstants from "../constants/FailedChangeStatusOrderConstants.jsx";

export default {
    fetchOrders: () => {
        Dispatcher.dispatch({
            actionType: FailedChangeStatusOrderConstants.FETCH_ORDERS
        })
    },
    fetchedOrders: (ordersDTO, filter) => {
        Dispatcher.dispatch({
            actionType: FailedChangeStatusOrderConstants.FETCHED_ORDERS,
            ordersDTO: ordersDTO,
            filter: filter,
        })
    },
    changeFilter: (filter) => {
        Dispatcher.dispatch({
            actionType: FailedChangeStatusOrderConstants.CHANGE_FILTER,
            filter: {
                currentPage: filter.page,
            }
        })
    },
    checkOrder: (selectedOrder) => {
        Dispatcher.dispatch({
            actionType: FailedChangeStatusOrderConstants.CHECK_ORDER,
            selectedOrder: selectedOrder
        })
    },
    checkAllOrder: (orders) => {
        Dispatcher.dispatch({
            actionType: FailedChangeStatusOrderConstants.CHECK_ALL_ORDER,
            orders: orders
        })
    },
    startChangeStatusOrder: () => {
        Dispatcher.dispatch({
            actionType: FailedChangeStatusOrderConstants.START_CHANGE_ORDER_STATUS
        })
    }
}