import React from 'react'
import request from 'reqwest'
import when from 'when'
import Constant from "../../common/constants/Constants.jsx";
import LoginAction from "../../authentication/actions/LoginAction.jsx";
import FlyerCampaignConstant from "../constants/FlyerCampaignConstant.jsx";
import FlyerCampaignAction from "../actions/FlyerCampaignAction.jsx";

class FlyerCampaignService {
  getAllFlyerCampaign() {
    let url = FlyerCampaignConstant.API.GET_ALL_FLYER_CAMPAIGN;
    when(request({
      url: url,
      method: 'GET',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      }
    })).then(function (response) {
      switch (response.code) {
        case Constant.RESPONSE_STATUS.SUCCESS:
          FlyerCampaignAction.loadedAllFlyerCampaign(response.data ? response.data : []);
          break;
        case Constant.RESPONSE_STATUS.UNAUTHORIZED:
          LoginAction.loginUser({});
          break;
        case Constant.RESPONSE_STATUS.BAD_REQUEST:
          FlyerCampaignAction.badRequest(response.message);
          break;
        case Constant.RESPONSE_STATUS.SERVER_ERROR:
          FlyerCampaignAction.error(response.message);
          break;
        default:
          break;
      }
    })
  }

  getFlyerCampaign(flyerCampaign) {
    FlyerCampaignAction.loadedFlyerCampaign(flyerCampaign);
  }

  addFlyerCampaign(flyerCampaign) {
    let self = this;
    when(request({
      url: FlyerCampaignConstant.API.ADD_FLYER_CAMPAIGN,
      method: 'POST',
      crossOrigin: true,
      data: flyerCampaign,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      }
    })).then(function (response) {
      switch (response.code) {
        case Constant.RESPONSE_STATUS.SUCCESS:
          self.getAllFlyerCampaign()
          break;
        case Constant.RESPONSE_STATUS.UNAUTHORIZED:
          LoginAction.loginUser({});
          break;
        case Constant.RESPONSE_STATUS.BAD_REQUEST:
          FlyerCampaignAction.badRequest(response.message);
          break;
        case Constant.RESPONSE_STATUS.SERVER_ERROR:
          FlyerCampaignAction.error(response.message);
          break;
        default:
          break;
      }
    })
  }

  updateFlyerCampaign(flyerCampaignId, flyerCampaign) {
    let self = this;
    when(request({
      url: FlyerCampaignConstant.API.UPDATE_FLYER_CAMPAIGN,
      method: 'PUT',
      crossOrigin: true,
      data: flyerCampaign,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      }
    })).then(function (response) {
      switch (response.code) {
        case Constant.RESPONSE_STATUS.SUCCESS:
          self.getAllFlyerCampaign()
          break;
        case Constant.RESPONSE_STATUS.UNAUTHORIZED:
          LoginAction.loginUser({});
          break;
        case Constant.RESPONSE_STATUS.BAD_REQUEST:
          FlyerCampaignAction.badRequest(response.message);
          break;
        case Constant.RESPONSE_STATUS.SERVER_ERROR:
          FlyerCampaignAction.error(response.message);
          break;
        default:
          break;
      }
    })
  }

  deleteFlyerCampaign(id) {
    let self = this;
    when(request({
      url: FlyerCampaignConstant.API.DELETE_FLYER_CAMPAIGN + '/' + id,
      method: 'DELETE',
      crossOrigin: true,
      headers: {
        'Csrf-Token': 'nocheck',
        'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
      }
    })).then(function (response) {
      switch (response.code) {
        case Constant.RESPONSE_STATUS.SUCCESS:
          self.getAllFlyerCampaign()
          break;
        case Constant.RESPONSE_STATUS.UNAUTHORIZED:
          LoginAction.loginUser({});
          break;
        case Constant.RESPONSE_STATUS.BAD_REQUEST:
          FlyerCampaignAction.badRequest(response.message);
          break;
        case Constant.RESPONSE_STATUS.SERVER_ERROR:
          FlyerCampaignAction.error(response.message);
          break;
        default:
          break;
      }
    })
  }

}

export default new FlyerCampaignService();
