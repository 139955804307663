import React from 'react';
import OrderTableRowComponent from './OrderTableRowComponent.jsx';

export default class  OrderTableComponent extends React.Component {
    constructor() {
        super();
    }

    render(){
        return(
            <div className="tbl-admin tbl-template">
                <table className="table table-striped table-hover table-bordered">
                    <thead>
                    <tr>
                        <th>EC ID</th>
                        <th className="idColHd">IMとの連携オーダーID</th>
                        <th>氏名</th>
                        <th>注文日時</th>
                        <th>ステータス</th>
                        <th>領収書発行</th>
                        <th>受注後メール</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.orderRows}
                    </tbody>
                </table>
            </div>
        )
    }

    get orderRows() {
        var orders = this.props.orders;
        var loadDetailOrder = this.props.loadDetailOrder;
        if(orders.length > 0)
            return (orders.map(function(order){
                return <OrderTableRowComponent order={order} key={order.orderNumber} loadDetailOrder={loadDetailOrder}/>
            }));
        else
            return <tr>
                <td colSpan="8">{this.props.orderFilter.keyword ? <span>検索結果は0件です。</span> : <span>注文は0件です。</span> }</td>
            </tr>
    }
}