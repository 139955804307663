/**
 * Created by thangkc on 20/09/2016.
 */
import React from 'react';
import JapanPostalCodeComponent from '../../../common/components/JapanPostalCodeComponent.jsx';

export default class ReceiverComponent extends React.Component {
    constructor() {
        super();
        this.state = this._getState();
        this._onChange = this._onChange.bind(this);
    }

    _onChange() {
        this.setState(this._getState());
    }

    _getState() {
        return {};
    }

    receiverName(receiver) {
        if (!receiver.familyName && !receiver.firstName) return ""
        return `${receiver.familyName} ${receiver.firstName}`
    }

    receiverNameKana(receiver) {
        if (!receiver.familyNameKana && !receiver.firstNameKana) return ""
        return `${receiver.familyNameKana} ${receiver.firstNameKana}`
    }

    receiverAddress(receiver) {
        if (!receiver.prefecture && !receiver.city && !receiver.subAddress1 && !receiver.subAddress2) return ""
        return `${receiver.prefecture} ${receiver.city} ${receiver.subAddress1} ${receiver.subAddress2}`
    }

    render() {
        var receiver = this.props.receiver;
        return (
            <div className="col-md-6">
                <div className="clearfix">
                    <h4 className="title pull-left">お届け情報</h4>
                    {/*<button className="btn btn-raised btn-info pull-right" disabled={true}>編集</button>*/}
                </div>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th colSpan="2">お届け先情報</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th width="190px">氏名</th>
                            <td>{this.receiverName(receiver)}</td>
                        </tr>
                        <tr>
                            <th>氏名カナ</th>
                            <td>{this.receiverNameKana(receiver)}</td>
                        </tr>
                        <tr>
                            <th>郵便番号</th>
                            <td>
                                <JapanPostalCodeComponent postalCode={receiver.zipCode} />
                            </td>
                        </tr>
                        <tr>
                            <th>住所</th>
                            <td>{this.receiverAddress(receiver)}</td>
                        </tr>
                        <tr>
                            <th>電話番号</th>
                            <td>{receiver.phoneNumber ? receiver.phoneNumber : ""}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}