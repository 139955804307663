import React from "react";
import LoadingStore from "../../stores/LoadingStore.jsx";

class CustomLoading extends React.Component {
    constructor() {
        super();
        this.state = this._getState()
        this._onChange = this._onChange.bind(this)
    }

    _getState() {
        return {
            isLoading: LoadingStore.isLoading()
        }
    }

    _onChange() {
        this.setState(this._getState())
    }

    componentDidMount() {
        LoadingStore.addChangeListener(this._onChange)
    }

    componentWillUnmount() {
        LoadingStore.removeChangeListener(this._onChange)
    }

    render() {
        if (!this.state.isLoading) return null
        return (
            <div className="loading-overlay flex-center">
                <span className="loader"></span>
            </div>
        )
    }
}

export default CustomLoading