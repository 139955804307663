import Dispatcher from '../../common/dispatcher/Dispatcher.jsx'
import OrderConstant from '../constants/OrderConstant.jsx'

export default {
  loadedOrders: (ordersDTO, orderFilter) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.LOADED_ORDERS,
      ordersDTO: ordersDTO,
      orderFilter: orderFilter,
    })
  },
  detail: (order) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.DETAIL,
      order: order,
    })
  },
  invoiceLog: (log) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.INVOICE_LOG,
      log: log
    })
  },
  showOrderDetail: () => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.SHOW_ORDER_DETAIL,
    })
  },
  hideOrderDetail: () => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.HIDE_ORDER_DETAIL,
    })
  },
  changeKeyword: (keyword) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.ON_CHANGE_KEYWORD,
      keyword: keyword,
    })
  },
  changeStatus: (status, processMethod) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.ON_SEARCH_STATUS,
      status: status,
      processMethod: processMethod
    })
  },
  changePage: (currentPage) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.ON_SEARCH_PAGE,
      currentPage: currentPage,
    })
  },
  changeOrderStatus: (httpCode, message, data) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.ON_CHANGE_STATUS,
      order: data,
      message: message,
      httpCode: httpCode,
    })
  },
  changeEcOrderStatus: (httpCode, message, data) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.ON_CHANGE_EC_STATUS,
      orderForEc: data,
      messageForEc: message,
      httpCodeForEc: httpCode,
    })
  },
  rollbackChangeOrderStatus: (httpCode, message, errorCode) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.ON_ROLLBACK_CHANGE_STATUS,
      message: message,
      errorCode: errorCode,
      httpCode: httpCode,
    })
  },
  synchronizeOrder: (order) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.SYNCHRONIZE_ORDER_SUCCESSFULLY,
      order: order,
      httpCode: 200,
    })
  },
  rollbackOrderSynchronization: (httpCode) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.SYNCHRONIZE_ORDER_UNSUCCESSFULLY,
      httpCode: httpCode,
    })
  },
  createImageMagicOrder: (httpCode, errorCode, data) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.CREATE_IMAGEMAGIC_ORDER,
      httpCode: httpCode,
      errorCode: errorCode,
      data: data,
    })
  },
  verifyOrderPayment: (httpCode, paymentStatus) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.VERIFY_ORDER_PAYMENT,
      httpCode: httpCode,
      paymentStatus: paymentStatus,
    })
  },
  changeInvoiceCreatableSuccess: () => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.CHANGE_INVOICE_CREATABLE_SUCCESS,
    })
  },
  updateShipInfo: (httpCode, message, data) => {
    Dispatcher.dispatch({
      actionType: OrderConstant.ACTION_TYPE.ON_UPDATE_SHIP_INFO,
      order: data,
      message: message,
      httpCode: httpCode
    })
  },
}