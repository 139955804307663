/**
 * Created by
 */
import React from "react";
import request from "reqwest";
import when from "when";
import LoginAction from "../../authentication/actions/LoginAction.jsx";
import CollabDesignAction from "../actions/CollabDesignAction.jsx";
import CollabDesignConstant from "../constants/CollabDesignConstant.jsx";
import AlertAction from "../../common/components/actions/AlertAction.jsx";
import {AlertConstant} from "../../common/constants/AlertConstant.jsx";
import LoadingAction from "../../common/components/actions/LoadingAction.jsx";

class CollabDesignService {
    load(designFilter) {
        setTimeout(function () {LoadingAction.startLoading()}, 0)
        let url = CollabDesignConstant.API.URL + "?page=" + encodeURIComponent(designFilter.currentPage)
            + "&pageSize=" + encodeURIComponent(designFilter.pageSize);
        if (designFilter.status && designFilter.status !== CollabDesignConstant.STATUS.ALL)
            url += "&status=" + encodeURIComponent(designFilter.status);
        if (designFilter.keyword.trim())
            url += "&keyword=" + encodeURIComponent(designFilter.keyword.trim());

        when(request({
            url: url,
            method: 'GET',
            crossOrigin: true,
            headers: {
                'Csrf-Token': "nocheck",
                'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
            }
        })).then(function (response) {
            LoadingAction.stopLoading();
            switch (response.code) {
                case 200:
                    CollabDesignAction.load(response.data, designFilter);
                    break;
                case 401:
                    LoginAction.loginUser({});
                    break;
                default :
                    break;
            }
        })
    }
    updateApproveDesign(designId, status) {
        let url = CollabDesignConstant.API.URL + "?designId=" + encodeURIComponent(designId)
            + "&status=" + encodeURIComponent(status);

        when(request({
            url: url,
            method: 'PUT',
            crossOrigin: true,
            headers: {
                'Csrf-Token': "nocheck",
                'Authorization': localStorage.getItem('token') ? localStorage.getItem('token') : ''
            }
        })).then(function (response) {
            switch (response.code) {
                case 200:
                    AlertAction.alert(AlertConstant.SUCCESS, "更新に成功しました！")
                    break;
                case 401:
                    LoginAction.loginUser({});
                    break;
                default :
                    AlertAction.alert(AlertConstant.DANGER, "更新に失敗しました！")
                    break;
            }
        })
    }

}

export default new CollabDesignService()
